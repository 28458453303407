import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

const FieldLabel = ({ label, htmlFor, className, required, translate }) => {
    const t = useTranslate()

    return (
        <label
            htmlFor={htmlFor || undefined}
            className={`a-mediumText a-lightText ${className || ''}`}
        >
            {translate ? t(label) : label}
            {required && <span className="redStar"> *</span>}
        </label>
    )
}

FieldLabel.propTypes = {
    label: PropTypes.string,
    htmlFor: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    translate: PropTypes.bool,
}

FieldLabel.defaultProps = {
    translate: true,
}

export default FieldLabel
