import React from 'react'

const AirDivingSupervisorBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>Air Diving Supervisor</title>
            <path
                d="M5.41815 12.5045C5.41997 12.1735 5.59239 11.8663 5.87574 11.693L25.0001 0V50C18.7065 46.4789 11.5736 40.5634 8.21696 33.8028C5.2514 27.8299 5.34253 19.585 5.40402 14.0216C5.4099 13.49 5.4155 12.9828 5.41815 12.5045Z"
                fill="#A031E4"
            />
            <path
                d="M44.5822 12.5045C44.5803 12.1735 44.4079 11.8663 44.1246 11.693L25.0001 0V50C31.2938 46.4789 38.4267 40.5634 41.7833 33.8028C44.7489 27.8299 44.6578 19.585 44.5963 14.0216C44.5904 13.49 44.5848 12.9828 44.5822 12.5045Z"
                fill="#A031E4"
            />
            <g filter="url(#filter0_i_21877_20973)">
                <path
                    d="M8.48924 14.4269C8.49077 14.1468 8.63444 13.8869 8.87057 13.7403L24.8076 3.84619V46.1539C19.5628 43.1745 13.6188 38.1691 10.8216 32.4486C8.35029 27.3946 8.42623 20.4181 8.47747 15.7106C8.48237 15.2608 8.48704 14.8317 8.48924 14.4269Z"
                    fill="#F3F4F5"
                />
                <path
                    d="M41.1259 14.4269C41.1244 14.1468 40.9807 13.8869 40.7446 13.7403L24.8076 3.84619V46.1539C30.0523 43.1745 35.9964 38.1691 38.7936 32.4486C41.2649 27.3946 41.1889 20.4181 41.1377 15.7106C41.1328 15.2608 41.1281 14.8317 41.1259 14.4269Z"
                    fill="#F3F4F5"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9524 12.6923H25.9418V16.1111H23.9524V12.6923ZM21.5648 13.547H23.1563V15.2564H21.5648V13.547ZM24.748 16.9658C22.5506 16.9658 20.7692 18.7472 20.7692 20.9446V24.6581H28.7268V20.9446C28.7268 18.7472 26.9454 16.9658 24.748 16.9658ZM20.7692 28.0769V31.7904C20.7692 33.9879 22.5506 35.7692 24.748 35.7692C26.9454 35.7692 28.7268 33.9879 28.7268 31.7904V28.0769H20.7692ZM29.5222 13.547H27.1349V15.2564H29.5222V13.547ZM32.3075 12.6923H30.3181V16.1111H32.3075V12.6923Z"
                fill="#A031E4"
            />
            <defs>
                <filter
                    id="filter0_i_21877_20973"
                    x="8.46143"
                    y="3.84619"
                    width="33.077"
                    height="42.6924"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.384615" dy="0.384615" />
                    <feGaussianBlur stdDeviation="0.192308" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_20973"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default AirDivingSupervisorBadge
