import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import useQueryParams from 'hooks/useQueryParams'

import { formatDivingModeExperience } from 'utils/filterButtonFormatters'
import isFormEmpty from 'utils/emptyForm'

import COLORS from 'constants/colors'
import {
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    ICON_SIZE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Modal from 'components/Modal'
import {
    CheckboxField,
    InputField,
    MultiselectField,
    ArrayCustomGroupField,
    SelectGroupField,
    SelectField,
} from 'components/formFields'
import ENTITIES from 'constants/entities'
import Separator from 'components/Separator'
import DiversFilterFormikContext from './DiversFilterFormikContext'
import Loader from 'components/Loader'

const DiverFilterFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext

    const { experienceDivingModeItem } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (!firstRender.current) {
            resetField('experienceDivingModeItem.fromDives', '')
            resetField('experienceDivingModeItem.toDives', '')
            resetField('experienceDivingModeItem.fromDays', '')
            resetField('experienceDivingModeItem.toDays', '')
            resetField('experienceDivingModeItem.fromBellRuns', '')
            resetField('experienceDivingModeItem.toBellRuns', '')
        }
    }, [experienceDivingModeItem.divingMode])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

const DiverFilters = ({ activeFilters }) => {
    const t = useTranslate()

    const { filterParams, replaceFilterParams } = useQueryParams()

    const [open, setOpen] = useState(false)

    const emptyValues = {
        availability: false,
        previouslyEmployed: false,
        yearsOfExperience: { from: '', to: '' },
        experienceDivingModeItem: {
            divingMode: null,
            fromDives: '',
            toDives: '',
            fromDays: '',
            toDays: '',
            fromBellRuns: '',
            toBellRuns: '',
        },
        experienceDivingMode: [],
        country: [],
        region: [],
        role: [],
        skillCategory: [],
        skill: [],
        language: [],
        age: { from: '', to: '' },
    }

    const initialValues = {
        availability: Boolean(activeFilters.availability),
        previouslyEmployed: Boolean(activeFilters.previouslyEmployed),
        yearsOfExperience:
            activeFilters.yearsOfExperience ?? emptyValues.yearsOfExperience,
        experienceDivingModeItem: emptyValues.experienceDivingModeItem,
        experienceDivingMode:
            activeFilters.experienceDivingMode ??
            emptyValues.experienceDivingMode,
        country: activeFilters.country ?? emptyValues.country,
        region: activeFilters.region ?? emptyValues.region,
        role: activeFilters.role ?? emptyValues.role,
        skillCategory: activeFilters.skillCategory ?? emptyValues.skillCategory,
        skill: activeFilters.skill ?? emptyValues.skill,
        language: activeFilters.language ?? emptyValues.language,
        age: activeFilters.age ?? emptyValues.age,
    }

    const validation = Yup.object({
        experienceDivingModeItem: Yup.object().shape({
            fromDives: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toDives: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toDives', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromDives)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'experienceDivingModeItem.toDives',
                        })
                    }
                    return true
                }),
            fromDays: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toDays: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toDays', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromDays)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'experienceDivingModeItem.toDays',
                        })
                    }
                    return true
                }),
            fromBellRuns: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toBellRuns: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toBellRuns', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromBellRuns)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'experienceDivingModeItem.toBellRuns',
                        })
                    }
                    return true
                }),
        }),
        yearsOfExperience: Yup.object().shape({
            from: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            to: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('to', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.from)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'yearsOfExperience.to',
                        })
                    }
                    return true
                }),
        }),
        age: Yup.object().shape({
            from: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            to: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('to', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.from)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'age.to',
                        })
                    }
                    return true
                }),
        }),
    })

    const handleFilter = (
        { availability, previouslyEmployed, ...filters },
        { setSubmitting }
    ) => {
        setSubmitting(true)
        replaceFilterParams({
            ...filters,
            availability: availability ? 1 : null,
            previouslyEmployed: previouslyEmployed ? 1 : null,
            name: filterParams.name,
        })
        setSubmitting(false)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
    }

    const hasValue = (value) => value || value === 0

    const canAddSkill = (item, experienceDivingMode) =>
        item.divingMode &&
        !experienceDivingMode.some(
            (el) => el.divingMode.id === item.divingMode.id
        ) &&
        (hasValue(item.fromDives) ||
            hasValue(item.toDives) ||
            hasValue(item.fromDays) ||
            hasValue(item.toDays) ||
            hasValue(item.fromBellRuns) ||
            hasValue(item.toBellRuns) ||
            item.divingMode.id === 8)

    return (
        <div>
            <Button
                label="button.filters"
                onClick={() => setOpen(true)}
                icon={ICONS.FILTER}
                iconSize={ICON_SIZE.SIZE16}
                iconColor={COLORS.DARK_BLUE_60}
                btnClass={BUTTON_STATUS.QUATERNARY}
                iconPosition={ICON_POSITION.RIGHT}
            />
            {open && (
                <Modal
                    open={open}
                    setOpen={setOpen}
                    closeOnClickOutside={false}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleFilter}
                    >
                        {({ values, setFieldValue, isSubmitting }) => (
                            <Form>
                                <DiverFilterFormContext />
                                <DiversFilterFormikContext
                                    emptyValues={emptyValues}
                                />
                                <div className="_wr">
                                    <div className="_w">
                                        <h3 className="-mb20 _12 justify-center">
                                            {t('general.filterUsers')}
                                        </h3>
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.availability')}
                                        </span>
                                        <div className="_12 _m6 -mb15">
                                            <CheckboxField
                                                name="availability"
                                                label="form.label.showAvailableUsers"
                                                translate
                                            />
                                        </div>

                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t(
                                                'general.yearsOfExperienceInDivingIndustry'
                                            )}
                                        </span>
                                        <div className="_12 _m6 toInput">
                                            <InputField
                                                name="yearsOfExperience.from"
                                                label="form.label.from"
                                                placeholder="form.placeholder.durationFrom"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <div className="_12 _m6 toInput">
                                            <InputField
                                                name="yearsOfExperience.to"
                                                label="form.label.to"
                                                placeholder="form.placeholder.durationTo"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t(
                                                'general.experienceInSpecificDivingMode'
                                            )}
                                        </span>
                                        <div className="_12">
                                            <ArrayCustomGroupField
                                                name="experienceDivingMode"
                                                itemName="experienceDivingModeItem"
                                                itemInitialValue={
                                                    emptyValues.experienceDivingModeItem
                                                }
                                                canAddItem={canAddSkill}
                                                renderItem={
                                                    formatDivingModeExperience
                                                }
                                            >
                                                <SelectField
                                                    name="experienceDivingModeItem.divingMode"
                                                    label="form.label.divingMode"
                                                    placeholder="form.placeholder.divingMode"
                                                    entityType={
                                                        ENTITIES.DIVING_MODE
                                                    }
                                                    noneOption={false}
                                                />
                                                {[1, 2, 4, 5, 7].includes(
                                                    values
                                                        .experienceDivingModeItem
                                                        .divingMode?.id
                                                ) && (
                                                    <Fragment>
                                                        <InputField
                                                            name="experienceDivingModeItem.fromDives"
                                                            label="form.label.from"
                                                            placeholder="form.placeholder.fromDives"
                                                            type={
                                                                INPUT_FILED_TYPE.NUMBER
                                                            }
                                                            units="form.placeholder.dives"
                                                        />
                                                        <InputField
                                                            name="experienceDivingModeItem.toDives"
                                                            label="form.label.to"
                                                            placeholder="form.placeholder.toDives"
                                                            type={
                                                                INPUT_FILED_TYPE.NUMBER
                                                            }
                                                            units="form.placeholder.dives"
                                                        />
                                                    </Fragment>
                                                )}
                                                {values.experienceDivingModeItem
                                                    .divingMode?.id === 3 && (
                                                    <Fragment>
                                                        <InputField
                                                            name="experienceDivingModeItem.fromDays"
                                                            label="form.label.from"
                                                            placeholder="form.placeholder.fromDays"
                                                            type={
                                                                INPUT_FILED_TYPE.NUMBER
                                                            }
                                                            units="general.days"
                                                        />
                                                        <InputField
                                                            name="experienceDivingModeItem.toDays"
                                                            label="form.label.to"
                                                            placeholder="form.placeholder.toDays"
                                                            type={
                                                                INPUT_FILED_TYPE.NUMBER
                                                            }
                                                            units="general.days"
                                                        />
                                                    </Fragment>
                                                )}
                                                {[3, 6].includes(
                                                    values
                                                        .experienceDivingModeItem
                                                        .divingMode?.id
                                                ) && (
                                                    <Fragment>
                                                        <InputField
                                                            name="experienceDivingModeItem.fromBellRuns"
                                                            label="form.label.from"
                                                            placeholder="form.placeholder.fromDays"
                                                            type={
                                                                INPUT_FILED_TYPE.NUMBER
                                                            }
                                                            units="form.placeholder.bellRunsUnit"
                                                        />
                                                        <InputField
                                                            name="experienceDivingModeItem.toBellRuns"
                                                            label="form.label.to"
                                                            placeholder="form.placeholder.toDays"
                                                            type={
                                                                INPUT_FILED_TYPE.NUMBER
                                                            }
                                                            units="form.placeholder.bellRunsUnit"
                                                        />
                                                    </Fragment>
                                                )}
                                            </ArrayCustomGroupField>
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.location')}
                                        </span>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="country"
                                                label="form.label.country"
                                                placeholder="form.placeholder.selectCountry"
                                                entityType={ENTITIES.COUNTRY}
                                                searchable
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="region"
                                                label="form.label.region"
                                                placeholder="form.placeholder.selectRegion"
                                                entityType={ENTITIES.REGION}
                                                searchable
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.workInformation')}
                                        </span>
                                        <div className="_12 -mb20">
                                            <CheckboxField
                                                name="previouslyEmployed"
                                                label="form.label.previouslyEmployed"
                                                translate
                                            />
                                        </div>

                                        <div className="_12 _l6">
                                            <MultiselectField
                                                name="role"
                                                label="form.label.roles"
                                                placeholder="form.placeholder.selectRoles"
                                                entityType={
                                                    ENTITIES.DIVER_POSITION
                                                }
                                                searchable
                                            />
                                        </div>
                                        <div className="_wr">
                                            <div className="_w">
                                                <div className="_12 _m6">
                                                    <MultiselectField
                                                        name="skillCategory"
                                                        label="form.label.skillsCategory"
                                                        placeholder="form.placeholder.skillsCategory"
                                                        entityType={
                                                            ENTITIES.SKILL_CATEGORY
                                                        }
                                                        searchable
                                                    />
                                                </div>
                                                <div className="_12 _m6">
                                                    <MultiselectField
                                                        name="skill"
                                                        label="form.label.skills"
                                                        placeholder="form.placeholder.skills"
                                                        entityType={
                                                            ENTITIES.SKILL
                                                        }
                                                        params={
                                                            values.skillCategory
                                                                .length > 0
                                                                ? {
                                                                      'skillCategories.id':
                                                                          values.skillCategory.map(
                                                                              (
                                                                                  category
                                                                              ) =>
                                                                                  category.id
                                                                          ),
                                                                  }
                                                                : null
                                                        }
                                                        reload={
                                                            values.skillCategory
                                                                .length
                                                        }
                                                        disabled={
                                                            !values
                                                                .skillCategory
                                                                .length
                                                        }
                                                        searchable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.language')}
                                        </span>
                                        <div className="_12 oneLineSelectGroupSkill">
                                            <SelectGroupField
                                                name="language"
                                                fieldsName={[
                                                    'language',
                                                    'languageProficiency',
                                                ]}
                                                fieldsPlaceholder={[
                                                    'form.placeholder.selectLanguage',
                                                    'form.placeholder.selectProficiency',
                                                ]}
                                                fieldsEntityType={[
                                                    ENTITIES.LANGUAGE,
                                                    ENTITIES.LANGUAGE_PROFICIENCY,
                                                ]}
                                                fieldsRequired={[false, false]}
                                                fieldsShowLabel={[true, true]}
                                                required
                                                fieldsSearchable={[true, true]}
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.age')}
                                        </span>
                                        <div className="_12 _m6 toInput">
                                            <InputField
                                                name="age.from"
                                                label="form.label.from"
                                                placeholder="form.placeholder.durationFrom"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <div className="_12 _m6 toInput">
                                            <InputField
                                                name="age.to"
                                                label="form.label.to"
                                                placeholder="form.placeholder.durationTo"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>
                                    <div className="_w -buttons space-between -mt15">
                                        <div className="_12 _m4 -mt10 -prevButton">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.SECONDARY
                                                }
                                                label="button.cancel"
                                                onClick={() => setOpen(false)}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="_12 _m4 -mt10 -skipButton">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.TERTIARY
                                                }
                                                label="button.resetFilters"
                                                icon={ICONS.LINE}
                                                iconPosition={
                                                    ICON_POSITION.RIGHT
                                                }
                                                iconColor={COLORS.SECONDARY}
                                                onClick={() =>
                                                    handleReset(
                                                        values,
                                                        setFieldValue
                                                    )
                                                }
                                                disabled={
                                                    isSubmitting ||
                                                    isFormEmpty(values)
                                                }
                                            />
                                        </div>
                                        <div className="_12 _m4 -mt10 -nextButton">
                                            <Button
                                                btnClass={BUTTON_STATUS.PRIMARY}
                                                type={BUTTON_TYPE.SUBMIT}
                                                label="button.filter"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
        </div>
    )
}

DiverFilters.propTypes = {
    activeFilters: PropTypes.object,
}

export default DiverFilters
