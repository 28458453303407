import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
} from 'services/moment.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
} from 'constants/enums'

import Modal from 'components/Modal'
import {
    CheckboxField,
    DateTimeField,
    FileUploadField,
    InputField,
} from 'components/formFields'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import FocusError from 'components/FocusError'

const FormalEducationForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        schoolName: initialData?.schoolName ?? '',
        degree: initialData?.degree ?? '',
        fieldOfStudy: initialData?.fieldOfStudy ?? '',
        enrolled: initialData?.enrolled ?? '',
        completed: initialData?.completed ?? '',
        stillEnrolled: initialData?.stillEnrolled ?? false,
        files: initialData?.files ?? []
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        schoolName: Yup.string().trim().required(requiredMessage),
        degree: Yup.string().trim().required(requiredMessage),
        fieldOfStudy: Yup.string().trim().required(requiredMessage),
        enrolled: Yup.string()
            .nullable()
            .test('enrolled', (value, { createError }) => {
                console.log('value', value);
                if (!value) {
                    return true
                }

                if (!getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)) {
                    return createError({
                        message: t('form.error.enrolledDateInFuture'),
                        path: 'enrolled',
                    })
                }

                return true
            }),
        completed: Yup.string()
            .nullable()
            .test('completed', (value, { parent, createError }) => {
                if (parent.stillEnrolled === true) {
                    return true
                }

                if (!value) {
                    return createError({
                        message: requiredMessage,
                        path: 'completed',
                    })
                }

                if (!getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)) {
                    return createError({
                        message: t('form.error.completedDateInFuture'),
                        path: 'completed',
                    })
                }

                if (!parent.enrolled) {
                    return true
                }

                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.enrolled,
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return createError({
                        message: t('form.error.completedDateBeforeEnrolled'),
                        path: 'completed',
                    })
                }
                return true
            }),
        stillEnrolled: Yup.boolean().required(requiredMessage),
        files: Yup.array().when('stillEnrolled', {
            is: (val) => val !== true,
            then: () =>
                Yup.array()
                    .min(1, t('form.error.atLeastOneFormalEducation'))
                    .required(requiredMessage),
            otherwise: () => Yup.array().notRequired(),
        }),
    })

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await handleSubmit({
                ...formData,
                user: { id: userId, entityType: ENTITIES.USER },
            })

            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => setOpen(false)

    return (
        <Modal open={open} setOpen={setOpen} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, values, setFieldValue, setFieldTouched }) => {
                    return (
                        <Form className="-form">
                            <FocusError />
                            <div className="_wr">
                                <div className="_w">
                                    {title && (
                                        <h3 className="-mb20 _12">
                                            {t(title)}
                                        </h3>
                                    )}
                                    <div className="_12 _m6">
                                        <InputField
                                            name="schoolName"
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField name="degree" required />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField
                                            name="fieldOfStudy"
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <DateTimeField
                                            name="enrolled"
                                            icon={true}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <DateTimeField
                                            name="completed"
                                            icon={true}
                                            disabled={values.stillEnrolled === true}
                                            required={values.stillEnrolled !== true}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div className="_12 _m6 centered-checkbox">
                                        <CheckboxField
                                            name="stillEnrolled"
                                            label="form.label.stillEnrolled"
                                            translate
                                            onChange={(e) => {
                                                const newValue = e.target.checked
                                                setFieldValue(
                                                    'stillEnrolled',
                                                    newValue
                                                )

                                                if (newValue === true) {
                                                    setFieldValue(
                                                        'completed',
                                                        null
                                                    )
                                                    setFieldTouched('completed', false)
                                                }
                                            }}
                                        />
                                    </div>
                                    <Separator />
                                    <FileUploadField
                                        name="files"
                                        label="form.label.uploadDiploma"
                                        required={values.stillEnrolled !== true}
                                    />
                                </div>
                            </div>
                            <div className="_wr">
                                <div className="_w -buttons -pb20">
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.cancel"
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            onClick={handleClose}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            label="button.saveFormalEducation"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

FormalEducationForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
}

export default FormalEducationForm
