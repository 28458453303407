import React, { Fragment, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { DiveRecordContext } from './contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'

import useFetchDataById from 'hooks/useFetchDataById'

import StepButtons from 'components/StepButtons'
import DiveSummary from './components/diveSummary/DiveSummary'

const DiveSummaryVerification = () => {
    const { diveRecordId, verificationToken } = useParams()

    const { diveRecord, goPreviousStep, goToNextStep } = useContext(
        DiveRecordContext
    )

    const { data: diveSummaryData } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordDiveSummary',
                'diveRecordDiveSummary.mentalWellnessElements',
                'diveRecordDiveSummary.traumaInjuryLevel',
                'diveRecordDiveSummary.decompressionIllness',
                'diveRecordDiveSummary.pressureInjuries',
                'diveRecordDiveSummary.equipmentFailures',
                'diveRecordDiveSummary.closeCall',
                'diveRecordDiveSummary.files',
            ],
        }
    )

    const data = diveRecordId ? diveSummaryData : diveRecord.data

    if (!data) return null

    return (
        <Fragment>
            <div className="ofs_l2 _l8">
                <DiveSummary
                    data={data.diveRecordDiveSummary}
                    diveRecordId={diveRecordId ? data.id : data._id}
                    diveRecordType={data.diveRecordType}
                    fetchEntity={
                        verificationToken
                            ? `${ENTITIES.FILE}/${ENTITIES.DIVE_RECORD_FIRST_VERIFICATION_UPLOAD}/${verificationToken}?fileId=`
                            : ENTITIES.FILES_GET
                    }
                    verification
                />
            </div>
            <div className="_wr">
                <StepButtons
                    handleBack={goPreviousStep}
                    handleNext={goToNextStep}
                    canSkip={false}
                />
            </div>
        </Fragment>
    )
}

export default DiveSummaryVerification
