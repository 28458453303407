import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    MOMENT_FORMATS,
    formatDate,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
} from 'services/moment.service'

import useFetchData from 'hooks/useFetchData'

import { formatRequestData } from 'utils/jsonApiFormatters'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    FILE_UPLOAD_TYPE,
    TRAVEL_DOCUMENTATION_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'

import RadioGroupField from 'components/formFields/RadioGroupField'
import FileUploadField from 'components/formFields/FileUploadField'
import DateTimeField from 'components/formFields/DateTimeField'
import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import Modal from 'components/Modal'
import SelectField from 'components/formFields/SelectField'
import InfoList from 'components/InfoList'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import VisaForm from './VisaForm'
import RecordForm from './RecordForm'
import COLORS from 'constants/colors'
import FocusError from 'components/FocusError'

const { PASSPORT, SEAMANS_BOOK } = TRAVEL_DOCUMENTATION_TYPE

const TravelDocumentFormContext = ({
    initialValues,
    setVisas,
    setRecords,
    setReloadFiles,
    setShowVisaForm,
    setShowRecordForm,
}) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setTouched } = formikContext

    const { travelDocumentationType, ...formFields } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            Object.keys(formFields).forEach((key) => {
                setFieldValue(key, initialValues[key])
            })
            setTouched({})
            setVisas([])
            setRecords([])
            setReloadFiles((reload) => !reload)
            setShowVisaForm(false)
            setShowRecordForm(false)
        } else {
            firstRender.current = false
        }
    }, [travelDocumentationType])

    return null
}

const TravelDocumentForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const [reloadFiles, setReloadFiles] = useState(false)
    const [showVisaForm, setShowVisaForm] = useState(false)
    const [showRecordForm, setShowRecordForm] = useState(false)
    const [visas, setVisas] = useState(initialData?.visas ?? [])
    const [records, setRecords] = useState(
        initialData?.recordInformations ?? []
    )
    const [submitted, setSubmitted] = useState(false)

    const formRef = useRef()

    const { data: documentationTypes, isLoading } = useFetchData(
        ENTITIES.TRAVEL_DOCUMENTATION_TYPE,
        {},
        true,
        false
    )

    if (isLoading || !documentationTypes.length) return null

    const initialValues = {
        travelDocumentationType:
            initialData?.travelDocumentationType ?? documentationTypes[0],
        documentNumber: initialData?.documentNumber ?? '',
        dateOfExpiatory: initialData?.dateOfExpiatory ?? '',
        countryIssue: initialData?.countryIssue ?? null,
        files: initialData?.files ?? [],
        serviceBookId: initialData?.serviceBookId ?? '',
        dateOfIssuance: initialData?.dateOfIssuance ?? '',
        issuingAuthority: initialData?.issuingAuthority ?? '',
    }

    const requiredMessage = t('form.error.required')

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const validation = Yup.object({
        travelDocumentationType: Yup.object().required(requiredMessage),
        documentNumber: Yup.string().when('travelDocumentationType', {
            is: (val) => val.id !== SEAMANS_BOOK,
            then: () => Yup.string().trim().required(requiredMessage),

            otherwise: () => Yup.string(),
        }),
        dateOfExpiatory: Yup.date()
            .min(today, t('form.error.expirationDateInPast'))
            .test('dateOfExpiatory', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.dateOfIssuance,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.expirationAfterIssueDate'),
                        path: 'dateOfExpiatory',
                    })
                }
                return true
            })
            .required(requiredMessage),
        countryIssue: Yup.object().when('travelDocumentationType', {
            is: (val) => val.id !== SEAMANS_BOOK,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
        files: Yup.array()
            .min(1, t('form.error.atLeastOnePersonalDocument'))
            .required(requiredMessage),
        serviceBookId: Yup.string().when('travelDocumentationType', {
            is: (val) => val.id === SEAMANS_BOOK,
            then: () => Yup.string().trim().required(requiredMessage),

            otherwise: () => Yup.string(),
        }),
        dateOfIssuance: Yup.date().when('travelDocumentationType', {
            is: (val) => val.id === SEAMANS_BOOK,
            then: () =>
                Yup.date()
                    .test(
                        'dateOfIssuance',
                        (value, { parent, createError }) => {
                            if (
                                getIsDateSameOrBeforeCurrentDate(
                                    value,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return true
                            }
                            return createError({
                                message: t('form.error.dateOfIssuanceInFuture'),
                                path: 'dateOfIssuance',
                            })
                        }
                    )
                    .required(requiredMessage),
            otherwise: () => Yup.date(),
        }),
        issuingAuthority: Yup.string().when('travelDocumentationType', {
            is: (val) => val.id === SEAMANS_BOOK,
            then: () => Yup.string().trim().required(requiredMessage),
            otherwise: () => Yup.string(),
        }),
    })

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            setSubmitted(true)

            await handleSubmit({
                ...formData,
                user: { id: userId, entityType: ENTITIES.USER },
                visas: visas.map((item) =>
                    formatRequestData(ENTITIES.VISA, item)
                ),
                recordInformations: records.map((item) =>
                    formatRequestData(ENTITIES.RECORD_INFORMATION, item)
                ),
            })

            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
            setSubmitted(false)
        }
    }

    const handleClose = () => setOpen(false)

    const handleSubmitForm = () => formRef?.current?.handleSubmit()

    const handleShowVisaForm = () => setShowVisaForm(true)

    const handleShowRecordForm = () => setShowRecordForm(true)

    return (
        <Modal open={open} setOpen={setOpen} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
                innerRef={formRef}
            >
                {({ values: { travelDocumentationType }, isSubmitting }) => {
                    const isPassport = travelDocumentationType.id === PASSPORT
                    const isSeamansBook =
                        travelDocumentationType.id === SEAMANS_BOOK

                    return (
                        <Form>
                            <FocusError />
                            <TravelDocumentFormContext
                                initialValues={initialValues}
                                setVisas={setVisas}
                                setRecords={setRecords}
                                setReloadFiles={setReloadFiles}
                                setShowVisaForm={setShowVisaForm}
                                setShowRecordForm={setShowRecordForm}
                            />
                            <div className="_wr">
                                <div className="_w">
                                    {title && (
                                        <h3 className="-mb20 _12">
                                            {t(title)}
                                        </h3>
                                    )}
                                    <div className="_12">
                                        <RadioGroupField
                                            name="travelDocumentationType"
                                            label="form.label.travelDocumentationType"
                                            defaultOptions={documentationTypes}
                                            descriptionAttribute="note"
                                            disabled={!!initialData}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="_w">
                                    <Separator />
                                    {!isPassport && !isSeamansBook && (
                                        <h6 className="-mb20 _12">
                                            {t('general.nationalIdInformation')}
                                        </h6>
                                    )}
                                    {isPassport && (
                                        <h6 className="-mb20 _12">
                                            {t('general.passportInformation')}
                                        </h6>
                                    )}
                                    {isSeamansBook && (
                                        <h6 className="-mb20 _12">
                                            {t('general.seamanBookInformation')}
                                        </h6>
                                    )}
                                    {!isSeamansBook && (
                                        <div className="_12 _m6">
                                            <InputField
                                                name="documentNumber"
                                                label="form.label.documentNumber"
                                                placeholder="form.placeholder.documentNumber"
                                                required
                                            />
                                        </div>
                                    )}
                                    {isSeamansBook && (
                                        <Fragment>
                                            <div className="_12 _m6">
                                                <InputField
                                                    name="serviceBookId"
                                                    label="form.label.serviceBookId"
                                                    placeholder="form.placeholder.serviceBookId"
                                                    required
                                                />
                                            </div>
                                            <div className="_12 _m6 calendarMovedToRight">
                                                <DateTimeField
                                                    name="dateOfIssuance"
                                                    label="form.label.dateOfIssuance"
                                                    required
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                    <div className="_12 _m6 calendarMovedToRight">
                                        <DateTimeField
                                            name="dateOfExpiatory"
                                            label="form.label.dateOfExpiatory"
                                            required
                                        />
                                    </div>
                                    {!isSeamansBook && (
                                        <div className="_12 _m6 -z2">
                                            <SelectField
                                                name="countryIssue"
                                                label="form.label.countryIssue"
                                                placeholder="form.placeholder.countryIssue"
                                                entityType={ENTITIES.COUNTRY}
                                                searchable
                                                required
                                            />
                                        </div>
                                    )}
                                    {isSeamansBook && (
                                        <div className="_12 _m6">
                                            <InputField
                                                name="issuingAuthority"
                                                label="form.label.issuingAuthority"
                                                placeholder="form.placeholder.issuingAuthority"
                                                required
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="_w -z1">
                                    <Separator />
                                    <FileUploadField
                                        name="files"
                                        label="form.label.travelDocumentationFiles"
                                        type={FILE_UPLOAD_TYPE.FILE}
                                        reload={reloadFiles}
                                        required
                                    />
                                </div>
                                {isPassport && (
                                    <div className="_w">
                                        <div className="_12">
                                            <InfoList
                                                title="general.visasInformation"
                                                data={visas}
                                                setData={setVisas}
                                                confirmMessage="message.areYouSureDeleteVisaInfo"
                                                displayAttributes={[
                                                    { key: 'visaType' },
                                                    { key: 'country.name' },
                                                ]}
                                                displayValues={[
                                                    {
                                                        key: 'dateOfExpiatory',
                                                        label:
                                                            'general.expiring',
                                                        formatValue: formatDate,
                                                    },
                                                ]}
                                                separator
                                                threeColumns
                                            />
                                        </div>
                                    </div>
                                )}
                                {isSeamansBook && (
                                    <div className="_w">
                                        <div className="_12">
                                            <InfoList
                                                title="general.recordInformation"
                                                data={records}
                                                setData={setRecords}
                                                confirmMessage="message.areYouSureDeleteRecordInfo"
                                                displayAttributes={[
                                                    { key: 'nameOfShip' },
                                                    {
                                                        key: 'dateOfEngagement',
                                                        formatValue: formatDate,
                                                    },
                                                ]}
                                                displayValues={[
                                                    {
                                                        key: 'position',
                                                    },
                                                ]}
                                                separator
                                                threeColumns
                                            />
                                        </div>
                                    </div>
                                )}
                                {isPassport && !showVisaForm && (
                                    <div className="_w -mt30">
                                        <div className="_12 ofs_m4 _m4 justify-center">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.SECONDARY
                                                }
                                                type={BUTTON_TYPE.BUTTON}
                                                label="button.addVisa"
                                                icon={ICONS.PLUS}
                                                iconColor={COLORS.SECONDARY}
                                                onClick={handleShowVisaForm}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                )}
                                {isSeamansBook && !showRecordForm && (
                                    <div className="_w -mt30">
                                        <div className="_12 ofs_m4 _m4 justify-center">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.SECONDARY
                                                }
                                                type={BUTTON_TYPE.BUTTON}
                                                label="button.addRecordInformation"
                                                icon={ICONS.PLUS}
                                                iconColor={COLORS.SECONDARY}
                                                onClick={handleShowRecordForm}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                )}
                                {isSubmitting && <Loader />}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            {showVisaForm && (
                <VisaForm
                    setVisas={setVisas}
                    setShowVisaForm={setShowVisaForm}
                    visas={visas}
                />
            )}
            {showRecordForm && (
                <RecordForm
                    setRecords={setRecords}
                    setShowRecordForm={setShowRecordForm}
                    records={records}
                />
            )}
            <div className="_wr">
                <div className="_w -buttons -pb20">
                    <div className="_12 _xs6 -mt40">
                        <Button
                            btnClass={BUTTON_STATUS.SECONDARY}
                            type={BUTTON_TYPE.BUTTON}
                            label="button.cancel"
                            onClick={handleClose}
                            disabled={submitted}
                        />
                    </div>
                    <div className="_12 _xs6 -mt40">
                        <Button
                            btnClass={BUTTON_STATUS.PRIMARY}
                            type={BUTTON_TYPE.SUBMIT}
                            onClick={handleSubmitForm}
                            label="button.savePersonalDocument"
                            disabled={submitted}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

TravelDocumentForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
}

export default TravelDocumentForm
