import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    DIVE_RECORD_STATUS,
} from 'constants/enums'

import { DiveRecordContext } from '../contexts/DiveRecordContext'
import { AlertContext } from 'contexts/AlertContext'

import { persistEntityService } from 'services/entity.service'

import TextAreaField from 'components/formFields/TextAreaField'
import Modal from 'components/Modal'
import Button from 'components/Button'
import FocusError from '../../../../components/FocusError'
import Loader from 'components/Loader'

const RecordVerificationRejectModal = ({
    setOpen,
    setSubmitted,
    submitted,
}) => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const { setAlert } = useContext(AlertContext)
    const { goToProjectPage } = useContext(DiveRecordContext)

    const initialValues = {
        rejectComment: '',
    }

    const validation = Yup.object().shape({
        rejectComment: Yup.string().required(t('form.error.required')),
    })

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = async (formData) => {
        try {
            setSubmitted(true)
            await persistEntityService(
                ENTITIES.DIVE_RECORD,
                {
                    ...formData,
                    status: DIVE_RECORD_STATUS.REJECTED.id,
                },
                diveRecordId
            )
            setSubmitted(false)
            goToProjectPage()
            setAlert(t('message.diveRecordRejected'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Modal
            open={true}
            setOpen={setOpen}
            closeOnClickOutside={false}
            title="message.rejectDiveRecordTitle"
            type="-confirmModal -smallModal"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, values, isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <div className="_wr">
                            <div className="_w">
                                <span className="_12">
                                    {t('message.rejectDiveRecordDescription')}
                                </span>
                            </div>

                            <div className="_w -pt30">
                                <div className="_12">
                                    <TextAreaField
                                        name="rejectComment"
                                        label="form.label.comment"
                                        placeholder="form.placeholder.comment"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="_w -buttons -pt25">
                                <div className="_12 _xs6">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.cancel"
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        onClick={handleClose}
                                        disabled={isSubmitting || submitted}
                                    />
                                </div>

                                <div className="_12 _xs6">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        label="button.rejectDiveRecord"
                                        onClick={() => handleSubmit(values)}
                                        disabled={isSubmitting || submitted}
                                    />
                                </div>
                            </div>
                        </div>
                        {(isSubmitting || submitted) && <Loader />}
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default RecordVerificationRejectModal
