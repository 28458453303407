import React from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import ROUTES from 'constants/routes'
import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'

import Button from 'components/Button'

const NotFoundPage = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    return (
        <div className="m-404">
            <div className="m-404__logo"></div>
            <div className="m-404__404"></div>
            <div className="m-404__textBlock">
                <h1 className="-mt50">{t('general.errorTitle')}</h1>
                <Button
                    btnClass={BUTTON_STATUS.PRIMARY}
                    type={BUTTON_TYPE.BUTTON}
                    label="button.goBack"
                    onClick={() => navigate(`${ROUTES.HOME}`)}
                />
            </div>
        </div>
    )
}

export default NotFoundPage
