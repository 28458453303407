import { useLocation, useNavigate } from 'react-router-dom'

import { formatUrl } from 'utils/jsonApiFormatters'

const useQueryParams = () => {
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    const searchParams = search.slice(1)

    const paramsArray = searchParams ? searchParams.split('&') : []
    const queryParams = paramsArray.reduce((acc, item) => {
        if (item.includes('.id[]=')) {
            const [key, value] = item.split('.id[]=')
            if (acc[key]) {
                acc[key].push({ id: value })
            } else {
                acc[key] = [{ id: value }]
            }
        } else if (item.includes('[]=')) {
            const [key, value] = item.split('[]=')
            if (acc[key]) {
                acc[key].push(value)
            } else {
                acc[key] = [value]
            }
        } else if (item.includes('][')) {
            const [keyValue, itemValue] = item.split('][')
            const [key, index] = keyValue.split('[')
            const [itemKey, value] = itemValue.split(']=')
            if (acc[key]) {
                if (acc[key][index - 1]) {
                    acc[key][index - 1] = {
                        ...acc[key][index - 1],
                        [itemKey]: value,
                    }
                } else {
                    acc[key].push({ [itemKey]: value })
                }
            } else {
                acc[key] = [{ [itemKey]: value }]
            }
        } else if (item.includes('[') && item.includes(']')) {
            const [key, itemValue] = item.split('[')
            const [itemKey, value] = itemValue.split(']=')
            acc[key] = { ...acc[key], [itemKey]: value }
        } else if (item.includes('.id=')) {
            const [key, value] = item.split('.id=')
            acc[key] = { id: value }
        } else {
            const [key, value] = item.split('=')
            acc[key] = decodeURIComponent(value)
        }

        return acc
    }, {})

    const { page, itemsPerPage, sort, tab, subTab, ...filterParams } =
        queryParams

    const updateQueryParams = (newQueryParams) => {
        const url = formatUrl(pathname, {
            ...queryParams,
            ...newQueryParams,
        })
        navigate(url)
    }

    const replaceQueryParams = (newQueryParams) => {
        const url = formatUrl(pathname, newQueryParams)
        navigate(url)
    }

    const addFilterParams = (newFilterParams) => {
        const url = formatUrl(pathname, {
            itemsPerPage,
            sort,
            tab,
            subTab,
            ...filterParams,
            ...newFilterParams,
        })
        navigate(url)
    }

    const replaceFilterParams = (newFilterParams) => {
        const url = formatUrl(pathname, {
            itemsPerPage,
            sort,
            tab,
            subTab,
            ...newFilterParams,
        })
        navigate(url)
    }

    return {
        page,
        itemsPerPage,
        sort,
        tab,
        subTab,
        filterParams,
        updateQueryParams,
        replaceQueryParams,
        addFilterParams,
        replaceFilterParams,
    }
}

export default useQueryParams
