import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { createEntityService, editEntityService } from 'services/entity.service'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import MultiselectField from 'components/formFields/MultiselectField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const ServicesInfo = ({ personData, companyData }) => {
    const navigate = useNavigate()

    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { userId, currentUser, fetchCurrentUser, fetchCompanyData } =
        useContext(CurrentUserContext)

    const initialValues = {
        services: companyData.services ?? [],
        regions: companyData.regions ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        services: Yup.array().min(1, requiredMessage).required(requiredMessage),
        regions: Yup.array().min(1, requiredMessage).required(requiredMessage),
    })

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const { data } = await editEntityService(ENTITIES.USER, userId, {
                ...personData,
                finishedSignUp: true,
            })
            await createEntityService(ENTITIES.COMPANY, {
                ...companyData,
                ...formData,
                email: currentUser.email,
                user: data,
            })
            await fetchCurrentUser()
            await fetchCompanyData()
            navigate(ROUTES.HOME)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div className="m-form -login">
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ values, isSubmitting, ...formProps }) => (
                    <Form>
                        <FocusError />
                        <div className="_w">
                            <div className="_l12">
                                <h1 className="-title -mb40">
                                    {t('general.services')}
                                </h1>
                                <MultiselectField
                                    name="services"
                                    label="form.label.familyOfServices"
                                    entityType={ENTITIES.SERVICE}
                                    valueType={SELECT_VALUE_TYPE.OBJECT}
                                    searchable
                                    createNew
                                    required
                                />
                                <MultiselectField
                                    name="regions"
                                    label="form.label.locationsOfServices"
                                    placeholder="form.placeholder.locationsOfServices"
                                    entityType={ENTITIES.REGION}
                                    searchable
                                    required
                                />
                                <div className="-mt30">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        label="button.finish"
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <span
                                    className={`a-blueText a-bodyTextRegular -skipLink -mt30 ${
                                        isSubmitting ? '-disabled' : ''
                                    }`}
                                    onClick={() => {
                                        if (!isSubmitting) {
                                            handleSubmit(values, formProps)
                                        }
                                    }}
                                >
                                    {t('button.skipAndFinishLater')}
                                </span>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

ServicesInfo.propTypes = {
    personData: PropTypes.object.isRequired,
    companyData: PropTypes.object.isRequired,
}

export default ServicesInfo
