import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import handleLogout from 'utils/logoutHelper'

import ROUTES from 'constants/routes'
import { BUTTON_SIZE, BUTTON_STATUS, BUTTON_TYPE, ROLES } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import { RadioGroupField } from 'components/formFields'
import Button from 'components/Button'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

export const SIGN_UP_AS = [
    {
        ...ROLES.ROLE_DIVER,
        title: 'general.diveIndustryProfessional',
        description: 'general.diveIndustryProfessionalDescription',
    },
    {
        ...ROLES.ROLE_DIVING_CONTRACTOR,
        title: 'general.divingOrganization',
        description: 'general.divingOrganizationDescription',
    },
]

const SignUpAs = ({ userData, setUserData, setShowOnboarding }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setCurrentUser, setCurrentCompany } = useContext(CurrentUserContext)

    const initialValues = {
        role: userData.role || ROLES.ROLE_DIVER,
    }

    const validation = Yup.object({})

    const handleSubmit = ({ role }) => {
        delete role['title']
        delete role['description']
        setUserData((data) => ({ ...data, role }))
        setShowOnboarding(true)
    }

    const handleGoBack = () => {
        navigate(ROUTES.LOGIN)
        handleLogout(setCurrentUser, setCurrentCompany)
    }

    return (
        <div className="-verification">
            <div className="-backBtn">
                <Button
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.SMALL}
                    icon={ICONS.ARROW_LEFT}
                    iconColor={COLORS.SECONDARY}
                    onClick={handleGoBack}
                    label="button.back"
                />
            </div>
            <h1 className="-title -mb40">{t('noAuth.selectRoleForSignUp')}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <RadioGroupField
                            name="role"
                            label="form.label.signUpRole"
                            defaultOptions={SIGN_UP_AS.map((item) => ({
                                ...item,
                                title: t(item.title),
                                description: t(item.description),
                            }))}
                            displayAttribute="title"
                            descriptionAttribute="description"
                            column={true}
                            showLabel={false}
                            required
                        />
                        <div className="-mt30">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.next"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

SignUpAs.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
    setShowOnboarding: PropTypes.func.isRequired,
}

export default SignUpAs
