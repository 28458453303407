import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
} from 'services/moment.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    FILE_UPLOAD_TYPE,
    ICON_SIZE,
} from 'constants/enums'

import Button from 'components/Button'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    CheckboxField,
    DateTimeField,
    FileUploadField,
    InputField,
    SelectField,
    TextAreaField,
} from 'components/formFields'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const PastEmploymentForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const requiredMessage = t('form.error.required')

    const initialValues = {
        employer: initialData?.employer ?? null,
        location: initialData?.location ?? '',
        contactPerson: initialData?.contactPerson ?? '',
        contactEmail: initialData?.contactEmail ?? '',
        contactPhone: initialData?.contactPhone ?? '',
        pastEmploymentPosition: initialData?.pastEmploymentPosition ?? null,
        industry: initialData?.industry ?? null,
        startedDate: initialData?.startedDate ?? '',
        finishedDate: initialData?.finishedDate ?? null,
        stillEmployed: initialData?.stillEmployed ?? false,
        jobDuties: initialData?.jobDuties ?? '',
        files: initialData?.files ?? [],
    }

    const validation = Yup.object({
        employer: Yup.object().required(requiredMessage),
        location: Yup.string().trim().required(requiredMessage),
        contactPerson: Yup.string().trim().required(requiredMessage),
        contactEmail: Yup.string()
            .trim()
            .email(t('form.error.invalidEmail'))
            .required(requiredMessage),
        contactPhone: Yup.number()
            .typeError(t('form.error.invalidPhone'))
            .required(requiredMessage),
        pastEmploymentPosition: Yup.object().required(requiredMessage),
        industry: Yup.object().required(requiredMessage),
        startedDate: Yup.date()
            .test('startedDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.startedDate'),
                    path: 'startedDate',
                })
            })
            .required(requiredMessage),
        finishedDate: Yup.string().when('stillEmployed', {
            is: (val) => val === true,
            then: () => Yup.date().notRequired(),
            otherwise: () =>
                Yup.date()
                    .test('finishedDate', (value, { parent, createError }) => {
                        if (
                            getIsDateSameOrBefore(
                                value,
                                parent.startedDate,
                                MOMENT_FORMATS.DATE
                            ) ||
                            !getIsDateSameOrBeforeCurrentDate(
                                value,
                                MOMENT_FORMATS.DATE
                            )
                        ) {
                            return createError({
                                message: t('form.error.invalidDate'),
                                path: 'finishedDate',
                            })
                        }
                        return true
                    })
                    .required(requiredMessage),
        }),
        jobDuties: Yup.string()
            .max(500, t('form.error.maxCharsLimit'))
            .trim()
            .required(requiredMessage),
        stillEmployed: Yup.boolean().required(requiredMessage),
        files: Yup.array(),
    })

    const onSubmit = async ({ ...formData }) => {
        try {
            await handleSubmit({
                ...formData,
                user: { id: userId, entityType: ENTITIES.USER },
            })
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Modal open={open} setOpen={setOpen} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({
                    values: { stillEmployed },
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <Form>
                        <FocusError />
                        <div className="_wr">
                            <div className="_w">
                                {title && (
                                    <h3 className="-mb20 _12">{t(title)}</h3>
                                )}
                            </div>
                            <div className="_w">
                                {/* NOTE:: test after BE implementation of create new */}
                                <div className="_12 _m6">
                                    <SelectField
                                        name="employer"
                                        label="form.label.employer"
                                        entityType={ENTITIES.EMPLOYER}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="location"
                                        label="form.label.location"
                                        placeholder="form.placeholder.location"
                                        icon={ICONS.LOCATION}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactPerson"
                                        label="form.label.contactPerson"
                                        placeholder="form.placeholder.contactPerson"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactEmail"
                                        label="form.label.contactEmail"
                                        placeholder="form.placeholder.contactEmail"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactPhone"
                                        label="form.label.contactPhone"
                                        placeholder="form.placeholder.contactPhone"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                {/* NOTE:: test after BE implementation of create new */}
                                <div className="_12 _m6">
                                    <SelectField
                                        name="pastEmploymentPosition"
                                        placeholder="form.placeholder.diverPosition"
                                        label="form.label.position"
                                        entityType={
                                            ENTITIES.PAST_EMPLOYMENT_POSITION
                                        }
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="industry"
                                        label="form.label.industry"
                                        entityType={ENTITIES.INDUSTRY}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                            </div>

                            <div className="_w -mt15">
                                <div className="_12 _m4">
                                    <DateTimeField
                                        name="startedDate"
                                        label="form.label.startedDate"
                                        required
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <DateTimeField
                                        name="finishedDate"
                                        label="form.label.finishedDate"
                                        required
                                        disabled={stillEmployed === true}
                                    />
                                </div>
                                <div className="_12 _m4 centered-checkbox">
                                    <CheckboxField
                                        name="stillEmployed"
                                        label="form.label.stillEmployed"
                                        placeholder="form.label.stillEmployed"
                                        translate
                                        onChange={(e) => {
                                            const newValue = e.target.checked
                                            setFieldValue(
                                                'stillEmployed',
                                                newValue
                                            )
                                            if (newValue === true) {
                                                setFieldValue(
                                                    'finishedDate',
                                                    null
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="jobDuties"
                                        label="form.label.jobDuties"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="_w">
                                <Separator />
                                <FileUploadField
                                    name="files"
                                    label="form.label.pastEmploymentUploadFiles"
                                    type={FILE_UPLOAD_TYPE.FILE}
                                />
                            </div>
                            <div className="_w -buttons -pb20">
                                <div className="_12 _xs6 -mt40">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.cancel"
                                        onClick={handleClose}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="_12 _xs6 -mt40">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        label="button.savePastEmployment"
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default PastEmploymentForm
