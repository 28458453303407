import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import { BASE_URL } from 'utils/axiosClient'

import { BANNER_TYPE } from 'constants/enums'

const Banner = ({ type, image, name, welcomeName }) => {
    const t = useTranslate()

    const isHomepageBanner = type === BANNER_TYPE.HOMEPAGE
    // const isProjectBanner = type === BANNER_TYPE.PROJECT

    return (
        <div
            className={`a-banner ${
                isHomepageBanner ? '-homepage' : '-project'
            } `}
        >
            <div className="a-banner__image">
                {image && (
                    <img
                        className="-image"
                        src={`${BASE_URL}${image}`}
                        alt="banner-image"
                    />
                )}
                <img
                    className="-placeholder"
                    src={require('assets/images/banner-placeholder.png')}
                    alt="banner-image-placeholder"
                />
            </div>
            <div className="a-banner__text">
                {isHomepageBanner ? (
                    <Fragment>
                        <h1
                            className="-title"
                            title={t('general.welcome') + ' ' + welcomeName}
                        >
                            {t('general.welcome')}, {welcomeName || ''}
                        </h1>
                        <h2 className="-name -mt10"> {name} </h2>
                    </Fragment>
                ) : (
                    <Fragment>
                        <span className="a-bodyTextRegular -subtitle -mb10">
                            {t('general.project')}:
                        </span>
                        <h1 className="-title -project">{name}</h1>
                    </Fragment>
                )}
            </div>
            {/* NOTE: hidden for now  */}
            {/* {isProjectBanner && (
                <div className="a-banner__share">
                    <div className="-iconWrapper">
                        <Icon
                            name={ICONS.SHARE}
                            size={ICON_SIZE.SIZE20}
                            color={COLORS.PRIMARY}
                        />
                    </div>
                </div>
            )} */}
        </div>
    )
}

Banner.propTypes = {
    type: PropTypes.oneOf([BANNER_TYPE.HOMEPAGE, BANNER_TYPE.PROJECT]),
    image: PropTypes.string,
    name: PropTypes.string,
    welcomeName: PropTypes.string,
}

Banner.defaultProps = {
    type: BANNER_TYPE.HOMEPAGE,
}

export default Banner
