import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'

import Modal from 'components/Modal'
import Button from 'components/Button'
import { InputField } from 'components/formFields'

const EditTitleModal = ({ setOpen, formRef }) => {
    const t = useTranslate()

    const {
        current: { values, setFieldValue },
    } = formRef

    const initialValues = {
        title: values.currentTitle,
    }

    const validation = Yup.object({
        title: Yup.string().trim().required(t('form.error.required')),
    })

    const handleSubmit = ({ title }) => {
        setFieldValue('currentTitle', title)
        setOpen(false)
    }

    return (
        <Modal
            setOpen={setOpen}
            closeOnClickOutside={false}
            type="-confirmModal -smallModal"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="-modalElements">
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <h3 className="-mb20 _12">
                                    {t('general.editTitle')}
                                </h3>
                            </div>
                            <div className=" _w">
                                <div className="_12">
                                    <InputField name="title" required />
                                </div>
                            </div>
                            <div className="-mt10 _w -buttons">
                                <div className="_12 _xs6 -mt10">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        label="button.cancel"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    />
                                </div>
                                <div className="_12 _xs6 -mt10">
                                    <Button
                                        type={BUTTON_TYPE.SUBMIT}
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        label="button.saveChanges"
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

EditTitleModal.propTypes = {
    setOpen: PropTypes.func,
    formRef: PropTypes.object.isRequired,
}

export default EditTitleModal
