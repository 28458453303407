import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'

import Button from 'components/Button'
import Icon from './Icon'

const ConfirmationModal = () => {
    const t = useTranslate()

    const {
        confirmationModal: {
            message,
            customText,
            title,
            icon,
            iconColor,
            iconSize,
            confirmLabel = 'button.confirm',
            cancelLabel = 'button.cancel',
            handleConfirm,
            handleCancel,
            translateMessage = true,
            hideCancel = false,
        },
    } = useContext(ConfirmationModalContext)

    if (!message && !customText) return null

    return (
        <div className="m-modal -show -confirmModal">
            <div className="wrapper">
                <div className="m-modal__content">
                    <div className="-insideContent">
                        {handleCancel && (
                            <span
                                onClick={handleCancel}
                                className="removeItemIcon"
                            ></span>
                        )}
                        <div className="_wr -modalElements">
                            <div className="_w">
                                {icon && (
                                    <div className="_12">
                                        <div className="justify-center -mb20">
                                            <Icon
                                                name={icon}
                                                iconColor={iconColor}
                                                iconSize={iconSize}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="_12">
                                    <div
                                        className={
                                            icon
                                                ? 'justify-center'
                                                : 'm-modal__content--text '
                                        }
                                    >
                                        {title && (
                                            <h3 className="-headerTitle">
                                                {' '}
                                                {t(title)}
                                            </h3>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_12">
                                    <div className="m-modal__content--text">
                                        {message && (
                                            <span className="-mt20 a-bodyTextRegular">
                                                {translateMessage
                                                    ? t(message)
                                                    : message}
                                            </span>
                                        )}
                                        {customText}
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                {!hideCancel && (
                                    <div className="_12 -mt30">
                                        <Button
                                            label={cancelLabel}
                                            onClick={handleCancel}
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            buttonSize={BUTTON_SIZE.LARGE}
                                        />
                                    </div>
                                )}
                                <div className="_12 -mt20">
                                    <Button
                                        label={confirmLabel}
                                        onClick={handleConfirm}
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        buttonSize={BUTTON_SIZE.LARGE}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal
