import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

const Modal = ({
    open,
    setOpen,
    closeOnClickOutside,
    type,
    children,
    title,
}) => {
    const t = useTranslate()
    const hocRef = useRef(null)
    const { isTablet } = useWindowDimensions()

    useEffect(() => {
        if (closeOnClickOutside) {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }
    }, [])

    const handleClickOutside = (event) => {
        if (
            hocRef?.current &&
            !hocRef.current.contains(event.target) &&
            setOpen
        ) {
            setOpen(false)
        }
    }

    const closeModal = () => {
        if (setOpen) {
            setOpen(false)
        }
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
            return () => {
                document.body.style.overflow = 'unset'
            }
        }
    }, [open])

    useEffect(() => {
        if (!isTablet) {
            const handleScrollToFirstBottom = () => {
                const { scrollTop } = hocRef.current
                const distanceFromBottom = 155

                if (scrollTop < distanceFromBottom) {
                    hocRef.current.scrollTop = distanceFromBottom
                }
            }

            const container = hocRef.current
            handleScrollToFirstBottom()

            container.addEventListener('scroll', handleScrollToFirstBottom)

            return () => {
                container.removeEventListener(
                    'scroll',
                    handleScrollToFirstBottom
                )
            }
        }
    }, [])

    return (
        <div className={`m-modal ${open ? '-show' : ''} ${type || ''}`}>
            <div className="wrapper">
                <div className="m-modal__content" ref={hocRef}>
                    <div className="-insideContent">
                        {setOpen && (
                            <span
                                onClick={closeModal}
                                className="removeItemIcon"
                            ></span>
                        )}
                        <div className="m-modal__body _12">
                            {title && <h5 className="-title">{t(title)}</h5>}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    setOpen: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    type: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    title: PropTypes.string,
}

Modal.defaultProps = {
    open: true,
    closeOnClickOutside: true,
}

export default Modal
