import React from 'react'
import PropTypes from 'prop-types'

const InfoRow = ({ children, withButtons, withColumns }) => {
    return (
        <div
            className={`-infoRow ${withButtons ? '-withButtonsWrapper' : ''} ${
                withColumns ? '-withColumns' : ''
            }`}
        >
            {children}
        </div>
    )
}

InfoRow.propTypes = {
    withButtons: PropTypes.bool,
    withColumns: PropTypes.bool,
}

InfoRow.defaultProps = {
    withButtons: false,
    withColumns: false,
}

export default InfoRow
