import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
} from 'constants/enums'

import Loader from 'components/Loader'
import Button from 'components/Button'
import Modal from 'components/Modal'
import SelectField from 'components/formFields/SelectField'
import * as Yup from 'yup'
import axios, { POST } from 'utils/axiosClient'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

const DiveTeamStatusUpdate = ({
    projectPositionId,
    updateListFunc,
    disabled = false,
    diveInviteStatuses,
}) => {
    const t = useTranslate()

    const [open, setOpen] = useState(false)

    const initialValues = {
        status: null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        status: Yup.object().required(requiredMessage),
    })

    const handleSubmit = async (formData) => {
        await axios(POST, '/diver_invite/bulk_update_status', {
            projectPositionId,
            statusId: formData.status.id,
        })

        updateListFunc()
        setOpen(false)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    if (!diveInviteStatuses) return null

    return (
        <div>
            <Button
                icon={ICONS.SETUP}
                iconSize={ICON_SIZE.SIZE20}
                iconColor={COLORS.SECONDARY}
                onClick={() => setOpen(true)}
                btnClass={BUTTON_STATUS.SECONDARY}
                additionalClasses={`update-all-statuses + ${
                    disabled ? ' -secondaryDisabled' : ''
                }`}
                buttonSize={BUTTON_SIZE.LARGE}
                tooltip={'general.changeStatusForAllCandidates'}
                disabled={disabled}
            ></Button>

            {open && (
                <Modal
                    open={open}
                    setOpen={setOpen}
                    title="general.updateStatusForAllCandidates"
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, values }) => (
                            <Form className="-modalElements">
                                <div className="_wr -modalElements">
                                    <div className="_w">
                                        <div className="-mt20 -mb30 _12">
                                            {t(
                                                'general.diverInviteStatusChange'
                                            )}
                                        </div>

                                        <div className="_12">
                                            <SelectField
                                                name="status"
                                                label="form.label.diverInviteStatus"
                                                placeholder="form.placeholder.diverInviteStatus"
                                                defaultOptions={
                                                    diveInviteStatuses
                                                }
                                                searchable
                                                required
                                            />
                                        </div>
                                    </div>
                                    {isSubmitting && <Loader />}
                                </div>
                                <div className="_w -buttons -pb20">
                                    <div className="_12 _xs6 -mt60">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.cancel"
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            onClick={handleCancel}
                                        />
                                    </div>
                                    <div className="_12 _xs6 -mt60">
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            label="button.continue"
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
        </div>
    )
}

DiveTeamStatusUpdate.propTypes = {
    projectPositionId: PropTypes.number,
    updateListFunc: PropTypes.func,
    diveInviteStatuses: PropTypes.array,
}

export default DiveTeamStatusUpdate
