const COLORS = {
    PRIMARY: '#171e3c',
    SECONDARY: '#4a4fff',
    DARK_BLUE_15: '#dcdde2',
    DARK_BLUE_20: '#d1d2d8',
    DARK_BLUE_40: '#a2a5b1',
    DARK_BLUE_60: '#74788a',
    DARK_BLUE_80: '#454b63',
    WHITE: '#ffffff',
    RED: '#ff4a4a',
    GRAY: '#454b63',
    SECONDARY_60: '#9295ff',
    SECONDARY_05: '#f3f4f5',
    ORANGE_60: '#fbb666',
    RED_60: '#ff9292',
    GREEN_60: '#66d685',
    GREEN: '#00ba34',
    PURPLE_60: '#c684ef',
    TRANSPARENT: 'transparent',
}
export default COLORS
