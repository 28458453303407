export const NO_SKILLS_EXPERIENCE_TEXT = {
    NO_DATA: 'general.noExperienceAndSkills',
    NO_EXPERIENCE: 'general.noExperience',
    NO_SKILLS: 'general.noSkills',
}

export const renderNoDataText = (data, isLoading, text) => {
    return (
        data?.length === 0 &&
        !isLoading && (
            <span className="a-captionsTextRegular a-lightText justify-center -mt20">
                {text}
            </span>
        )
    )
}

export const getNoSkillsAndExperienceText = (skills, experience) => {
    if (skills.length === 0 && experience.length === 0) {
        return NO_SKILLS_EXPERIENCE_TEXT.NO_DATA
    } else if (skills.length > 0 && experience.length === 0) {
        return NO_SKILLS_EXPERIENCE_TEXT.NO_EXPERIENCE
    } else if (skills.length === 0 && experience.length > 0) {
        return NO_SKILLS_EXPERIENCE_TEXT.NO_SKILLS
    }
}
