import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const ChatUserSearch = ({
    user,
    unreadCount = 0,
    activeChannel,
    channel,
    handleClick,
}) => {
    return (
        <li
            key={user.id}
            className={`-result chat-users-search-result a-mediumText ${
                !!activeChannel && !!channel && activeChannel === channel
                    ? '-active'
                    : ''
            }`}
            onClick={() => handleClick(user)}
        >
            <div className={'result-wrapper'}>
                <div className="-resultImg">
                    {user.image ? (
                        <img alt="" src={`${user.image}`} />
                    ) : (
                        <span className="m-avatar__icon">
                            <Icon
                                name={ICONS.USER}
                                color={COLORS.DARK_BLUE_40}
                                size={ICON_SIZE.SIZE20}
                            />
                        </span>
                    )}
                    {user.online && <div className="-onlineIcon"></div>}
                </div>
                <div className="column -resultText">
                    <div className="aligned-center -nameWrapper">
                        <span className="a-mediumTextSemiBold -name">
                            {user.name}
                        </span>
                    </div>
                </div>
            </div>

            {unreadCount > 0 && (
                <span className={'unread-count'}> {unreadCount}</span>
            )}
        </li>
    )
}

export default ChatUserSearch
