import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import handleLogout from 'utils/logoutHelper'

import { PROTECTED_ROUTES } from 'constants/constants'

const useCanLeavePage = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [canLeavePage, setCanLeavePage] = useState(true)

    const { setCurrentUser, setCurrentCompany } = useContext(CurrentUserContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const handleStayOnCurrentPage = () => {
        closeConfirmationModal()
    }

    const handleLeaveCurrentPage = (path) => {
        setCanLeavePage(true)
        closeConfirmationModal()
        if (path) {
            navigate(path)
        } else {
            handleLogout(setCurrentUser, setCurrentCompany)
        }
    }

    const handleShowModal = (path) => {
        showConfirmationModal({
            confirmLabel: 'general.leavePage',
            cancelLabel: 'general.dontLeavePage',
            message: 'general.areYouSureLeavingPage',
            title: 'general.leavingPage',
            handleConfirm: () => handleLeaveCurrentPage(path),
            handleCancel: () => handleStayOnCurrentPage(),
        })
    }

    useEffect(() => {
        if (PROTECTED_ROUTES.some((route) => pathname.includes(route))) {
            setCanLeavePage(false)
        } else {
            setCanLeavePage(true)
        }
    }, [canLeavePage, pathname])

    return { canLeavePage, handleShowModal, setCanLeavePage, pathname }
}

export default useCanLeavePage
