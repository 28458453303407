import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'
import { checkExpiryDate, formatDate } from 'services/moment.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    ICON_SIZE,
    TRAVEL_DOCUMENTATION_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoDropdown from 'components/InfoDropdown'
import InfoFiles from 'components/InfoFiles'

const { PASSPORT, SEAMANS_BOOK } = TRAVEL_DOCUMENTATION_TYPE

const TravelDocumentCard = ({
    item,
    showActions,
    fetchData,
    refetchData,
    isLoading,
    setEdit,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.TRAVEL_DOCUMENTATION, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteTravelDocument',
            title: 'general.deleteTravelDocument',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    const isPassport = item.travelDocumentationType?.id === PASSPORT
    const isSeamansBook = item.travelDocumentationType?.id === SEAMANS_BOOK
    return (
        <div className="-mt20">
            <CardContainer
                title={`${item.travelDocumentationType?.name}`}
                item={item}
                actions={
                    showActions
                        ? [
                              {
                                  handleAction: handleEdit,
                                  icon: ICONS.EDIT,
                                  iconColor: COLORS.PRIMARY,
                                  tooltip: 'button.editTravelDocument',
                              },
                              {
                                  handleAction: handleDelete,
                                  icon: ICONS.DELETE,
                                  iconColor: COLORS.RED,
                                  tooltip: 'button.deleteTravelDocument',
                              },
                          ]
                        : []
                }
                fullTitle
            >
                {isSeamansBook ? (
                    <Info
                        label="form.label.serviceBookId"
                        value={item.serviceBookId}
                    />
                ) : (
                    <Info
                        label="form.label.documentNumber"
                        value={item.documentNumber}
                    />
                )}

                {isSeamansBook && (
                    <Info
                        label="form.label.dateOfIssuance"
                        value={formatDate(item.dateOfIssuance)}
                        textColor="orange"
                    />
                )}

                <Info
                    label="form.label.dateOfExpiatory"
                    value={formatDate(item.dateOfExpiatory)}
                    textColor={checkExpiryDate(item.dateOfExpiatory)}
                />

                {isSeamansBook ? (
                    <Info
                        label="form.label.issuingAuthority"
                        value={item.issuingAuthority}
                    />
                ) : (
                    <Info
                        label="form.label.countryIssue"
                        value={item.countryIssue?.name}
                    />
                )}

                <InfoFiles label="general.documentsCopy" files={item.files} />

                {isPassport && item.visas?.length > 0 && (
                    <InfoDropdown title="general.visas" isLoading={isLoading}>
                        {item.visas.map((item, index) => (
                            <div key={item.id} className="-elements">
                                <h6 className="a-bodyTextMedium">
                                    {`${t('general.visa')} ${index + 1}`}
                                </h6>
                                <Info
                                    label="form.label.visaNumber"
                                    value={item.visaNumber}
                                />
                                <Info
                                    label="form.label.dateOfExpiatory"
                                    value={formatDate(item.dateOfExpiatory)}
                                    textColor={checkExpiryDate(
                                        item.dateOfExpiatory
                                    )}
                                />
                                <Info
                                    label="form.label.countryIssue"
                                    value={item.country?.name}
                                />
                                <Info
                                    label="form.label.visaType"
                                    value={item.visaType}
                                />
                                <Info
                                    label="form.label.visaName"
                                    value={item.visaName}
                                />
                                <InfoFiles
                                    label="general.documentsCopy"
                                    files={item.files}
                                />
                            </div>
                        ))}
                    </InfoDropdown>
                )}

                {isSeamansBook && item.recordInformations?.length > 0 && (
                    <InfoDropdown title="general.records" isLoading={isLoading}>
                        {item.recordInformations.map((item, index) => (
                            <div key={item.id} className="-elements">
                                <h6 className="a-bodyTextMedium">
                                    {`${t('general.record')} ${index + 1}`}
                                </h6>
                                <Info
                                    label="form.label.nameOfShip"
                                    value={item.nameOfShip}
                                />
                                <Info
                                    label="form.label.shipsPort"
                                    value={item.shipsPort}
                                />
                                <Info
                                    label="form.label.shipsOfficialNumber"
                                    value={item.shipsOfficialNumber}
                                />
                                <Info
                                    label="form.label.shipsGrossTonnageShort"
                                    value={item.shipsGrossTonnage}
                                />
                                <Info
                                    label="form.label.dateOfEngagement"
                                    value={formatDate(item.dateOfEngagement)}
                                    textColor="orange"
                                />
                                <Info
                                    label="form.label.placeOfEngagement"
                                    value={item.placeOfEngagement}
                                />
                                <Info
                                    label="form.label.dateOfDischarge"
                                    value={formatDate(item.dateOfDischarge)}
                                    textColor="orange"
                                />
                                <Info
                                    label="form.label.placeOfDischarge"
                                    value={item.placeOfDischarge}
                                />
                                <Info
                                    label="form.label.rank"
                                    value={item.rank}
                                />
                                <Info
                                    label="form.label.position"
                                    value={item.position}
                                />
                                <Info
                                    label="form.label.descriptionOfVoyage"
                                    value={item.descriptionOfVoyage}
                                    column
                                />
                                <Info
                                    label="form.label.nameOfMaster"
                                    value={item.nameOfMaster}
                                />
                                <InfoFiles
                                    label="general.documentsCopy"
                                    files={item.files}
                                />
                            </div>
                        ))}
                    </InfoDropdown>
                )}
            </CardContainer>
        </div>
    )
}

TravelDocumentCard.propTypes = {
    item: PropTypes.object,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    dataLength: PropTypes.number,
    refetchData: PropTypes.func,
    setEdit: PropTypes.func,
}

TravelDocumentCard.defaultProps = {
    showActions: false,
}

export default TravelDocumentCard
