import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'
import { formatDate, formatMonthYearDate } from 'services/moment.service'

import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import { renderNoDataText } from '../../utils/noDataHelper'
import renderLoader from '../../utils/loaderHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import InfoRow from 'components/InfoRow'
import EditFormalEducation from './EditFormalEducation'

const FormalEducationList = ({
    data,
    isLoading,
    fetchData,
    profilePage,
    showActions,
    refetchData,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [edit, setEdit] = useState(null)

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.FORMAL_EDUCATION, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) => {
        showConfirmationModal({
            title: 'general.deleteFormalEducation',
            message: 'message.areYouSureDeleteFormalEducation',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })
    }

    return (
        <Fragment>
            {data.map((item) => (
                <div key={item?.id} className="-mt20">
                    <CardContainer
                        title={`${item.degree}`}
                        item={item}
                        actions={
                            showActions
                                ? [
                                      {
                                          handleAction: handleEdit,
                                          icon: ICONS.EDIT,
                                          iconColor: COLORS.PRIMARY,
                                          tooltip: 'button.editFormalEducation',
                                      },
                                      {
                                          handleAction: handleDelete,
                                          icon: ICONS.DELETE,
                                          iconColor: COLORS.RED,
                                          tooltip:
                                              'button.deleteFormalEducation',
                                      },
                                  ]
                                : []
                        }
                        fullTitle
                    >
                        <Info
                            label="form.label.schoolName"
                            value={item.schoolName}
                        />
                        <Info label="form.label.degree" value={item.degree} />
                        <Info
                            label="form.label.fieldOfStudy"
                            value={item.fieldOfStudy}
                        />
                        <InfoRow>
                            {item.enrolled && (
                                <Info
                                    label="form.label.dateOfEnrolled"
                                    value={formatDate(item.enrolled)}
                                />
                            )}

                            {item.completed && (
                                <Info
                                    label="form.label.completed"
                                    value={formatDate(item.completed)}
                                />
                            )}

                            {!item.completed && (
                                <Info
                                    label="form.label.completed"
                                    value={t('form.label.stillEnrolled')}
                                />
                            )}
                        </InfoRow>
                        <InfoFiles
                            label="form.label.diploma"
                            files={item?.files}
                        />
                    </CardContainer>
                </div>
            ))}

            {!!edit && (
                <EditFormalEducation
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    showActions={showActions}
                />
            )}

            {showEmptyValueMessage &&
                renderNoDataText(
                    data,
                    isLoading,
                    t('general.noFormalEducations')
                )}
            {renderLoader(isLoading, profilePage)}
        </Fragment>
    )
}

FormalEducationList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
}

FormalEducationList.defaultProps = {
    showActions: false,
}

export default FormalEducationList
