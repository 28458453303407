import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
    POSITIONS,
} from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { TAB } from './constants/tabs'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Modal from 'components/Modal'
import TabNavigation from 'components/tabs/TabNavigation'

const DiveTeamMembersList = ({ diveTeam, isLoading, isProjectCreator }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)

    const { tab } = useQueryParams()

    const { id } = useParams()

    const filterForRole = (role) =>
        diveTeam.filter((item) => item.positionTitle === role.name)

    const divingSupervisors = filterForRole(POSITIONS.DIVING_SUPERVISOR)
    const divers = filterForRole(POSITIONS.DIVER)
    const divingMedics = filterForRole(POSITIONS.DIVING_MEDIC)
    const equipmentTechnicians = filterForRole(POSITIONS.EQUIPMENT_TECHNICIAN)
    const lifeSupports = filterForRole(POSITIONS.LIFE_SUPPORT)

    const TAB_COMPONENTS = {
        [TAB.DIVING_SUPERVISOR]: divingSupervisors,
        [TAB.DIVER]: divers,
        [TAB.DIVING_MEDIC]: divingMedics,
        [TAB.EQUIPMENT_TECHNICIAN]: equipmentTechnicians,
        [TAB.LIFE_SUPPORT]: lifeSupports,
    }

    const handleViewDiveTeam = () => {
        navigate(`${ROUTES.PROJECT}/${id}${ROUTES.DIVE_TEAM}`)
    }

    const getContent = (employees) => {
        if (!employees.length) {
            return <Fragment />
        }

        return employees.slice(0, 5).map((item) => (
            <div className="-mt10" key={item.id}>
                <CardContainer
                    title={item.workerName}
                    subtitle={item.positionTitle}
                    captionSubtitle
                    withAvatar
                    avatar={item.avatarPath}
                    verifiedName={!!item.identityVerified}
                    cardContainerLink={
                        ROUTES.DIVER_PROFILE + `/${item.profileHash}`
                    }
                />
            </div>
        ))
    }

    const renderTabContent = () => {
        const tabElements = TAB_COMPONENTS[tab] || divers

        return tabElements.map((item) => (
            <div className="_12 _l4 -mb10" key={item.id}>
                <CardContainer
                    title={item.workerName}
                    subtitle={item.positionTitle}
                    subtitle2={item.countryName}
                    captionSubtitle
                    withAvatar
                    avatar={item.avatarPath}
                    verifiedName={!!item.identityVerified}
                    cardContainerLink={
                        ROUTES.DIVER_PROFILE + `/${item.profileHash}`
                    }
                />
            </div>
        ))
    }

    const tabData = [
        {
            key: TAB.DIVER,
            title:
                `${divers.length} ` +
                `${t(
                    `${divers.length > 1 ? 'general.divers' : 'general.diver'}`
                )}`,
            hidden: divers.length === 0,
        },
        {
            key: TAB.DIVING_SUPERVISOR,
            title:
                `${divingSupervisors.length} ` +
                `${t(
                    `${
                        divingSupervisors.length > 1
                            ? 'general.divingSupervisors'
                            : 'general.divingSupervisor'
                    }`
                )}`,
            hidden: divingSupervisors.length === 0,
        },
        {
            key: TAB.DIVING_MEDIC,
            title:
                `${divingMedics.length} ` +
                `${t(
                    `${
                        divingMedics.length > 1
                            ? 'general.diveMedics'
                            : 'general.diveMedic'
                    }`
                )}`,
            hidden: divingMedics.length === 0,
        },
        {
            key: TAB.EQUIPMENT_TECHNICIAN,
            title:
                `${equipmentTechnicians.length} ` +
                `${t(
                    `${
                        equipmentTechnicians.length > 1
                            ? 'general.equipmentTechnicians'
                            : 'general.equipmentTechnician'
                    }`
                )}`,
            hidden: equipmentTechnicians.length === 0,
        },
        {
            key: TAB.LIFE_SUPPORT,
            title: `${lifeSupports.length} ` + `${t('general.lifeSupport')}`,
            hidden: lifeSupports.length === 0,
        },
    ]

    return (
        <Fragment>
            <div>
                <h3 className="-mb15">{t('general.teamMembers')}</h3>
                {diveTeam.length !== 0 && (
                    <p className="a-bodyTextRegular a-lightText -opacity-60 -mt10 -mb20">
                        {t(
                            `${
                                diveTeam.length === 1
                                    ? 'general.thereIs'
                                    : 'general.thereAre'
                            }`
                        )}{' '}
                        {diveTeam.length}{' '}
                        {t(
                            `${
                                diveTeam.length === 1
                                    ? 'general.teamMemberOnProject'
                                    : 'general.teamMembersOnProject'
                            }`
                        )}
                    </p>
                )}

                {getContent(diveTeam)}
                {diveTeam.length > 5 && !isProjectCreator && (
                    <div className="-mt20">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                            label="button.viewTeamMembers"
                            onClick={() => {
                                setOpenModal(true)
                            }}
                            icon={ICONS.ARROW_RIGHT}
                            iconColor={COLORS.SECONDARY}
                            iconPosition={ICON_POSITION.RIGHT}
                        />
                    </div>
                )}

                {diveTeam.length === 0 && !isLoading && (
                    <span className="a-bodyTextRegular a-lightText justify-center -mt5">
                        {t('general.noTeamMembers')}
                    </span>
                )}
            </div>
            <div>
                {isProjectCreator && (
                    <div className="-mt20">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                            label="button.goToTeamManagement"
                            onClick={handleViewDiveTeam}
                            icon={ICONS.ARROW_RIGHT}
                            iconColor={COLORS.SECONDARY}
                            iconPosition={ICON_POSITION.RIGHT}
                        />
                    </div>
                )}
            </div>
            {openModal && (
                <Modal
                    open={openModal}
                    setOpen={setOpenModal}
                    closeOnClickOutside={false}
                    type="-diveTeamMembersList"
                >
                    <div className="_wr -pb30">
                        <div className="_w">
                            <h3 className="-mb20 _12">
                                {t('general.teamMembers')}
                            </h3>
                            <div className="_12">
                                <TabNavigation
                                    tabs={tabData}
                                    tabQueryParam="tab"
                                    translate={false}
                                />
                            </div>
                        </div>
                        <div className="_w">{renderTabContent()}</div>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

DiveTeamMembersList.propTypes = {
    projectId: PropTypes.number,
}

export default DiveTeamMembersList
