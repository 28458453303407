import React from 'react'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Icon from 'components/Icon'

const DropdownActions = ({ open, setOpen, actions, item, textPreview = false, icon = null, customClass = '', chat }) => {
    const t = useTranslate()

    return (
        <div
            className={`a-actions ${customClass}`}
            onClick={(e) => {
                chat && e.stopPropagation()
                setOpen(!open)
            }}
        >
            { icon ?? <Icon name={ICONS.DOTS} /> }
            <div className={`a-actions__buttons ${open ? '-active' : ''}`}>
                {actions.map(
                    ({ handleAction, tooltip, icon, iconColor }, index) => (
                        <div
                            key={index}
                            onClick={(e) =>
                                chat
                                    ? handleAction(e, item)
                                    : handleAction(item)
                            }
                            title={t(tooltip)}
                        >
                            {textPreview ?
                                <p>{t(tooltip)}</p>
                                : <Icon
                                    name={icon}
                                    size={ICON_SIZE.SIZE20}
                                    color={iconColor}
                                />
                            }

                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default clickOutsideHOC(DropdownActions)
