import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import REGEXP from 'constants/regex'

import Button from 'components/Button'
import Loader from 'components/Loader'
import { InputField } from 'components/formFields'
import FocusError from 'components/FocusError'

const InviteDiverForm = ({
    choosedItem,
    setChoosedItem,
    handleClose,
    refetchData,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        diverEmail: '',
        projectPosition: {},
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        diverEmail: Yup.string()
            .trim()
            .email(t('form.error.invalidEmail'))
            .matches(REGEXP.EMAIL, t('form.error.invalidEmail'))
            .required(requiredMessage),
    })

    const onSubmit = async (data, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true)
            await createEntityService(ENTITIES.INVITE, {
                ...data,
                diverEmail: data.diverEmail.trim(),
                projectPosition: choosedItem,
            })
            setAlert(t('form.success.successfully'), ALERT_TYPES.SUCCESS)
            setChoosedItem(null)
            resetForm({})
            handleClose()
            if (refetchData) refetchData()
        } catch (error) {
            if (
                error.response.data.message ===
                'diverEmail: Exist invite for this diver!'
            ) {
                setAlert(t('form.error.existInviteForEmail'), ALERT_TYPES.ERROR)
            } else if (
                error.response.data.message ===
                'diverEmail: You cannot invite yourself'
            ) {
                setAlert(t('form.error.canNotInviteYouself'), ALERT_TYPES.ERROR)
            } else {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {({ isSubmitting }) => (
                <Form className="m-modal__form">
                    <FocusError />
                    <div className="_wr">
                        <div className="-form _w">
                            <div className="_12">
                                <InputField
                                    type={INPUT_FILED_TYPE.TEXT}
                                    name="diverEmail"
                                    placeholder="form.placeholder.diverEmail"
                                    icon={ICONS.MAIL}
                                    iconColor={COLORS.DARK_BLUE_60}
                                    size={ICON_SIZE.SIZE20}
                                    required
                                />
                            </div>
                            <div className="-buttons _12 -mt10">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label="button.cancel"
                                    onClick={handleClose}
                                    isSubmitting={isSubmitting}
                                />
                                <Button
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    label="button.sendInvitation"
                                    disabled={isSubmitting}
                                />
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

InviteDiverForm.propTypes = {
    choosedItem: PropTypes.object,
    setChoosedItem: PropTypes.func,
    handleClose: PropTypes.func,
    refetchData: PropTypes.func,
}

export default InviteDiverForm
