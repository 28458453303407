const ICONS = {
    HOME_LOGO_LIGHT: 'home_logo_light',
    HOME_LOGO_DARK: 'home_logo_dark',
    CHEVRON_DOWN_SQUARE: 'chevron_down_square',
    CHEVRON_UP_SQUARE: 'chevron_up_square',
    CHEVRON_RIGHT_SQUARE: 'chevron_right_square',
    CHEVRON_LEFT_SQUARE: 'chevron_left_square',
    CHEVRON_DOWN_CIRCLE: 'chevron_down_circle',
    CHEVRON_UP_CIRCLE: 'chevron_up_circle',
    CHEVRON_RIGHT_CIRCLE: 'chevron_right_circle',
    CHEVRON_LEFT_CIRCLE: 'chevron_left_circle',
    CHEVRON_DOWN: 'chevron_down',
    CHEVRON_UP: 'chevron_up',
    CHEVRON_RIGHT: 'chevron_right',
    CHEVRON_LEFT: 'chevron_left',
    DOUBLE_CHEVRON_LEFT: 'double_chevron_left',
    ARROW_DOWN_SQUARE: 'arrow_down_square',
    ARROW_UP_SQUARE: 'arrow_up_square',
    ARROW_RIGHT_SQUARE: 'arrow_right_square',
    ARROW_LEFT_SQUARE: 'arrow_left_square',
    ARROW_DOWN_CIRCLE: 'arrow_down_circle',
    ARROW_UP_CIRCLE: 'arrow_up_circle',
    ARROW_RIGHT_CIRCLE: 'arrow_right_circle',
    ARROW_LEFT_CIRCLE: 'arrow_left_circle',
    ARROW_DOWN_RIGHT_CIRCLE: 'arrow_down_right_circle',
    ARROW_DOWN_LEFT_CIRCLE: 'arrow_down_left_circle',
    ARROW_UP_RIGHT_CIRCLE: 'arrow_up_right_circle',
    ARROW_UP_LEFT_CIRCLE: 'arrow_up_left_circle',
    ARROW_DOWN: 'arrow_down',
    ARROW_UP: 'arrow_up',
    ARROW_RIGHT: 'arrow_right',
    ARROW_LEFT: 'arrow_left',
    ARROW_DOWN_RIGHT: 'arrow_down_right',
    ARROW_DOWN_LEFT: 'arrow_down_left',
    ARROW_UP_RIGHT: 'arrow_up_right',
    ARROW_UP_LEFT: 'arrow_up_left',
    SWAP_VERTICAL: 'swap_vertical',
    SWAP_HORIZONTAL: 'swap-horizontal',
    RELOAD: 'reload',
    EXPAND: 'expand',
    COLLAPSE: 'collapse',
    DOTS: 'dots',
    WHITE_CHECKMARK: 'white_checkmark',
    DARK_CHECKMARK: 'dark_checkmark',
    CLOSE: 'close',
    PLUS: 'plus',
    MAIL: 'mail',
    TIME: 'time',
    FILTER: 'filter',
    LOCATION: 'location',
    PHONE: 'phone',
    UPLOAD: 'upload',
    USER: 'user',
    EDIT: 'edit',
    COPY: 'copy',
    DELETE: 'delete',
    NOTIFICATIONS: 'notifications',
    NOTIFICATIONS_NO_CIRCLE: 'notifications_no_circle',
    INFO_CIRCLE: 'info_circle',
    SEARCH: 'search',
    HOMEPAGE: 'homepage',
    HOMEPAGE_BREADCRUMB: 'homepage_breadcrumb',
    DIVE_PROJECTS: 'dive_projects',
    DIVE_PROJECTS_OUTLINE: 'dive_projects_outline',
    GALLERY: 'gallery',
    CALENDAR: 'calendar',
    FAQ: 'faq',
    LEGAL: 'legal',
    LOGOUT: 'logout',
    SETTINGS: 'settings',
    GOOGLE: 'google',
    LINKEDIN: 'linkedin',
    HOME_PAGE: 'home_page',
    STEP_ICON: 'step_icon',
    CHECKMARK_ICON: 'checkmark_icon',
    SORT_ASCENDING: 'sort_ascending',
    SORT_DESCENDING: 'sort_descending',
    LINE: 'line',
    DOCUMENT: 'document',
    FOOTER_MAIL: 'footer_mail',
    FOOTER_PHONE: 'footer_phone',
    FOOTER_LOCATION: 'footer_location',
    ALERT_ERROR: 'alert_error',
    ALERT_WARNING: 'alert_warning',
    ALERT_SUCCESS: 'alert_success',
    ALERT_NOTIFICATION: 'alert_notification',
    OFFICE: 'office',
    HOUSE: 'house',
    TOOLS: 'tools',
    BADGE: 'badge',
    SCHOOL: 'school',
    BOOK: 'book',
    BADGE_CIRCLE: 'badge_circle',
    SHARE: 'share',
    BANNER_PLACEHOLDER: 'banner_placeholder',
    GLOBE: 'globe',
    FAVORITES: 'favorites',
    VERIFICATION: 'verification',
    EDIT_USER_PROFILE: 'edit_user_profile',
    WEBSITE: 'website',
    SHOW_PASSWORD: 'show_password',
    HIDE_PASSWORD: 'hide_password',
    GALLERY_PHOTO: 'gallery_photo',
    CHECKMARK_ICON_ROUNDED: 'checkmark_icon_rounded',
    DOWNLOAD_IMAGE: 'download_image',
    PERSONAL_INFO: 'personal_info',
    DIVE_RECORDS: 'dive_records',
    MEDICAL_INFO: 'medical_info',
    TRAVEL_DOCUMENTATION: 'travel_documentation',
    TRAINING_RECORD: 'training_record',
    PERSONAL_EQUIPMENT: 'personal_equipment',
    PAST_EMPLOYMENT: 'past_employment',
    SKILLS: 'skills',
    ALERT_INFO: 'alert_info',
    NOTIFICATION_DANGER: 'notification_danger',
    APPLICATION_ACCEPTED: 'application_accepted',
    APPLICATION_REJECTED: 'application_rejected',
    CAMERA: 'camera',
    PHOTO: 'photo',
    ABOUT_COMPANY: 'about_company',
    BUILDING: 'building',
    GRANT_ACCESS: 'grant_access',
    DENY_ACCESS: 'deny_access',
    CHANGE_ACCESS: 'change_access',
    VERIFICATION_PROFILE: 'verification_profile',
    WALLET: 'wallet',
    CHAT: 'chat',
    CHAT_WITH_ICON: 'chat_with_icon',
    PIN: 'pin',
    UNITS: 'units',
    DATA_ACCESS: 'data_access',
    LINK: 'link',
    CHECKMARK_CIRCLE: 'checkmark_circle',
    VERIFICATION_SHARE: 'verification_share',
    HELP_AND_INFO: 'help_and_info',
    EXPLORE: 'explore',
    RELEASE_NOTES: 'release_notes',
    LEVEL_0: 'level_0',
    LEVEL_1: 'level_1',
    LEVEL_2: 'level_2',
    LEVEL_3: 'level_3',
    LEVEL_4: 'level_4',
    LEVEL_5: 'level_5',
    USER_DIVER: 'user_diver',
    USER_SUPERVISOR: 'user_supervisor',
    SAVE: 'save',
    ROUNDED_EDIT: 'rounded_edit',
    SETUP: 'setup',
    LEFT_DOUBLE_ARROW: 'left_double_arrow',
    LEFT_ARROW: 'left_arrow',
    RIGHT_DOUBLE_ARROW: 'right_double_arrow',
    RIGHT_ARROW: 'right_arrow',
    CLEAR: 'clear',
    HELP_AND_INFO_DESCRIPTION: 'help_and_info_description',
    DIVER: 'diver',
    CLOCK: 'clock',
    EXPORT_CV: 'export_cv',
}
export default ICONS
