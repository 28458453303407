import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { isFormDisabled } from 'utils/form'
import useWindowDimensions from 'hooks/useWindowDimension'

import COLORS from 'constants/colors'
import { ICON_SIZE, TOOLTIP_POSITION } from 'constants/enums'

import { CHECKBOX } from 'constants/enums'

import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'
import Tooltip from 'components/Tooltip'
import Icon from './Icon'

const Checkbox = ({
    label,
    value,
    error,
    required,
    groupName,
    disabled,
    translate,
    title,
    tooltip,
    icon,
    type,
    ...props
}) => {
    const fieldRef = useRef()
    const t = useTranslate()

    const { isSmallerThanXlScreen } = useWindowDimensions()

    const isDisabled = disabled || isFormDisabled(fieldRef)

    const id = `${groupName}-${props.name}`

    return (
        <div>
            {title && (
                <FieldLabel htmlFor={id} label={title} translate={translate} />
            )}
            <div
                className={
                    title
                        ? `a-checkboxWithTitle ${isDisabled ? '-disabled' : ''} ${
                            type || ''
                        }`
                        : `a-checkbox ${isDisabled ? '-disabled' : ''} ${
                            type || ''
                        }`
                }
            >
                <input
                    {...props}
                    ref={fieldRef}
                    type="checkbox"
                    checked={value}
                    id={id}
                    disabled={isDisabled}
                />
                <FieldLabel htmlFor={id} label={label} translate={translate} />
                {tooltip && icon && (
                    <span className="help-icon-checkbox -modal">
                        <Tooltip
                            tooltip={t(tooltip)}
                            position={
                                isSmallerThanXlScreen
                                    ? TOOLTIP_POSITION.BOTTOM
                                    : TOOLTIP_POSITION.RIGHT
                            }
                        >
                            <Icon
                                name={icon}
                                color={COLORS.SECONDARY}
                                size={ICON_SIZE.SIZE20}
                            />
                        </Tooltip>
                    </span>
                )}
                {error && <FieldError error={error} />}
            </div>
        </div>
    )
}

export const CheckboxMainPropTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    groupName: PropTypes.string,
    translate: PropTypes.bool,
}

Checkbox.propTypes = {
    ...CheckboxMainPropTypes,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    type: PropTypes.oneOf([CHECKBOX.DEFAULT, CHECKBOX.CHECKED]),
}

Checkbox.defaultProps = {
    required: false,
    translate: false,
    type: CHECKBOX.DEFAULT,
}

export default Checkbox
