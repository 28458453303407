import React, { Fragment, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { MOMENT_FORMATS, getIsDateSameOrBefore } from 'services/moment.service'

import ProjectFormContext from 'screens/common/singleProject/formContext/ProjectFormContext'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import Loader from 'components/Loader'
import DateTimeField from 'components/formFields/DateTimeField'
import TextAreaField from 'components/formFields/TextAreaField'
import SelectField from 'components/formFields/SelectField'
import InfoList from 'components/InfoList'
import Separator from 'components/Separator'
import AvailablePositionsForm from './AvailablePositionsForm'
import FocusError from 'components/FocusError'
import LocationMapField from 'components/formFields/LocationMapField'
import { CheckboxField } from 'components/formFields'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'

const ProjectForm = ({ handleSubmit, initialData }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)
    const { currentUser } = useContext(CurrentUserContext)

    const formRef = useRef()
    const [availablePositions, setAvailablePositions] = useState(
        initialData?.projectPositions ?? []
    )
    const [positionsError, setPositionsError] = useState()
    const [locationError, setLocationError] = useState()
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const [submitted, setSubmitted] = useState(false)

    const initialValues = {
        projectIdNumber: initialData?.projectIdNumber ?? '',
        name: initialData?.name ?? '',
        client: initialData?.client ?? '',
        projectStatus: initialData?.projectStatus ?? null,
        hidden: initialData?.hidden ?? false,
        fromDate: initialData?.fromDate ?? '',
        toDate: initialData?.toDate ?? '',
        country: initialData?.country ?? null,
        region: initialData?.region ?? null,
        locationDetail: initialData?.locationDetail ?? '',
        locationType: initialData?.locationType ?? null,
        countryOfMobilization: initialData?.countryOfMobilization ?? null,
        location: {
            geoLat: initialData?.geoLat ?? '',
            geoLong: initialData?.geoLong ?? '',
        },
        bodyOfWater: initialData?.bodyOfWater ?? null,
        altitude: initialData?.altitude ?? '',
        industry: initialData?.industry ?? null,
        projectIndustryType: initialData?.projectIndustryType ?? null,
        projectScope: initialData?.projectScope ?? '',
        undisclosed: initialData?.undisclosed ?? false,
        divingMode: initialData?.divingMode ?? null,
        fromDurationDays: initialData?.fromDurationDays ?? '',
        toDurationDays: initialData?.toDurationDays ?? '',
        projectManager: initialData?.projectManager ?? '',
        divingPlatform: initialData?.divingPlatform ?? '',
        nameOfVessel: initialData?.nameOfVessel ?? '',
        fromDepth: initialData?.fromDepth ?? '',
        toDepth: initialData?.toDepth ?? '',
        projectPositions: availablePositions,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        projectIdNumber: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_NUMERIC, t('form.error.onlyLetterAndNumber')),
        name: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_NUMERIC, t('form.error.onlyLetterAndNumber'))
            .required(requiredMessage),
        client: Yup.string().trim(),
        projectStatus: Yup.object().required(requiredMessage),
        fromDate: Yup.date().nullable(),
        toDate: Yup.date()
            .nullable()
            .test('toDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.fromDate,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.toDateAfterFromDate'),
                        path: 'toDate',
                    })
                }
                return true
            }),
        country: Yup.object().required(requiredMessage),
        region: Yup.object().required(requiredMessage),
        locationType: Yup.object().required(requiredMessage),
        countryOfMobilization: Yup.object().required(requiredMessage),
        bodyOfWater: Yup.object().required(requiredMessage),
        altitude: Yup.number()
            .min(0, t('form.error.positiveAltitude'))
            .required(requiredMessage),
        industry: Yup.object().required(requiredMessage),
        projectIndustryType: Yup.object().required(requiredMessage),
        projectScope: Yup.string().trim().required(requiredMessage),
        divingMode: Yup.object().required(requiredMessage),
        fromDurationDays: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber')),
        toDurationDays: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .test('toDurationDays', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.fromDurationDays)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanFrom'),
                        path: 'toDurationDays',
                    })
                }
                return true
            }),
        projectManager: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_WITH_SPACES, t('form.error.onlyLetter'))
            .required(requiredMessage),
        divingPlatform: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_WITH_SPACES, t('form.error.onlyLetter')),
        nameOfVessel: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_WITH_SPACES, t('form.error.onlyLetter')),
        fromDepth: Yup.number().min(0, t('form.error.invalidNumber')),
        toDepth: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .test('toDepth', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.fromDepth)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanFrom'),
                        path: 'toDepth',
                    })
                }
                return true
            }),
    })

    const isImperialUnit =
        initialData?.unitImperial ??
        currentUser.userSetting.unitImperial ??
        true

    const onSubmit = async (
        { projectPositions, location, ...formData },
        { setSubmitting, resetForm }
    ) => {
        try {
            if (availablePositions?.length === 0) {
                setPositionsError('Please add at least one position')
                const selector = `[scroll-attribute=availablePositionsErrorScroll]`
                const errorElement = document.querySelector(selector)
                if (errorElement) {
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            } else if (location.geoLat === '' || location.geoLong === '') {
                setLocationError(t('form.error.noLocation'))
                const selector = `[scroll-attribute=locationErrorScroll]`
                const errorElement = document.querySelector(selector)
                if (errorElement) {
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            } else {
                setSubmitting(true)
                setSubmitted(true)
                await handleSubmit({
                    projectPositions: availablePositions,
                    ...formData,
                    unitImperial: isImperialUnit,
                    geoLat: location.geoLat.toString(),
                    geoLong: location.geoLong.toString(),
                })

                resetForm({})
                setAvailablePositions([])
            }
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
            setSubmitted(false)
        }
    }

    const handleSubmitForm = () => formRef?.current?.handleSubmit()

    const handleConfirm = () => {
        closeConfirmationModal()
        navigate(ROUTES.HOME)
    }

    const handleCancel = () => closeConfirmationModal()

    const handleClose = () => {
        showConfirmationModal({
            confirmLabel: 'general.leavePage',
            cancelLabel: 'general.dontLeavePage',
            message: 'general.areYouSureLeavingPage',
            title: 'general.leavingPage',
            handleConfirm: () => handleConfirm(),
            handleCancel,
        })
    }

    const formatSkills = (item) => {
        return (
            <Fragment>
                {item.map((position, index) => {
                    return (
                        <span key={index} className="-value -orange -withColor">
                            {position?.skillCategory.name +
                                '-' +
                                position?.skill.name}
                        </span>
                    )
                })}
            </Fragment>
        )
    }

    const formatPayRate = (item) => {
        return item || null
    }

    const unitOfMeasurementLabel = isImperialUnit
        ? 'form.label.ft'
        : 'form.label.m'

    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validation}
            >
                {({ isSubmitting, values: { industry } }) => (
                    <Form>
                        <FocusError />
                        <ProjectFormContext />
                        <div className="_wr">
                            <div className="_w -mt20">
                                <div className="_12 _l4">
                                    <InputField
                                        name="projectIdNumber"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="name"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        label="form.label.diveProjectName"
                                        placeholder="form.placeholder.diveProjectName"
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="client"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="projectStatus"
                                        placeholder="form.placeholder.projectStatus"
                                        entityType={ENTITIES.PROJECT_STATUS}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4 centered-checkbox">
                                    <CheckboxField
                                        name="hidden"
                                        label="form.label.hidden"
                                        translate
                                    />
                                    <span className="help-icon">
                                        <Tooltip
                                            tooltip={t(
                                                'form.label.hiddenInfoText'
                                            )}
                                        >
                                            <Icon
                                                name={ICONS.HELP_AND_INFO}
                                                color={COLORS.SECONDARY}
                                                size={ICON_SIZE.SIZE20}
                                            />
                                        </Tooltip>
                                    </span>
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.startDateRange')}
                                </span>
                                <div className="_12 _l4 -mt20">
                                    <DateTimeField
                                        name="fromDate"
                                        label="form.label.startDateRangeFrom"
                                    />
                                </div>
                                <div className="_12 _l4 -mt20">
                                    <DateTimeField
                                        name="toDate"
                                        label="form.label.startDateRangeTo"
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <SelectField
                                        name="country"
                                        label="form.label.countryOfWork"
                                        placeholder="form.placeholder.countryOfWork"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="region"
                                        label="form.label.geographicalRegion"
                                        placeholder="form.placeholder.geographicalRegion"
                                        entityType={ENTITIES.REGION}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="locationDetail"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="locationType"
                                        placeholder="form.placeholder.locationType"
                                        entityType={ENTITIES.LOCATION_TYPE}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="countryOfMobilization"
                                        placeholder="form.placeholder.countryOfMobilization"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                    />
                                </div>

                                {/* GOOGLE MAP */}
                                <div className="_12 -mt20">
                                    <LocationMapField
                                        name="location"
                                        label="form.label.mapLocation"
                                        description="form.label.mapLocationDesc"
                                        errorMessage={locationError}
                                        required
                                    />
                                </div>

                                <div className="_12 _m4">
                                    <SelectField
                                        name="bodyOfWater"
                                        entityType={ENTITIES.BODY_OF_WATER}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _m4 ">
                                    <InputField
                                        name="altitude"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        required
                                        units={unitOfMeasurementLabel}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <SelectField
                                        name="industry"
                                        placeholder="form.placeholder.industryType"
                                        entityType={ENTITIES.INDUSTRY}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="projectIndustryType"
                                        label="form.label.projectType"
                                        placeholder="form.placeholder.projectType"
                                        entityType={
                                            ENTITIES.PROJECT_INDUSTRY_TYPES
                                        }
                                        params={
                                            industry && {
                                                'industries.id': industry.id,
                                            }
                                        }
                                        searchable
                                        required
                                        disabled={!industry}
                                        reload={industry}
                                        createNew
                                        createNewParams={{
                                            related_entity: ENTITIES.INDUSTRY,
                                            related_entity_id: industry?.id,
                                        }}
                                    />
                                </div>
                                <div className="_12 -mt20">
                                    <TextAreaField
                                        name="projectScope"
                                        label="form.label.projectScopeOfWork"
                                        placeholder="form.placeholder.projectScopeOfWork"
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="divingMode"
                                        label="form.label.modeOfDive"
                                        placeholder="form.placeholder.modeOfDive"
                                        entityType={ENTITIES.DIVING_MODE}
                                        searchable
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.projectDurationInDays')}
                                </span>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="fromDurationDays"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.projectDurationInDaysFrom"
                                        placeholder="form.placeholder.projectDurationInDaysFrom"
                                    />
                                </div>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="toDurationDays"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.projectDurationInDaysTo"
                                        placeholder="form.placeholder.projectDurationInDaysTo"
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <InputField
                                        name="projectManager"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="divingPlatform"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="nameOfVessel"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.waterDepthRange')}
                                </span>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="fromDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.shallowestDepth"
                                        placeholder={
                                            isImperialUnit
                                                ? 'form.placeholder.shallowestDepthImperial'
                                                : 'form.placeholder.shallowestDepth'
                                        }
                                    />
                                </div>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="toDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.deepestDepth"
                                        placeholder={
                                            isImperialUnit
                                                ? 'form.placeholder.deepestDepthImperial'
                                                : 'form.placeholder.deepestDepth'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </Form>
                )}
            </Formik>
            <div scroll-attribute="availablePositionsErrorScroll">
                <AvailablePositionsForm
                    initialData={initialData}
                    availablePositions={availablePositions}
                    setAvailablePositions={setAvailablePositions}
                    positionsError={positionsError}
                />
            </div>
            <div className="_wr -mt30">
                <div className="_w">
                    <div className="_12">
                        <InfoList
                            data={availablePositions}
                            setData={setAvailablePositions}
                            confirmMessage="message.areYouSureDeleteProjectPosition"
                            displayAttributes={[{ key: 'diverPosition.name' }]}
                            displayFromToValues={[
                                {
                                    label: 'form.label.payRate',
                                    from: 'fromPayRate',
                                    formatFromValue: formatPayRate,
                                    to: 'toPayRate',
                                    formatToValue: formatPayRate,
                                    unit: '$',
                                },
                            ]}
                            displayValues={[
                                {
                                    key: 'numOfWorkers',
                                    unit: 'form.label.workers',
                                },
                                {
                                    key: 'projectPositionSkill',
                                    label: 'form.label.skills',
                                    formatValue: formatSkills,
                                },
                            ]}
                            confirmLabel="button.deleteProjectPosition"
                            deleteTitle="general.deleteProjectPosition"
                        />
                    </div>
                </div>
            </div>
            <div className="_wr">
                <div className="_w">
                    <div className="space-between -buttons fullWidth">
                        <div className="_12 _xs6 _m3 -prevButton -mt40">
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                label="button.cancel"
                                onClick={handleClose}
                                buttonSize={BUTTON_SIZE.LARGE}
                                disabled={submitted}
                            />
                        </div>
                        <div className="_12 _xs6 _m3 -nextButton -mt40">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                buttonSize={BUTTON_SIZE.LARGE}
                                label={
                                    initialData
                                        ? 'button.saveChanges'
                                        : 'button.saveProject'
                                }
                                type={BUTTON_TYPE.SUBMIT}
                                onClick={handleSubmitForm}
                                disabled={submitted}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectForm
