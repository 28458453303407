import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { INPUT_FILED_TYPE } from 'constants/enums'

import Input from './Input'

const TimePicker = ({ name, value, valueFormat, handleChange }) => {
    const [hours, setHours] = useState(
        value ? moment(value, valueFormat).format('H') : ''
    )
    const [minutes, setMinutes] = useState(
        value ? moment(value, valueFormat).format('m') : ''
    )

    useEffect(() => {
        setHours(value ? moment(value, valueFormat).format('H') : '')
        setMinutes(value ? moment(value, valueFormat).format('m') : '')
    }, [value])

    const handleChangeTime = (event, min, max, setTime, unit) => {
        const timeValue = event.target.value

        if (timeValue < min || timeValue > max) {
            event.preventDefault()
            return
        }

        setTime(timeValue)

        const time = value
            ? moment(value, valueFormat).set(unit, timeValue)
            : moment()
                  .set(unit, timeValue)
                  .set(unit === 'hour' ? 'minute' : 'hour', '0')
                  .set('second', 0)

        handleChange(time)
    }

    const handleKeyDown = (event) => {
        if (event.key === '-' || event.key === 'e') {
            event.preventDefault()
        }
    }

    return (
        <div className="react-datetime-picker__clock react-datetime-picker__clock--open">
            <Input
                name={`${name}-hours`}
                label="form.label.hoursFull"
                placeholder="form.placeholder.hours"
                value={hours}
                onChange={(e) => handleChangeTime(e, 0, 23, setHours, 'hour')}
                onKeyDown={handleKeyDown}
                type={INPUT_FILED_TYPE.NUMBER}
                max={23}
                min={0}
            />
            <span className="timeInputSeparator">:</span>
            <Input
                name={`${name}-minutes`}
                label="form.label.minutesFull"
                placeholder="form.placeholder.minutes"
                value={minutes}
                onChange={(e) =>
                    handleChangeTime(e, 0, 59, setMinutes, 'minute')
                }
                onKeyDown={handleKeyDown}
                type={INPUT_FILED_TYPE.NUMBER}
                max={59}
                min={0}
            />
        </div>
    )
}

TimePicker.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
}

export default TimePicker
