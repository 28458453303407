import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'

const WorkPerformedSummary = ({
    data,
    diveRecordId,
    verification,
    diveRecordType,
}) => {
    const t = useTranslate()

    if (!data) return null

    const tasks =
        data?.tasks?.length > 0
            ? data?.tasks?.map((item) => item.name)
            : t('general.notApplicable')

    const supervisorTasks =
        data?.supervisorTasks?.length > 0
            ? data?.supervisorTasks?.map((item) => item.name)
            : t('general.notApplicable')

    const objects =
        data?.objects?.length > 0
            ? data?.objects?.map((item) => item.name)
            : t('general.notApplicable')

    const activities =
        data?.activities?.length > 0
            ? data?.activities?.map((item) => item.name)
            : t('general.notApplicable')

    const tools =
        data?.tools?.length > 0
            ? data?.tools?.map((item) => item.name)
            : t('general.notApplicable')

    return (
        <CardContainer recordSummary noBorderCard={!verification}>
            {supervisorTasks?.length > 0 && diveRecordType === 2 && (
                <div className="-infoGroup column">
                    <Fragment>
                        <Info
                            label="general.supervisorTasks"
                            value={supervisorTasks}
                            color="gray"
                            column
                        />
                    </Fragment>
                </div>
            )}
            <div className="-infoGroup column">
                <Info label="general.tasks" value={tasks} color="gray" column />
            </div>
            <div className="-infoGroup column">
                <Info
                    label="general.objects"
                    value={objects}
                    color="gray"
                    column
                />
            </div>
            <div className="-infoGroup column">
                <Info
                    label="general.activities"
                    value={activities}
                    color="gray"
                    column
                />
            </div>
            <div className="-infoGroup column">
                <Info label="general.tools" value={tools} color="gray" column />
            </div>
        </CardContainer>
    )
}

WorkPerformedSummary.propTypes = {
    data: PropTypes.object,
    diveRecordId: PropTypes.number,
    verification: PropTypes.bool,
}

export default WorkPerformedSummary
