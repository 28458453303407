import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { BASE_URL } from 'utils/axiosClient'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import DropdownActions from 'components/DropdownActions'
import Icon from 'components/Icon'
import StatusActions from 'components/status/StatusActions'
// import IconButton from './IconButton'

const CardContainer = ({
    icon,
    iconColor,
    iconSize,
    title,
    statuses,
    actions,
    headerActions,
    children,
    item,
    recordSummary,
    insideCardContainer,
    noBorderCard,
    withAvatar,
    avatar,
    placeholderIcon,
    link,
    hideShowRecord,
    inModal,
    customImage,
    identityVerified,
    verifiedName,
    subtitle,
    subtitle2,
    captionSubtitle,
    cardContainerLink,
    fullTitle,
    largeTitle,
    sidebarCard,
    titleInTwoLines,
    statusActionsCombined,
    customIcon,
}) => {
    const t = useTranslate()

    // const [hideRecord, setHideRecord] = useState(false)

    // NOTE:: implement later
    // const setShowHideButtonIcon = () => {
    //     return hideRecord ? ICONS.HIDE_PASSWORD : ICONS.SHOW_PASSWORD
    // }

    // const handleClick = () => {
    //     setHideRecord(!hideRecord)
    // }

    const titleContent = () => {
        return (
            <Fragment>
                {customIcon}
                {icon && <Icon name={icon} color={iconColor} size={iconSize} />}
                {recordSummary || largeTitle ? (
                    <h3>{title}</h3>
                ) : (
                    <span
                        className={`a-bodyTextMedium ${
                            insideCardContainer ? 'a-lightText' : ''
                        } ${
                            subtitle ? 'column aligned-start' : 'aligned-center'
                        } -titleWrapper`}
                    >
                        <span
                            className={`${
                                verifiedName ? 'aligned-center' : ''
                            }`}
                        >
                            <span className="-titleContent">{title}</span>
                            {verifiedName && (
                                <span className="verifiedIcon">
                                    <Icon name={ICONS.VERIFICATION_PROFILE} />
                                </span>
                            )}
                        </span>
                        {subtitle && (
                            <span
                                className={`${
                                    captionSubtitle
                                        ? 'a-captionsTextRegular'
                                        : 'a-mediumText'
                                } a-lightText -opacity-60 -mt3`}
                            >
                                {subtitle}{' '}
                                {subtitle && subtitle2 && <>&#x2022;</>}{' '}
                                {subtitle2}
                            </span>
                        )}
                    </span>
                )}
            </Fragment>
        )
    }

    const renderSingleAction = (
        { handleAction, tooltip, icon, iconColor },
        customAction
    ) => {
        return (
            <div
                className={`-singleAction ${customAction || ''}`}
                onClick={() => handleAction(item)}
                title={t(tooltip)}
            >
                <Icon name={icon} size={ICON_SIZE.SIZE20} color={iconColor} />
            </div>
        )
    }

    return (
        <div
            className={`a-stepCard ${recordSummary ? '-recordSummary' : ''} ${
                noBorderCard ? '-noBorderCard' : ''
            } ${customImage ? '-withCustomImage' : ''} ${
                cardContainerLink ? '-withHover' : ''
            } ${sidebarCard ? '-sidebarCard' : ''} ${
                !children ? '-withOutBody' : ''
            }`}
        >
            {customImage && <div className="-customImage">{customImage}</div>}
            <div className="column">
                <div className="space-between a-stepCard__head">
                    <div
                        className={`-title ${inModal ? '-modal' : ''} ${
                            fullTitle ? '-fullTitle' : ''
                        } ${largeTitle ? '-largeTitle' : ''} ${
                            titleInTwoLines ? '-titleInTwoLines' : ''
                        } ${customIcon ? '-customIcon' : ''} ${
                            identityVerified ? 'aligned-start' : ''
                        }`}
                    >
                        {withAvatar && !link && (
                            <div
                                className={`-avatarImage ${
                                    !avatar ? '-noAvatar' : ''
                                }`}
                            >
                                {avatar ? (
                                    <img src={`${BASE_URL}${avatar}`} />
                                ) : (
                                    <Icon
                                        name={placeholderIcon || ICONS.USER}
                                        iconSize={ICON_SIZE.SIZE20}
                                        color={COLORS.DARK_BLUE_60}
                                    />
                                )}
                            </div>
                        )}
                        {link ? (
                            <Link
                                className={`a-link a-bodyTextMedium aligned-center justify-center -value -valueWithoutColor ${
                                    icon ? '-icon' : ''
                                }`}
                                to={link}
                            >
                                {withAvatar && (
                                    <div
                                        className={`-avatarImage ${
                                            !avatar ? '-noAvatar' : ''
                                        }`}
                                    >
                                        {avatar ? (
                                            <img src={`${BASE_URL}${avatar}`} />
                                        ) : (
                                            <Icon
                                                name={
                                                    placeholderIcon ||
                                                    ICONS.USER
                                                }
                                                iconSize={ICON_SIZE.SIZE20}
                                                color={COLORS.DARK_BLUE_60}
                                            />
                                        )}
                                    </div>
                                )}
                                {titleContent()}
                            </Link>
                        ) : (
                            <Fragment>{titleContent()}</Fragment>
                        )}
                        {identityVerified && (
                            <span className="verifiedIcon">
                                <Icon name={ICONS.VERIFICATION_PROFILE} />
                            </span>
                        )}
                    </div>
                    {(statuses.length !== 0 ||
                        actions?.length !== 0 ||
                        headerActions?.length !== 0) && (
                        <div
                            className={`-status ${
                                statuses.length !== 0 ? 'fullWidth' : ''
                            }`}
                        >
                            {/* {hideShowRecord && (
                        <div>
                            <IconButton
                                icon={setShowHideButtonIcon()}
                                iconSize={ICON_SIZE.SIZE16}
                                iconColor={COLORS.PRIMARY}
                                className="-small"
                                onClick={handleClick}
                                tooltip={
                                    hideRecord
                                        ? 'general.showExpiredCertificate'
                                        : 'general.hideExpiredCertificate'
                                }
                                iconLabel={
                                    hideRecord ? 'general.show' : 'general.hide'
                                }
                            />
                        </div>
                    )} */}

                            {!statusActionsCombined &&
                                statuses.map(
                                    ({ name, icon, color }, index) =>
                                        name[0] && (
                                            <div
                                                key={index}
                                                className={`a-status a-captionsTextRegular ${
                                                    color ? `-${color}` : ''
                                                }`}
                                            >
                                                {icon && (
                                                    <Icon
                                                        name={
                                                            ICONS.CHEVRON_DOWN_CIRCLE
                                                        }
                                                    />
                                                )}
                                                {name && <span>{name}</span>}
                                            </div>
                                        )
                                )}

                            {headerActions?.map((action, index) => (
                                <Fragment key={index}>
                                    {renderSingleAction(
                                        action,
                                        '-headerAction'
                                    )}
                                </Fragment>
                            ))}

                            {statusActionsCombined && actions?.length > 1 && (
                                <StatusActions
                                    actions={actions}
                                    statuses={statuses}
                                    item={item}
                                />
                            )}

                            {!statusActionsCombined &&
                                actions?.length === 1 &&
                                renderSingleAction(actions[0])}

                            {!statusActionsCombined && actions?.length > 1 && (
                                <DropdownActions
                                    actions={actions}
                                    item={item}
                                />
                            )}
                        </div>
                    )}
                </div>
                {children && <div className="a-stepCard__body">{children}</div>}
            </div>
            {cardContainerLink && (
                <Link
                    to={cardContainerLink}
                    className="-cardContainerLink"
                ></Link>
            )}
        </div>
    )
}

CardContainer.propTypes = {
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    title: PropTypes.string,
    statuses: PropTypes.array,
    actions: PropTypes.array,
    headerActions: PropTypes.array,
    item: PropTypes.object,
    recordSummary: PropTypes.bool,
    insideCardContainer: PropTypes.bool,
    noBorderCard: PropTypes.bool,
    withAvatar: PropTypes.bool,
    avatar: PropTypes.string,
    placeholderIcon: PropTypes.string,
    link: PropTypes.string,
    hideShowRecord: PropTypes.bool,
    inModal: PropTypes.bool,
    verifiedName: PropTypes.bool,
    subtitle: PropTypes.string,
    subtitle2: PropTypes.string,
    fullTitle: PropTypes.bool,
    largeTitle: PropTypes.bool,
    sidebarCard: PropTypes.bool,
    titleInTwoLines: PropTypes.bool,
    captionSubtitle: PropTypes.bool,
    customIcon: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

CardContainer.defaultProps = {
    iconColor: COLORS.GRAY,
    iconSize: ICON_SIZE.SIZE20,
    statuses: [],
    actions: [],
    headerActions: [],
    item: {},
    recordSummary: false,
    insideCardContainer: false,
    noBorderCard: false,
    withAvatar: false,
    link: '',
    hideShowRecord: false,
    inModal: false,
    verifiedName: false,
    fullTitle: false,
    largeTitle: false,
    sidebarCard: false,
    titleInTwoLines: false,
    captionSubtitle: false,
}

export default CardContainer
