import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import { ACCESS_CONTROL_STATUSES } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import ChangeAccessAction from './ChangeAccessAction'
import Icon from 'components/Icon'

const { NEW } = ACCESS_CONTROL_STATUSES

const AccessControlActions = ({ item, fetchData }) => {
    const t = useTranslate()

    const { id, status } = item

    const handleDenyAccess = async () => {
        try {
            await editEntityService(ENTITIES.DATA_ACCESS_REQUEST, id, {
                status: ACCESS_CONTROL_STATUSES.REJECTED.id,
            })
            fetchData()
        } catch (error) {
            console.log('error >> ', error)
        }
    }

    return (
        <div className="-accessControlActions">
            <ChangeAccessAction item={item} fetchData={fetchData} />
            {status === NEW.id && (
                <div
                    className="a-access__select -deny"
                    onClick={handleDenyAccess}
                >
                    <Icon name={ICONS.DENY_ACCESS} />
                    {t('general.denyAccess')}
                </div>
            )}
        </div>
    )
}

AccessControlActions.propTypes = {
    item: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
}

export default AccessControlActions
