import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    DIVE_RECORD_STATUS,
    ICON_POSITION,
    INFO_VALUE_TYPE,
    POSITIONS,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { DIVE_RECORD_STEPS } from 'screens/diver/diveRecord/constants/diveRecordSteps'
import { formatUrl } from 'utils/jsonApiFormatters'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    createEntityService,
    deleteEntityService,
} from 'services/entity.service'
import { formatDate } from 'services/moment.service'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import VerificationLinkModal from './VerificationLink'
import VerificationLevelIcon from 'components/VerificationLevelIcon'

const DiveRecordListCard = ({ diveRecord, fetchData, sidebarList }) => {
    const { setAlert } = useContext(AlertContext)
    const { userId, isContractor } = useContext(CurrentUserContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const t = useTranslate()
    const navigate = useNavigate()

    const [openVerificationLink, setOpenVerificationLink] = useState(false)

    const copyDiveRecord = async ({ id }) => {
        try {
            await createEntityService(
                `${ENTITIES.COPY_DIVE_RECORD}/${id}`,
                null,
                false
            )
            fetchData()
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR, t)
        }
    }

    const projectId = diveRecord.projectId

    const creator = {
        id: diveRecord.creatorId,
        fullName: diveRecord.creatorFullName,
        avatarPath: diveRecord.avatarPath,
        profileHash: diveRecord.creatorProfileHash,
    }

    const urlParams = {
        role: diveRecord.diveRecordType,
        divingMode: diveRecord.divingModeId,
    }

    const editDiveRecord = () => {
        const lastProgressStepRoute =
            DIVE_RECORD_STEPS.find((item) => item.id === diveRecord.currentStep)
                ?.route ?? DIVE_RECORD_STEPS[0].route

        projectId
            ? navigate(
                  formatUrl(
                      `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                      urlParams
                  )
              )
            : navigate(
                  formatUrl(
                      `/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                      urlParams
                  )
              )
    }

    const verifyDiveRecord = () => {
        navigate(
            `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD_SUMMARY}/${diveRecord.id}/${DIVE_RECORD_STEPS[0].route}`
        )
    }

    const deleteDiveRecord = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteRecord',
            title: 'general.deleteRecord',
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.DIVE_RECORD, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const shareVerificationLink = () => {
        setOpenVerificationLink(true)
    }

    const getStatusInfo = () => {
        switch (diveRecord.status) {
            case DIVE_RECORD_STATUS.SUBMITTED.id:
                return {
                    statusText: t('general.diveRecordStatusSubmitted'),
                    statusColor: 'blue',
                }
            case DIVE_RECORD_STATUS.ACCEPTED.id:
                return {
                    statusText: diveRecord.projectId
                        ? t('general.diveRecordStatusAccepted')
                        : t('general.verifiedLevel1'),
                    statusColor: 'green',
                }
            case DIVE_RECORD_STATUS.REJECTED.id:
                return {
                    statusText: t('general.diveRecordStatusRejected'),
                    statusColor: 'red',
                }
            case DIVE_RECORD_STATUS.COMPLETED.id:
                return {
                    statusText: t('general.diveRecordStatusCompleted'),
                    statusColor: 'green',
                }
            case DIVE_RECORD_STATUS.DRAFT.id:
            default:
                return {
                    statusText: t('general.diveRecordStatusDraft'),
                    statusColor: 'gray',
                }
        }
    }

    const { statusText, statusColor } = getStatusInfo()

    const getActions = () => {
        if (isContractor || creator.id !== userId) {
            return []
        }

        const actions = [
            {
                handleAction: copyDiveRecord,
                icon: ICONS.COPY,
                tooltip: 'button.diveRecordCopy',
            },
        ]

        if (canContinueEditing()) {
            actions.push(
                {
                    handleAction: editDiveRecord,
                    icon: ICONS.EDIT,
                    iconColor: COLORS.PRIMARY,
                    tooltip: 'button.diveRecordEdit',
                },
                {
                    handleAction: deleteDiveRecord,
                    icon: ICONS.DELETE,
                    iconColor: COLORS.RED,
                    tooltip: 'button.diveRecordDelete',
                }
            )
        }

        return actions
    }

    const getHeaderActions = () => {
        const actions = []

        if (isContractor || creator.id !== userId) {
            return actions
        }

        if (isCompletedHistoricalDiveRecord) {
            actions.push({
                handleAction: shareVerificationLink,
                icon: ICONS.VERIFICATION_SHARE,
                iconColor: COLORS.PRIMARY,
                tooltip: 'button.sendForVerificationOutside',
            })
        }

        return actions
    }

    // If the dive record status is COMPLETED and there is no projectId, then it is a Historical dive record,
    // and if the status is COMPLETED and there is a projectId, then it is a Supervisor dive record.
    const isCompletedHistoricalDiveRecord =
        diveRecord.status === DIVE_RECORD_STATUS.COMPLETED.id && !projectId

    const isCompletedSupervisorDiveRecord =
        diveRecord.status === DIVE_RECORD_STATUS.COMPLETED.id && projectId

    const canContinueEditing = () => {
        if (isContractor || creator.id !== userId) {
            return false
        }

        return (
            diveRecord.status === DIVE_RECORD_STATUS.DRAFT.id ||
            diveRecord.status === DIVE_RECORD_STATUS.REJECTED.id ||
            isCompletedSupervisorDiveRecord
        )
    }

    const canViewDiveSummary =
        diveRecord.status === DIVE_RECORD_STATUS.ACCEPTED.id ||
        (diveRecord.status === DIVE_RECORD_STATUS.COMPLETED.id && !projectId) ||
        (creator.id === userId &&
            diveRecord.status === DIVE_RECORD_STATUS.SUBMITTED.id) ||
        (isContractor && diveRecord.status === DIVE_RECORD_STATUS.COMPLETED.id)

    const supervisor = diveRecord.supervisorId
        ? {
              id: diveRecord.supervisorId,
              fullName: diveRecord.supervisorFullName,
              avatarPath: diveRecord.supervisorAvatarPath,
              profileHash: diveRecord.supervisorProfileHash,
          }
        : null

    const canDoVerification =
        supervisor?.id === userId &&
        diveRecord.status === DIVE_RECORD_STATUS.SUBMITTED.id

    const dateOfDive = formatDate(diveRecord.dateOfDive)

    const renderRoleName = (roleNumber) => {
        const roleKey = Object.keys(POSITIONS).find(
            (key) => POSITIONS[key].id === roleNumber
        )
        return POSITIONS[roleKey].name
    }

    const viewDiveSummaryLink = `${
        projectId ? `${ROUTES.PROJECT}/${projectId}` : ''
    }/${ROUTES.DIVE_RECORDS}/${diveRecord.id}`

    const continueFillingOutLink = () => {
        const lastProgressStepRoute =
            DIVE_RECORD_STEPS.find((item) => item.id === diveRecord.currentStep)
                ?.route ?? DIVE_RECORD_STEPS[0].route

        return projectId
            ? formatUrl(
                  `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                  urlParams
              )
            : formatUrl(
                  `/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                  urlParams
              )
    }

    const renderCardLink = () => {
        if (canViewDiveSummary) {
            return viewDiveSummaryLink
        }
        if (canContinueEditing()) {
            return continueFillingOutLink()
        }
        if (canDoVerification) {
            return `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD_SUMMARY}/${diveRecord.id}/${DIVE_RECORD_STEPS[0].route}`
        }
        return ''
    }

    const diveRecordName =
        (diveRecord?.projectName ? diveRecord.projectName + ' - ' : '') +
        (diveRecord.dateOfDive ? dateOfDive + ' - ' : '') +
        '#' +
        diveRecord.recordNumber

    return (
        <CardContainer
            customIcon={
                diveRecord.status !== DIVE_RECORD_STATUS.DRAFT.id && (
                    <VerificationLevelIcon
                        levelOfVerification={diveRecord.verifiedLevel}
                    />
                )
            }
            title={diveRecordName}
            item={diveRecord}
            actions={!sidebarList ? getActions() : null}
            headerActions={getHeaderActions()}
            statuses={[
                {
                    type: 'status',
                    name: statusText,
                    color: statusColor,
                },
            ]}
            cardContainerLink={renderCardLink()}
            sidebarCard={sidebarList}
            titleInTwoLines
        >
            {sidebarList && (
                <InfoRow>
                    {diveRecord.divingModeId && (
                        <Info
                            value={diveRecord.divingModeName}
                            color="orange"
                        />
                    )}
                    {diveRecord.diveRecordType && (
                        <Info
                            value={renderRoleName(diveRecord.diveRecordType)}
                            color="purple"
                        />
                    )}
                </InfoRow>
            )}
            {diveRecord.divingModeId && !sidebarList && (
                <Info
                    label="form.label.divingMode"
                    value={diveRecord.divingModeName}
                    color="orange"
                />
            )}
            {diveRecord.diveRecordType && !sidebarList && (
                <Info
                    label="form.label.divingRole"
                    value={renderRoleName(diveRecord.diveRecordType)}
                    color="purple"
                />
            )}

            {creator && creator.id !== userId && (
                <>
                    <Info
                        label={`${!sidebarList ? 'form.label.diver' : ''}`}
                        value={creator}
                        valueType={INFO_VALUE_TYPE.DIVER_PROFILE_LINK}
                        iconName={
                            diveRecord.diveRecordType === 2
                                ? ICONS.USER_SUPERVISOR
                                : ICONS.USER_DIVER
                        }
                    />
                </>
            )}

            {supervisor && supervisor.id !== userId && (
                <Info
                    label={`${!sidebarList ? 'form.label.diveSupervisor' : ''}`}
                    value={supervisor}
                    valueType={INFO_VALUE_TYPE.DIVER_PROFILE_LINK}
                    verifiedUser={supervisor.identityVerified}
                    iconName={ICONS.USER_SUPERVISOR}
                />
            )}

            {diveRecord.rejectComment && (
                <Info
                    label="form.label.commentFromSupervisor"
                    value={diveRecord.rejectComment}
                    column
                    descriptionText
                />
            )}

            {!sidebarList && (
                <InfoRow withButtons>
                    <div>
                        {canContinueEditing() && (
                            <Button
                                label={
                                    isCompletedSupervisorDiveRecord
                                        ? 'button.editDiveRecord'
                                        : 'button.diveRecordContinue'
                                }
                                buttonSize={BUTTON_SIZE.SMALL}
                                onClick={editDiveRecord}
                                btnClass={BUTTON_STATUS.TERTIARY}
                                icon={ICONS.ARROW_RIGHT}
                                iconColor={COLORS.SECONDARY}
                                iconPosition={ICON_POSITION.RIGHT}
                            />
                        )}
                        {canDoVerification && (
                            <Button
                                label="button.diveRecordVerify"
                                buttonSize={BUTTON_SIZE.SMALL}
                                onClick={verifyDiveRecord}
                                btnClass={BUTTON_STATUS.TERTIARY}
                                icon={ICONS.ARROW_RIGHT}
                                iconColor={COLORS.SECONDARY}
                                iconPosition={ICON_POSITION.RIGHT}
                            />
                        )}
                    </div>
                </InfoRow>
            )}
            {sidebarList && canContinueEditing() && (
                <div className="-mt15 btnLeftAlign">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        icon={ICONS.ARROW_RIGHT}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        label="button.continueFillingOut"
                        onClick={editDiveRecord}
                    />
                </div>
            )}
            {openVerificationLink && (
                <VerificationLinkModal
                    setOpen={setOpenVerificationLink}
                    diveRecord={diveRecord}
                    diveRecordName={diveRecordName}
                />
            )}
        </CardContainer>
    )
}

DiveRecordListCard.propTypes = {
    diveRecord: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    sidebarList: PropTypes.bool,
}

export default DiveRecordListCard
