import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import ROUTES from 'constants/routes'
import { TAB } from '../constants/tabs'

import CompleteProfile from 'components/CompleteProfile'

const ProfileCompletion = ({ userID, user, myProfile, profileHash }) => {
    const { isSmallScreen } = useWindowDimensions()

    const {
        completionProgress: {
            medicalInfo,
            personalInfo,
            travelDocument,
            trainingRecord,
            skill,
        },
    } = user

    const tabs = [
        {
            tab: TAB.PERSONAL_INFO,
            key: 'personalInfo',
            value: personalInfo,
            route: ROUTES.DIVER_PROFILE,
            params: { tab: TAB.PERSONAL_INFO },
            message: 'general.addMoreToPersonalInfo',
            additionalMessage: 'general.completePersonalInfo',
        },
        {
            tab: TAB.MEDICAL_INFO,
            key: 'medicalInfo',
            value: medicalInfo,
            route: ROUTES.DIVER_PROFILE,
            params: { tab: TAB.MEDICAL_INFO },
            message: 'general.addMoreToMedicalInfo',
            additionalMessage: 'general.completeMedicalInfo',
        },
        {
            tab: TAB.TRAVEL_DOCUMENTATION,
            key: 'travelDocument',
            value: travelDocument,
            route: ROUTES.DIVER_PROFILE,
            params: { tab: TAB.TRAVEL_DOCUMENTATION },
            message: 'general.addPersonalId',
            additionalMessage: 'general.completeTravelDocument',
        },
        {
            tab: TAB.TRAINING_RECORD,
            key: 'trainingRecord',
            value: trainingRecord,
            route: ROUTES.DIVER_PROFILE,
            params: { tab: TAB.TRAINING_RECORD },
            message: 'general.addEducationAndTrainingRecord',
            additionalMessage: 'general.completeTrainingRecord',
        },
        {
            tab: TAB.SKILLS,
            key: 'skill',
            value: skill,
            route: ROUTES.DIVER_PROFILE,
            params: { tab: TAB.SKILLS },
            message: 'general.addExperienceAndSkills',
            additionalMessage: 'general.completeSkills',
        },
    ]

    const completedSteps = tabs.filter(
        ({ key }) => user.completionProgress[key] !== 0
    )

    const stepsToComplete = tabs.filter(
        ({ key }) => user.completionProgress[key] === 0
    )

    if (!myProfile || stepsToComplete.length === 0) return null

    return (
        <div
            className={`${
                isSmallScreen ? 'm-boxes__main -mb20' : 'm-boxes__white'
            } -noPadding`}
        >
            <CompleteProfile
                title={'general.profileCompletion'}
                profilePage={true}
                showProgress={true}
                stepsToComplete={stepsToComplete}
                completedSteps={completedSteps}
                allSteps={tabs}
                entityId={userID}
                currentUser={user}
                profileHash={profileHash}
            />
        </div>
    )
}

ProfileCompletion.propTypes = {
    user: PropTypes.object,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    profileHash: PropTypes.string,
}

export default ProfileCompletion
