import { POSITIONS } from 'constants/enums'
import diverImg from 'assets/images/Diver.png'
import divingSupervisorImg from 'assets/images/Dive medic.png'
import diveMedicImg from 'assets/images/Dive medic (1).png'
import equipmentTechnician from 'assets/images/Equipment techinician.png'
import lifeSupportImg from 'assets/images/Life support.png'

const getPositionImage = (position) => {
    switch (position) {
        case POSITIONS.DIVER.name:
            return diverImg
        case POSITIONS.DIVING_SUPERVISOR.name:
            return divingSupervisorImg
        case POSITIONS.DIVING_MEDIC.name:
            return diveMedicImg
        case POSITIONS.EQUIPMENT_TECHNICIAN.name:
            return equipmentTechnician
        case POSITIONS.LIFE_SUPPORT.name:
            return lifeSupportImg
        default:
            break
    }
}


export { getPositionImage }