import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { validateMinutesNotRequired } from 'utils/yupValidations'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { CheckboxField, InputField } from 'components/formFields'
import { DIVING_MODES } from 'screens/diver/diveRecord/constants/divingModes'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const ExperienceFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext

    const {
        experienceDivingModes,
        totalDivesNumber,
        divesAir,
        divesNitrox,
        divesMixedGas,
    } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            experienceDivingModes?.forEach((item, index) => {
                if (
                    (!item.dives || item.dives < 1) &&
                    (!item.days || item.days < 1)
                ) {
                    Object.keys(item).forEach((k) => {
                        if (
                            k === 'bottomTimeHours' ||
                            k === 'bottomTimeMinutes' ||
                            k === 'bellRuns'
                        ) {
                            setFieldValue(
                                `experienceDivingModes[${index}].${k}`,
                                null
                            )
                            setFieldTouched(
                                `experienceDivingModes[${index}].${k}`,
                                false
                            )
                        }
                    })
                }
            })

            if (!totalDivesNumber) {
                const totalDives =
                    (parseInt(values.divesAir) || 0) +
                    (parseInt(values.divesNitrox) || 0) +
                    (parseInt(values.divesMixedGas) || 0)

                const totalBottomTimeHours =
                    (parseInt(values.bottomTimeHoursAir) || 0) +
                    (parseInt(values.bottomTimeHoursNitrox) || 0) +
                    (parseInt(values.bottomTimeHoursMixedGas) || 0)

                const totalBottomTimeMinutes =
                    (parseInt(values.bottomTimeMinutesAir) || 0) +
                    (parseInt(values.bottomTimeMinutesNitrox) || 0) +
                    (parseInt(values.bottomTimeMinutesMixedGas) || 0)

                const convertedHours = Math.floor(totalBottomTimeMinutes / 60)
                const convertedMinutes = totalBottomTimeMinutes % 60

                setFieldValue('divesSum', totalDives)
                setFieldValue(
                    'bottomTimeHoursSum',
                    totalBottomTimeHours + convertedHours
                )
                setFieldValue('bottomTimeMinutesSum', convertedMinutes)
            }

            if (totalDivesNumber) {
                setFieldValue('divesAir', '')
                setFieldValue('bottomTimeHoursAir', '')
                setFieldValue('bottomTimeMinutesAir', '')
                setFieldValue('divesNitrox', '')
                setFieldValue('bottomTimeHoursNitrox', '')
                setFieldValue('bottomTimeMinutesNitrox', '')
                setFieldValue('divesMixedGas', '')
                setFieldValue('bottomTimeHoursMixedGas', '')
                setFieldValue('bottomTimeMinutesMixedGas', '')
            }
        } else {
            firstRender.current = false
        }
    }, [experienceDivingModes, values, setFieldValue, totalDivesNumber])

    useEffect(() => {
        if (!firstRender.current && !divesAir) {
            setFieldValue('bottomTimeHoursAir', '')
            setFieldValue('bottomTimeMinutesAir', '')
        }
    }, [divesAir, setFieldValue])

    useEffect(() => {
        if (!firstRender.current && !divesNitrox) {
            setFieldValue('bottomTimeHoursNitrox', '')
            setFieldValue('bottomTimeMinutesNitrox', '')
        }
    }, [divesNitrox, setFieldValue])

    useEffect(() => {
        if (!firstRender.current && !divesMixedGas) {
            setFieldValue('bottomTimeHoursMixedGas', '')
            setFieldValue('bottomTimeMinutesMixedGas', '')
        }
    }, [divesMixedGas, setFieldValue])

    return null
}

const ExperienceForm = ({
    title,
    handleSubmit,
    initialData,
    setOpen,
    open,
    divingMode,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const requiredMessage = t('form.error.required')
    const [experienceError, setExperienceError] = useState()

    const closedBellSaturationDivingMode =
        divingMode?.id === DIVING_MODES.CLOSED_BELL_SATURATION.id

    const initialValues = {
        divesAir: initialData?.divesAir ?? '',
        bottomTimeHoursAir: initialData?.bottomTimeHoursAir ?? '',
        bottomTimeMinutesAir: initialData?.bottomTimeMinutesAir ?? '',
        divesNitrox: initialData?.divesNitrox ?? '',
        bottomTimeHoursNitrox: initialData?.bottomTimeHoursNitrox ?? '',
        bottomTimeMinutesNitrox: initialData?.bottomTimeMinutesNitrox ?? '',
        divesMixedGas: initialData?.divesMixedGas ?? '',
        bottomTimeHoursMixedGas: initialData?.bottomTimeHoursMixedGas ?? '',
        bottomTimeMinutesMixedGas: initialData?.bottomTimeMinutesMixedGas ?? '',
        divesSum: initialData?.divesSum ?? '',
        bottomTimeHoursSum: initialData?.bottomTimeHoursSum ?? '',
        bottomTimeMinutesSum: initialData?.bottomTimeMinutesSum ?? '',
        totalDivesNumber: initialData?.totalDivesNumber ?? false,
        daysBell: initialData?.daysBell ?? '',
        bellRuns: initialData?.bellRuns ?? '',
        bottomTimeHoursBell: initialData?.bottomTimeHoursBell ?? '',
        bottomTimeMinutesBell: initialData?.bottomTimeMinutesBell ?? '',
    }

    const validateDivingMode = (
        shouldValidate,
        field,
        hoursField,
        minutesField
    ) => {
        if (!shouldValidate) return Yup.number()

        return Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .test(field, (value, { parent, createError }) => {
                if (
                    Number(value) > 0 &&
                    !Number(parent[hoursField]) &&
                    !Number(parent[minutesField])
                ) {
                    return createError({
                        message: t(
                            'form.error.diveExperienceBottomTimeDurationInvalid'
                        ),
                        path: field,
                    })
                }
                return true
            })
    }

    const validateDivingModeHours = (shouldValidate) => {
        if (!shouldValidate) return Yup.number()
        return Yup.number().min(0, t('form.error.invalidNumber'))
    }

    const validateDivingModeMinutes = (shouldValidate) => {
        if (!shouldValidate) return Yup.number()
        return validateMinutesNotRequired(t)
    }

    const validation = Yup.object({
        divesAir: validateDivingMode(
            !closedBellSaturationDivingMode,
            'divesAir',
            'bottomTimeHoursAir',
            'bottomTimeMinutesAir'
        ),
        bottomTimeHoursAir: validateDivingModeHours(
            !closedBellSaturationDivingMode
        ),
        bottomTimeMinutesAir: validateDivingModeMinutes(
            !closedBellSaturationDivingMode
        ),
        divesNitrox: validateDivingMode(
            !closedBellSaturationDivingMode,
            'divesNitrox',
            'bottomTimeHoursNitrox',
            'bottomTimeMinutesNitrox'
        ),
        bottomTimeHoursNitrox: validateDivingModeHours(
            !closedBellSaturationDivingMode
        ),
        bottomTimeMinutesNitrox: validateDivingModeMinutes(
            !closedBellSaturationDivingMode
        ),
        divesMixedGas: validateDivingMode(
            !closedBellSaturationDivingMode,
            'divesMixedGas',
            'bottomTimeHoursMixedGas',
            'bottomTimeMinutesMixedGas'
        ),
        bottomTimeHoursMixedGas: validateDivingModeHours(
            !closedBellSaturationDivingMode
        ),
        bottomTimeMinutesMixedGas: validateDivingModeMinutes(
            !closedBellSaturationDivingMode
        ),
        divesSum: Yup.number().when('totalDivesNumber', {
            is: (val) => val === true,
            then: () =>
                validateDivingMode(
                    !closedBellSaturationDivingMode,
                    'divesSum',
                    'bottomTimeHoursSum',
                    'bottomTimeMinutesSum'
                ),
            otherwise: () => Yup.number(),
        }),
        bottomTimeHoursSum: validateDivingModeHours(
            !closedBellSaturationDivingMode
        ),
        bottomTimeMinutesSum: validateDivingModeMinutes(
            !closedBellSaturationDivingMode
        ),
        daysBell: validateDivingMode(
            closedBellSaturationDivingMode,
            'daysBell',
            'bottomTimeHoursBell',
            'bottomTimeMinutesBell'
        ),
        bellRuns: validateDivingMode(
            closedBellSaturationDivingMode,
            'bellRuns',
            'bottomTimeHoursBell',
            'bottomTimeMinutesBell'
        ),
        bottomTimeHoursBell: validateDivingModeHours(
            closedBellSaturationDivingMode
        ),
        bottomTimeMinutesBell: validateDivingModeMinutes(
            closedBellSaturationDivingMode
        ),
    })

    const onSubmit = async (formData) => {
        try {
            if (
                !formData.divesAir &&
                !formData.divesNitrox &&
                !formData.divesMixedGas &&
                !formData.divesSum &&
                !closedBellSaturationDivingMode
            ) {
                setExperienceError(t('general.pleaseAddAtLeastOne'))
            } else if (
                closedBellSaturationDivingMode &&
                !formData.daysBell &&
                !formData.bellRuns
            ) {
                setExperienceError(
                    t('general.pleaseAddAtLeastOneDaysOrBellRuns')
                )
            } else {
                await handleSubmit({ ...formData, divingMode: divingMode })
                handleClose()
            }
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            closeOnClickOutside={false}
            type="experience-modal"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({
                    values: {
                        divesAir,
                        divesNitrox,
                        divesMixedGas,
                        divesSum,
                        totalDivesNumber,
                        daysBell,
                        bellRuns,
                    },
                    isSubmitting,
                }) => {
                    return (
                        <Form className="m-modal__form">
                            <div
                                className={`_wr ${
                                    totalDivesNumber
                                        ? '-totalDivesNumberActive'
                                        : '-totalDivesNumberDisabled'
                                }`}
                            >
                                <ExperienceFormContext
                                    initialValues={initialValues}
                                />
                                <FocusError />
                                <div className="-form _w">
                                    <h3 className="-mb20 _12">{title}</h3>
                                    {experienceError && (
                                        <span className="errorMessage -active _12 -mt10">
                                            {experienceError}
                                        </span>
                                    )}

                                    {closedBellSaturationDivingMode ? (
                                        <Fragment>
                                            <div className="_w -divingModes -mt20">
                                                <div className="_12 _l6">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="daysBell"
                                                        label="form.label.days"
                                                        placeholder="form.label.days"
                                                    />
                                                </div>

                                                <div className="_12 _l6">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bellRuns"
                                                        label="form.label.bellRuns"
                                                        placeholder="form.placeholder.bellRuns"
                                                    />
                                                </div>
                                                <div className="_6 _l3 -unitsWrapper">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeHoursBell"
                                                        label="form.label.bottomTime"
                                                        placeholder="form.placeholder.bottomTimeHours"
                                                        units="form.label.hours"
                                                        disabled={
                                                            !daysBell &&
                                                            !bellRuns
                                                        }
                                                    />
                                                </div>
                                                <div className="_6 _l3 fakeLabel">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeMinutesBell"
                                                        label="general.fakeLabel"
                                                        showLabel={true}
                                                        placeholder="form.placeholder.bottomTimeMinutes"
                                                        units="form.label.minutes"
                                                        disabled={
                                                            !daysBell &&
                                                            !bellRuns
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className="_w -divingModes -mt20">
                                                <div className="_12 _l6">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="divesAir"
                                                        label="form.label.divesWithAir"
                                                        placeholder="form.label.divesWithAir"
                                                        disabled={
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 -unitsWrapper">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeHoursAir"
                                                        label="form.label.bottomTime"
                                                        placeholder="form.placeholder.bottomTimeHours"
                                                        units="form.label.hours"
                                                        disabled={
                                                            !divesAir ||
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 fakeLabel">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeMinutesAir"
                                                        label="general.fakeLabel"
                                                        showLabel={true}
                                                        placeholder="form.placeholder.bottomTimeMinutes"
                                                        units="form.label.minutes"
                                                        disabled={
                                                            !divesAir ||
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="_w -divingModes -mt20">
                                                <div className="_12 _l6">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="divesNitrox"
                                                        label="form.label.divesWithNITROX"
                                                        placeholder="form.label.divesWithNITROX"
                                                        disabled={
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 -unitsWrapper">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeHoursNitrox"
                                                        label="form.label.bottomTime"
                                                        placeholder="form.placeholder.bottomTimeHours"
                                                        units="form.label.hours"
                                                        disabled={
                                                            !divesNitrox ||
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 fakeLabel">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeMinutesNitrox"
                                                        label="general.fakeLabel"
                                                        showLabel={true}
                                                        placeholder="form.placeholder.bottomTimeMinutes"
                                                        units="form.label.minutes"
                                                        disabled={
                                                            !divesNitrox ||
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="_w -divingModes -mt20">
                                                <div className="_12 _l6">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="divesMixedGas"
                                                        label="form.label.divesWithMixedGas"
                                                        placeholder="form.label.divesWithMixedGas"
                                                        disabled={
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 -unitsWrapper">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeHoursMixedGas"
                                                        label="form.label.bottomTime"
                                                        placeholder="form.placeholder.bottomTimeHours"
                                                        units="form.label.hours"
                                                        disabled={
                                                            !divesMixedGas ||
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 fakeLabel">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeMinutesMixedGas"
                                                        label="general.fakeLabel"
                                                        showLabel={true}
                                                        placeholder="form.placeholder.bottomTimeMinutes"
                                                        units="form.label.minutes"
                                                        disabled={
                                                            !divesMixedGas ||
                                                            totalDivesNumber
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="_w -divingModes -mt20 -totalNumbersOfDives">
                                                <div className="_12 _l6">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="divesSum"
                                                        label="form.label.totalNumberOfDives"
                                                        placeholder="form.label.totalNumberOfDives"
                                                        disabled={
                                                            !totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 -unitsWrapper">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeHoursSum"
                                                        label="form.label.totalBottomTime"
                                                        placeholder="form.placeholder.bottomTimeHours"
                                                        units="form.label.hours"
                                                        disabled={
                                                            !divesSum ||
                                                            !totalDivesNumber
                                                        }
                                                    />
                                                </div>

                                                <div className="_6 _l3 fakeLabel">
                                                    <InputField
                                                        type={
                                                            INPUT_FILED_TYPE.NUMBER
                                                        }
                                                        name="bottomTimeMinutesSum"
                                                        label="general.fakeLabel"
                                                        showLabel={true}
                                                        placeholder="form.placeholder.bottomTimeMinutes"
                                                        units="form.label.minutes"
                                                        disabled={
                                                            !divesSum ||
                                                            !totalDivesNumber
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="_w">
                                                <div className="_12">
                                                    <CheckboxField
                                                        name="totalDivesNumber"
                                                        label="form.label.onlyTotalNumberOfDives"
                                                        translate
                                                    />
                                                </div>
                                            </div>
                                            <div className="_w">
                                                <div className="_12">
                                                    <span className="a-captionsTextRegular a-lightText -opacity-60">
                                                        {t(
                                                            'general.totalYearsInfo'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}

                                    <div className="-mt10 -buttons space-between _12">
                                        <div className="_12 _xs6 -mt10">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.SECONDARY
                                                }
                                                type={BUTTON_TYPE.BUTTON}
                                                label="button.cancel"
                                                onClick={() => {
                                                    setOpen(false)
                                                }}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="_12 _xs6 -mt10">
                                            <Button
                                                btnClass={BUTTON_STATUS.PRIMARY}
                                                type={BUTTON_TYPE.SUBMIT}
                                                label="button.saveExperience"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isSubmitting && <Loader />}
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

ExperienceForm.propTypes = {
    title: PropTypes.string,
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    divingModes: PropTypes.array,
}

export default ExperienceForm
