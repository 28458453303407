import React from 'react'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import { createEntityService } from 'services/entity.service'
import { getProjectBreadcrumbs } from '../utils/getProjectBreadcrumbs'

import useWindowDimensions from 'hooks/useWindowDimension'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import ProjectForm from './components/ProjectForm'
import Breadcrumbs from 'components/Breadcrumbs'

const CreateProject = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { isTablet } = useWindowDimensions()

    const handleSubmit = async (formData) => {
        const newProject = await createEntityService(
            ENTITIES.PROJECT,
            formData,
            true,
            ['projectPositions']
        )
        navigate(`${ROUTES.PROJECT}/${newProject?.data.id}`)
    }

    const handleClose = () => {
        navigate(-1)
    }
    return (
        <div>
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={getProjectBreadcrumbs(ROUTES.CREATE, pathname)}
                />
            </div>
            <div className="m-project m-boxes__main -fullWidth">
                {isTablet && (
                    <span
                        onClick={handleClose}
                        className="removeItemIcon -large"
                    ></span>
                )}
                <div className="_wr">
                    <div className="_w">
                        <h3 className="_12">
                            {t('general.createDiveProject')}
                        </h3>
                    </div>
                </div>
                <ProjectForm handleSubmit={handleSubmit} />
            </div>
        </div>
    )
}

export default CreateProject
