import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import { TAB } from './constants/tabs'
import COLORS from 'constants/colors'

import TabNavigation from 'components/tabs/TabNavigation'
import CompanyInfo from './components/CompanyInfo'

const CompanyInformation = ({ myProfile, company }) => {
    const t = useTranslate()

    return (
        <div>
            <CompanyInfo myProfile={myProfile} company={company} />

            <div className="-mt20 -pt15 a-separator -top">
                <h3 className="a-bodyTextMedium -mb20">
                    {t('general.companyInformation')}
                </h3>
            </div>

            <TabNavigation
                tabs={[
                    {
                        key: TAB.ABOUT_COMPANY,
                        title: 'general.aboutCompany',
                        icon: `${ICONS.ABOUT_COMPANY}`,
                    },
                    {
                        key: TAB.DIVE_PROJECTS,
                        title: 'general.diveProjects',
                        icon: `${ICONS.DIVE_PROJECTS_OUTLINE}`,
                    },
                    {
                        key: TAB.FAVORITE_USERS,
                        title: 'general.favoriteUsers',
                        icon: `${ICONS.FAVORITES}`,
                        iconColor: COLORS.TRANSPARENT,
                        hidden: !myProfile,
                    },
                ]}
                tabQueryParam="tab"
                columnView
                scrollToTarget={false}
            />
        </div>
    )
}

CompanyInformation.propTypes = {
    myProfile: PropTypes.bool,
    company: PropTypes.object,
}

export default CompanyInformation
