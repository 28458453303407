import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'
import Step from './Step'

const Stepper = ({
    steps,
    activeStepIndex,
    currentStep,
    title,
    allowedStep,
    handleClick,
}) => {
    const progress = ((activeStepIndex * 100) / steps.length).toFixed()
    const t = useTranslate()

    return (
        <div className="a-stepper -mt25 -mb20">
            {title && (
                <div className={`a-stepper__top -mb20`}>
                    <h3 className="-title">{t(title)}</h3>
                </div>
            )}
            <div className="a-stepper__steps">
                {steps.map((step, index) => (
                    <Fragment key={step.id}>
                        <Step
                            index={index}
                            currentStep={currentStep}
                            {...step}
                            numberOfSteps={steps.length}
                            allowedStep={allowedStep}
                            handleClick={handleClick}
                            progress={Number(progress)}
                        />
                        {index !== steps.length - 1 && (
                            <div className="-arrowRight">
                                <Icon
                                    name={ICONS.CHEVRON_RIGHT}
                                    size={ICON_SIZE.SIZE18}
                                    color={COLORS.DARK_BLUE_60}
                                />
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

Stepper.propTypes = {
    steps: PropTypes.array.isRequired,
    activeStepIndex: PropTypes.number,
    currentStep: PropTypes.number.isRequired,
    title: PropTypes.string,
    allowedStep: PropTypes.number,
    handleClick: PropTypes.func,
}

export default Stepper
