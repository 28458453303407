import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import Modal from 'components/Modal'
import InviteDiverForm from './InviteDiverForm'
import ContractorPositionCard from './ContractorPositionCard'

const ContractorPositionList = ({
    projectPositions,
    refetchData,
    isSideCard,
}) => {
    const t = useTranslate()

    const [choosedPosition, setChoosedPosition] = useState(null)

    const { data, meta, isLoading, loadMore, fetchData } = projectPositions

    const handleClose = () => setChoosedPosition(null)

    const handleOpen = (position) => setChoosedPosition(position)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    return (
        <div>
            {data.map((position, index) => {
                return (
                    <div key={index}>
                        <ContractorPositionCard
                            data={position}
                            handleAction={() => handleOpen(position)}
                            isSideCard={isSideCard}
                        />
                    </div>
                )
            })}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {data.length === 0 && (
                <span className="a-mediumText a-lightText -opacity-60 justify-center">
                    {t('general.allPositionsAssigned')}
                </span>
            )}
            {!!choosedPosition && (
                <Modal
                    open={!!choosedPosition}
                    setOpen={setChoosedPosition}
                    closeOnClickOutside={false}
                    title="general.inviteDiver"
                    type="-confirmModal -smallModal"
                >
                    <InviteDiverForm
                        data={data}
                        choosedItem={choosedPosition}
                        setChoosedItem={setChoosedPosition}
                        handleClose={handleClose}
                        refetchData={refetchData}
                    />
                </Modal>
            )}
        </div>
    )
}

ContractorPositionList.propTypes = {
    projectPositions: PropTypes.object,
    refetchData: PropTypes.func,
    isSideCard: PropTypes.bool,
}

ContractorPositionList.defaultProps = {
    isSideCard: false,
}

export default ContractorPositionList
