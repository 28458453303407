import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import loadable from 'utils/loadable'

import ROUTES from 'constants/routes'

import getCommonUserRoutes from './getCommonUserRoutes'

import { AuthLayout, FullScreenLayout } from 'components/layouts'
import { MyDiveProjects } from 'screens/diver/MyDiveProjects'
import {
    DiveProfileRecord,
    DiveRecord,
    DiveSummaryRecord,
    EquipmentRecord,
    GeneralInformationRecord,
    WorkPerformedRecord,
    RecordVerification,
    GeneralInformationVerification,
    EquipmentVerification,
    DiveProfileVerification,
    WorkPerformedVerification,
    DiveSummaryVerification,
} from 'screens/diver/diveRecord'
import {
    MedicalInfoAccessControl,
    PastEmploymentAccessControl,
    PersonalEquipmentAccessControl,
    Settings,
    TrainingRecordsAccessControl,
    TravelDocumentsAccessControl,
    UnitsOfMeasurement,
} from 'screens/diver/settings'
import MyDiveRecords from 'screens/diver/myDiveRecords/MyDiveRecords'
import DiveRecordSummary from 'screens/diver/DiveRecordSummary'
import { GalleryPage } from 'screens/common/gallery'
import { ChatPage } from 'screens/common/chat/ChatPage'

const Homepage = loadable(() => import('screens/diver/Homepage/Homepage'))

const getDiveRecordRoutes = () => (
    <Fragment>
        <Route
            path={ROUTES.GENERAL_INFORMATION}
            element={<GeneralInformationRecord />}
        />
        <Route path={ROUTES.EQUIPMENT} element={<EquipmentRecord />} />
        <Route path={ROUTES.DIVE_PROFILE} element={<DiveProfileRecord />} />
        <Route path={ROUTES.WORK_PERFORMED} element={<WorkPerformedRecord />} />
        <Route path={ROUTES.DIVE_SUMMARY} element={<DiveSummaryRecord />} />
    </Fragment>
)

export const getDiveRecordVerificationRoutes = () => (
    <Fragment>
        <Route
            path={ROUTES.GENERAL_INFORMATION}
            element={<GeneralInformationVerification />}
        />
        <Route path={ROUTES.EQUIPMENT} element={<EquipmentVerification />} />
        <Route
            path={ROUTES.DIVE_PROFILE}
            element={<DiveProfileVerification />}
        />
        <Route
            path={ROUTES.WORK_PERFORMED}
            element={<WorkPerformedVerification />}
        />
        <Route
            path={ROUTES.DIVE_SUMMARY}
            element={<DiveSummaryVerification />}
        />
        <Route path={ROUTES.VERIFICATION} element={<RecordVerification />} />
    </Fragment>
)

export const getHistoricalDiveRecordVerificationRoute = () => (
    <Route
        path={`${ROUTES.DIVE_RECORD_SUMMARY}/:verificationToken`}
        element={<DiveRecord />}
    >
        {getDiveRecordVerificationRoutes()}
    </Route>
)

const DiverRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path={ROUTES.HOME} element={<Homepage />} />
                <Route
                    path={ROUTES.MY_DIVE_PROJECTS}
                    element={<MyDiveProjects />}
                />
                <Route path={ROUTES.DIVE_RECORDS} element={<MyDiveRecords />} />
                <Route path={ROUTES.SETTINGS} element={<Settings />}>
                    <Route index element={<UnitsOfMeasurement />} />
                    <Route
                        path={ROUTES.UNITS_OF_MEASUREMENT}
                        element={<UnitsOfMeasurement />}
                    />
                    <Route
                        path={`${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.MEDICAL_INFO}`}
                        element={<MedicalInfoAccessControl />}
                    />
                    <Route
                        path={`${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAVEL_DOCUMENTATION}`}
                        element={<TravelDocumentsAccessControl />}
                    />
                    <Route
                        path={`${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAINING_RECORDS}`}
                        element={<TrainingRecordsAccessControl />}
                    />
                    <Route
                        path={`${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PERSONAL_EQUIPMENT}`}
                        element={<PersonalEquipmentAccessControl />}
                    />
                    <Route
                        path={`${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PAST_EMPLOYMENT}`}
                        element={<PastEmploymentAccessControl />}
                    />
                </Route>
                <Route
                    path={`${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORDS}/:diveRecordId`}
                    element={<DiveRecordSummary />}
                />
                <Route
                    path={`${ROUTES.DIVE_RECORDS}/:diveRecordId`}
                    element={<DiveRecordSummary />}
                />
                <Route path={ROUTES.GALLERY} element={<GalleryPage />} />
                <Route path={ROUTES.CHAT} element={<ChatPage />} />
            </Route>
            <Route element={<FullScreenLayout />}>
                <Route
                    path={`${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORD}/:diveRecordId?`}
                    element={<DiveRecord />}
                >
                    {getDiveRecordRoutes()}
                </Route>
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.DIVE_RECORD}/:diveRecordId?`}
                    element={<DiveRecord />}
                >
                    {getDiveRecordRoutes()}
                </Route>
                <Route
                    path={`/${ROUTES.DIVE_RECORD}/:diveRecordId?`}
                    element={<DiveRecord />}
                >
                    {getDiveRecordRoutes()}
                </Route>
                <Route
                    path={`${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORD_SUMMARY}/:diveRecordId`}
                    element={<DiveRecord />}
                >
                    {getDiveRecordVerificationRoutes()}
                </Route>
                {getHistoricalDiveRecordVerificationRoute()}
            </Route>
            {getCommonUserRoutes()}
        </Routes>
    )
}

export default DiverRoutes
