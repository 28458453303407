import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import Separator from 'components/Separator'

const EquipmentSummary = ({
    data,
    diveRecordId,
    unitImperial,
    verification,
}) => {
    const t = useTranslate()

    if (!data) return null

    const unitOfMeasurementBarOrPsi = unitImperial
        ? t('form.label.psi')
        : t('form.label.bar')

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.in')
        : t('form.label.mm')

    const unitOfMeasurementLabelForWeight = unitImperial
        ? t('form.label.lbs')
        : t('form.label.kg')

    const otherEquipmentOutfittings = data?.otherEquipmentOutfittings?.map(
        (item) => item.name
    )

    const buoyancyFlotations = data?.buoyancyFlotations?.map(
        (item) => item.name
    )

    const apparelThickness = data.apparelThickness
        ? `, ${data.apparelThickness}${unitOfMeasurementLabel}`
        : ''

    const divingApparelProtection = `${data?.divingApparelProtection?.name}${apparelThickness}`

    return (
        <CardContainer recordSummary noBorderCard={!verification}>
            {data?.breathingApparatusType && (
                <>
                    <span className="-mb15 a-bodyTextRegular">
                        {t('general.breathingApparatusType')}
                    </span>
                    <Info
                        label="general.breathingApparatusType"
                        value={data.breathingApparatusType.name}
                    />

                    <Separator fullWidth />
                </>
            )}
            {(buoyancyFlotations?.length > 0 ||
                data?.buoyancyTotalWeight ||
                data?.divingApparelProtection ||
                otherEquipmentOutfittings?.length > 0) && (
                <span className="-mb10 a-bodyTextRegular">
                    {t('general.equipment')}
                </span>
            )}
            {data?.divingApparelProtection && (
                <Info
                    label="form.label.divingApparelProtection"
                    value={divingApparelProtection}
                    withBorder
                    withSpaceBetween
                />
            )}
            {buoyancyFlotations?.length > 0 && (
                <Info
                    label="form.placeholder.buoyancyFlotations"
                    value={buoyancyFlotations}
                    color="gray"
                    withBorder
                    withSpaceBetween
                />
            )}
            {data.buoyancyTotalWeight && (
                <Info
                    label="general.buoyancyTotalWeight"
                    value={`${data.buoyancyTotalWeight}${unitOfMeasurementLabelForWeight}`}
                    withBorder={otherEquipmentOutfittings?.length > 0}
                    withSpaceBetween
                />
            )}
            {otherEquipmentOutfittings?.length > 0 && (
                <Info
                    label="form.label.otherEquipmentOutfittings"
                    value={otherEquipmentOutfittings}
                    color="gray"
                    withSpaceBetween
                />
            )}
            {(buoyancyFlotations?.length > 0 ||
                data?.buoyancyTotalWeight ||
                data?.divingApparelProtection ||
                otherEquipmentOutfittings?.length > 0) && (
                <Separator fullWidth />
            )}
            <span className="a-bodyTextRegular">
                {t('general.gasCylindersUsed')}
            </span>
            {data.gasCylinders?.map(
                (
                    {
                        id,
                        displayName,
                        size,
                        startPressure,
                        endPressure,
                        gasCylinderPurpose,
                    },
                    index
                ) => (
                    <div className="-mt20" key={id}>
                        <CardContainer
                            title={`${t('general.gasCylinder')} ${index + 1}`}
                            insideCardContainer
                        >
                            <Info
                                label="form.label.gasCylinderPurpose"
                                value={gasCylinderPurpose.name}
                                withBorder
                                withSpaceBetween
                            />
                            {size && (
                                <Info
                                    label="form.label.size"
                                    value={size}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {startPressure && (
                                <Info
                                    label="form.label.startPressure"
                                    value={`${startPressure}${unitOfMeasurementBarOrPsi}`}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {endPressure && (
                                <Info
                                    label="form.label.endPressure"
                                    value={`${endPressure}${unitOfMeasurementBarOrPsi}`}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.gasContents"
                                value={displayName}
                                withSpaceBetween
                            />
                        </CardContainer>
                    </div>
                )
            )}
        </CardContainer>
    )
}

EquipmentSummary.propTypes = {
    data: PropTypes.object,
    diveRecordId: PropTypes.number,
    unitImperial: PropTypes.bool,
    verification: PropTypes.bool,
}

export default EquipmentSummary
