import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'

import { persistEntityService } from 'services/entity.service'

import {
    positiveNumberAndLessThan100Validation,
    validate24Hours,
    validateMinutes,
} from 'utils/yupValidations'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { InputField } from 'components/formFields'
import Loader from 'components/Loader'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'

const ChamberEventFormModal = ({ initialData, closeModal, modalData }) => {
    const t = useTranslate()

    const { diveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)

    const {
        data: { diveRecordDiveData, unitImperial },
    } = diveRecord

    const {
        entity,
        fetchData,
        timeStartLabel,
        timeEndLabel,
        holdDeptsTimeLabel,
        cardTitle,
    } = modalData

    const initialValues = {
        chamberInitialDepth: initialData?.chamberInitialDepth ?? '',
        timeStartHours: initialData?.timeStartHours ?? '',
        timeStartMinutes: initialData?.timeStartMinutes ?? '',
        chamberInitialO2: initialData?.chamberInitialO2 ?? '',
        chamberFinalDepth: initialData?.chamberFinalDepth ?? '',
        timeStopHours: initialData?.timeStopHours ?? '',
        timeStopMinutes: initialData?.timeStopMinutes ?? '',
        chamberFinalO2: initialData?.chamberFinalO2 ?? '',
        holdDepthsTime: initialData?.holdDepthsTime ?? '',
        comment: initialData?.comment ?? '',
    }

    const requiredMessage = t('form.error.required')

    const positiveNumberValidation = Yup.number()
        .required(requiredMessage)
        .positive(t('form.error.positiveNumber'))

    const validation = Yup.object({
        chamberInitialDepth: positiveNumberValidation,
        timeStartHours: validate24Hours(t),
        timeStartMinutes: validateMinutes(t),
        chamberInitialO2: positiveNumberAndLessThan100Validation(
            t,
            requiredMessage
        ),
        chamberFinalDepth: positiveNumberValidation,
        timeStopHours: validate24Hours(t),
        timeStopMinutes: validateMinutes(t),
        chamberFinalO2: positiveNumberAndLessThan100Validation(
            t,
            requiredMessage
        ),
        holdDepthsTime: Yup.string().trim().required(requiredMessage),
        comment: Yup.string(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                entity,
                {
                    ...formData,
                    diveRecordDiveData: diveRecordDiveData,
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    return (
        <Modal open={true} setOpen={closeModal} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <h3 className="-mb20 _12">{t(cardTitle)}</h3>

                        <div className="_wr">
                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberInitialDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        min={'0'}
                                        required
                                    />
                                </div>

                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <span className="a-mediumText a-lightText">
                                        {t(timeStartLabel)}
                                        <span className="redStar"> *</span>
                                    </span>
                                    <div className="space-between">
                                        <InputField
                                            name="timeStartHours"
                                            label={timeStartLabel}
                                            placeholder={'form.placeholder.0'}
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                            required
                                        />
                                        <InputField
                                            name="timeStartMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.0"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberInitialO2"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units="form.label.%"
                                        min={'0'}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberFinalDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        min={'0'}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4 fakeLabel multipleInputs">
                                    <span className="a-mediumText a-lightText">
                                        {t(timeEndLabel)}
                                        <span className="redStar"> *</span>
                                    </span>
                                    <div className="space-between">
                                        <InputField
                                            name="timeStopHours"
                                            label={timeEndLabel}
                                            placeholder={'form.placeholder.0'}
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.hh"
                                            min={'0'}
                                            required
                                        />
                                        <InputField
                                            name="timeStopMinutes"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.0"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.min"
                                            min={'0'}
                                            max={'59'}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="_12 _l4">
                                    <InputField
                                        name="chamberFinalO2"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units="form.label.%"
                                        min={'0'}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12 _l4">
                                    <InputField
                                        name="holdDepthsTime"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        label={
                                            'form.label.' + holdDeptsTimeLabel
                                        }
                                        placeholder={
                                            'form.placeholder.' +
                                            holdDeptsTimeLabel
                                        }
                                        required
                                    />
                                </div>
                            </div>

                            <div className="_w -mt5">
                                <div className="_12">
                                    <TextAreaField name="comment" />
                                </div>
                            </div>
                        </div>

                        <div className="_wr">
                            <div className="_w -buttons -pb20">
                                <div className="_12 _xs6 -mt40">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.cancel"
                                        onClick={closeModal}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="_12 _xs6 -mt40">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        label="button.save"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                {isSubmitting && <Loader />}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ChamberEventFormModal
