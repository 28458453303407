import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { BASE_URL } from 'utils/axiosClient'
import { BUTTON_SIZE, BUTTON_STATUS, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'
import ContactInfo from './ContactInfo'
import PersonalInfoModal from '../modals/CompanyInfoModal'
import Button from 'components/Button'
// import IconButton from 'components/IconButton'

const CompanyInfo = ({ myProfile, company }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const [open, setOpen] = useState(location.state?.companyInfo || false)

    const { name, email, fullPhoneNumber, website, logoPath, founded } = company

    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const completeYourProfileDisplay = useMemo(() => {
        return !founded || !fullPhoneNumber || !website || !logoPath
    }, [founded, fullPhoneNumber, website, logoPath])

    const handleEdit = () => {
        setOpen(true)
    }

    // NOTE:: for later (design needs to be changed)
    // const handleShare = () => {
    //     console.log('Share')
    // }

    return (
        <div className="a-userInfo">
            <div className="a-userInfo__header space-between">
                {/* <IconButton onClick={handleShare} icon={ICONS.SHARE} /> */}

                {myProfile && (
                    <div className="-editProfileButton" onClick={handleEdit}>
                        <Icon name={ICONS.EDIT} color={COLORS.DARK_BLUE_40} />
                    </div>
                )}
            </div>

            <div className="a-userInfo__mainInfo">
                <div className="aligned-center -profileImgAndText">
                    <div
                        className={`a-userInfo__mainInfo--${
                            logoPath ? 'image' : 'placeholder'
                        }`}
                        style={{
                            backgroundImage: `url("${
                                logoPath ? BASE_URL + logoPath : ''
                            }")`,
                        }}
                    >
                        {!logoPath && (
                            <div className="-placeholderImage">
                                <Icon
                                    name={ICONS.USER}
                                    color={COLORS.WHITE}
                                    size={ICON_SIZE.SIZE80}
                                />
                            </div>
                        )}
                    </div>
                    <div className="a-userInfo__mainInfo--info">
                        <span className="-name -mt15 a-bodyTextMedium">
                            {name}
                        </span>
                    </div>
                </div>

                {myProfile && completeYourProfileDisplay && (
                    <div className="-mt10 a-separator -top -pt15">
                        <h3 className="a-bodyTextMedium -mb10">
                            {t('general.incompleteProfile')}
                        </h3>
                        <Button
                            label="button.completeYourProfile"
                            buttonSize={BUTTON_SIZE.MEDIUM}
                            btnClass={BUTTON_STATUS.SECONDARY}
                            onClick={handleEdit}
                        />
                    </div>
                )}

                <div className="m-userMainInfo__mainInfo--contactInfo -mt20 -pt15 a-separator -top">
                    <ContactInfo
                        website={website}
                        email={email}
                        fullPhoneNumber={fullPhoneNumber}
                    />
                </div>
            </div>

            {open && <PersonalInfoModal open={open} setOpen={setOpen} />}
        </div>
    )
}

CompanyInfo.propTypes = {
    myProfile: PropTypes.bool,
    company: PropTypes.object,
}

export default CompanyInfo
