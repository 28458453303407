import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import {
    SUB_TAB,
    TAB,
} from 'screens/diver/profile/diverInformation/constants/tabs'

export const NOTIFICATION_LEVEL = {
    INFO: 1,
    WARNING: 2,
    DANGER: 3,
}

export const NOTIFICATION_TYPE = {
    POSITION_INVITE_SENT_TO_DIVER: 1,
    POSITION_INVITE_ACCEPTED: 2,
    POSITION_INVITE_REJECTED: 3,
    POSITION_APPLICATION_SUBMITTED_BY_DIVER: 4,
    POSITION_APPLICATION_ACCEPTED: 5,
    POSITION_APPLICATION_REJECTED: 6,
    MEDICAL_RECORD_EXPIRING: 7,
    MEDICAL_RECORD_EXPIRED: 8,
    TRAVEL_DOCUMENT_EXPIRING: 9,
    TRAVEL_DOCUMENT_EXPIRED: 10,
    CERTIFICATE_EXPIRING: 11,
    CERTIFICATE_EXPIRED: 12,
    DATA_ACCESS_REQUEST_RECEIVED: 13,
    DIVE_INVITE_EMPLOYED: 14,
    DIVE_INVITE_CANDIDATE_STATUS_CHANGE: 15,
}

export const renderNotificationIcon = (notificationLevel) => {
    switch (notificationLevel) {
        case NOTIFICATION_LEVEL.DANGER:
            return ICONS.NOTIFICATION_DANGER
        case NOTIFICATION_LEVEL.WARNING:
            return ICONS.ALERT_WARNING
        default:
            return ICONS.ALERT_INFO
    }
}

export const notificationNavigation = (
    projectId,
    notificationType,
    userId,
    positionId,
    additionalData
) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.MEDICAL_RECORD_EXPIRED:
        case NOTIFICATION_TYPE.MEDICAL_RECORD_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.MEDICAL_INFO}`
        case NOTIFICATION_TYPE.TRAVEL_DOCUMENT_EXPIRED:
        case NOTIFICATION_TYPE.TRAVEL_DOCUMENT_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.TRAVEL_DOCUMENTATION}`
        case NOTIFICATION_TYPE.CERTIFICATE_EXPIRED:
        case NOTIFICATION_TYPE.CERTIFICATE_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.TRAINING_RECORD}&subTab=${SUB_TAB.CERTIFICATES}`
        case NOTIFICATION_TYPE.POSITION_APPLICATION_SUBMITTED_BY_DIVER:
            return `${ROUTES.PROJECT}/${projectId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=2`
        case NOTIFICATION_TYPE.POSITION_INVITE_ACCEPTED:
            return `${ROUTES.PROJECT}/${projectId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=1`
        case NOTIFICATION_TYPE.POSITION_INVITE_REJECTED:
            return `${ROUTES.PROJECT}/${projectId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=3`
        case NOTIFICATION_TYPE.DATA_ACCESS_REQUEST_RECEIVED:
            return `${ROUTES.SETTINGS}/${ROUTES.DATA_ACCESS_CONTROL}/${getDataAccessModulePath(additionalData?.module)}`
        default:
            return `${ROUTES.PROJECT}/${projectId}`
    }
}

export const renderImageBoxBadge = (notificationType) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.POSITION_INVITE_SENT_TO_DIVER:
        case NOTIFICATION_TYPE.POSITION_APPLICATION_SUBMITTED_BY_DIVER:
            return '-applied'
        case NOTIFICATION_TYPE.POSITION_INVITE_ACCEPTED:
            return '-accepted'
        case NOTIFICATION_TYPE.POSITION_INVITE_REJECTED:
            return '-rejected'
        default:
            return '-noBadge'
    }
}

const getDataAccessModulePath = (module) => {
    console.log('module', module);

    switch (module) {
        case 1:
            return ROUTES.MEDICAL_INFO
        case 2:
            return ROUTES.TRAVEL_DOCUMENTATION
        case 3:
            return ROUTES.TRAINING_RECORDS
        case 4:
            return ROUTES.PERSONAL_EQUIPMENT
        case 5:
        default:
            return ROUTES.PAST_EMPLOYMENT
    }
}

export const setImageBoxClassName = (notificationLevel, notificationType) => {
    switch (notificationLevel) {
        case NOTIFICATION_LEVEL.INFO:
            if (
                notificationType !==
                    NOTIFICATION_TYPE.POSITION_APPLICATION_ACCEPTED &&
                notificationType !==
                    NOTIFICATION_TYPE.POSITION_APPLICATION_REJECTED
            ) {
                return '-noBcg'
            } else {
                return notificationType ===
                    NOTIFICATION_TYPE.POSITION_APPLICATION_ACCEPTED
                    ? '-accepted'
                    : '-rejected'
            }
        case NOTIFICATION_LEVEL.WARNING:
            return '-warning'
        default:
            return '-danger'
    }
}
