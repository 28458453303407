import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import {
    NO_SKILLS_EXPERIENCE_TEXT,
    getNoSkillsAndExperienceText,
} from '../utils/noDataHelper'

import ENTITIES from 'constants/entities'

import StepButtons from 'components/StepButtons'
import ExperienceWrapper from './components/ExperienceWrapper'
import SkillRecordWrapper from './components/SkillRecordWrapper'
import TabNavigation from 'components/tabs/TabNavigation'
import { useLocation } from 'react-router-dom'
import useQueryParams from 'hooks/useQueryParams'

const EXPERIENCE_AND_SKILLS_OPTIONS = {
    EXPERIENCES: 'experiences',
    SKILLS: 'skills',
}

const SkillsOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    profilePage,
    myProfile,
    refetchData,
    hideFiles,
}) => {
    const t = useTranslate()
    const searchParams = useLocation().search
    const { subTab } = useQueryParams()

    const showActions = myProfile || !profilePage

    const {
        data: experience,
        isLoading: isLoadingExperience,
        fetchData: fetchExperience,
    } = useFetchData(
        ENTITIES.EXPERIENCE,
        {
            include:
                'experienceDivingModes,divingMode,experienceDivingModes.divingMode',
            'user.id': userID,
        },
        true,
        false
    )

    const {
        data: skills,
        isLoading: isLoadingSkills,
        fetchData: fetchSkills,
    } = useFetchData(
        ENTITIES.SKILL_RECORD,
        { include: 'skillCategory,skill', 'user.id': userID },
        true,
        false
    )

    const noDataText = getNoSkillsAndExperienceText(skills, experience)
    const showNoDataText = noDataText === NO_SKILLS_EXPERIENCE_TEXT.NO_DATA
    const condition = !showActions && showNoDataText

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const renderTabContent = () => {
        if (subTab === EXPERIENCE_AND_SKILLS_OPTIONS.SKILLS) {
            return (
                <div
                    className={
                        !profilePage ? '_12 _m6 -secondColumn -mt30' : ''
                    }
                >
                    {showEmptyValueMessage && (
                        <span className="a-captionsTextRegular a-lightText justify-center -mt20">
                            {t(noDataText)}
                        </span>
                    )}
                    <SkillRecordWrapper
                        data={skills}
                        fetchData={fetchSkills}
                        isLoading={isLoadingSkills}
                        title={!profilePage ? 'general.skills' : ''}
                        profilePage={profilePage}
                        showActions={showActions}
                        noDataText={noDataText}
                        refetchData={refetchData}
                    />
                </div>
            )
        } else {
            return (
                <div className={!profilePage ? '_w' : ''}>
                    <div
                        className={
                            !profilePage ? '_6 _m3 -firstColumn -mt30' : ''
                        }
                    ></div>
                    <ExperienceWrapper
                        data={experience}
                        fetchData={fetchExperience}
                        isLoading={isLoadingExperience}
                        profilePage={profilePage}
                        showActions={showActions}
                        noDataText={noDataText}
                        refetchData={refetchData}
                    />
                </div>
            )
        }
    }

    return (
        <Fragment>
            <div
                className={
                    profilePage
                        ? 'm-profileTabs -skills'
                        : '-contentElements -skillsOnboarding'
                }
            >
                {!profilePage && (
                    <Fragment>
                        <h2 className="-mt35 _12 a-centeredText">
                            {t('general.skillsAndExperience')}
                        </h2>
                        <div className="-description -mt20 _12">
                            <p className="a-bodyTextRegular a-centeredText -descriptionText">
                                {t('general.skillsAndExperienceDescription')}
                            </p>
                        </div>
                    </Fragment>
                )}

                <div className={!profilePage ? '_wr' : ''}>
                    <div>
                        <div className="-mb20">
                            <div className="aligned-center a-tabName">
                                <span className="a-bodyTextMedium ">
                                    {t('general.experienceAndSkills')}
                                </span>
                            </div>
                        </div>
                        {hideFiles && (
                            <p className="a-mediumText a-lightText -mt20 -mb20">
                                {t('general.noAccessToFiles')}
                            </p>
                        )}
                        <TabNavigation
                            tabs={[
                                {
                                    key: EXPERIENCE_AND_SKILLS_OPTIONS.EXPERIENCES,
                                    title: 'general.experience',
                                    queryParams: searchParams,
                                },
                                {
                                    key: EXPERIENCE_AND_SKILLS_OPTIONS.SKILLS,
                                    title: 'general.skills',
                                    queryParams: searchParams,
                                },
                            ]}
                            tabQueryParam="subTab"
                        />
                        {renderTabContent()}
                    </div>
                </div>
            </div>

            {!profilePage && (
                <div className="_wr">
                    <StepButtons
                        skipLabel="button.skipAndFinishStep"
                        nextlabel="button.finishOnboarding"
                        handleBack={goPreviousStep}
                        handleNext={goToNextStep}
                        handleSkip={goToNextStep}
                        canSkip={!skills.length && !experience.length}
                    />
                </div>
            )}
        </Fragment>
    )
}

SkillsOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
}

SkillsOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default SkillsOnboarding
