import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'
import { BUTTON_STATUS, BUTTON_TYPE, FILE_UPLOAD_TYPE } from 'constants/enums'
import ICONS from 'constants/icons'

import InputField from 'components/formFields/InputField'
import DateTimeField from 'components/formFields/DateTimeField'
import SelectField from 'components/formFields/SelectField'
import Button from 'components/Button'
import FileUploadField from 'components/formFields/FileUploadField'
import Separator from 'components/Separator'
import COLORS from 'constants/colors'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const VisaForm = ({ setVisas, setShowVisaForm, visas }) => {
    const t = useTranslate()

    const initialValues = {
        visaNumber: '',
        dateOfExpiatory: '',
        country: null,
        visaType: '',
        visaName: '',
        files: [],
    }

    const requiredMessage = t('form.error.required')

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const validation = Yup.object({
        visaNumber: Yup.string().trim().required(requiredMessage),
        dateOfExpiatory: Yup.date()
            .min(today, t('form.error.expirationDateInPast'))
            .required(requiredMessage),
        country: Yup.object().required(requiredMessage),
        visaType: Yup.string().trim().required(requiredMessage),
        visaName: Yup.string().trim().required(requiredMessage),
        files: Yup.array()
            .min(1, t('form.error.atLeastOneCopyOfVisa'))
            .required(requiredMessage),
    })

    const onSubmit = ({ ...formData }) => {
        setVisas((visas) => [
            ...visas,
            {
                ...formData,
            },
        ])
        setShowVisaForm(false)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <div className="_wr">
                        <div className="_w">
                            <Separator />
                        </div>
                    </div>
                    <div className="_wr">
                        {visas.length === 0 && (
                            <div className="_w">
                                <h3 className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.visasInformation')}
                                </h3>
                            </div>
                        )}
                        <div className="_w">
                            <div className="_12 _m4">
                                <InputField name="visaNumber" required />
                            </div>
                            <div className="_12 _m4">
                                <DateTimeField
                                    name="dateOfExpiatory"
                                    label="form.label.dateOfExpiatoryVisa"
                                    required
                                />
                            </div>
                            <div className="_12 _m4">
                                <SelectField
                                    name="country"
                                    label="form.label.countryOfIssue"
                                    placeholder="form.placeholder.countryOfIssue"
                                    entityType={ENTITIES.COUNTRY}
                                    searchable
                                    required
                                />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="visaType" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="visaName" required />
                            </div>
                        </div>
                        <div className="_w -mt5">
                            <FileUploadField
                                name="files"
                                label="form.label.visaFiles"
                                type={FILE_UPLOAD_TYPE.FILE}
                                required
                            />
                        </div>
                        <div className="_w ">
                            <div className="_8 _m5 _l3 -mt20 fullWidthButton -left">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    icon={ICONS.PLUS}
                                    iconColor={COLORS.SECONDARY}
                                    label="button.saveThisVisa"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="_4 _m3 _l2 -mt20 fullWidthButton -right">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label="button.cancel"
                                    onClick={() => setShowVisaForm(false)}
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                    </div>
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

VisaForm.propTypes = {
    setVisas: PropTypes.func.isRequired,
    setShowVisaForm: PropTypes.func.isRequired,
}

export default VisaForm
