import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import GasCylindersFormContext from '../formContext/GasCylindersFormContext'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import { SIZE_IMPERIAL, SIZE_METRIC } from '../constants/diveRecordEntities'
import { GAS_CONTENTS } from '../constants/gasContents'

import { DiveRecordContext } from '../contexts/DiveRecordContext'

import {
    filterGacCylinderPurposes,
    shouldShowHelium,
    shouldShowNitrogen,
    shouldShowOxygen,
} from 'utils/diveRecordFunctions'

import useFetchData from 'hooks/useFetchData'

import Button from 'components/Button'
import Loader from 'components/Loader'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import FocusError from '../../../../components/FocusError'

const GasCylindersForm = ({
    initialData,
    gasCylinders,
    setGasCylinders,
    gasCylindersError,
    isImperialUnit,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { diveRecord } = useContext(DiveRecordContext)

    const requiredMessage = t('form.error.required')

    const { data: gasCylinderPurposes } = useFetchData(
        ENTITIES.GAS_CYLINDER_PURPOSE,
        {}
    )

    if (!diveRecord.data || !gasCylinderPurposes.length) {
        return null
    }

    // not all purposes are available for all dive modes, so we need to filter them
    const availablePurposes = filterGacCylinderPurposes(
        diveRecord.data,
        gasCylinderPurposes
    )

    const unitOfMeasurementBarOrPsi = isImperialUnit
        ? 'form.label.psi'
        : 'form.label.bar'

    const checkSum = (sum, path, createError) => {
        if (sum > 100) {
            return createError({
                path: path,
                message: 'Sum of fields must not exceed 100',
            })
        }
        return true
    }

    const initialValues = {
        gasCylinderPurpose: initialData?.gasCylinderPurpose ?? null,
        size: initialData?.size ?? '',
        startPressure: initialData?.startPressure ?? '',
        endPressure: initialData?.endPressure ?? '',
        gasContents: initialData?.gasContents ?? null,
        oxygen: initialData?.oxygen ?? '',
        helium: initialData?.helium ?? '',
        nitrogen: initialData?.nitrogen ?? '',
    }

    const validation = Yup.object().shape({
        gasCylinderPurpose: Yup.object().required(requiredMessage),
        size: Yup.string().nullable(),
        startPressure: Yup.number().min(0, t('form.error.invalidNumber')),
        endPressure: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .test('endPressure', (value, { parent, createError }) => {
                if (Number(value) > Number(parent.startPressure)) {
                    return createError({
                        message: t('form.error.mustBeLessThanFrom'),
                        path: 'endPressure',
                    })
                }
                return true
            }),
        gasContents: Yup.object().required(requiredMessage),
        oxygen: Yup.number().when('gasContents', {
            is: (val) =>
                val?.name === GAS_CONTENTS.NITROX ||
                val?.name === GAS_CONTENTS.HELIOX ||
                val?.name === GAS_CONTENTS.TRIMIX,
            then: () =>
                Yup.number()
                    .test('gasContents', (value, { parent, createError }) => {
                        if (parent.gasContents.name === GAS_CONTENTS.NITROX) {
                            const { oxygen, nitrogen } = parent
                            const sum = oxygen + nitrogen
                            return checkSum(sum, 'nitrogen', createError)
                        } else if (
                            parent.gasContents.name === GAS_CONTENTS.HELIOX
                        ) {
                            const { oxygen, helium } = parent
                            const sum = oxygen + helium
                            return checkSum(sum, 'helium', createError)
                        } else if (
                            parent.gasContents.name === GAS_CONTENTS.TRIMIX
                        ) {
                            const { oxygen, helium, nitrogen } = parent
                            const sum = oxygen + helium + nitrogen
                            return checkSum(sum, 'helium', createError)
                        }
                    })
                    .min(1, t('form.error.invalidNumber'))
                    .required(requiredMessage),

            otherwise: () => Yup.number(),
        }),
        helium: Yup.number().when('gasContents', {
            is: (val) =>
                val?.name === GAS_CONTENTS.HELIOX ||
                val?.name === GAS_CONTENTS.TRIMIX,
            then: () =>
                Yup.number()
                    .min(1, t('form.error.invalidNumber'))
                    .required(requiredMessage),
            otherwise: () => Yup.number(),
        }),
        nitrogen: Yup.number().when('gasContents', {
            is: (val) =>
                val?.name === GAS_CONTENTS.NITROX ||
                val?.name === GAS_CONTENTS.TRIMIX,
            then: () =>
                Yup.number()
                    .min(1, t('form.error.invalidNumber'))
                    .required(requiredMessage),
            otherwise: () => Yup.number(),
        }),
    })

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true)
            setGasCylinders([...gasCylinders, values])
            resetForm({})
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
        >
            {({ handleSubmit, isSubmitting, values }) => {
                const { gasContents, gasCylinderPurpose } = values
                return (
                    <Form>
                        <FocusError />
                        <GasCylindersFormContext />
                        <div className="_wr">
                            <div className="_w">
                                <span className="-mt5 _12 a-bodyTextRegular">
                                    {t('general.gasCylindersUsed')}
                                </span>
                                <span className=" -mt5 _12 a-mediumText a-lightText">
                                    {t('general.addAllGasCylinders')}
                                </span>

                                {gasCylindersError &&
                                    gasCylinders.length === 0 && (
                                        <span className="_12 errorMessage -active">
                                            {gasCylindersError}
                                        </span>
                                    )}
                            </div>

                            <div className="_w -mt20">
                                <div className="_12 _m6 _l4">
                                    <SelectField
                                        name="gasCylinderPurpose"
                                        defaultOptions={availablePurposes}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _m6 _l4">
                                    <SelectField
                                        name="gasContents"
                                        entityType={ENTITIES.GAS_CONTENTS}
                                        searchable
                                        required
                                    />
                                </div>

                                {gasContents &&
                                    shouldShowOxygen(gasContents) && (
                                        <div className="_12 _m4 ">
                                            <InputField
                                                name="oxygen"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                required
                                            />
                                        </div>
                                    )}

                                {gasContents &&
                                    shouldShowNitrogen(gasContents) && (
                                        <div className="_12 _m4 ">
                                            <InputField
                                                name="nitrogen"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                required
                                            />
                                        </div>
                                    )}

                                {gasContents &&
                                    shouldShowHelium(gasContents) && (
                                        <div className="_12 _m4 ">
                                            <InputField
                                                name="helium"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                required
                                            />
                                        </div>
                                    )}
                                <div className="_12 _m6 _l4">
                                    <SelectField
                                        name="size"
                                        defaultOptions={
                                            isImperialUnit
                                                ? SIZE_IMPERIAL
                                                : SIZE_METRIC
                                        }
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        displayAttribute={null}
                                        createNew
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6 _l2">
                                    <InputField
                                        name="startPressure"
                                        placeholder={
                                            isImperialUnit
                                                ? 'form.placeholder.startPressureImperial'
                                                : 'form.placeholder.startPressure'
                                        }
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementBarOrPsi}
                                    />
                                </div>
                                <div className="_12 _m6 _l2">
                                    <InputField
                                        name="endPressure"
                                        placeholder={
                                            isImperialUnit
                                                ? 'form.placeholder.endPressureImperial'
                                                : 'form.placeholder.endPressure'
                                        }
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementBarOrPsi}
                                    />
                                </div>
                            </div>

                            <div className="ofs_2 _8 ofs_l5 _l3 -mt20 justify-center">
                                <Button
                                    label="button.addGasCylinder"
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    type={BUTTON_TYPE.SUBMIT}
                                    icon={ICONS.PLUS}
                                    iconColor={COLORS.SECONDARY}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                />
                            </div>

                            {isSubmitting && <Loader />}
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

GasCylindersForm.propTypes = {
    initialData: PropTypes.object,
    gasCylinders: PropTypes.array,
    setGasCylinders: PropTypes.func,
    gasCylindersError: PropTypes.string,
}

GasCylindersForm.defaultProps = {
    gasCylinders: [],
    gasCylindersError: '',
}

export default GasCylindersForm
