import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'
import ActionButton from './ActionButton'
import CellItem from './CellItem'

const TableBody = ({
    headerItems,
    data,
    isLoading,
    rowActions,
    selectedRowActions,
    onClickRow,
    selectedRow,
    setSelectedRow,
    defaultItemsPerPage,
    idKey,
}) => {
    const t = useTranslate()

    const { itemsPerPage: queryItemsPerPage } = useQueryParams()

    const itemsPerPage = Number(queryItemsPerPage) || defaultItemsPerPage

    const actionsColSpan = rowActions.length + 1

    const handleClickRow = (item) => {
        if (selectedRowActions.length > 0) {
            if (selectedRow && selectedRow[idKey] === item[idKey]) {
                setSelectedRow(null)
                if (onClickRow) onClickRow(null)
            } else {
                setSelectedRow(item)
                if (onClickRow) onClickRow(item)
            }
        } else if (onClickRow) {
            onClickRow(item)
        }
    }

    const isRowClickable = onClickRow || selectedRowActions.length > 0

    if (isLoading && data?.length === 0)
        return (
            <tbody>
                {[...Array(itemsPerPage).keys()].map((item) => (
                    <tr key={item} className="m-table__placeholder">
                        {headerItems.map(({ key }) => (
                            <td key={key}>
                                <span></span>
                            </td>
                        ))}
                        {rowActions && (
                            <td>
                                <div className="-placeholderActions">
                                    <span>
                                        <Icon
                                            name={ICONS.DOTS}
                                            color={COLORS.SECONDARY}
                                            size={ICON_SIZE.SIZE16}
                                        />
                                    </span>
                                </div>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        )

    if (data?.length === 0)
        return (
            <tbody>
                <tr className="m-table__empty">
                    <td colSpan={headerItems.length + rowActions.length}>
                        <span className="a-captionsTextRegular a-lightText">
                            {t('general.noItems')}
                        </span>
                    </td>
                </tr>
            </tbody>
        )

    if (!isLoading || data?.length > 0)
        return (
            <tbody>
                {data.map((item, index) => (
                    <tr
                        key={index}
                        onClick={() => handleClickRow(item)}
                        className={`${isRowClickable ? 'clickable' : ''} ${
                            item[idKey] &&
                            (selectedRow && selectedRow[idKey] === item[idKey]
                                ? '-selected'
                                : '')
                        }`}
                    >
                        {headerItems.map(({ key, ...headerItem }) => (
                            <td key={key}>
                                <CellItem
                                    key={key}
                                    headerKey={key}
                                    headerItem={headerItem}
                                    item={item}
                                />
                            </td>
                        ))}
                        {rowActions.length > 0 && (
                            <td colSpan={actionsColSpan}>
                                <div className="m-table__actionsCell">
                                    {rowActions.map(
                                        (
                                            {
                                                ActionComponent = ActionButton,
                                                ...action
                                            },
                                            index
                                        ) => (
                                            <ActionComponent
                                                key={index}
                                                {...action}
                                                item={item}
                                                stopPropagation={
                                                    !!isRowClickable
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        )
}

TableBody.propTypes = {
    headerItems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    rowActions: PropTypes.array,
    selectedRowActions: PropTypes.array,
    onClickRow: PropTypes.func,
    selectedRow: PropTypes.object,
    setSelectedRow: PropTypes.func,
    defaultItemsPerPage: PropTypes.number,
    idKey: PropTypes.string,
}

TableBody.defaultProps = {
    rowActions: [],
}

export default TableBody
