import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import { ALERT_TYPES, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

const ChamberEventList = ({
    data,
    fetchData,
    isLoading,
    setEditItem,
    entity,
    labels,
    hideActions,
    unitImperial,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const {
        timeStartLabel,
        timeEndLabel,
        holdDeptsTimeLabel,
        areYouSureLabel,
        deleteLabel,
        cardTitle,
        editButtonTitle,
        viewFullLabel,
    } = labels

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(entity, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: areYouSureLabel,
            title: deleteLabel,
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })

    if (isLoading && data.length === 0 && !hideActions) {
        return <CardPlaceholder />
    }

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            !hideActions ? '_12 _l6 -mt20' : 'fullWidth -mb20'
                        }`}
                    >
                        <CardContainer
                            item={item}
                            title={t(cardTitle)}
                            actions={
                                hideActions
                                    ? []
                                    : [
                                          {
                                              handleAction: setEditItem,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.PRIMARY,
                                              tooltip: editButtonTitle,
                                          },
                                          {
                                              handleAction: handleDelete,
                                              icon: ICONS.DELETE,
                                              iconColor: COLORS.RED,
                                              tooltip: deleteLabel,
                                          },
                                      ]
                            }
                        >
                            <Info
                                label="form.label.chamberInitialDepth"
                                value={
                                    item.chamberInitialDepth +
                                    unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label={timeStartLabel}
                                value={
                                    item.timeStartHours +
                                    'h ' +
                                    item.timeStartMinutes +
                                    'min'
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.chamberInitialO2"
                                value={item.chamberInitialO2 + '%'}
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.chamberFinalDepth"
                                value={
                                    item.chamberFinalDepth +
                                    unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label={timeEndLabel}
                                value={
                                    item.timeStopHours +
                                    'h ' +
                                    item.timeStopMinutes +
                                    'min'
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.chamberFinalO2"
                                value={item.chamberFinalO2 + '%'}
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label={'form.label.' + holdDeptsTimeLabel}
                                value={item.holdDepthsTime}
                                withBorder
                                withSpaceBetween
                            />
                            {item.comment && (
                                <Info
                                    label="form.label.comment"
                                    value={item.comment}
                                    column
                                />
                            )}
                            {!hideActions && (
                                <Button
                                    label={viewFullLabel}
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.ARROW_RIGHT}
                                    iconColor={COLORS.SECONDARY}
                                    iconPosition={ICON_POSITION.RIGHT}
                                    onClick={() => setEditItem(item)}
                                />
                            )}
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ChamberEventList
