import { GAS_CONTENTS } from '../screens/diver/diveRecord/constants/gasContents'
import {
    BREATHING_APPARATUS_TYPES,
    DECOMPRESSION_TYPES,
    DIVE_MODES,
    GAS_CYLINDER_PURPOSES,
} from '../constants/enums'
import { DIVE_RECORD_STEPS } from '../screens/diver/diveRecord/constants/diveRecordSteps'

const DIVE_DATA_TABS = {
    DIVE_EVENT: 'diver_event',
    SURFACE_DECOMPRESSION_EVENT: 'surface_decompression_event',
    CHAMBER_PRESSURIZATION_EVENT: 'chamber_pressurization_event',
    CHAMBER_DECOMPRESSION_EVENT: 'chamber_decompression_event',
    BELL_RUN_EVENT: 'bell_run_event',
    LOCK_OUT_EVENT: 'lock_out_event',
    SATURATION_DAILY_EVENT: 'saturation_daily_event',
}

const getAllowedTabs = (diveRecord) => {
    const { divingMode } = diveRecord.diveRecordGeneralInformation
    const { diveEvents } = diveRecord.diveRecordDiveData

    if (
        [
            DIVE_MODES.SCUBA_CONVENTIONAL.id,
            DIVE_MODES.SURFACE_SUPPLIED.id,
            DIVE_MODES.SUPPLIED_WET_BELL.id,
            DIVE_MODES.RE_BREATHER.id,
        ].includes(divingMode.id)
    ) {
        if (
            diveEvents &&
            diveEvents[0] &&
            diveEvents[0]?.decompressionType?.id ===
                DECOMPRESSION_TYPES.SURFACE_DECOMPRESSION.id
        ) {
            return [
                DIVE_DATA_TABS.DIVE_EVENT,
                DIVE_DATA_TABS.SURFACE_DECOMPRESSION_EVENT,
            ]
        }

        return [DIVE_DATA_TABS.DIVE_EVENT]
    }

    if (DIVE_MODES.CLOSED_BELL_SATURATION.id === divingMode.id) {
        return [
            DIVE_DATA_TABS.SATURATION_DAILY_EVENT,
            DIVE_DATA_TABS.CHAMBER_PRESSURIZATION_EVENT,
            DIVE_DATA_TABS.CHAMBER_DECOMPRESSION_EVENT,
            DIVE_DATA_TABS.BELL_RUN_EVENT,
            DIVE_DATA_TABS.LOCK_OUT_EVENT,
        ]
    }

    if (DIVE_MODES.CHAMBER_PRESSURIZATION_ONLY.id === divingMode.id) {
        return [DIVE_DATA_TABS.SURFACE_DECOMPRESSION_EVENT]
    }

    return Object.values(DIVE_DATA_TABS)
}

const getVisibleProfileTabs = (diveRecord, search) => {
    const isChamberOnly = isChamberPressurizationOnly(
        diveRecord.diveRecordGeneralInformation.divingMode
    )

    const allTabs = [
        {
            key: DIVE_DATA_TABS.DIVE_EVENT,
            title: 'general.diveEvent',
            queryParams: search,
        },
        {
            key: DIVE_DATA_TABS.SATURATION_DAILY_EVENT,
            title: 'saturationDailyEvent.title',
            queryParams: search,
        },
        {
            key: DIVE_DATA_TABS.SURFACE_DECOMPRESSION_EVENT,
            title: isChamberOnly
                ? 'chamberRecompressionOnlyEvent.title'
                : 'general.surfaceDecompressionEvent',
            queryParams: search,
        },
        {
            key: DIVE_DATA_TABS.CHAMBER_PRESSURIZATION_EVENT,
            title: 'saturationChamberPressurizationEvent.title',
            queryParams: search,
        },
        {
            key: DIVE_DATA_TABS.CHAMBER_DECOMPRESSION_EVENT,
            title: 'saturationChamberDecompressionEvent.title',
            queryParams: search,
        },
        {
            key: DIVE_DATA_TABS.BELL_RUN_EVENT,
            title: 'general.bellRunEvent',
            queryParams: search,
        },
        {
            key: DIVE_DATA_TABS.LOCK_OUT_EVENT,
            title: 'lockOutEvent.title',
            queryParams: search,
        },
    ]

    const allowed = getAllowedTabs(diveRecord)

    return allTabs.filter((item) => {
        return allowed.includes(item.key)
    })
}

const filterDiveRecordSteps = (
    divingMode,
    isSupervisor,
    isSupervisorDiveRecord
) => {
    const isChamber = divingMode && isChamberPressurizationOnly(divingMode)

    let allSteps = DIVE_RECORD_STEPS
    if (!isSupervisor) {
        allSteps = allSteps.filter((item) => item.id < 6)
    }

    if (isChamber) {
        allSteps = allSteps.filter((item) => item.id !== 2 && item.id !== 4)
    }

    if (isSupervisorDiveRecord) {
        allSteps = allSteps.filter((item) => item.id !== 2)
    }

    return allSteps
}

const filterGacCylinderPurposes = (diveRecord, purposes) => {
    const { divingMode } = diveRecord.diveRecordGeneralInformation

    if ([DIVE_MODES.SCUBA_CONVENTIONAL.id].includes(divingMode.id)) {
        return purposes.filter((item) => {
            return (
                item.id === GAS_CYLINDER_PURPOSES.PRIMARY_BREATHING_SOURCE.id ||
                item.id === GAS_CYLINDER_PURPOSES.DECOMPRESSION_MIX.id
            )
        })
    }

    if (
        [
            DIVE_MODES.SURFACE_SUPPLIED.id,
            DIVE_MODES.SUPPLIED_WET_BELL.id,
        ].includes(divingMode.id)
    ) {
        return purposes.filter((item) => {
            return (
                item.id === GAS_CYLINDER_PURPOSES.SECONDARY_BREATHING_SOURCE.id
            )
        })
    }

    if ([DIVE_MODES.CLOSED_BELL_SATURATION.id].includes(divingMode.id)) {
        return purposes.filter((item) => {
            return item.id === GAS_CYLINDER_PURPOSES.BAILOUT_CYLINDER.id
        })
    }

    return purposes
}

const filterGasContents = (gasContents, divingMode) => {
    if (isReBreather(divingMode)) {
        return gasContents.filter((mix) => mix.name !== GAS_CONTENTS.OXYGEN)
    }

    return gasContents
}

const divingEventHasInputsForGasContents = (divingMode) => {
    return (
        divingMode.id === DIVE_MODES.SURFACE_SUPPLIED.id ||
        divingMode.id === DIVE_MODES.SUPPLIED_WET_BELL.id ||
        divingMode.id === DIVE_MODES.RE_BREATHER.id
    )
}

const getDefaultBreathingApparatusType = (diveRecord) => {
    if (!diveRecord) return null

    const { divingMode } = diveRecord.diveRecordGeneralInformation

    if (divingMode.id === DIVE_MODES.CLOSED_BELL_SATURATION.id) {
        return BREATHING_APPARATUS_TYPES.RECLAIM_HELMET
    }

    if (divingMode.id === DIVE_MODES.RE_BREATHER.id) {
        return BREATHING_APPARATUS_TYPES.CCR
    }

    return null
}

const isReBreather = (divingMode) => {
    if (!divingMode) {
        return false
    }

    return divingMode.id === DIVE_MODES.RE_BREATHER.id
}

const isChamberPressurizationOnly = (divingMode) => {
    if (!divingMode) {
        return false
    }

    return divingMode.id === DIVE_MODES.CHAMBER_PRESSURIZATION_ONLY.id
}

const shouldShowOxygen = (gasContents, divingMode) => {
    if (
        divingMode &&
        isReBreather(divingMode) &&
        gasContents.name === GAS_CONTENTS.AIR
    ) {
        return true
    }

    return (
        gasContents.name === GAS_CONTENTS.TRIMIX ||
        gasContents.name === GAS_CONTENTS.NITROX ||
        gasContents.name === GAS_CONTENTS.HELIOX
    )
}

const shouldShowHelium = (gasContents) => {
    return (
        gasContents.name === GAS_CONTENTS.TRIMIX ||
        gasContents.name === GAS_CONTENTS.HELIOX
    )
}

const shouldShowNitrogen = (gasContents, divingMode) => {
    if (
        divingMode &&
        isReBreather(divingMode) &&
        gasContents.name === GAS_CONTENTS.AIR
    ) {
        return true
    }

    return (
        gasContents.name === GAS_CONTENTS.TRIMIX ||
        gasContents.name === GAS_CONTENTS.NITROX
    )
}

export {
    DIVE_DATA_TABS,
    getVisibleProfileTabs,
    filterDiveRecordSteps,
    filterGacCylinderPurposes,
    filterGasContents,
    divingEventHasInputsForGasContents,
    getDefaultBreathingApparatusType,
    isReBreather,
    isChamberPressurizationOnly,
    shouldShowOxygen,
    shouldShowHelium,
    shouldShowNitrogen,
}
