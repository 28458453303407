import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import { renderNoDataText } from '../utils/noDataHelper'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import { PastEmployments, CreatePastEmployment } from './'
import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'
import AddNew from '../AddNew'

const PastEmploymentOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    myProfile,
    profilePage,
    hideFiles,
}) => {
    const t = useTranslate()

    const [create, setCreate] = useState(false)

    const showActions = myProfile || !profilePage

    const { data, isLoading, fetchData } = useFetchData(
        ENTITIES.PAST_EMPLOYMENT,
        {
            include: 'files,employer,pastEmploymentPosition,industry',
            'user.id': userID,
        },
        true,
        false
    )

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleOpenModal = () => setCreate(true)

    return (
        <>
            <div className={profilePage ? 'm-profileTabs' : '-contentElements'}>
                {profilePage ? (
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.pastEmployment')}
                        </span>
                    </div>
                ) : (
                    <Fragment>
                        <h2 className="-mt35 _12 a-centeredText">
                            {t('general.pastEmployment')}
                        </h2>
                        <div className="-description -mt20 _12">
                            <p className="a-bodyTextRegular a-centeredText -descriptionText">
                                {t('general.listOfPastEmployments')}
                            </p>
                        </div>
                    </Fragment>
                )}
                {hideFiles && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.noAccessToFiles')}
                    </p>
                )}
                {showActions && (
                    <div className={!profilePage ? '_12' : ''}>
                        <AddNew
                            handleAction={handleOpenModal}
                            label="general.addANewEmployment"
                        />
                    </div>
                )}

                {create && (
                    <CreatePastEmployment
                        open={create}
                        setOpen={setCreate}
                        fetchData={fetchData}
                    />
                )}

                <PastEmployments
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    showActions={showActions}
                    profilePage={profilePage}
                />
            </div>

            {!profilePage && (
                <div className="_wr">
                    <StepButtons
                        handleBack={goPreviousStep}
                        handleNext={goToNextStep}
                        handleSkip={goToNextStep}
                        canSkip={!data.length}
                    />
                </div>
            )}

            {showEmptyValueMessage &&
                renderNoDataText(
                    data,
                    isLoading,
                    t('general.noPastEmployment')
                )}

            {isLoading && data.length > 0 && <Loader />}
        </>
    )
}

PastEmploymentOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    hideFiles: PropTypes.bool,
}

PastEmploymentOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
    hideFiles: false,
}

export default PastEmploymentOnboarding
