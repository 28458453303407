import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/DataAccessControl'

const PastEmploymentAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.PAST_EMPLOYMENT}
            accessTypeLabel="form.label.selectAccessForPastEmployment"
            dataAccessAttribute="accessPastEmployment"
            title="general.pastEmployment"
        />
    )
}

export default PastEmploymentAccessControl
