import React, { useContext, useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import {
    getIsDateSameOrAfter,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
    MOMENT_FORMATS,
} from 'services/moment.service'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    FILE_UPLOAD_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import { YES_NO_OPTIONS } from 'constants/constants'

import Button from 'components/Button'
import Modal from 'components/Modal'
import {
    FileUploadField,
    DateTimeField,
    InputField,
    SelectField,
    RadioGroupField,
} from 'components/formFields'
import Separator from 'components/Separator'
import Loader from 'components/Loader'
import FocusError from 'components/FocusError'

const PersonalEquipmentForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const initialValues = {
        equipmentType: initialData?.equipmentType ?? null,
        equipmentBrand: initialData?.equipmentBrand ?? null,
        model: initialData?.model ?? '',
        serialNumber: initialData?.serialNumber ?? '',
        dateOfManufacture: initialData?.dateOfManufacture ?? '',
        datePutInService: initialData?.datePutInService ?? '',
        removedFromService:
            !initialData || initialData.removedFromService === false ? 0 : 1,
        dateRemovedFromService: initialData?.dateRemovedFromService ?? '',
        files: initialData?.files ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        equipmentType: Yup.object().required(requiredMessage),
        equipmentBrand: Yup.object().required(requiredMessage),
        model: Yup.string().trim().required(requiredMessage),
        serialNumber: Yup.string().trim().required(requiredMessage),
        dateOfManufacture: Yup.string()
            .nullable()
            .test('dateOfManufacture', (value, { parent, createError }) => {
                if (value === '' || value === null || value === undefined) {
                    return true // Allow empty string, null, or undefined
                }
                if (
                    getIsDateSameOrBefore(value, today, MOMENT_FORMATS.DATE_API)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.dateOfManufactureInFuture'),
                    path: 'dateOfManufacture',
                })
            }),
        datePutInService: Yup.string()
            .nullable()
            .test('datePutInService', (value, { parent, createError }) => {
                if (value === '' || value === null || value === undefined) {
                    return true // Allow empty string, null, or undefined
                }

                if (
                    !getIsDateSameOrBefore(
                        value,
                        today,
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return createError({
                        message: t('form.error.datePutInServiceInFuture'),
                        path: 'datePutInService',
                    })
                }

                if (!parent.dateOfManufacture) {
                    return true;
                }

                if (
                    getIsDateSameOrAfter(
                        value,
                        parent.dateOfManufacture,
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return true
                }

                return createError({
                    message: t('form.error.datePutInService'),
                    path: 'datePutInService',
                })
            }),
        removedFromService: Yup.boolean().required(requiredMessage),
        dateRemovedFromService: Yup.date().when('removedFromService', {
            is: (val) => val === true,
            then: () =>
                Yup.date()
                    .test(
                        'dateRemovedFromService',
                        (value, { parent, createError }) => {
                            if (
                                getIsDateSameOrBefore(
                                    value,
                                    parent.datePutInService,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return createError({
                                    message: t(
                                        'form.error.removeBeforePutToService'
                                    ),
                                    path: 'dateRemovedFromService',
                                })
                            }
                            return true
                        }
                    )
                    .test(
                        'dateRemovedFromService',
                        (value, { parent, createError }) => {
                            if (
                                getIsDateSameOrBeforeCurrentDate(
                                    value,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return true
                            }
                            return createError({
                                message: t('form.error.dateRemovedFromService'),
                                path: 'dateRemovedFromService',
                            })
                        }
                    )
                    .required(requiredMessage),
            otherwise: () => Yup.string().notRequired(),
        }),
        files: Yup.array(),
    })

    const onSubmit = async (formData) => {
        try {
            await handleSubmit({
                ...formData,
                removedFromService: !!formData.removedFromService,
                user: { id: userId, entityType: ENTITIES.USER },
            })
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Modal open={open} setOpen={setOpen} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({
                    values: { removedFromService },
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <Form>
                        <FocusError />
                        <div className="_wr">
                            {title && <h3 className="-mb20 _12">{t(title)}</h3>}
                            <div className="_w">
                                <div className="_12 _m6">
                                    <SelectField
                                        name="equipmentType"
                                        entityType={ENTITIES.EQUIPMENT_TYPE}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="equipmentBrand"
                                        entityType={ENTITIES.EQUIPMENT_BRAND}
                                        required
                                        searchable
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <InputField
                                        name="model"
                                        placeholder="form.placeholder.equipmentModel"
                                        label="form.label.equipmentModel"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <InputField name="serialNumber" required />
                                </div>

                                <div className="_12 _m6 ">
                                    <DateTimeField
                                        name="dateOfManufacture"
                                        dateFormat="MMM/y"
                                        monthPicker
                                    />
                                </div>

                                <div className="_12 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="datePutInService"
                                        dateFormat="MMM/y"
                                        monthPicker
                                    />
                                </div>

                                <div className="_12 _m6">
                                    <RadioGroupField
                                        name="removedFromService"
                                        label="form.label.removedFromService"
                                        defaultOptions={YES_NO_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                        onUpdate={(newValue) => {
                                            setFieldValue(
                                                'removedFromService',
                                                newValue
                                            )
                                            if (newValue === 0) {
                                                setFieldValue(
                                                    'dateRemovedFromService',
                                                    null
                                                )
                                            }
                                        }}
                                    />
                                </div>

                                <div className="_12 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="dateRemovedFromService"
                                        required={removedFromService === 1}
                                        disabled={removedFromService === 0}
                                        dateFormat="MMM/y"
                                        monthPicker
                                    />
                                </div>
                            </div>

                            <div className="_w">
                                <Separator />
                                <FileUploadField
                                    name="files"
                                    label="form.label.uploadMaintenanceRecord"
                                    type={FILE_UPLOAD_TYPE.FILE}
                                />
                            </div>

                            <div className="_w -buttons -pb20">
                                <div className="_12 _xs6 -mt40">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.cancel"
                                        onClick={handleClose}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="_12 _xs6 -mt40">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        label="button.saveEquipment"
                                        disabled={isSubmitting}
                                    />
                                </div>
                                {isSubmitting && <Loader />}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default PersonalEquipmentForm
