import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { BASE_URL } from 'utils/axiosClient'

import COLORS from 'constants/colors'
import { AVAILABILITY, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'
// import IconButton from 'components/IconButton'

const UserInfo = ({ user, myProfile, setOpen }) => {
    const t = useTranslate()

    const {
        firstName,
        middleName,
        lastName,
        currentTitle,
        positions,
        userStatus,
        avatarPath,
        identityVerified,
    } = user

    const handleEdit = () => {
        setOpen(true)
    }

    // NOTE:: for later (design needs to be changed)
    // const handleShare = () => {
    //     console.log('Share')
    // }

    const renderStatusClassName = (userStatus) => {
        switch (userStatus) {
            case AVAILABILITY.AVAILABLE_FOR_HIRE:
                return '-available'
            case AVAILABILITY.UNAVAILABLE:
                return '-unavailable'
            default:
                return '-noStatus'
        }
    }

    return (
        <div className="a-userInfo">
            <div className="a-userInfo__header space-between">
                {/* <IconButton onClick={handleShare} icon={ICONS.SHARE} /> */}
                <div>
                    {myProfile && (
                        <div
                            className="-editProfileButton"
                            onClick={handleEdit}
                        >
                            <Icon
                                name={ICONS.EDIT}
                                color={COLORS.DARK_BLUE_40}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="a-userInfo__mainInfo">
                <div className="aligned-center -profileImgAndText">
                    <div
                        className={`a-userInfo__mainInfo--${
                            avatarPath ? 'image' : 'placeholder'
                        }`}
                        style={{
                            backgroundImage: `url("${
                                avatarPath ? BASE_URL + avatarPath : ''
                            }")`,
                        }}
                    >
                        {!avatarPath && (
                            <div className="-placeholderImage">
                                <Icon
                                    name={ICONS.USER}
                                    color={COLORS.WHITE}
                                    size={ICON_SIZE.SIZE80}
                                />
                            </div>
                        )}
                    </div>
                    {userStatus && (
                        <div
                            className={`-status ${renderStatusClassName(
                                userStatus
                            )}`}
                        ></div>
                    )}

                    <div className="a-userInfo__mainInfo--info -mt5">
                        <span className="-name a-bodyTextMedium aligned-center">
                            {firstName}{' '}
                            {middleName && middleName.charAt(0) + '.'}{' '}
                            {lastName}
                            {identityVerified && (
                                <span className="verifiedIcon">
                                    <Icon name={ICONS.VERIFICATION_PROFILE} />
                                </span>
                            )}
                        </span>
                        <span className="-name a-bodyTextMedium aligned-center">
                            {currentTitle}
                        </span>
                        {positions && (
                            <div className="-mt10 listOfStatuses">
                                {positions.map((position, index) => (
                                    <span
                                        className="a-status a-captionsTextRegular -gray"
                                        key={index}
                                    >
                                        {position}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

UserInfo.propTypes = {
    user: PropTypes.object,
    myProfile: PropTypes.bool,
    userID: PropTypes.number,
    verified: PropTypes.bool,
    setOpen: PropTypes.func,
}

UserInfo.defaultProps = {
    myProfile: false,
    verified: false,
}

export default UserInfo
