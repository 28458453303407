import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import ROUTES from 'constants/routes'
import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'

const SideBarItem = ({
    label,
    to,
    icon,
    subitems,
    active,
    setActive,
    className,
    mainClassName,
    setOpen,
    open,
    notAvailableAtTheMoment,
}) => {
    const t = useTranslate()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const handleClick = (e, to) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(to)
    }

    const handleSetActive = () => {
        if (notAvailableAtTheMoment) {
            setAlert(t('alerts.messages.notAvailable'))
        } else {
            setActive(subitems && active === to ? null : to)
        }

        if (setOpen) {
            setOpen(!open)
        }
    }

    const getIsActive = (path) => {
        if (pathname === ROUTES.HOME) {
            return path === ROUTES.HOME
        }
        if (pathname === ROUTES.SETTINGS)
            return path === ROUTES.UNITS_OF_MEASUREMENT
        return pathname.includes(path)
    }

    return (
        <Link
            className={`${mainClassName}__itemContainer a-bodyTextMedium ${
                active === to ? ' -active' : ''
            } ${subitems ? '-withSubItems' : ''}`}
            onClick={handleSetActive}
            to={subitems ? pathname : to}
        >
            <div
                className={`${mainClassName}__item ${
                    getIsActive(to) && !notAvailableAtTheMoment
                        ? ' -active'
                        : ''
                } ${className || ''}`}
                title={t(label)}
            >
                {icon && (
                    <Icon
                        name={icon}
                        color={COLORS.SECONDARY}
                        size={ICON_SIZE.SIZE20}
                    />
                )}
                <span
                    className={`${mainClassName}__itemLabel a-bodyTextMedium`}
                >
                    {t(label)}
                </span>
                {subitems && (
                    <span className={`${mainClassName}__subItemsIcon`}></span>
                )}
            </div>
            {subitems && (
                <div className={`${mainClassName}__subitems`}>
                    {subitems.map((item) => (
                        <div
                            className={`${mainClassName}__subitem ${
                                getIsActive(item.to) ? ' -active' : ''
                            }`}
                            key={item.key}
                            onClick={(e) => handleClick(e, item.to)}
                            title={t(item.label)}
                        >
                            {item.icon && (
                                <Icon
                                    name={item.icon}
                                    color={COLORS.PRIMARY}
                                    size={ICON_SIZE.SIZE24}
                                />
                            )}
                            <span className={`${mainClassName}__subitemLabel`}>
                                {t(item.label)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </Link>
    )
}

SideBarItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    icon: PropTypes.string,
    subitems: PropTypes.array,
    active: PropTypes.string,
    setActive: PropTypes.func,
    className: PropTypes.string,
    mainClassName: PropTypes.string,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    notAvailableAtTheMoment: PropTypes.bool,
}

SideBarItem.defaultProps = {
    notAvailableAtTheMoment: false,
    mainClassName: 'm-sidebar',
}

export default SideBarItem
