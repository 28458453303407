import { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { formatDateTime } from 'services/moment.service'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const PinnedMessages = ({ activeChannel }) => {
    const t = useTranslate()

    const initialPinnedMessages = activeChannel?.state?.pinnedMessages

    const [pinned, setPinned] = useState(initialPinnedMessages)

    const handlePinned = (event, setState) => {
        const eventTypes = ['message.updated']

        if (eventTypes.includes(event.type)) {
            setState(activeChannel?.state?.pinnedMessages)
        }
    }

    useEffect(() => {
        if (!activeChannel) return

        const messageEventListener = activeChannel.on((event) => {
            handlePinned(event, setPinned)
        })

        return () => {
            messageEventListener.unsubscribe()
        }
    }, [activeChannel])

    const handlePinnedMessage = (pinnedMessage) => {
        const activePinnedMessage = document.querySelectorAll(
            `[data-message-id='${pinnedMessage?.id}']`
        )

        activePinnedMessage[0].style.scrollMargin = '80px'
        activePinnedMessage[0].scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        })
    }

    if (pinned.length === 0) return null
    return (
        <div
            className={`-pinnedMessages ${
                pinned.length === 1 ? '-onlyOne' : ''
            }`}
        >
            {pinned
                .slice()
                .reverse()
                .map((pinnedMessage, index) => (
                    <div
                        key={index}
                        className="-messageWrapper"
                        onClick={() => {
                            handlePinnedMessage(pinnedMessage)
                        }}
                    >
                        <Icon
                            name={ICONS.PIN}
                            color={COLORS.PRIMARY}
                            size={ICON_SIZE.SIZE20}
                        />
                        <div className=" fullWidth">
                            <span
                                className="-content a-mediumText"
                                dangerouslySetInnerHTML={{
                                    __html: pinnedMessage.html,
                                }}
                            ></span>
                            <div className="space-between fullWidth -pinned">
                                <span className="a-captionsTextRegular a-lightText -opacity-60">
                                    {t('general.pinnedBy')}:{' '}
                                    {pinnedMessage.pinned_by.name}
                                </span>
                                <span className="a-captionsTextRegular a-lightText -opacity-60">
                                    {t('general.pinnedAt')}:{' '}
                                    {formatDateTime(pinnedMessage.pinned_at)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    )
}

export default PinnedMessages
