import React, { Fragment } from 'react'
import { Navigate, Route } from 'react-router-dom'

import ROUTES from 'constants/routes'

import { AuthLayout, FullScreenLayout } from 'components/layouts'
import { SingleProject, NotFoundPage } from 'screens/common'
import ProjectDiveRecords from '../../screens/common/projectDiveRecords/ProjectDiveRecords'
import { ProfileScreen as DiverProfile } from 'screens/diver/profile'
import { ProfileScreen as ContractorProfile } from 'screens/divingContractor'
import DiveRecordSummary from 'screens/diver/DiveRecordSummary'
import { HelpAndInfo } from 'screens/common/helpAndInfo'
import DiveRecordVerificationLevels from 'screens/common/helpAndInfo/DiveRecordVerificationLevels'
import Release090 from '../../screens/common/helpAndInfo/release/Release090'
import Release091 from '../../screens/common/helpAndInfo/release/Release091'
import Release100 from 'screens/common/helpAndInfo/release/Release100'

const CommonUserRoutes = () => {
    return (
        <Fragment>
            <Route element={<AuthLayout />}>
                <Route
                    path={`${ROUTES.PROJECT}/:id`}
                    element={<SingleProject />}
                />
                <Route
                    path={`${ROUTES.PROJECT}/:id/${ROUTES.DIVE_RECORDS}`}
                    element={<ProjectDiveRecords />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:id`}
                    element={<SingleProject />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:id/${ROUTES.MY_PROJECT_RECORDS}`}
                    element={<ProjectDiveRecords />}
                />
                <Route
                    path={`${ROUTES.DIVER_PROFILE}/:profileHash`}
                    element={<DiverProfile />}
                />
                <Route
                    path={`${ROUTES.DIVING_CONTRACTOR_PROFILE}/:profileHash`}
                    element={<ContractorProfile />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.MY_PROJECT_RECORDS}/:diveRecordId`}
                    element={<DiveRecordSummary />}
                />
                <Route path={ROUTES.HELP_AND_INFO} element={<HelpAndInfo />}>
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`}
                        element={<DiveRecordVerificationLevels />}
                    />
                    <Route
                        path={`${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_0}`}
                        element={<Release090 />}
                    />
                    <Route
                        path={`${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_1}`}
                        element={<Release091 />}
                    />
                    <Route
                        path={`${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`}
                        element={<Release100 />}
                    />
                </Route>
            </Route>
            <Route element={<FullScreenLayout />}>
                <Route
                    path={ROUTES.NOT_FOUND_PAGE}
                    element={<NotFoundPage />}
                />
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Fragment>
    )
}

export default CommonUserRoutes
