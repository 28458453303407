import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import ServicesInfo from './ServicesInfo'
import ContactPersonInfo from './ContactPersonInfo'
import CompanyInfo from './CompanyInfo'

const SIGNUP_STEPS = [ContactPersonInfo, CompanyInfo, ServicesInfo]

const DiveOrganizationOnboarding = ({ role, setShowOnboarding }) => {
    const [step, setStep] = useState(0)
    const [personData, setPersonData] = useState({ role })
    const [companyData, setCompanyData] = useState({})

    const renderStepContent = () => {
        const StepContent = SIGNUP_STEPS[step]
        return (
            <StepContent
                personData={personData}
                setPersonData={setPersonData}
                companyData={companyData}
                setCompanyData={setCompanyData}
                setStep={setStep}
            />
        )
    }

    const handleGoBack = () => {
        if (!step) {
            setShowOnboarding(false)
        } else {
            setStep(step - 1)
        }
    }

    return (
        <Fragment>
            <div className="-backBtn">
                <Button
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.SMALL}
                    icon={ICONS.ARROW_LEFT}
                    iconColor={COLORS.SECONDARY}
                    onClick={() => handleGoBack()}
                    label="button.back"
                />
            </div>
            {renderStepContent()}
        </Fragment>
    )
}

DiveOrganizationOnboarding.propTypes = {
    role: PropTypes.object.isRequired,
    setShowOnboarding: PropTypes.func.isRequired,
}

export default DiveOrganizationOnboarding
