import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'

import COLORS from 'constants/colors'
import {
    BUTTON_STATUS,
    BUTTON_TYPE,
    DIVE_RECORD_STATUS,
    ICON_POSITION,
    ICON_SIZE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'

import useQueryParams from 'hooks/useQueryParams'
import isFormEmpty from 'utils/emptyForm'

import Button from 'components/Button'
import Modal from 'components/Modal'
import {
    DateTimeField,
    MultiselectField,
    SelectField,
} from 'components/formFields'
import Separator from 'components/Separator'
import { MOMENT_FORMATS, getIsDateSameOrBefore } from 'services/moment.service'
import Loader from 'components/Loader'

const DiveRecordFilters = ({ activeFilters, projectId }) => {
    const t = useTranslate()
    const { replaceFilterParams } = useQueryParams()

    const [open, setOpen] = useState(false)

    const emptyValues = {
        divingMode: [],
        status: [],
        project: null,
        fromDate: null,
        toDate: null,
    }

    const initialValues = {
        divingMode: activeFilters.divingMode ?? emptyValues.divingMode,
        status: activeFilters.status ?? emptyValues.status,
        project: activeFilters.project ?? emptyValues.project,
        fromDate: activeFilters.fromDate ?? emptyValues.fromDate,
        toDate: activeFilters.toDate ?? emptyValues.toDate,
    }

    const validation = Yup.object({
        fromDate: Yup.date().nullable(),
        toDate: Yup.date()
            .nullable()
            .test('toDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.fromDate,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.toDateAfterFromDate'),
                        path: 'toDate',
                    })
                }
                return true
            }),
    })

    const handleFilter = (filters, { setSubmitting }) => {
        setSubmitting(true)
        replaceFilterParams(filters)
        setSubmitting(false)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
    }

    return (
        <div>
            <Button
                label="button.filters"
                onClick={() => setOpen(true)}
                icon={ICONS.FILTER}
                iconSize={ICON_SIZE.SIZE16}
                iconColor={COLORS.DARK_BLUE_60}
                btnClass={BUTTON_STATUS.QUATERNARY}
                iconPosition={ICON_POSITION.RIGHT}
            />
            {open && (
                <Modal
                    open={open}
                    setOpen={setOpen}
                    closeOnClickOutside={false}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleFilter}
                    >
                        {({ values, setFieldValue, isSubmitting }) => (
                            <Form>
                                <div className="_wr">
                                    <div className="_w">
                                        <h3 className="-mb20 _12 justify-center">
                                            {t('general.filterDiveRecords')}
                                        </h3>

                                        <h6 className="-mb20 _12">
                                            {t('general.diveRecordInfo')}
                                        </h6>

                                        {!projectId && (
                                            <div className="_12 _m6">
                                                <SelectField
                                                    name="project"
                                                    entityType={
                                                        ENTITIES.PROJECT
                                                    }
                                                    params={{
                                                        myDiveProjects: true,
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div className="_12 _m6">
                                            <SelectField
                                                name="status"
                                                defaultOptions={Object.values(
                                                    DIVE_RECORD_STATUS
                                                )}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                            />
                                        </div>

                                        <Separator />

                                        <h6 className="-mb20 _12">
                                            {t('form.label.dateOfDive')}
                                        </h6>

                                        <div className="_12 _m6">
                                            <DateTimeField
                                                name="fromDate"
                                                label="form.label.from"
                                            />
                                        </div>

                                        <div className="_12 _m6 calendarMovedToRight">
                                            <DateTimeField
                                                name="toDate"
                                                label="form.label.to"
                                            />
                                        </div>

                                        <Separator />

                                        <h6 className="-mb20 _12">
                                            {t('general.diveInfo')}
                                        </h6>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="divingMode"
                                                label="form.label.modeOfDive"
                                                placeholder="form.placeholder.selectModeOfDive"
                                                entityType={
                                                    ENTITIES.DIVING_MODE
                                                }
                                                searchable
                                            />
                                        </div>
                                    </div>

                                    <div className="_w -buttons space-between -mt15">
                                        <div className="_12 _m4 -mt10 -prevButton">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.SECONDARY
                                                }
                                                label="button.cancel"
                                                onClick={() => setOpen(false)}
                                                isSubmitting={isSubmitting}
                                            />
                                        </div>
                                        <div className="_12 _m4 -mt10 -skipButton">
                                            <Button
                                                btnClass={
                                                    BUTTON_STATUS.TERTIARY
                                                }
                                                label="button.resetFilters"
                                                icon={ICONS.LINE}
                                                iconPosition={
                                                    ICON_POSITION.RIGHT
                                                }
                                                iconColor={COLORS.SECONDARY}
                                                onClick={() =>
                                                    handleReset(
                                                        values,
                                                        setFieldValue
                                                    )
                                                }
                                                disabled={
                                                    isSubmitting ||
                                                    isFormEmpty(values)
                                                }
                                            />
                                        </div>
                                        <div className="_12 _m4 -mt10 -nextButton">
                                            <Button
                                                btnClass={BUTTON_STATUS.PRIMARY}
                                                type={BUTTON_TYPE.SUBMIT}
                                                label="button.filter"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
        </div>
    )
}

DiveRecordFilters.propTypes = {
    activeFilters: PropTypes.object,
}

export default DiveRecordFilters
