import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ROUTES from 'constants/routes'

import { NoAuthLayout } from 'components/layouts'
import Register from 'screens/noAuth/Register'

const OnboardingRoutes = () => {
    return (
        <Routes>
            <Route element={<NoAuthLayout />}>
                <Route path={ROUTES.REGISTER} element={<Register />} />
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.REGISTER} />} />
        </Routes>
    )
}

export default OnboardingRoutes
