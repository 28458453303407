import { useEffect, useRef } from 'react'

import { POSITIONS } from 'constants/enums'

const { useFormikContext } = require('formik')

const CreateDiveRecordModalContext = ({
    positionOnCurrentProject,
    chamberRecompressionOnlyDivingMode,
}) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { diveRecordType } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (
            !firstRender.current &&
            diveRecordType === POSITIONS.DIVING_MEDIC.id
        ) {
            setFieldValue('divingMode', chamberRecompressionOnlyDivingMode)
            setFieldTouched('divingMode', false)
        }
        if (firstRender.current) {
            firstRender.current = false
            return
        }
    }, [diveRecordType])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

export default CreateDiveRecordModalContext
