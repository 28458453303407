import { Link, Outlet, useLocation } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import Breadcrumbs from 'components/Breadcrumbs'
import TabNavigation from 'components/tabs/TabNavigation'
import Icon from 'components/Icon'

const TABS = [
    {
        key: 'explore-skill-n-depth',
        subItemsTitle: 'general.exploreSkillNDepth',
        to: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`,
        icon: ICONS.EXPLORE,
        subItems: [
            {
                key: 'dive-record-verification-levels',
                label: 'general.diveRecordVerificationLevels',
                to: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`,
                translate: true,
            },
        ],
    },
    {
        key: 'release-notes',
        subItemsTitle: 'general.releaseNotes',
        to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`,
        icon: ICONS.RELEASE_NOTES,
        subItems: [
            {
                key: 'release-1-0-0',
                label: 'general.release100',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`,
                translate: true,
            },
            {
                key: 'release-0-9-1',
                label: 'general.release091',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_1}`,
                translate: true,
            },
            {
                key: 'release-0-9-0',
                label: 'general.release090',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_0}`,
                translate: true,
            },
        ],
    },
]

const HelpAndInfo = () => {
    const t = useTranslate()
    const { pathname } = useLocation()
    const showDefaultContent = pathname.endsWith(ROUTES.HELP_AND_INFO)

    return (
        <div className="m-boxes -left -settings">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'general.infoCenter',
                            to: pathname,
                            icon: ICONS.HOMEPAGE_BREADCRUMB,
                            showAlways: true,
                        },
                    ]}
                />
            </div>
            <div className="m-boxes__main -withDefaultContent fullHeightBoxes">
                {showDefaultContent && (
                    <div className="-defaultContent column aligned-center justify-center fullHeight">
                        <Icon name={ICONS.HELP_AND_INFO_DESCRIPTION} />
                        <span className="a-bodyTextMedium -mb20 -mt20">
                            {t('general.helpAndInfoTitle')}
                        </span>
                        <p className="a-mediumText centered-text a-lightText -defaultContentDescription">
                            {t('general.helpAndInfoDescription')}
                        </p>
                    </div>
                )}
                <Outlet />
            </div>
            <div className="m-boxes__side column fullHeightBoxes">
                <span className="a-bodyTextMedium -boxTitle -mb10">
                    {t('general.helpAndInfo')}
                </span>
                <TabNavigation tabs={TABS} columnView />
            </div>
        </div>
    )
}

export default HelpAndInfo
