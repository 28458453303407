import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const ContactInfo = ({ website, email, fullPhoneNumber }) => {
    const t = useTranslate()

    const { isSmallScreen } = useWindowDimensions()

    return (
        <Fragment>
            {!isSmallScreen && (
                <span className="a-bodyTextMedium -mb20">
                    {t('general.contactInfo')}
                </span>
            )}

            <div className={`-email ${isSmallScreen ? '-mt20' : ''}`}>
                <Icon name={ICONS.FOOTER_MAIL} color={COLORS.DARK_BLUE_20} />
                <Link
                    className="a-mediumText a-lightText -opacity-60"
                    to=""
                    onClick={() => (window.location = `mailto:${email}`)}
                    title={email}
                >
                    {email}
                </Link>
            </div>
            {fullPhoneNumber && (
                <div className="-phone">
                    <Icon
                        name={ICONS.FOOTER_PHONE}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <Link
                        className="a-mediumText a-lightText -opacity-60"
                        title={fullPhoneNumber}
                        onClick={() => (window.location = `tel:${email}`)}
                    >
                        {fullPhoneNumber}
                    </Link>
                </div>
            )}
            {website && (
                <div className="-website">
                    <Icon
                        name={ICONS.WEBSITE}
                        color={COLORS.DARK_BLUE_20}
                        size={ICON_SIZE.SIZE16}
                    />
                    <Link
                        className="a-mediumText a-lightText -opacity-60"
                        to={
                            website.startsWith('http')
                                ? website
                                : '//' + website
                        }
                        target="blank"
                        title={website}
                    >
                        {website}
                    </Link>
                </div>
            )}
        </Fragment>
    )
}

ContactInfo.propTypes = {
    email: PropTypes.string,
    website: PropTypes.string,
    fullPhoneNumber: PropTypes.string,
}

export default ContactInfo
