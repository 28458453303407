import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'

import useToggleDropdown from 'hooks/useToggleDropdown'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const InfoDropdown = ({
    title,
    children,
    isLoading,
    openByDefault,
    icon,
    columnTabs,
}) => {
    const t = useTranslate()

    const { contentRef, open, setOpen } = useToggleDropdown(openByDefault)

    useEffect(() => {
        setOpen(openByDefault)
    }, [isLoading])

    return (
        <div
            className={`a-infoDropdown ${open ? '-open' : ''} ${
                columnTabs ? '-columnTabs' : ''
            }`}
        >
            <div
                onClick={() => setOpen(!open)}
                className="a-infoDropdown__header"
            >
                {icon && (
                    <Icon
                        name={icon}
                        color={COLORS.SECONDARY}
                        size={ICON_SIZE.SIZE20}
                    />
                )}
                <span
                    className={`${
                        columnTabs ? 'a-mediumText' : 'a-bodyTextMedium'
                    }`}
                >
                    {t(title)}
                </span>
                <div className="-icon">
                    <Icon name={ICONS.CHEVRON_DOWN} />
                </div>
            </div>
            <div className="a-infoDropdown__content" ref={contentRef}>
                {children}
            </div>
        </div>
    )
}

InfoDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    openByDefault: PropTypes.bool,
    icon: PropTypes.string,
    columnTabs: PropTypes.bool,
}

InfoDropdown.defaultProps = {
    columnTabs: false,
}

export default InfoDropdown
