import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { BASE_URL } from 'utils/axiosClient'

import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'

const UserCard = ({
    title,
    children,
    withAvatar,
    avatar,
    link,
    placeholderIcon,
    withIcon,
    cardContainerLink,
}) => {
    return (
        <div
            className={`a-userCard ${withIcon ? '-withIcon' : ''} ${
                cardContainerLink ? '-cardContainerLink' : ''
            }`}
        >
            <div className="a-userCard__content aligned-start">
                {withAvatar && (
                    <div className="-avatarImage">
                        {avatar ? (
                            <img src={`${BASE_URL}${avatar}`} />
                        ) : (
                            <Icon
                                name={placeholderIcon || ICONS.USER}
                                iconSize={ICON_SIZE.SIZE20}
                                color={COLORS.DARK_BLUE_60}
                            />
                        )}
                    </div>
                )}
                <div className="-textContent">
                    <div className="-title">
                        {link ? (
                            <Link
                                className="a-link aligned-center justify-center -value -valueWithoutColor"
                                to={link}
                            >
                                <span className="a-bodyTextMedium">
                                    {title}
                                </span>
                            </Link>
                        ) : (
                            <span className="a-bodyTextMedium">{title}</span>
                        )}
                    </div>
                    <div className="-values">{children}</div>
                </div>
            </div>
            {cardContainerLink && (
                <Link
                    to={cardContainerLink}
                    className="-cardContainerLink"
                ></Link>
            )}
        </div>
    )
}

UserCard.propTypes = {
    title: PropTypes.string,
    withAvatar: PropTypes.bool,
    avatar: PropTypes.string,
    link: PropTypes.string,
    placeholderIcon: PropTypes.string,
    withIcon: PropTypes.bool,
    cardContainerLink: PropTypes.string,
}

UserCard.defaultProps = {
    withAvatar: false,
    link: '',
    withIcon: false,
    cardContainerLink: '',
}

export default UserCard
