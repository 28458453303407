import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useMenuItems from 'hooks/useMenuItems'

import handleLogout from 'utils/logoutHelper'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import Logo from 'components/Logo'
import SidebarItem from './SidebarItem'

const Sidebar = ({
    open,
    setOpen,
    hocRef,
    menu,
    isMobile,
    isSmallScreen,
    hamBtnRef,
}) => {
    const t = useTranslate()
    const menuItems = useMenuItems(menu)

    const [active, setActive] = useState()

    const {
        currentUser,
        setCurrentUser,
        userId,
        isDiver,
        currentCompany,
        setCurrentCompany,
    } = useContext(CurrentUserContext)

    if (!currentUser || (!isDiver && !currentCompany)) return null

    return (
        <div
            ref={hocRef}
            className={`m-sidebar ${!isMobile ? '-lowerLevel' : ''}  ${
                open ? '' : '-closed'
            }`}
        >
            <div className="m-sidebar__scroll">
                {isMobile && (
                    <div
                        className="m-toggler"
                        ref={hamBtnRef}
                        onClick={() => setOpen(!open)}
                    >
                        <span></span>
                        <span></span>
                    </div>
                )}
                {isMobile && (
                    <Logo
                        color1={COLORS.PRIMARY}
                        color2={COLORS.SECONDARY}
                        color3={COLORS.SECONDARY}
                    />
                )}
                {isSmallScreen && (
                    <>
                        <Link
                            onClick={() => {
                                setOpen(!open)
                            }}
                            to={
                                isDiver
                                    ? `${ROUTES.DIVER_PROFILE}/${currentUser.profileHash}`
                                    : `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${currentCompany.profileHash}`
                            }
                        >
                            <Avatar
                                firstName={currentUser.firstName}
                                lastName={currentUser.lastName}
                                avatar={
                                    currentUser.avatarPath
                                        ? currentUser.avatarPath
                                        : currentCompany?.logo?.path
                                }
                            />
                        </Link>
                        <Link
                            className="m-avatar__item -logout a-bodyTextMedium"
                            to="#"
                            onClick={() =>
                                handleLogout(setCurrentUser, setCurrentCompany)
                            }
                        >
                            <Icon
                                name={ICONS.LOGOUT}
                                color={COLORS.SECONDARY}
                                size={ICON_SIZE.SIZE20}
                            />
                            {t('button.logout')}
                        </Link>
                        <div className="a-separator -bottom -dashedbottom" />
                    </>
                )}
                {menuItems.map(
                    ({
                        key,
                        label,
                        to,
                        icon,
                        subitems,
                        className,
                        separator,
                        notAvailableAtTheMoment,
                    }) =>
                        separator ? (
                            <div
                                key={key}
                                className="a-separator -bottom -dashedbottom"
                            ></div>
                        ) : (
                            <SidebarItem
                                key={key}
                                label={label}
                                to={to}
                                icon={icon}
                                subitems={subitems}
                                active={active}
                                className={className}
                                setActive={setActive}
                                setOpen={setOpen}
                                open={open}
                                notAvailableAtTheMoment={
                                    notAvailableAtTheMoment
                                }
                            />
                        )
                )}
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    menu: PropTypes.array,
    isSmallScreen: PropTypes.bool,
    isMobile: PropTypes.bool,
}

export default Sidebar
