import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const AboutCompany = ({ company }) => {
    const t = useTranslate()

    const {
        hqAddress,
        size,
        industries,
        services,
        vessels,
        clients,
        user,
        about,
        regions,
        hqCountry,
        tinVat,
    } = company

    return (
        <div className="m-userMainInfo">
            <div className="m-userMainInfo__about">
                <div className="aligned-center a-tabName -mb20">
                    <span className="a-bodyTextMedium ">
                        {t('general.aboutCompany')}
                    </span>
                </div>
                <div className="-aboutMaininfo -diverProfile">
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.hqLocation')}
                        </span>
                        <span className="a-mediumText aligned-start -mt10">
                            <Icon
                                name={ICONS.LOCATION}
                                color={COLORS.DARK_BLUE_20}
                                size={ICON_SIZE.SIZE20}
                            />
                            {hqCountry
                                ? hqCountry?.name + ', ' + hqAddress
                                : hqAddress}
                        </span>
                    </div>
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.contactPerson')}
                        </span>
                        <span className="a-mediumText aligned-start -mt10">
                            <Icon
                                name={ICONS.USER}
                                color={COLORS.DARK_BLUE_20}
                            />
                            {user.fullName}
                        </span>
                    </div>
                </div>

                {about && (
                    <p className="a-mediumText -mt20 a-lightText -about">
                        {about}
                    </p>
                )}

                <div className="-rowInformation -mt20 aligned-center -mt20 -mb20">
                    {size && (
                        <div className="aligned-center -companySize -rowElement">
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('general.companySize')}:
                            </span>
                            <span className="a-status -blue a-captionsTextRegular">
                                {size?.name}
                            </span>
                        </div>
                    )}
                    <div className="-rowElement">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.tinVat')}
                        </span>
                        <span className="a-status -purple a-captionsTextRegular">
                            {tinVat}
                        </span>
                    </div>
                </div>

                {industries && (
                    <div className="column -industry -mb20">
                        <span className="a-mediumText a-lightText -opacity-60 -mb10">
                            {t('general.industry')}
                        </span>
                        <div className="listOfStatuses">
                            {industries?.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -orange a-captionsTextRegular"
                                    >
                                        {item.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}

                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mb10">
                        {t('general.familyOfProvidedServices')}
                    </span>
                    <div className="listOfStatuses">
                        {services?.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className="a-status -purple a-captionsTextRegular"
                                >
                                    {item.name}
                                </span>
                            )
                        })}
                    </div>
                </div>

                {regions && (
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                            {t('general.locationsOfServices')}
                        </span>
                        <div className="listOfStatuses">
                            {regions?.map((region, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -green a-captionsTextRegular"
                                    >
                                        {region.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}

                {vessels && (
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                            {t('general.vessels')}
                        </span>
                        <div className="listOfStatuses">
                            {vessels?.map((vessel, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -orange a-captionsTextRegular"
                                    >
                                        {vessel.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}

                {clients && (
                    <div className="m-userMainInfo__about -mt20">
                        <div className="column">
                            <p className="-mb20 a-bodyTextMedium">
                                {t('general.clients')}
                            </p>
                            <div className="listOfStatuses">
                                {clients?.map((client, index) => {
                                    return (
                                        <span
                                            key={index}
                                            className="a-status -orange a-captionsTextRegular"
                                        >
                                            {client.name}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

AboutCompany.propTypes = {
    company: PropTypes.object,
}

export default AboutCompany
