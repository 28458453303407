import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import Modal from 'components/Modal'
import SelectMultiselectGroupField from 'components/formFields/SelectMultiselectGroupField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const SkillRecordForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const initialValues = {
        skillRecords: initialData || [],
    }

    const validation = Yup.object({
        skillRecords: Yup.array(),
    })

    return (
        <Modal open={open} setOpen={setOpen} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className="m-modal__form">
                        <FocusError />
                        <div className="_wr">
                            <div className="-form _w">
                                <h3 className="-mb20 _12 justify-center">
                                    {t(title)}
                                </h3>
                                <div className="oneLineSelectGroupSkill _12">
                                    <SelectMultiselectGroupField
                                        name="skillRecords"
                                        title="general.addAllSkills"
                                        fieldsName={['skillCategory', 'skill']}
                                        fieldsPlaceholder={[
                                            'form.placeholder.skillCategory',
                                            'form.placeholder.skill',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.SKILL_CATEGORY,
                                            ENTITIES.SKILL,
                                        ]}
                                        fieldsShowLabel={[false, false]}
                                        fieldsRequired={[true, true]}
                                        disableAddOnlyForBoth={true}
                                        buttonLabel="button.add"
                                        secondFieldRelation="skillCategories.id"
                                        fieldsSearchable={[true, true]}
                                        listingTitle={t(
                                            'general.selectedSkills'
                                        )}
                                        disableOneField={[false, true]}
                                    />
                                </div>

                                <div className="-mt10 -buttons space-between _12">
                                    <div className="_12 _xs6 -mt10">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.cancel"
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="_12 _xs6 -mt10">
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            label="button.saveSkills"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default SkillRecordForm
