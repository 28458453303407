const Loader = ({ size = "100" }) => {

    return (
        <div className='loader-container'>
            <div className="loader">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336 333" fill="white">

                    <path fill="#4A4FFF" fillRule="evenodd" d="M316.054 165.228h-63.543c0 6.293-.682 12.435-2.047 18.35-6.976 30.103-30.71 53.837-60.814 60.813l-5.08 18.123c20.246-2.73 38.52-11.678 52.851-24.796l14.483 8.19-8.189-14.483c13.118-14.332 22.066-32.606 24.795-52.852l10.313-2.881 37.231-10.464ZM171.3 20.398v63.619c6.294 0 12.436.682 18.35 2.047 30.104 6.976 53.838 30.71 60.814 60.813l18.122 5.081c-2.729-20.246-11.677-38.52-24.795-52.852l8.189-14.483-14.559 8.114C223.09 79.619 204.816 70.67 184.57 67.94l-2.882-10.312L171.3 20.398ZM26.47 165.228H90.09c0-6.294.682-12.436 2.046-18.351 6.977-30.103 30.71-53.837 60.814-60.813l5.08-18.123c-20.246 2.73-38.52 11.678-52.851 24.796l-14.483-8.19 8.19 14.483c-13.119 14.332-22.067 32.606-24.796 52.852l-10.313 2.881-37.307 10.465ZM171.3 309.982v-63.619c-6.294 0-12.436-.683-18.35-2.048-30.104-6.976-53.837-30.71-60.814-60.813l-18.122-5.081c2.73 20.246 11.677 38.521 24.795 52.852l-8.19 14.483 14.484-8.189c14.331 13.118 32.606 22.065 52.851 24.795l2.882 10.313 10.464 37.307Z" clipRule="evenodd">
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            from="0 172 165"
                            to="360 172 165"
                            dur="3s"
                            repeatCount="indefinite" />
                    </path>
                    <path fill="#171E3C" fillRule="evenodd" d="m199.887 262.514 2.199 8.265-4.777 1.289-2.199-8.265c1.592-.455 3.184-.834 4.777-1.289Zm68.699-125.949 8.265-2.199 1.289 4.777-8.265 2.199c-.455-1.592-.834-3.185-1.289-4.777ZM65.673 196.013l-1.29-4.777 8.266-2.199c.379 1.593.834 3.185 1.289 4.777l-8.265 2.199Zm158.403 55.733 4.322 7.431-4.246 2.503-4.323-7.431a209.913 209.913 0 0 0 4.247-2.503Zm33.743-139.37 7.431-4.322 2.502 4.246-7.431 4.322c-.834-1.44-1.668-2.881-2.502-4.246ZM77.274 222.325l-2.502-4.246 7.431-4.322a47.886 47.886 0 0 0 2.502 4.246l-7.43 4.322Zm36.852-151.123 4.247-2.502 4.322 7.43a47.877 47.877 0 0 0-4.247 2.503l-4.322-7.431Zm146.195 142.555 7.431 4.322-2.502 4.246-7.431-4.322a209.822 209.822 0 0 0 2.502-4.246ZM219.829 76.131l4.323-7.431 4.246 2.502-4.322 7.43c-1.365-.833-2.806-1.667-4.247-2.501ZM118.448 261.68l-4.246-2.503 4.322-7.431c1.441.834 2.806 1.669 4.247 2.503l-4.323 7.431ZM74.772 112.376l2.502-4.246 7.431 4.322c-.834 1.44-1.668 2.805-2.502 4.246l-7.431-4.322Zm195.027 76.661 8.266 2.199-1.289 4.777-8.266-2.199c.531-1.592.91-3.184 1.289-4.777ZM195.11 66.652l2.199-8.265 4.777 1.289-2.199 8.265c-1.593-.53-3.185-.91-4.777-1.289Zm-49.895 205.416-4.777-1.289 2.199-8.265c1.593.455 3.185.91 4.777 1.289l-2.199 8.265ZM64.384 139.143l1.289-4.777 8.265 2.199c-.455 1.592-.91 3.185-1.29 4.777l-8.264-2.199ZM140.438 59.6l4.777-1.289 2.199 8.265c-1.592.38-3.184.834-4.777 1.29l-2.199-8.266Z" clipRule="evenodd"/>
                    <path fill="#171E3C" d="M321.893 172.734c-.455-.455-.682-1.061-.682-1.82v-15.999h14.103v3.185H324.85v4.246h9.63v3.185h-9.63v4.701h10.464v3.185h-11.525c-.834 0-1.441-.228-1.896-.683Z"/>
                    <path fill="#171E3C" d="M163.869 0h3.261l8.871 12.587V0h3.564v18.426h-3.26l-8.872-12.587v12.587h-3.564V0Z"/>
                    <path fill="#171E3C" d="M163.793 330.986c-1.061-1.062-1.668-2.578-1.668-4.55h3.564c0 .91.227 1.668.682 2.123.455.531 1.138.759 1.972.759h2.957c.758 0 1.365-.152 1.896-.455.455-.304.758-.759.758-1.289v-.91c0-.834-.607-1.365-1.896-1.593l-4.853-.834c-1.592-.227-2.805-.758-3.563-1.516-.834-.759-1.214-1.669-1.214-2.882v-1.441c0-.909.228-1.744.759-2.502.53-.758 1.213-1.289 2.123-1.668.91-.379 1.971-.607 3.185-.607h2.957c1.137 0 2.123.228 3.033.683a4.682 4.682 0 0 1 2.047 1.971c.455.834.759 1.82.759 2.958h-3.564c0-.759-.228-1.365-.607-1.744-.379-.455-.91-.683-1.592-.683h-2.958c-.758 0-1.365.152-1.744.455a1.489 1.489 0 0 0-.682 1.289v.683c0 .909.607 1.44 1.82 1.668l4.853.834c1.592.303 2.805.758 3.639 1.516a3.49 3.49 0 0 1 1.289 2.73v1.744c0 .91-.227 1.744-.758 2.503-.531.758-1.213 1.289-2.199 1.668-.91.379-2.047.606-3.26.606h-3.033c-2.124.076-3.64-.455-4.702-1.516ZM4.48 172.962c-.379-.304-.682-.834-.834-1.593l-3.26-16.454h3.64L6.3 168.033h.076l3.791-12.132h1.29c.454 0 .833.151 1.137.379.303.227.606.606.758 1.213l3.185 10.54h.076l2.274-13.118h3.716l-3.26 16.379c-.152.682-.38 1.213-.759 1.592-.379.303-.91.531-1.516.531H15.55l-3.943-12.663h-.076l-4.019 12.663H5.997c-.607 0-1.138-.152-1.517-.455Z"/>
                </svg>
            </div>
        </div>
    )
}

export default Loader
