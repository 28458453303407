import { useEffect, useState } from 'react'

import { getEntityService } from 'services/entity.service'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import { TAB } from '../diverInformation/constants/tabs'
import ICONS from 'constants/icons'

const DATA_ACCESS_ITEMS = {
    [TAB.MEDICAL_INFO]: {
        key: 'accessMedicalInformation',
        module: ACCESS_CONTROL_MODULES.MEDICAL_INFORMATION,
        icon: ICONS.MEDICAL_INFO,
        title: 'general.medicalInformation',
    },
    [TAB.TRAVEL_DOCUMENTATION]: {
        key: 'accessTravelDocuments',
        module: ACCESS_CONTROL_MODULES.TRAVEL_DOCUMENTS,
        icon: ICONS.TRAVEL_DOCUMENTATION,
        title: 'general.travelDocumentation',
    },
    [TAB.TRAINING_RECORD]: {
        key: 'accessTrainingRecords',
        module: ACCESS_CONTROL_MODULES.TRAINING_RECORDS,
        icon: ICONS.TRAINING_RECORD,
        title: 'general.educationAndTraining',
    },
    [TAB.PERSONAL_EQUIPMENT]: {
        key: 'accessPersonalEquipment',
        module: ACCESS_CONTROL_MODULES.PERSONAL_EQUIPMENT,
        icon: ICONS.PERSONAL_EQUIPMENT,
        title: 'general.personalEquipment',
    },
    [TAB.PAST_EMPLOYMENT]: {
        key: 'accessPastEmployment',
        module: ACCESS_CONTROL_MODULES.PAST_EMPLOYMENT,
        icon: ICONS.PAST_EMPLOYMENT,
        title: 'general.pastEmployment',
    },
}

const useDataAccessControl = (profile, tab, userId) => {
    const [status, setStatus] = useState(null)
    const [dataAccessTab, setDataAccessTab] = useState(tab)
    const [requestInProgress, setRequestInProgress] = useState(false)

    const dataAccessItem = DATA_ACCESS_ITEMS[tab]

    const isPublicAccess = dataAccessItem
        ? profile?.dataAccess[dataAccessItem.key]
        : true

    const grantedWithFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id
    const grantedWithoutFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id

    const checkAccessControl = async () => {
        try {
            setRequestInProgress(true);
            const { data } = await getEntityService(
                ENTITIES.DATA_ACCESS_REQUEST,
                {
                    'requester.id': userId,
                    'target.id': profile.id,
                    module: dataAccessItem.module,
                }
            )
            if (data.length) {
                setStatus(data[0].status)
            } else {
                setStatus(0)
            }
            setRequestInProgress(false);
            setDataAccessTab(tab)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!isPublicAccess && profile && dataAccessItem) {
            checkAccessControl()
        } else {
            setDataAccessTab(tab)
        }
    }, [profile, tab])

    return {
        hasAccess: isPublicAccess || grantedWithFiles || grantedWithoutFiles,
        hideFiles: grantedWithoutFiles,
        canRequestAccess: status === 0,
        dataAccessItem,
        dataAccessTab,
        status,
        checkAccessControl,
        requestInProgress
    }
}

export default useDataAccessControl
