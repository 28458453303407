import React, { Fragment, useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { BUTTON_STATUS, BUTTON_TYPE, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import { getValueWithKey } from 'utils/dataFormatters'

import Button from 'components/Button'
import Separator from './Separator'

const InfoList = ({
    title,
    data,
    setData,
    displayAttributes,
    displayValues,
    displayFromToValues,
    confirmMessage,
    confirmLabel,
    separator,
    deleteTitle,
    labelFirst,
    translateLabel,
    canDelete,
    threeColumns,
}) => {
    const t = useTranslate()

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleConfirm = (index) => {
        const updatedData = [...data]
        updatedData.splice(index, 1)
        setData(updatedData)
        closeConfirmationModal()
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = (index) =>
        showConfirmationModal({
            confirmLabel,
            message: confirmMessage,
            title: deleteTitle,
            handleConfirm: () => handleConfirm(index),
            handleCancel,
        })

    if (data.length < 1) return null

    return (
        <Fragment>
            {separator && (
                <div className="_w">
                    <Separator />
                </div>
            )}
            <div
                className={`a-infoList ${threeColumns ? '-threeColumns' : ''}`}
            >
                {title && (
                    <span className="a-bodyTextRegular a-infoList__text">
                        {t(title)}
                    </span>
                )}
                <div className="a-infoList__content">
                    {data.map((item, index) => (
                        <div key={index} className="-row">
                            {displayAttributes.length != 0 && (
                                <div
                                    className={`-label ${
                                        labelFirst ? '-labelFirstWrap' : ''
                                    }`}
                                >
                                    {displayAttributes.map(
                                        (
                                            {
                                                key,
                                                label,
                                                formatValue,
                                                unit,
                                                unitFunction,
                                                elementKey,
                                            },
                                            index
                                        ) =>
                                            getValueWithKey(key, item) !==
                                                undefined &&
                                            item[key] !== '' &&
                                            item[key] !== null &&
                                            (labelFirst ? (
                                                <span
                                                    key={key}
                                                    className="-labelFirst"
                                                >
                                                    <span className="a-mediumText a-lightText -beforelabel">
                                                        {label &&
                                                            (translateLabel
                                                                ? t(label)
                                                                : label)}
                                                    </span>
                                                    <span className="a-mediumText -value">
                                                        {formatValue
                                                            ? formatValue(
                                                                  getValueWithKey(
                                                                      key,
                                                                      item
                                                                  )
                                                              )
                                                            : getValueWithKey(
                                                                  key,
                                                                  item
                                                              )}
                                                    </span>
                                                    {unit && (
                                                        <span
                                                            key={index}
                                                            className="a-mediumText -unit"
                                                        >
                                                            {unit}
                                                        </span>
                                                    )}
                                                    {unitFunction && (
                                                        <span className="a-mediumText -unitFunction">
                                                            {item[
                                                                elementKey[0]
                                                            ] && '('}
                                                            {elementKey.map(
                                                                (el, index) => (
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {index >
                                                                            0 &&
                                                                            item[
                                                                                el
                                                                            ] &&
                                                                            item[
                                                                                el
                                                                            ] !==
                                                                                '' &&
                                                                            ', '}
                                                                        {unitFunction(
                                                                            item[
                                                                                el
                                                                            ],
                                                                            el
                                                                        )}
                                                                    </span>
                                                                )
                                                            )}
                                                            {item[
                                                                elementKey[0]
                                                            ] && ')'}
                                                        </span>
                                                    )}
                                                </span>
                                            ) : (
                                                <span
                                                    key={key}
                                                    className="a-mediumText"
                                                >
                                                    {formatValue
                                                        ? formatValue(
                                                              getValueWithKey(
                                                                  key,
                                                                  item
                                                              )
                                                          )
                                                        : getValueWithKey(
                                                              key,
                                                              item
                                                          )}
                                                    {label && t(label)}
                                                </span>
                                            ))
                                    )}
                                </div>
                            )}
                            <div className="-values">
                                <div className="-wrap">
                                    {displayFromToValues?.length !== 0 && (
                                        <div>
                                            {displayFromToValues.map(
                                                (
                                                    {
                                                        key,
                                                        label,
                                                        from,
                                                        formatFromValue,
                                                        formatToValue,
                                                        to,
                                                        unit,
                                                        translate,
                                                    },
                                                    index
                                                ) =>
                                                    (item[from] ||
                                                        item[to]) && (
                                                        <div
                                                            key={index}
                                                            className={`-valueWrapper${
                                                                (item[from] ||
                                                                    item[to]) &&
                                                                'WithLine'
                                                            }`}
                                                        >
                                                            {label &&
                                                                (item[from] ||
                                                                    item[
                                                                        to
                                                                    ]) && (
                                                                    <span className="-placeholder a-captionsTextRegular a-lightText -opacity-60">
                                                                        {t(
                                                                            label
                                                                        )}
                                                                        :
                                                                    </span>
                                                                )}
                                                            {item[from] &&
                                                                !item[to] && (
                                                                    <span className="a-mediumText -from">
                                                                        from{' '}
                                                                    </span>
                                                                )}
                                                            <span className="a-mediumText">
                                                                {item[from]
                                                                    ? formatFromValue(
                                                                          item[
                                                                              from
                                                                          ]
                                                                      )
                                                                    : '0-'}
                                                            </span>
                                                            {item[from] &&
                                                                item[to] && (
                                                                    <span className="a-mediumText">
                                                                        -
                                                                    </span>
                                                                )}
                                                            {item[to] && (
                                                                <span className="a-mediumText">
                                                                    {formatToValue(
                                                                        item[to]
                                                                    )}
                                                                </span>
                                                            )}
                                                            {(item[from] ||
                                                                item[to]) &&
                                                                unit && (
                                                                    <span className="-placeholder a-captionsTextRegular a-lightText -opacity-60">
                                                                        {translate
                                                                            ? t(
                                                                                  unit
                                                                              )
                                                                            : unit}
                                                                    </span>
                                                                )}
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    )}

                                    {displayValues.length !== 0 && (
                                        <Fragment>
                                            {displayValues.map(
                                                (
                                                    {
                                                        key,
                                                        label,
                                                        unit,
                                                        formatValue,
                                                        color,
                                                        hyphen,
                                                    },
                                                    index
                                                ) => (
                                                    <Fragment key={key}>
                                                        {(item[key] ||
                                                            item[key] === 0) &&
                                                            item[key].length !==
                                                                0 && (
                                                                <span
                                                                    className={`${
                                                                        hyphen
                                                                            ? '-skipValueWrapper'
                                                                            : '-valueWrapper'
                                                                    } a-mediumText`}
                                                                >
                                                                    {label && (
                                                                        <span className="-placeholder a-captionsTextRegular a-lightText -opacity-60">
                                                                            {t(
                                                                                label
                                                                            )}
                                                                            :{' '}
                                                                        </span>
                                                                    )}
                                                                    <span
                                                                        className={`-value -${
                                                                            color ||
                                                                            ''
                                                                        } ${
                                                                            color
                                                                                ? '-withColor'
                                                                                : '-withoutColor'
                                                                        }`}
                                                                    >
                                                                        {formatValue
                                                                            ? formatValue(
                                                                                  item[
                                                                                      key
                                                                                  ]
                                                                              )
                                                                            : getValueWithKey(
                                                                                  key,
                                                                                  item
                                                                              )}
                                                                    </span>
                                                                    {hyphen && (
                                                                        <span className="-placeholder a-captionsTextRegular a-lightText -opacity-60">
                                                                            -
                                                                        </span>
                                                                    )}
                                                                    {unit && (
                                                                        <span className="-placeholder a-captionsTextRegular a-lightText -opacity-60">
                                                                            {t(
                                                                                unit
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            )}
                                                    </Fragment>
                                                )
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            {canDelete && (
                                <div className="-button">
                                    <Button
                                        onClick={() => handleDelete(index)}
                                        icon={ICONS.DELETE}
                                        iconColor={COLORS.RED}
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        iconSize={ICON_SIZE.SIZE20}
                                        buttonSize="20"
                                        type={BUTTON_TYPE.BUTTON}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

InfoList.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    setData: PropTypes.func.isRequired,
    displayAttributes: PropTypes.array,
    displayValues: PropTypes.array,
    displayFromToValues: PropTypes.array,
    confirmMessage: PropTypes.string,
    separator: PropTypes.bool,
    confirmLabel: PropTypes.string,
    deleteTitle: PropTypes.string,
    labelFirst: PropTypes.bool,
    translateLabel: PropTypes.bool,
    canDelete: PropTypes.bool,
    threeColumns: PropTypes.bool,
}

InfoList.defaultProps = {
    data: [],
    displayAttributes: [],
    displayValues: [],
    displayFromToValues: [],
    separator: false,
    labelFirst: false,
    translateLabel: true,
    canDelete: true,
    threeColumns: false,
}

export default InfoList
