import React from 'react'
import { useField } from 'formik'

import CheckboxGroup, {
    CheckboxGroupMainPropTypes,
} from 'components/CheckboxGroup'

const CheckboxGroupField = (props) => {
    const [field, meta, helpers] = useField(props)

    const { setValue } = helpers

    const hasError = meta.touched && meta.error

    return (
        <CheckboxGroup
            {...field}
            {...props}
            setValue={setValue}
            error={hasError}
        />
    )
}

CheckboxGroupField.propTypes = {
    ...CheckboxGroupMainPropTypes,
}

export default CheckboxGroupField
