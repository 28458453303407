import React from 'react'

const ReleasePage = ({releaseObject}) => {
    return (
        <div style={{ textAlign: 'justify' }}>
            <div className="-mb20">
                <span className="a-bodyTextMedium">
                    {releaseObject.title}
                </span>
            </div>

            <div className="-mb20">
                <span className="a-mediumText">
                    {releaseObject.introText}
                </span>
            </div>

            <div className="-mb40">
            <ul>
                    {releaseObject.items.map(
                        (item, index) => {
                            return (
                                <li
                                    key={index}
                                    className="a-mediumText a-lightText -liDisc -mb5"
                                >
                                    <strong>{item.title}</strong> {item.description}
                                </li>
                            )
                        },
                    )}
                </ul>
            </div>

            <div className="-mb20">
                <span className="a-bodyTextMedium">
                    {releaseObject.upcomingSectionTitle}
                </span>
            </div>

            <div className="-mb40">
                <ul>
                    {releaseObject.upcomingItems.map(
                        (item, index) => {
                            return (
                                <li
                                    key={index}
                                    className="a-mediumText a-lightText -liDisc -mb5"
                                >
                                    <strong>{item.title}</strong> {item.description}
                                </li>
                            )
                        },
                    )}
                </ul>
            </div>

            <div>
                <span className="a-mediumText">
                    {releaseObject.outroText}
                </span>
            </div>

        </div>
    )
}

export default ReleasePage
