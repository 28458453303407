import { Fragment, useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import UserCard from 'components/UserCard'
import IconButton from 'components/IconButton'
import { useNavigate } from 'react-router-dom'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { useRemoveFromList } from 'hooks/useRemoveUser'

const FavoriteUsersList = ({
    favoriteDivers,
    isLoadingFavorites,
    loadMore,
    setDivers,
    fetchFavorites,
    divers,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const removeUser = useRemoveFromList()

    const { currentUser } = useContext(CurrentUserContext)

    const contractorId = currentUser.companies && currentUser.companies[0].id

    const handleViewFavoriteUsers = () => {
        navigate(
            `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${contractorId}?tab=favoriteUsers`
        )
    }

    return (
        <Fragment>
            <h3 className="-mb20"> {t('general.yourFavoriteUsers')}</h3>

            {favoriteDivers.map((item, index) => {
                return (
                    <div key={index}>
                        <UserCard
                            withAvatar
                            avatar={item.avatarPath}
                            title={item.fullName}
                            cardContainerLink={`${ROUTES.DIVER_PROFILE}/${item?.profileHash}`}
                            verifiedName={item.identityVerified}
                            placeholderIcon={ICONS.USER}
                            withIcon
                        >
                            {item.countryName && (
                                <span className="-value a-captionsTextRegular a-lightText -opacity-60">
                                    {item.countryName}
                                </span>
                            )}
                            {item.countryName && item.role && (
                                <span className="-separator a-captionsTextRegular a-lightText -opacity-60">
                                    &#x2022;
                                </span>
                            )}
                            {item.role && (
                                <span className="-value a-captionsTextRegular a-lightText -opacity-60">
                                    {item.role}
                                </span>
                            )}
                            <IconButton
                                icon={ICONS.FAVORITES}
                                iconColor={
                                    item.favoriteUser
                                        ? COLORS.SECONDARY
                                        : COLORS.WHITE
                                }
                                onClick={() =>
                                    removeUser(
                                        item,
                                        divers,
                                        setDivers,
                                        fetchFavorites
                                    )
                                }
                            />
                        </UserCard>
                    </div>
                )
            })}

            {favoriteDivers.length === 0 && !isLoadingFavorites && (
                <span className="a-captionsTextRegular a-lightText justify-center a-centeredText">
                    {t('general.noFavoriteUsersSidebar')}
                </span>
            )}
            {loadMore && (
                <div className="justify-center -mt20">
                    <Button
                        label="button.viewMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        icon={ICONS.ARROW_RIGHT}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => {
                            handleViewFavoriteUsers()
                        }}
                    />
                </div>
            )}
        </Fragment>
    )
}

export default FavoriteUsersList
