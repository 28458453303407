import { useState, useEffect } from 'react'

import { SCREEN_WIDTH } from 'constants/enums'

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const isSmallerThanXXlScreen =
        windowDimensions.width < SCREEN_WIDTH.EXTRA_EXTRA_LARGE
    const isSmallerThanXlScreen =
        windowDimensions.width < SCREEN_WIDTH.EXTRA_LARGE
    const isSmallScreen = windowDimensions.width < SCREEN_WIDTH.LARGE
    const isTablet = windowDimensions.width < SCREEN_WIDTH.MEDIUM
    const isMobile = windowDimensions.width < SCREEN_WIDTH.SMALL

    return {
        ...windowDimensions,
        isSmallerThanXXlScreen,
        isSmallerThanXlScreen,
        isSmallScreen,
        isTablet,
        isMobile,
    }
}

export default useWindowDimensions
