import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'
import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { deleteEntityService, editEntityService } from 'services/entity.service'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    PROJECT_WORKER_STATUS,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'

import SearchAndFilter from 'components/SearchAndFilter'
import DiveTeamFilters from './DiveTeamFilters'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import Button from 'components/Button'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'
import useFetchData from 'hooks/useFetchData'
import DiveTeamStatusUpdate from './DiveTeamStatusUpdate'
import RejectionModal from 'components/rejectionModal/RejectionModal'

const filters = {}

const DiveTeamList = ({
    projectDiveTeam,
    activeFilters,
    refetchData,
    defaultProjectPosition,
}) => {
    const t = useTranslate()

    const { data, isLoading, meta, loadMore, fetchData } = projectDiveTeam
    const { status, ...searchFilters } = activeFilters
    const projectPosition =
        activeFilters.projectPosition || defaultProjectPosition
    const [rejectModalOpen, setRejectModalOpen] = useState(false)
    const [rejectingItem, setRejectingItem] = useState(null)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { setAlert } = useContext(AlertContext)

    const {
        data: diveInviteStatuses,
    } = useFetchData(ENTITIES.DIVER_INVITE_STATUS, { pagination: false })

    const formatWorkerLanguages = (workerLanguages) =>
        workerLanguages
            ?.map(
                ({ language, languageProficiency }) =>
                    `${
                        language.data.attributes.name
                    } (${languageProficiency.data.attributes.name.toLowerCase()})`
            )
            .join(', ')

    const formatExperiences = (experiences) => {
        if (!experiences || !experiences.length) {
            return 'N\\A'
        }

        return experiences
            .filter(
                ({ divingModeName }) => divingModeName && divingModeName !== '/'
            )
            .map(
                ({ dives, days, divingModeName }) =>
                    `${divingModeName} ${
                        dives && dives !== '/'
                            ? dives + ' ' + t('general.dives')
                            : ''
                    } ${
                        days && days !== '/'
                            ? days + ' ' + t('general.days')
                            : ''
                    }`
            )
    }

    const closeConfirmationModalAndRefreshPositions = () => {
        closeConfirmationModal()
        refetchData()
    }

    const handleConfirmationModal = (message) => {
        showConfirmationModal({
            message: `${t(message)}`,
            confirmLabel: 'button.ok',
            handleConfirm: () => closeConfirmationModalAndRefreshPositions(),
            handleCancel: () => closeConfirmationModalAndRefreshPositions(),
            translateMessage: false,
            hideCancel: true,
        })
    }

    const handleEmploy = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                personEmployed: true,
            },
            'general.successfullyEmployed',
            'form.error.canNotAcceptApplication'
        )
    }

    const handleAcceptApplication = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                inviteAccepted: true,
            },
            'general.successfullyAccepted',
            'form.error.canNotAcceptApplication'
        )
    }

    const handleRejectApplication = async (item) => {
        setRejectingItem(item)
        setRejectModalOpen(true)
    }

    const handleInviteAgain = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                inviteRejected: false,
            },
            'general.successfullyInvitedAgain',
            'form.error.canNotInviteAgain'
        )
    }

    const handleRemoveDiver = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                inviteRejected: true,
            },
            'general.successfullyRemoved',
            'form.error.canNotRemoveFromPosition'
        )
    }

    const handleApplicationChange = async (
        itemId,
        formData,
        successMessage,
        errorMessage
    ) => {
        try {
            await editEntityService(ENTITIES.INVITE, itemId, formData, true, [
                'positionRejections',
            ])
            setRejectModalOpen(false)
            handleConfirmationModal(successMessage)
        } catch (error) {
            if (error.response.data.message === 'Not Found') {
                setAlert(t(errorMessage), ALERT_TYPES.ERROR)
            } else {
                setAlert(error, ALERT_TYPES.ERROR)
            }
            refetchData()
        }
    }

    const handleCancelInvitation = async (item) => {
        await deleteEntityService(ENTITIES.INVITE, item.id)
        handleConfirmationModal('general.successfullyCanceled')
    }

    const getItemStatuses = (item) => {
        if (item.diverInviteStatus) {
            return [
                {
                    type: 'status',
                    name: [[item.diverInviteStatus.name]],
                    color: 'green',
                },
            ]
        }
        const statuses = []

        if (!item?.workerName) {
            statuses.push({
                type: 'status',
                name: [[t('general.unregisteredDiver')]],
                color: 'gray',
            })
        }

        if (item.systemStatus === 1) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusNewApplicant')]],
                color: 'purple',
            })
        }

        if (item.systemStatus === 2) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusNewInvite')]],
                color: 'purple',
            })
        }

        if (item.systemStatus === 3) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusReapplied')]],
                color: 'orange',
            })
        }

        if (item.systemStatus === 4) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusRejected')]],
                color: 'red',
            })
        }

        if (item.systemStatus === 5) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusAccepted')]],
                color: 'green',
            })
        }

        if (item.systemStatus === 6) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusEmployed')]],
                color: 'green',
            })
        }

        return statuses
    }

    const isApplicant = (item) => {
        return item.systemStatus === 5
    }

    const updateInviteStatus = async (item, status) => {
        try {
            await editEntityService(ENTITIES.INVITE, item.id, {
                status,
            })
            setAlert(t('general.statusUpdated'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(t('general.unableToUpdateStatus'), ALERT_TYPES.ERROR)
        }
    }

    const getItemActions = (item) => {
        if (!diveInviteStatuses || !diveInviteStatuses.length) {
            return []
        }

        return diveInviteStatuses.map((status) => {
            return {
                handleAction: () => {
                    updateInviteStatus(item, status).then((r) => {
                        refetchData()
                    })
                },
                icon: ICONS.EDIT,
                iconColor: COLORS.PRIMARY,
                tooltip: status.name,
            }
        })
    }

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    if (!data) return null

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchForUser"
                activeFilters={searchFilters}
                filters={filters}
                showDefaultKey={false}
                hiddenFilterKeys={['diverPosition', 'projectPosition']}
            >
                <DiveTeamFilters activeFilters={searchFilters} />
                {parseInt(activeFilters?.status) ===
                    PROJECT_WORKER_STATUS.CANDIDATES && (
                    <DiveTeamStatusUpdate
                        projectPositionId={parseInt(projectPosition)}
                        updateListFunc={() => {
                            refetchData()
                        }}
                        disabled={!data || !data.length}
                        diveInviteStatuses={diveInviteStatuses}
                    />
                )}
            </SearchAndFilter>

            {data.map((item, index) => (
                <div key={index} className="-mb10">
                    <CardContainer
                        withAvatar
                        avatar={item.avatarPath}
                        title={item.workerName || item.workerEmail}
                        subtitle={item.positionTitle}
                        item={item}
                        link={
                            item?.workerId
                                ? `${ROUTES.DIVER_PROFILE}/${item?.profileHash}`
                                : ''
                        }
                        identityVerified={!!item.identityVerified}
                        statuses={getItemStatuses(item)}
                        actions={
                            isApplicant(item) ? getItemActions(item) : null
                        }
                        statusActionsCombined={isApplicant(item)}
                    >
                        {item.positionDescription && (
                            <Info
                                label="form.label.description"
                                value={item.positionDescription}
                            />
                        )}
                        {item.countryName && (
                            <Info
                                label="form.label.country"
                                value={item.countryName}
                            />
                        )}
                        {item.workerLanguages && (
                            <Info
                                label="form.label.languages"
                                value={formatWorkerLanguages(
                                    item.workerLanguages
                                )}
                            />
                        )}
                        {item.experiences.length !== 0 && (
                            <Info
                                label="form.label.experiencedInModes"
                                value={formatExperiences(item.experiences)}
                                color="orange"
                            />
                        )}
                        {item?.workerName && (
                            <Info
                                label="form.label.totalYearsOfExperience"
                                value={item.totalExperienceYears || '0'}
                                color="gray"
                            />
                        )}

                        {(item.canBeAccepted ||
                            item.canBeEmployed ||
                            item.canBeRejected ||
                            item.canInviteAgain) && (
                            <div className="-infoRowButtons -twoButtons">
                                {item.canBeRejected && (
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        label="button.rejectApplication"
                                        onClick={() =>
                                            handleRejectApplication(item)
                                        }
                                    />
                                )}
                                {!item.canBeRejected && <div></div>}

                                {item.canInviteAgain && (
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        label="button.inviteAgain"
                                        onClick={() => handleInviteAgain(item)}
                                    />
                                )}

                                {item.canBeAccepted && (
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        label="button.acceptApplication"
                                        onClick={() =>
                                            handleAcceptApplication(item)
                                        }
                                    />
                                )}

                                {item.canBeEmployed && (
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        label="button.employApplication"
                                        onClick={() => handleEmploy(item)}
                                    />
                                )}
                            </div>
                        )}

                        {item.canBeCanceled && (
                            <div className="-infoRowButtons">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    buttonSize={BUTTON_SIZE.SMALL}
                                    label="button.cancelInvitation"
                                    onClick={() => handleCancelInvitation(item)}
                                />
                            </div>
                        )}

                        {item.canBeRemoved && (
                            <div className="-infoRowButtons">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    buttonSize={BUTTON_SIZE.SMALL}
                                    label="button.removeFromPosition"
                                    onClick={() => handleRemoveDiver(item)}
                                />
                            </div>
                        )}
                    </CardContainer>
                </div>
            ))}
            {data.length === 0 && (
                <span className="a-captionsTextRegular a-lightText justify-center">
                    {t('general.noUsersForStatus')}
                </span>
            )}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {rejectModalOpen && (
                <RejectionModal
                    diverInvite={rejectingItem}
                    setOpen={setRejectModalOpen}
                    handleReject={handleApplicationChange}
                />
            )}
        </div>
    )
}

DiveTeamList.propTypes = {
    projectDiveTeam: PropTypes.object,
    activeFilters: PropTypes.object,
    refetchData: PropTypes.func,
}

export default DiveTeamList
