import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import CreateDiveRecordModalForm from 'screens/diver/diveRecord/components/CreateDiveRecordModalForm'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'

const CreateDiveRecordModal = ({
    route,
    positionOnCurrentProject,
    profile,
    title,
}) => {
    const [openCreateDiveRecordModal, setOpenCreateDiveRecordModal] =
        useState(false)

    const handleCreateDiveRecord = () => {
        setOpenCreateDiveRecordModal(true)
    }

    return (
        <div>
            {profile ? (
                <AddNew
                    label="button.addDiveRecord"
                    handleAction={handleCreateDiveRecord}
                />
            ) : (
                <Button
                    label={`${title ? title : 'button.createDiveRecord'}`}
                    onClick={handleCreateDiveRecord}
                />
            )}

            {openCreateDiveRecordModal && (
                <CreateDiveRecordModalForm
                    setOpenCreateDiveRecordModal={setOpenCreateDiveRecordModal}
                    route={route}
                    positionOnCurrentProject={positionOnCurrentProject}
                    profile={profile}
                />
            )}
        </div>
    )
}

CreateDiveRecordModal.propTypes = {
    route: PropTypes.string,
    isSupervisorOnCurrentProject: PropTypes.bool,
    profile: PropTypes.bool,
    title: PropTypes.string,
}

CreateDiveRecordModal.defaultProps = {
    profile: false,
}

export default CreateDiveRecordModal
