import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Formik } from 'formik'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ProfileContext } from './context/ProfileContext'
import { AlertContext } from 'contexts/AlertContext'

import { editEntityService } from 'services/entity.service'

import useQueryParams from 'hooks/useQueryParams'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { BASE_FILE_URL } from 'utils/axiosClient'
import { TAB } from './diverInformation/constants/tabs'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'

import useWindowDimensions from 'hooks/useWindowDimension'
import useDataAccessControl from './hooks/useDataAccessControl'

import { DiverInformation } from './diverInformation'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { ProjectList } from 'components/projects'
import DiveRecordList from 'screens/common/diveRecordList/DiveRecordList'
import MedicalInfoOnboarding from '../DiverOnboarding/MedicalInfoOnboarding'
import { PastEmploymentOnboarding } from '../DiverOnboarding/PastEmploymentOnboarding'
import { PersonalEquipmentOnboarding } from '../DiverOnboarding/PersonalEquipmentOnboarding'
import { SkillsOnboarding } from '../DiverOnboarding/SkillsOnboarding'
import { TravelDocumentsOnboarding } from '../DiverOnboarding/TravelDocumentsOnboarding'
import RequestAccess from './RequestAccess'
import PersonalInfoCard from './diverInformation/components/PersonalInfoCard'
import ProfileCompletion from './diverInformation/components/ProfileCompletion'
import TrainingRecord from './diverInformation/components/TrainingRecord'
import PersonalInfoModal from './diverInformation/modals/PersonalInfoModal'
import CoverImageUploadFields from 'components/formFields/CoverImageUploadFields'
import ExportProfileAsCv from './diverInformation/components/ExportProfileAsCv'

const TAB_COMPONENTS = {
    [TAB.PERSONAL_INFO]: PersonalInfoCard,
    [TAB.DIVE_PROJECTS]: ProjectList,
    [TAB.DIVE_RECORDS]: DiveRecordList,
    [TAB.MEDICAL_INFO]: MedicalInfoOnboarding,
    [TAB.TRAVEL_DOCUMENTATION]: TravelDocumentsOnboarding,
    [TAB.TRAINING_RECORD]: TrainingRecord,
    [TAB.PERSONAL_EQUIPMENT]: PersonalEquipmentOnboarding,
    [TAB.PAST_EMPLOYMENT]: PastEmploymentOnboarding,
    [TAB.SKILLS]: SkillsOnboarding,
    [TAB.EXPORT_CV]: ExportProfileAsCv,
}

const Profile = () => {
    const { profileHash } = useParams()
    const { tab } = useQueryParams()
    const { isSmallScreen } = useWindowDimensions()

    const location = useLocation()

    const { currentUser, userId, fetchCurrentUser } = useContext(
        CurrentUserContext
    )
    const { profile, isLoadingProfile, fetchProfile } = useContext(
        ProfileContext
    )
    const { setAlert } = useContext(AlertContext)

    const isMyProfile = currentUser?.id === profile?.id
    const coverPhotoButtonTitle = !!currentUser?.heroImagePath
        ? 'general.editCoverPhoto'
        : 'general.addCoverPhoto'

    const [open, setOpen] = useState(location.state?.personalInfo || false)
    const [openCoverImageModal, setOpenCoverImageModal] = useState('')

    const initialValues = {
        heroImage: currentUser?.heroImage ?? null,
    }

    const handleSubmit = async (formData, { setSubmitting }, customData) => {
        try {
            setSubmitting(true)

            const data = { ...formData, heroImage: customData }
            await editEntityService(ENTITIES.USER, userId, data)
            fetchCurrentUser()

            setOpenCoverImageModal(false)
            setSubmitting(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleChildSubmit = async (customData, setSubmitting) => {
        handleSubmit(initialValues, { setSubmitting }, customData)
    }

    // NOTE: When navigated from profile page update button to the same tab you are already
    useEffect(() => {
        location.state?.personalInfo && setOpen(location.state?.personalInfo)
    }, [location.state?.personalInfo])

    const {
        hasAccess,
        hideFiles,
        canRequestAccess,
        dataAccessItem,
        dataAccessTab,
        status,
        checkAccessControl,
        requestInProgress,
    } = useDataAccessControl(profile, tab, userId)

    const renderTabContent = () => {
        // Avoiding rendering content until access is checked.
        // dataAccessTab represents the tab for which access is checked.
        if (tab !== dataAccessTab) return null
        const TabComponent = TAB_COMPONENTS[tab] || PersonalInfoCard
        return isMyProfile || hasAccess ? (
                <TabComponent
                    myProfile={isMyProfile}
                    userID={Number(profile.id)}
                    profilePage
                    refetchData={fetchProfile}
                    user={profile}
                    hideFiles={hideFiles}
                    setOpen={setOpen}
                />
        ) : (
            <RequestAccess
                dataAccessItem={dataAccessItem}
                canRequestAccess={canRequestAccess}
                status={status}
                profile={profile}
                checkAccessControl={checkAccessControl}
                requestInProgress={requestInProgress}
            />
        )
    }

    if (isLoadingProfile) return <Loader />

    if (!currentUser || !profile) return null

    const userCoverImage = () => {
        if (currentUser?.heroImagePath && isMyProfile) {
            return `url(${BASE_FILE_URL + currentUser?.heroImagePath})`
        } else if (!isMyProfile && profile?.heroImagePath) {
            return `url(${BASE_FILE_URL + profile?.heroImagePath})`
        } else {
            return ''
        }
    }

    return (
        <div>
            <div
                className="m-coverImage"
                {...{
                    style: {
                        backgroundImage: userCoverImage(),
                    },
                }}
            />
            <div className="_wr">
                <div className="_w">
                    <div className="m-boxes__top -coverImage">
                        {isMyProfile && (
                            <Button
                                label={coverPhotoButtonTitle}
                                onClick={() => setOpenCoverImageModal(true)}
                                icon={ICONS.CAMERA}
                                iconSize={ICON_SIZE.SIZE20}
                                btnClass={BUTTON_STATUS.COVER_BUTTON}
                                buttonSize={BUTTON_SIZE.COVER}
                            />
                        )}
                    </div>
                    <div className="m-boxes fullWidth">
                        {isSmallScreen && (
                            <ProfileCompletion
                                user={profile}
                                userID={Number(profile.id)}
                                myProfile={isMyProfile}
                                profileHash={profileHash}
                            />
                        )}
                        <div className="m-boxes__side -left -smallSidebar">
                            <DiverInformation
                                user={profile}
                                myProfile={isMyProfile}
                                userID={Number(profile.id)}
                                setOpen={setOpen}
                            />
                        </div>
                        <div className="m-boxes__main -fullHeight -noBackground -tabContentBox">
                            {!isSmallScreen && (
                                <ProfileCompletion
                                    user={profile}
                                    userID={Number(profile.id)}
                                    myProfile={isMyProfile}
                                    profileHash={profileHash}
                                />
                            )}
                            <div className="m-boxes__white -fullHeight -mb20">
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>
                </div>
                {open && (
                    <PersonalInfoModal
                        open={open}
                        setOpen={setOpen}
                        fetchProfileData={fetchProfile}
                    />
                )}

                {openCoverImageModal && (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ setSubmitting }) => (
                            <CoverImageUploadFields
                                name="heroImage"
                                open={openCoverImageModal}
                                setOpen={setOpenCoverImageModal}
                                title={coverPhotoButtonTitle}
                                handleChildSubmit={handleChildSubmit}
                                setSubmitting={setSubmitting}
                                image={initialValues.heroImage}
                            />
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}

export default Profile
