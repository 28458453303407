import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const verificationLevels = [
    {
        id: 0,
        icon: ICONS.LEVEL_0,
        title: 'Level 0: No Verification',
        description:
            'At this level, no verification has been performed on the dive record. Users should exercise caution as the authenticity of the dive record is not verified.',
        riskLevel: 'Critical',
        color: '-redText',
    },
    {
        id: 1,
        icon: ICONS.LEVEL_1,
        title: 'Level 1: Basic Verification by External Users',
        description:
            "Basic verification is conducted by users outside of our platform. This verification is based on the external user's prior knowledge and involvement with the diving activity or the diver.",
        process: [
            'A user sends a link to an external user.',
            'The external user signs a verification page and uploads an image of the certificate.',
        ],
        riskLevel: 'High',
        color: '-orangeText',
    },
    {
        id: 2,
        icon: ICONS.LEVEL_2,
        title: 'Level 2: Platform Member Verification',
        description:
            'Verification at this level is performed by members within our platform. Both the requesting user and the supervisor must be registered members of the platform, ensuring a more reliable verification process.',
        process: [
            'The user creates a dive record.',
            'The user sends a verification request to a designated supervisor within the platform.',
            'The supervisor gains access to the dive record.',
            'The supervisor analyzes the dive record and approves the request.',
        ],
        riskLevel: 'Medium',
        color: '-purpleText',
    },
    {
        id: 3,
        icon: ICONS.LEVEL_3,
        title: 'Level 3: Project-Based Platform Member Verification',
        description:
            'This level involves verification by platform members who are part of the same project. This additional requirement of project affiliation provides a higher degree of trust and context for the verification process.',
        process: [
            'The user creates a dive record.',
            'The user sends a verification request to a designated supervisor within the project.',
            'The supervisor gains access to the dive record.',
            'The supervisor analyzes the dive record and approves the request.',
        ],
        riskLevel: 'Low',
        color: '-blueText',
    },
    {
        id: 4,
        icon: ICONS.LEVEL_4,
        title: 'Level 4: QR Code Verification',
        description:
            'Verification at this level involves the use of QR codes, adding a layer of security. Enhanced security protocols are in place to ensure the integrity of the QR code verification process, making it more difficult for unauthorized modifications.',
        process: [
            'The user creates a dive record.',
            'The user sends a verification request to a designated supervisor within the project.',
            'A QR code is generated as part of the verification process.',
            'The supervisor gains access to the dive record.',
            'The supervisor analyzes the dive record.',
            'The supervisor uses mobile apps to scan and authenticate the QR code.',
        ],
        riskLevel: 'Minimal',
        color: '-greenText',
    },
    {
        id: 5,
        icon: ICONS.LEVEL_5,
        title: 'Level 5: NFC Verification',
        description:
            'The highest level of verification incorporates Near Field Communication (NFC) technology. This advanced method ensures a high level of security and authenticity in the verification process.',
        process: [
            'The user creates a dive record.',
            'The user sends a verification request to a designated supervisor within the project.',
            'The supervisor gains access to the dive record.',
            'The supervisor analyzes the dive record.',
            'Both users use mobile apps and NFC technology to authenticate the dive record.',
        ],
        riskLevel: 'Minimal',
        color: '-greenText',
    },
]

const DiveRecordVerificationLevels = () => {
    const t = useTranslate()

    return (
        <div>
            <div className="-mb20">
                <span className=" a-bodyTextMedium">
                    {t('general.diveRecordVerificationLevels')}
                </span>
            </div>
            {verificationLevels.map((level) => {
                return (
                    <Fragment key={level.id}>
                        <div className="-mb20">
                            <div className="aligned-center -gap10">
                                <Icon name={level.icon} />
                                <span className=" a-mediumTextSemiBold">
                                    {level.title}
                                </span>
                            </div>
                            <div className=" -mt10">
                                <span className="a-mediumText a-lightText">
                                    {level.description}
                                </span>
                            </div>
                            {level?.process && (
                                <div>
                                    <div className="-mt10">
                                        <span className="a-mediumText a-lightText">
                                            {t('general.process')}
                                        </span>
                                    </div>

                                    <div>
                                        <ul>
                                            {level.process.map(
                                                (process, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="a-mediumText a-lightText -liDisc"
                                                        >
                                                            {process}
                                                        </li>
                                                    )
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="-mt10">
                                <span className="a-mediumText a-lightText">
                                    {t('general.riskLevel')}{' '}
                                </span>
                                <span
                                    className={`a-mediumTextSemiBold ${level.color}`}
                                >
                                    {level.riskLevel}
                                </span>
                            </div>
                        </div>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default DiveRecordVerificationLevels
