import React, { useState, useEffect, useContext, useMemo } from 'react'

import { editEntityService } from 'services/entity.service'
import { getUserLanguage, setUserLanguage } from 'services/localStorage.service'

import { CurrentUserContext } from './CurrentUserContext'

import ENTITY from 'constants/entities'
import { DEFAULT_LANGUAGE } from 'constants/enums'

export const TranslationContext = React.createContext()

const TranslationContextProvider = (props) => {
    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)

    const [translations, setTranslations] = useState({
        locale: DEFAULT_LANGUAGE,
        messages: require(`../translations/${DEFAULT_LANGUAGE}.json`),
    })

    useEffect(() => {
        const currentLanguage =
            currentUser?.language || getUserLanguage() || DEFAULT_LANGUAGE

        setTranslations({
            locale: currentLanguage,
            messages: require(`../translations/${currentLanguage}.json`),
        })
    }, [currentUser])

    const handleSetTranslations = async (language) => {
        try {
            if (currentUser) {
                await editEntityService(ENTITY.USER, currentUser.id, {
                    language: { id: language.id, type: language.entityType },
                })
                fetchCurrentUser()
            } else {
                setTranslations({
                    locale: language.code,
                    messages: require(`../translations/${language.code}.json`),
                })
                setUserLanguage(language.code)
            }
        } catch (error) {
            throw error
        }
    }

    const contextValue = useMemo(
        () => ({
            translations,
            setTranslations: handleSetTranslations,
        }),
        [translations]
    )

    const { locale, messages } = translations
    if (!locale || !messages) return null

    return (
        <TranslationContext.Provider value={contextValue}>
            {props.children}
        </TranslationContext.Provider>
    )
}

export default TranslationContextProvider
