import React from 'react'

const CertifiedNdtInspectionBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>Certified NDT Inspection Diver</title>
            <circle cx="25" cy="25" r="23" fill="#4A4FFF" />
            <g filter="url(#filter0_i_21877_21092)">
                <circle cx="25" cy="24.9999" r="19.4615" fill="#F3F4F5" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.5929 28.1582C32.0283 27.1671 32.27 26.0716 32.27 24.9197C32.27 20.4692 28.6622 16.8615 24.2118 16.8615C19.7614 16.8615 16.1536 20.4692 16.1536 24.9197C16.1536 29.3701 19.7614 32.9779 24.2118 32.9779C26.6181 32.9779 28.778 31.9232 30.2545 30.2509L32.7157 31.62L32.4033 32.1816L36.3934 34.3886C37.2906 34.8848 38.4201 34.5613 38.9185 33.6653C39.4187 32.7663 39.0938 31.632 38.1935 31.134L34.2113 28.9315L33.9211 29.4533L31.5929 28.1582Z"
                fill="#4A4FFF"
            />
            <circle cx="24.2122" cy="24.9198" r="6.19865" fill="#D1D2D8" />
            <defs>
                <filter
                    id="filter0_i_21877_21092"
                    x="5.53845"
                    y="5.53833"
                    width="39.2769"
                    height="39.2769"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.176923" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21092"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default CertifiedNdtInspectionBadge
