import React from 'react'

const OilGasIndustryBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>Oil & Gas Diving</title>
            <circle cx="25" cy="25" r="23" fill="#FFC634" />
            <g filter="url(#filter0_i_21877_21061)">
                <circle cx="25.0002" cy="25" r="19.4615" fill="#F3F4F5" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.6685 23.5267C26.2343 24.5502 26.5562 25.7263 26.5562 26.9783C26.5562 30.9255 23.3558 34.1255 19.4086 34.1255C15.4614 34.1255 12.2615 30.9255 12.2615 26.9783C12.2615 25.7232 12.5856 24.5433 13.1533 23.5183L19.409 12.6154L25.6685 23.5267Z"
                fill="#FFE39C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.6682 23.5267C26.234 24.5501 26.5558 25.7262 26.5558 26.9783C26.5558 30.9251 23.3559 34.1254 19.4087 34.1254V12.6165L25.6682 23.5267Z"
                fill="#FFC634"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0316 23.6963C12.9192 26.7388 13.6892 31.7355 18.4841 32.17C15.5125 30.2891 14.5555 27.4723 15.0316 23.6963Z"
                fill="#FFF5CF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.4883 37.3785C23.1306 37.2481 18.9957 32.1643 22.2338 26.3208C23.6833 23.7055 25.5225 24.0658 24.8687 21.4603C27.1529 23.187 27.1986 25.0409 26.2236 27.3202C28.2747 24.5387 25.8192 22.8577 27.1383 19.3929C27.6372 18.082 29.3524 16.8054 31.3611 15.8744C30.8198 17.7478 30.8753 18.7081 31.1851 19.4598C32.659 23.0386 34.5992 25.0474 33.484 29.6518C35.271 27.3121 33.1009 25.6702 35.284 23.3305C34.7868 25.486 36.621 27.1572 36.846 29.9665C37.3156 35.8166 33.4123 37.4992 28.4883 37.3785Z"
                fill="#FFE39C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.3194 28.3899C24.0862 31.1225 25.6352 30.4753 26.8808 32.3372C26.8988 28.8089 29.7374 28.2611 30.2624 24.6512C31.4331 27.3578 32.0592 28.7943 31.0402 33.3873C33.7679 32.5786 34.906 31.1226 35.4718 28.7046C35.9364 34.4813 33.4663 37.033 28.6075 36.9955C23.9623 36.9596 22.0204 32.4872 24.3194 28.3899Z"
                fill="#FFC634"
            />
            <defs>
                <filter
                    id="filter0_i_21877_21061"
                    x="5.5387"
                    y="5.53845"
                    width="39.271"
                    height="39.271"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.347927" dy="0.347927" />
                    <feGaussianBlur stdDeviation="0.173963" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21061"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default OilGasIndustryBadge
