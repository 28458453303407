const getValueWithKey = (key, item) => {
    let retVal = null
    if (key.includes('.')) {
        const splitedKeyValues = key.split('.')
        splitedKeyValues.forEach((keyValue) => {
            if (!retVal) {
                retVal = item[keyValue]
            } else {
                retVal = retVal[keyValue]
            }
        })
    } else {
        retVal = item[key]
    }
    return retVal
}

const getProjectStatusColor = (status) => {
    switch (status) {
        case 'Finished':
            return 'green'
        case 'In progress':
            return 'orange'
        case 'Upcoming - Hiring':
            return 'purple'
        case 'In preparation':
            return 'gray'
        default:
            return 'gray'
    }
}

export { getValueWithKey, getProjectStatusColor }
