import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { Fragment, useRef } from 'react'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Icon from 'components/Icon'
import InfoDropdown from 'components/InfoDropdown'

const ColumnTabs = ({
    tabs,
    getPath,
    activeTab,
    translate,
    pathname,
    scrollToTarget,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { isSmallScreen } = useWindowDimensions()

    const activeTabRef = useRef(null)

    const handleActiveTabVisibility = () => {
        const activeTabComponent = document.querySelectorAll('.-tabContentBox')

        const targetScrollPosition =
            activeTabComponent[0]?.offsetTop + (isSmallScreen ? 40 : 0)

        scrollToTarget &&
            window.scrollTo({
                top: targetScrollPosition,
                behavior: 'smooth',
            })
    }

    const handleClick = (e, to) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(to)
    }

    const getIsActive = (path) => {
        if (pathname === ROUTES.HOME) {
            return path === ROUTES.HOME
        }
        if (pathname === ROUTES.SETTINGS)
            return path === ROUTES.UNITS_OF_MEASUREMENT
        return pathname.includes(path)
    }

    const renderIsActiveLink = (to, key) => {
        return to ? getIsActive(to) : key === activeTab.key
    }

    return (
        <div className="m-columnTabs fullWidth">
            <div className="-wrapper column">
                {tabs
                    .filter((tab) => !tab.hidden)
                    .map(
                        ({
                            key,
                            title,
                            queryParams,
                            badge,
                            icon,
                            iconColor,
                            subItems,
                            subItemsTitle,
                            to,
                        }) => (
                            <Fragment key={key}>
                                <Link
                                    key={key}
                                    className={`${
                                        renderIsActiveLink(to, key)
                                            ? '-active'
                                            : ''
                                    } m-columnTabs__tab ${
                                        subItems ? '-withSubItems' : ''
                                    }`}
                                    to={to ? to : getPath(key, queryParams)}
                                    ref={
                                        key === activeTab.key
                                            ? activeTabRef
                                            : null
                                    }
                                    onClick={handleActiveTabVisibility}
                                >
                                    {subItems ? (
                                        <InfoDropdown
                                            columnTabs
                                            title={subItemsTitle}
                                            icon={icon}
                                        >
                                            {subItems.map(
                                                ({
                                                    key,
                                                    label,
                                                    to,
                                                    translate,
                                                }) => (
                                                    <div
                                                        key={key}
                                                        className={`m-columnTabs__tab a-mediumText ${
                                                            getIsActive(to)
                                                                ? ' -active'
                                                                : ''
                                                        }`}
                                                        onClick={(e) =>
                                                            handleClick(e, to)
                                                        }
                                                    >
                                                        {translate
                                                            ? t(label)
                                                            : label}
                                                    </div>
                                                )
                                            )}
                                        </InfoDropdown>
                                    ) : (
                                        <Fragment>
                                            <div className="aligned-center -tabName a-mediumText">
                                                {icon && (
                                                    <Icon
                                                        name={icon}
                                                        color={
                                                            iconColor
                                                                ? iconColor
                                                                : COLORS.SECONDARY
                                                        }
                                                        size={ICON_SIZE.SIZE20}
                                                    />
                                                )}
                                                {translate ? t(title) : title}
                                                {badge && (
                                                    <span className="-incomplete aligned-center"></span>
                                                )}
                                            </div>
                                            <Icon name={ICONS.CHEVRON_RIGHT} />
                                        </Fragment>
                                    )}
                                </Link>
                            </Fragment>
                        )
                    )}
            </div>
        </div>
    )
}

ColumnTabs.propTypes = {
    tabs: PropTypes.array,
    getPath: PropTypes.func,
    activeTab: PropTypes.object,
    translate: PropTypes.bool,
}

export default ColumnTabs
