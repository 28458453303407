import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

import { NotificationsDropdown } from 'components/notificationsDropdown'
import AvatarDropdown from 'components/AvatarDropdown'
import Logo from 'components/Logo'

const Header = ({ openSidebar, setOpenSidebar, hamBtnRef }) => {
    return (
        <header className="m-header -topLevel">
            <div className="m-header__leftPart">
                <Logo
                    className="m-header__logo"
                    color1={COLORS.PRIMARY}
                    color2={COLORS.SECONDARY}
                    color3={COLORS.SECONDARY}
                />
                {/* <Search /> */}
            </div>
            <div className="m-header__main">
                <NotificationsDropdown />
                <AvatarDropdown />
                <div
                    className="m-toggler"
                    ref={hamBtnRef}
                    onClick={() => setOpenSidebar(!openSidebar)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    openSidebar: PropTypes.bool.isRequired,
    setOpenSidebar: PropTypes.func.isRequired,
    hamBtnRef: PropTypes.object,
}

export default Header
