import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

const About = ({ user }) => {
    const t = useTranslate()

    const { languages, regions } = user

    return (
        <Fragment>
            {languages && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.languages')}
                    </span>
                    <div className="listOfStatuses">
                        {languages.map((language, index) => (
                            <span
                                className="a-status -purple a-captionsTextRegular"
                                key={index}
                            >
                                {language.language.data.attributes.name}
                                {' - '}
                                {
                                    language.languageProficiency.data.attributes
                                        .name
                                }
                            </span>
                        ))}
                    </div>
                </div>
            )}
            {regions?.length !== 0 && (
                <div className="column">
                    <span className="a-mediumText a-lightText -opacity-60 -mt15 -mb10">
                        {t('general.preferredRegionsOfWork')}
                    </span>
                    <div className="listOfStatuses">
                        {regions.map((region, index) => (
                            <span
                                className="a-status -green a-captionsTextRegular"
                                key={index}
                            >
                                {region}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    )
}

About.propTypes = {
    user: PropTypes.object,
}

export default About
