import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import { PRIMARY_TRAINING_TYPE } from 'constants/enums'

import AddNew from '../../AddNew'
import CreateTrainingCourse from './CreateTrainingCourse'
import TrainingCourseList from './TrainingCourseList'
import FieldError from 'components/FieldError'

const TrainingCourses = ({
    data,
    isLoading,
    fetchData,
    profilePage,
    showActions,
    refetchData,
    error,
    addNewTitle= 'general.addTrainingCourse'
}) => {
    const [create, setCreate] = useState(false)

    const diveSchoolTrainings = data.filter(
        (item) =>
            item?.primaryTrainingType?.id ===
            PRIMARY_TRAINING_TYPE.DIVE_SCHOOL_TRAINING
    )

    const handleOpenModal = () => setCreate(true)

    return (
        <Fragment>
            {showActions && (
                <>
                    <AddNew
                        handleAction={handleOpenModal}
                        label={addNewTitle}
                        title={`${
                            profilePage ? '' : 'general.trainingCourses'
                        }`}
                        subtitle="general.mandatoryTrainingRecord"
                        required
                    />

                    {error && <FieldError error={error} />}
                </>
            )}

            {create && (
                <CreateTrainingCourse
                    open={create}
                    setOpen={setCreate}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    filteredData={diveSchoolTrainings}
                />
            )}

            <TrainingCourseList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                profilePage={profilePage}
                showActions={showActions}
                refetchData={refetchData}
                filteredData={diveSchoolTrainings}
            />
        </Fragment>
    )
}

TrainingCourses.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    profilePage: PropTypes.bool,
    refetchData: PropTypes.func,
    error: PropTypes.string,
}

TrainingCourses.defaultProps = {
    showActions: false,
}

export default TrainingCourses
