export const WAVE_HEIGHT_METRIC = [
    '0 - 0.5 m',
    '0.5 - 1.0 m',
    '1 - 1.5 m',
    '1.5 - 2.0 m',
    '2 - 3 m',
    '3 - 4 m',
    '4 - 5 m',
    '> 5 m',
]

export const WAVE_HEIGHT_IMPERIAL = [
    '0 - 2 ft',
    '2 - 3 ft',
    '3 - 5 ft',
    '5 - 7 ft',
    '7 - 10 ft',
    '10 - 13 ft',
    '13 - 16 ft',
    '> 16 ft',
]

export const WATER_TEMPERATURE_METRIC = [
    '0 - 4 °C',
    '5 - 10 °C',
    '11 - 15 °C',
    '16 - 20 °C',
    '21 - 25 °C',
    '26 - 30 °C',
    '> 31 °C',
]

export const WATER_TEMPERATURE_IMPERIAL = [
    '32 - 40 °F',
    '41 - 50 °F',
    '51 - 60 °F',
    '61 - 70 °F',
    '71 - 80 °F',
    '81 - 90 °F',
    '> 91 °F',
]

export const AIR_TEMPERATURE_METRIC = [
    '-29 to -17 °C',
    '-16 to -6 °C',
    '-5 to 4 °C',
    '5 to 15 °C',
    '16 to 26 °C',
    '27 to 37 °C',
    '> 37 °C',
]

export const AIR_TEMPERATURE_IMPERIAL = [
    '-20 - 0 °F',
    '1 - 20 °F',
    '21 - 40 °F',
    '41 - 60 °F',
    '61 - 80 °F',
    '81 - 100 °F',
    '> 100 °F',
]

export const WATER_VISIBILITY_METRIC = [
    'None',
    'Poor < 1 m',
    'Fair 2 - 5 m',
    'Good 5 - 10 m',
    'Great 10 - 20m',
    'Excellent >20m',
]

export const WATER_VISIBILITY_IMPERIAL = [
    'None',
    'Poor < 3 ft',
    'Fair 6 - 15 ft',
    'Good 16 - 30 ft',
    'Great 31 - 60 ft',
    'Excellent > 60 ft',
]

export const WATER_CURRENT = [
    'None 0 kts',
    'Slight 0 - .5 Kts',
    'Moderate .5 - 1.0 Kts',
    'Rapid 1.0 - 1.5 Kts',
    'Strong 1.5 - 2.0 Kts',
    'Heavy 2.0 - 3.0 Kts',
    'Difficult > 3.0 kts',
]

export const BOTTOM_CONDITION = [
    'Hard',
    'Firm',
    'Soft',
    'Sand',
    'Mud',
    'Clay',
    'Shell',
    'Coral',
    'Gravel - Rock',
    'Mixed',
]

export const SIZE_METRIC = [
    '2.7 L',
    '3.0 L',
    '5.7 L',
    '7.0 L',
    '10.5 L',
    '11.1 L',
    '12.2 L',
    '15.0 L',
    '18.0 L',
]

export const SIZE_IMPERIAL = [
    '19 ft³',
    '25 ft³',
    '40 ft³',
    '57 ft³',
    '77.5 ft³',
    '80 ft³',
    '98.8 ft³',
    '105 ft³',
    '120 ft³',
]
