import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { ROLES } from 'constants/enums'

import AdminRoutes from './AdminRoutes'
import OnboardingRoutes from './OnboardingRoutes'
import DiverRoutes from './DiverRoutes'
import DivingContractorRoutes from './DivingContractorRoutes'
import NoAuthRoutes from 'routes/NoAuthRoutes'

const { ROLE_DIVER, ROLE_SUPER_ADMIN, ROLE_DIVING_CONTRACTOR } = ROLES

const AuthRoutes = () => {
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    const { userId, currentUser } = useContext(CurrentUserContext)

    // Removing query params passed by Persona after verification
    useEffect(() => {
        if (search.includes('inquiry-id')) {
            navigate(pathname)
        }
    }, [])

    if (userId && !currentUser) return null

    const renderAllowedRoutes = () => {
        switch (currentUser?.role.name) {
            case ROLE_SUPER_ADMIN.name:
                return <AdminRoutes />
            case ROLE_DIVER.name:
                if (!currentUser.finishedSignUp) {
                    return <OnboardingRoutes />
                }
                return <DiverRoutes />
            case ROLE_DIVING_CONTRACTOR.name:
                if (!currentUser.finishedSignUp) {
                    return <OnboardingRoutes />
                }
                return <DivingContractorRoutes />
            default:
                return <NoAuthRoutes />
        }
    }

    return renderAllowedRoutes()
}

export default AuthRoutes
