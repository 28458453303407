const USER_TOKEN = 'webapp_user_token'
const USER_ID = 'webapp_user_id'
const USER_LANGUAGE = 'webapp_user_language_code'
const PROJECT_FILTERS = 'webapp_user_project_filters'

export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN)
}

export const setUserToken = (token) => {
    localStorage.setItem(USER_TOKEN, token)
}

export const deleteUserToken = () => {
    localStorage.removeItem(USER_TOKEN)
}

export const getUserId = () => {
    return Number(localStorage.getItem(USER_ID))
}

export const setUserId = (id) => {
    localStorage.setItem(USER_ID, id)
}

export const deleteUserId = () => {
    localStorage.removeItem(USER_ID)
}

export const getUserLanguage = () => {
    return localStorage.getItem(USER_LANGUAGE)
}

export const setUserLanguage = (code) => {
    localStorage.setItem(USER_LANGUAGE, code)
}

export const deleteUserLanguage = () => {
    localStorage.removeItem(USER_LANGUAGE)
}

export const getEntityFilters = (key) => {
    return localStorage.getItem(key)
}

export const setEntityFilters = (key, filters) => {
    localStorage.setItem(key, filters)
}

export const clearStorage = () => {
    localStorage.clear()
}
