import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { formatUrl } from 'utils/jsonApiFormatters'

import ENTITIES from 'constants/entities'
import useFetchData from 'hooks/useFetchData'
import CreateDiveRecordModalContext from '../contexts/CreateDiveRecordModalContext'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    POSITIONS,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ROUTES from 'constants/routes'
import { DIVING_MODES } from '../constants/divingModes'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { RadioGroupField, SelectField } from 'components/formFields'
import FocusError from '../../../../components/FocusError'

const CreateDiveRecordModalForm = ({
    setOpenCreateDiveRecordModal,
    positionOnCurrentProject,
    profile,
    projectId,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { id } = useParams()

    const formRef = useRef()

    const { data, isLoading } = useFetchData(ENTITIES.DIVING_MODE)

    const identificator = id || projectId

    const chamberRecompressionOnlyDivingMode = data.find(
        (item) => item.id === DIVING_MODES.CHAMBER_RECOMPRESSION_ONLY.id
    )

    const enabledDivingModes = (role) => {
        return data.filter(
            (mode) => mode.id !== DIVING_MODES.ATMOSPHERIC_DIVING_SUIT.id
        )
    }

    const ROLE_OPTIONS = Object.keys(POSITIONS)
        .map((key) => POSITIONS[key])
        .filter(
            (item) =>
                item.id === POSITIONS.DIVER.id ||
                item.id === POSITIONS.DIVING_SUPERVISOR.id
        )

    const handleSubmit = (formData) => {
        identificator && !profile
            ? navigate(
                  formatUrl(
                      `${ROUTES.PROJECT}/${identificator}/${ROUTES.DIVE_RECORD}/${ROUTES.GENERAL_INFORMATION}`,
                      {
                          role: findRole(),
                          divingMode: formData.divingMode.id,
                      }
                  )
              )
            : navigate(
                  formatUrl(
                      `/${ROUTES.DIVE_RECORD}/${ROUTES.GENERAL_INFORMATION}`,
                      {
                          role: formData.diveRecordType,
                          divingMode: formData.divingMode.id,
                      }
                  )
              )
    }

    const handleCancel = () => {
        setOpenCreateDiveRecordModal(false)
    }

    if (!data.length) return null
    if (isLoading) return <Loader />

    const initialValues = {
        diveRecordType: null,
        divingMode: null,
    }

    const validation = Yup.object({
        diveRecordType:
            identificator && !profile
                ? Yup.object().nullable()
                : Yup.string().required(t('form.error.required')),
        divingMode: Yup.object().required(t('form.error.required')),
    })

    const findRole = () => {
        const positionKey = Object.keys(POSITIONS).find(
            (key) => POSITIONS[key].name === positionOnCurrentProject
        )
        return POSITIONS[positionKey]?.id
    }

    return (
        <Modal
            closeOnClickOutside={false}
            type="-confirmModal -smallModal -diveRecordModal"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {({ isSubmitting, values }) => (
                    <Form className="-modalElements">
                        <FocusError />
                        <CreateDiveRecordModalContext
                            positionOnCurrentProject={findRole()}
                            chamberRecompressionOnlyDivingMode={
                                chamberRecompressionOnlyDivingMode
                            }
                        />
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <h3 className="-mb20 _12 a-centeredText -headerTitle">
                                    {identificator && !profile
                                        ? t('general.createDiveRecord')
                                        : t(
                                              'general.createHistoricalDiveRecord'
                                          )}
                                </h3>
                            </div>
                            <div className="_w">
                                {(!identificator || profile) && (
                                    <div className="_12 aligned-start -mb5">
                                        <RadioGroupField
                                            name="diveRecordType"
                                            label="form.label.creatingDiveRecordAs"
                                            defaultOptions={ROLE_OPTIONS}
                                            valueType={SELECT_VALUE_TYPE.STRING}
                                        />
                                    </div>
                                )}
                                <div className="_12">
                                    <SelectField
                                        name="divingMode"
                                        label="form.label.divingModeTechnique"
                                        placeholder="form.placeholder.divingModeTechnique"
                                        defaultOptions={enabledDivingModes(
                                            values.diveRecordType
                                        )}
                                        disabled={
                                            values?.diveRecordType ===
                                                POSITIONS.DIVING_MEDIC.id ||
                                            positionOnCurrentProject ===
                                                POSITIONS.DIVING_MEDIC.name
                                        }
                                        noneOption={false}
                                    />
                                </div>
                            </div>
                            <div className="-mt10 _w -buttons">
                                <div className="_12 _xs6 -mt10">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.cancel"
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        onClick={handleCancel}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="_12 _xs6 -mt10">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        label="button.continue"
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

CreateDiveRecordModalForm.propTypes = {
    setOpenCreateDiveRecordModal: PropTypes.func,
    route: PropTypes.string,
    isSupervisorOnCurrentProject: PropTypes.bool,
    profile: PropTypes.bool,
}

CreateDiveRecordModalForm.defaultProps = {
    profile: false,
}

export default CreateDiveRecordModalForm
