import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import useEmptyValueMessage from 'hooks/useEmptyValueMessage'
import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    BUTTON_STATUS,
    DIVE_RECORD_STATUS,
    ICON_POSITION,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { TAB } from 'screens/diver/profile/diverInformation/constants/tabs'

import Button from 'components/Button'
import SearchAndFilter from 'components/SearchAndFilter'
import DiveRecordFilters from './components/DiveRecordFilters'
import DiveRecordListCard from './components/DiveRecordListCard'
import CreateDiveRecordModal from '../CreateDiveRecordModal'

const filters = {
    project: { entityType: ENTITIES.PROJECT, displayAttribute: 'name' },
    divingMode: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    status: {
        formatter: (value) => {
            const selected = Object.values(DIVE_RECORD_STATUS).find(
                (item) => item.id === parseInt(value)
            )

            return selected ? selected.name : ''
        },
    },
    fromDate: { type: 'date' },
    toDate: { type: 'date' },
}

const DiveRecordList = ({ projectId, userID, profilePage, myProfile }) => {
    const t = useTranslate()

    const {
        data: diveRecords,
        meta,
        isLoading,
        fetchData,
        loadMore,
    } = useFetchDataByQueryParams(
        ENTITIES.DIVE_RECORD_ELASTIC,
        {
            'project.id': projectId,
            'creator.id': userID,
            isProjectPage: projectId ? 1 : 0,
            include:
                'diveRecordGeneralInformation,diveRecordGeneralInformation.divingMode,project,diveRecordGeneralInformation.supervisor,creator',
        },
        true
    )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.DIVE_RECORDS
    )

    const condition =
        diveRecords.length === 0 &&
        Object.keys(activeFilters).length === 0 &&
        !isLoading

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    if (!diveRecords) return null

    return (
        <div className="_wr profileWrapper">
            {profilePage ? (
                <div className="-mb20">
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.diveRecords')}
                        </span>
                    </div>
                    {myProfile && diveRecords.length > 0 && (
                        <div className="justify-center -mb20">
                            <CreateDiveRecordModal
                                route={`${ROUTES.DIVER_PROFILE}/${userID}?tab=${TAB.DIVE_RECORDS}`}
                                profile
                            />
                        </div>
                    )}
                </div>
            ) : (
                <h3 className="-mb20">
                    {t('general.diveRecords')} ({meta.totalItems})
                </h3>
            )}

            {!profilePage && (
                <SearchAndFilter
                    searchPlaceholder="general.searchDiveRecords"
                    activeFilters={activeFilters}
                    filters={filters}
                    showDefaultKey={false}
                >
                    <DiveRecordFilters
                        activeFilters={activeFilters}
                        projectId={projectId}
                    />
                </SearchAndFilter>
            )}

            {diveRecords.map((diveRecord) => (
                <div className="-mb20" key={diveRecord.id}>
                    <DiveRecordListCard
                        diveRecord={diveRecord}
                        fetchData={fetchData}
                    />
                </div>
            ))}

            {diveRecords.length === 0 &&
                Object.keys(activeFilters).length !== 0 &&
                !isLoading && (
                    <span className="a-captionsTextRegular a-lightText justify-center">
                        {t('general.noDiveRecordsForCriteria')}
                    </span>
                )}

            {showEmptyValueMessage && (
                <Fragment>
                    <span className="a-captionsTextRegular a-lightText justify-center">
                        {myProfile
                            ? t('general.noDiveRecordsMyProfile')
                            : t('general.noDiveRecords')}
                    </span>

                    {myProfile && (
                        <div className="justify-center">
                            <CreateDiveRecordModal
                                route={`${ROUTES.DIVER_PROFILE}/${userID}?tab=${TAB.DIVE_RECORDS}`}
                                profile
                            />
                        </div>
                    )}
                </Fragment>
            )}

            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}

            {renderLoader(isLoading, profilePage, isLoadingFilters)}
        </div>
    )
}

DiveRecordList.propTypes = {
    projectId: PropTypes.number,
    userID: PropTypes.number,
    profilePage: PropTypes.bool,
    myProfile: PropTypes.bool,
}

DiveRecordList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default DiveRecordList
