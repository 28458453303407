import { AVAILABILITY, COLORS_NAMES } from 'constants/enums'

const getProfileUserStatusColor = (status) => {
    switch (status) {
        case AVAILABILITY.AVAILABLE_FOR_HIRE:
            return COLORS_NAMES.GREEN
        case AVAILABILITY.UNAVAILABLE:
            return COLORS_NAMES.RED
        case AVAILABILITY.NO_STATUS:
            return COLORS_NAMES.GRAY
        default:
            return COLORS_NAMES.GRAY
    }
}
export { getProfileUserStatusColor }
