import { Form, Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { MOMENT_FORMATS, getIsDateSameOrBefore } from 'services/moment.service'
import useFormikFieldChange from 'hooks/useFormikFieldChange'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import REGEXP from 'constants/regex'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import {
    CheckboxField,
    DateTimeField,
    FileUploadField,
    InputField,
} from 'components/formFields'
import Modal from 'components/Modal'
import FocusError from 'components/FocusError'

const CertificateFormContext = ({ trainingSchoolName }) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { notExpire } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            if (notExpire) {
                setFieldValue('expiresDate', null)
                setFieldTouched('expiresDate', false)
            }
        } else {
            firstRender.current = false
        }
    }, [notExpire])

    const trainingSchollNameChangedHandler = (values, setFieldValue) => {
        setFieldValue('certificateIssuerName', trainingSchoolName)
    }

    useFormikFieldChange(
        ['trainingSchoolName'],
        trainingSchollNameChangedHandler
    )

    return null
}

const CertificateForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
    modal,
    setShowCertificateForm,
    setCertificates,
    trainingSchoolName,
}) => {
    const t = useTranslate()

    const { userId, currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        certificateIssuerName:
            initialData?.certificateIssuerName ?? trainingSchoolName ?? '',
        name: initialData?.name ?? '',
        issuedDate: initialData?.issuedDate ?? '',
        expiresDate: initialData?.expiresDate ?? '',
        notExpire: initialData?.notExpire ?? false,
        certificateNumber: initialData?.certificateNumber ?? '',
        certificateUrl: initialData?.certificateUrl ?? '',
        files: initialData?.files ?? [],
        user: currentUser ?? null,
    }

    const requiredMessage = t('form.error.required')

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const displayTitle = modal ? (
        <h3 className="-mb20 _12">{t(title)}</h3>
    ) : (
        <p className="a-bodyTextRegular -mb20 _12">{t(title)}</p>
    )

    const validation = Yup.object({
        certificateIssuerName: Yup.string().required(requiredMessage),
        name: Yup.string().required(requiredMessage),
        issuedDate: Yup.date()
            .max(today, t('form.error.dateOfIssuanceInFuture')),
        expiresDate: Yup.date().when('notExpire', {
            is: (val) => val === false,
            then: () =>
                Yup.date()
                    .test('expiresDate', (value, { parent, createError }) => {
                        if (!parent.issuedDate) {
                            return true
                        }

                        if (
                            getIsDateSameOrBefore(
                                value,
                                parent.issuedDate,
                                MOMENT_FORMATS.DATE
                            )
                        ) {
                            return createError({
                                message: t(
                                    'form.error.expirationAfterIssueDate'
                                ),
                                path: 'expiresDate',
                            })
                        }
                        return true
                    })
                    .required(requiredMessage),
            otherwise: () => Yup.date().nullable(),
        }),
        certificateNumber: Yup.string(),
        certificateUrl: Yup.string().matches(
            REGEXP.REGEXP_URL,
            t('form.error.certificateURL')
        ),
        files: Yup.array()
            .min(1, t('form.error.atLeastOneCertificate'))
            .required(requiredMessage),
    })

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        if (modal) {
            try {
                setSubmitting(true)
                await handleSubmit({
                    ...formData,
                    user: { id: userId, entityType: ENTITIES.USER },
                })

                handleClose()
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            } finally {
                setSubmitting(false)
            }
        } else {
            setCertificates((certificates) => [
                ...certificates,
                {
                    ...formData,
                },
            ])

            setShowCertificateForm(false)
        }
    }

    const handleClose = () => setOpen(false)

    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ values: { notExpire }, isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <CertificateFormContext
                            trainingSchoolName={trainingSchoolName}
                        />
                        <div className="_wr">
                            <div className="_w">
                                {!modal && <Separator />}
                                {title && displayTitle}

                                <div className="_12 _m6">
                                    <InputField
                                        name="certificateIssuerName"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="name"
                                        label="form.label.certificateName"
                                        placeholder="form.placeholder.certificateName"
                                        required
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <DateTimeField name="issuedDate"/>
                                </div>
                                <div className="_12 _m4">
                                    <DateTimeField
                                        name="expiresDate"
                                        disabled={notExpire}
                                        required
                                    />
                                </div>
                                <div className="_12 _m4 centered-checkbox">
                                    <CheckboxField
                                        name="notExpire"
                                        label="form.label.notExpire"
                                        translate
                                    />
                                </div>
                                <Separator />
                                <div className="_12 _m6">
                                    <InputField name="certificateNumber" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="certificateUrl" />
                                </div>
                                <Separator />
                                <FileUploadField
                                    name="files"
                                    label="form.label.trainingCertificateFiles"
                                    required
                                />
                            </div>
                            {modal ? (
                                <div className="_w -buttons -pb20">
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.cancel"
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            onClick={handleClose}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            label="button.saveCertificate"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="_w ">
                                    <div className="_8 _m5 _l3 -mt20 fullWidthButton -left">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            icon={ICONS.PLUS}
                                            iconColor={COLORS.SECONDARY}
                                            label="button.saveThisCertificate"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="_4 _m3 _l2 -mt20 fullWidthButton -right">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.cancel"
                                            onClick={() =>
                                                setShowCertificateForm(false)
                                            }
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            )}

                            {isSubmitting && <Loader />}
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <>
            {modal ? (
                <Modal
                    open={open}
                    setOpen={setOpen}
                    closeOnClickOutside={false}
                >
                    {renderForm()}
                </Modal>
            ) : (
                <div className="-mt30">{renderForm()}</div>
            )}
        </>
    )
}

CertificateForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    modal: PropTypes.bool,
    setShowCertificateForm: PropTypes.func,
    setCertificates: PropTypes.func,
    trainingSchoolName: PropTypes.string,
}

CertificateForm.defaultProps = {
    modal: true,
    trainingSchoolName: '',
}

export default CertificateForm
