import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import loadable from 'utils/loadable'

import ROUTES from 'constants/routes'

import { FullScreenLayout, NoAuthLayout } from 'components/layouts'
import { getHistoricalDiveRecordVerificationRoute } from 'routes/AuthRoutes/DiverRoutes'
import NotFoundPage from 'screens/common/NotFoundPage'

const Login = loadable(() => import('screens/noAuth/Login'))
const Register = loadable(() => import('screens/noAuth/Register'))
const ForgotPassword = loadable(() => import('screens/noAuth/ForgotPassword'))
const ResetPassword = loadable(() => import('screens/noAuth/ResetPassword'))
const RegisterPassword = loadable(() =>
    import('screens/noAuth/RegisterPassword')
)

const NoAuthRoutes = () => {
    return (
        <Routes>
            <Route element={<NoAuthLayout />}>
                <Route path={ROUTES.HOME} element={<Login />} />
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.REGISTER} element={<Register />} />
                <Route
                    path={`${ROUTES.REGISTER_PASSWORD}/:token`}
                    element={<RegisterPassword />}
                />
                <Route
                    path={ROUTES.FORGOT_PASSWORD}
                    element={<ForgotPassword />}
                />
                <Route
                    path={`${ROUTES.RESET_PASSWORD}/:token`}
                    element={<ResetPassword />}
                />
                <Route
                    path={ROUTES.NOT_FOUND_PAGE}
                    element={<NotFoundPage />}
                />
            </Route>
            <Route element={<FullScreenLayout />}>
                {getHistoricalDiveRecordVerificationRoute()}
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
    )
}

export default NoAuthRoutes
