const isFormDisabled = (fieldRef, checkNode = false) => {
    const current = checkNode ? fieldRef?.current?.node : fieldRef?.current
    if (!current?.form?.length) return false

    const { localName, disabled } = current.form[0]

    return localName === 'fieldset' && disabled
}

export { isFormDisabled }
