import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslate } from 'react-polyglot'

import { UPLOAD_STATUS } from '../constants/galleryConstants'

const ProgressBar = ({ progressObject }) => {
    const t = useTranslate()

    const displayText = useMemo(() => {
        return progressObject.status === UPLOAD_STATUS.UPLOADING
            ? t('general.numberOfUploadingPhotos')
            : t('general.numberOfDeletingPhotos')
    }, [progressObject])

    return (
        <div className="m-gallery__box -uploading -mb20">
            <span className="-percentage a-bodyTextMedium">
                {Math.round(
                    (progressObject.percentage + Number.EPSILON) * 100
                ) / 100}
                %
            </span>
            <div className="aligned-center">
                <div className="-progressBar">
                    <div className="-track"></div>
                    <div
                        className="-progress"
                        style={{
                            maxWidth: `${progressObject.percentage}%`,
                        }}
                    ></div>
                </div>
            </div>
            <span className="a-captionsTextRegular a-lightText">
                {`${progressObject.numberOfFiles} ${displayText}`}
            </span>
        </div>
    )
}

ProgressBar.propTypes = {
    progressObject: PropTypes.object.isRequired,
}

export default ProgressBar
