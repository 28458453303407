import React from 'react'

const NumberOfDivesBronzeBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>50 completed dives</title>
            <circle cx="25" cy="25" r="23" fill="#E9A654" />
            <g filter="url(#filter0_i_21877_20884)">
                <circle cx="24.9996" cy="25.0001" r="19.0943" fill="#E98736" />
            </g>
            <mask
                id="mask0_21877_20884"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="46"
                height="46"
            >
                <circle cx="25" cy="25" r="23" fill="#E9A655" />
            </mask>
            <g mask="url(#mask0_21877_20884)">
                <path
                    opacity="0.12"
                    d="M39.7544 2L49.267 11.5126L12.6828 48.0967L3.17026 38.5841L39.7544 2Z"
                    fill="white"
                />
                <path
                    opacity="0.12"
                    d="M25.1313 -4.5094L30.4312 0.790498L-6.15289 37.3746L-11.4528 32.0747L25.1313 -4.5094Z"
                    fill="white"
                />
                <path
                    opacity="0.12"
                    d="M56.3765 16.3208L61.6764 21.6207L25.0922 58.2048L19.7923 52.9049L56.3765 16.3208Z"
                    fill="white"
                />
            </g>
            <path
                d="M20.8281 29.2462C20.2508 29.2462 19.6695 29.1733 19.0842 29.0275C18.4989 28.8816 17.9938 28.6751 17.5688 28.4077L18.4227 26.5972C18.7675 26.824 19.1403 26.9982 19.5412 27.1197C19.9501 27.2412 20.355 27.302 20.7559 27.302C21.1568 27.302 21.4815 27.225 21.7301 27.0711C21.9786 26.9091 22.1029 26.6863 22.1029 26.4028C22.1029 26.2327 22.0548 26.0828 21.9586 25.9532C21.8624 25.8155 21.698 25.7142 21.4655 25.6494C21.233 25.5765 20.9002 25.54 20.4673 25.54H18.1822L18.6031 20.5701H23.9671V22.4293H19.445L20.6597 21.3721L20.3831 24.7259L19.1684 23.6687H21.0325C21.8664 23.6687 22.5359 23.7902 23.041 24.0333C23.5461 24.2682 23.9149 24.5882 24.1475 24.9932C24.38 25.3902 24.4962 25.8398 24.4962 26.342C24.4962 26.8443 24.3639 27.3182 24.0994 27.7637C23.8428 28.2012 23.4459 28.5576 22.9087 28.833C22.3715 29.1085 21.678 29.2462 20.8281 29.2462Z"
                fill="white"
            />
            <path
                d="M28.9031 29.2462C28.1815 29.2462 27.5401 29.072 26.9788 28.7237C26.4256 28.3753 25.9886 27.8731 25.6679 27.2169C25.3472 26.5607 25.1868 25.7628 25.1868 24.8231C25.1868 23.8834 25.3472 23.0855 25.6679 22.4293C25.9886 21.7731 26.4256 21.2709 26.9788 20.9225C27.5401 20.5742 28.1815 20.4 28.9031 20.4C29.6167 20.4 30.2501 20.5742 30.8033 20.9225C31.3645 21.2709 31.8055 21.7731 32.1262 22.4293C32.447 23.0855 32.6073 23.8834 32.6073 24.8231C32.6073 25.7628 32.447 26.5607 32.1262 27.2169C31.8055 27.8731 31.3645 28.3753 30.8033 28.7237C30.2501 29.072 29.6167 29.2462 28.9031 29.2462ZM28.9031 27.2777C29.1597 27.2777 29.3842 27.1967 29.5766 27.0346C29.777 26.8726 29.9334 26.6134 30.0456 26.257C30.1579 25.8924 30.214 25.4145 30.214 24.8231C30.214 24.2236 30.1579 23.7457 30.0456 23.3892C29.9334 23.0328 29.777 22.7736 29.5766 22.6116C29.3842 22.4495 29.1597 22.3685 28.9031 22.3685C28.6465 22.3685 28.418 22.4495 28.2176 22.6116C28.0251 22.7736 27.8688 23.0328 27.7485 23.3892C27.6363 23.7457 27.5801 24.2236 27.5801 24.8231C27.5801 25.4145 27.6363 25.8924 27.7485 26.257C27.8688 26.6134 28.0251 26.8726 28.2176 27.0346C28.418 27.1967 28.6465 27.2777 28.9031 27.2777Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_i_21877_20884"
                    x="5.90527"
                    y="5.90576"
                    width="38.5423"
                    height="38.5426"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.353846" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_20884"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default NumberOfDivesBronzeBadge
