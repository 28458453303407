import { createContext, useContext, useEffect, useMemo } from 'react'

import useFetchData from 'hooks/useFetchData'

import { CurrentUserContext } from './CurrentUserContext'

import ENTITIES from 'constants/entities'

export const NotificationContext = createContext()

const REFETCH_TIME = 15000

const NotificationContextProvider = ({ children }) => {
    const { currentUser } = useContext(CurrentUserContext)

    const {
        data: notifications,
        meta,
        isLoading: isLoadingNotifications,
        loadMore: loadMoreNotifications,
        fetchData: fetchNotifications,
    } = useFetchData(
        ENTITIES.NOTIFICATIONS,
        { include: 'user' },
        currentUser,
        true,
        currentUser?.id
    )

    const {
        data: unreadNotifications,
        isLoading: isLoadingUnreadNotifications,
        loadMore: loadMoreUnreadNotifications,
        fetchData: fetchUnreadNotifications,
    } = useFetchData(
        ENTITIES.NOTIFICATIONS,
        { seen: false, include: 'user' },
        currentUser,
        false,
        currentUser?.id
    )

    const contextValue = useMemo(
        () => ({
            notifications,
            fetchNotifications,
            isLoadingNotifications,
            loadMoreNotifications,
            unreadNotifications,
            fetchUnreadNotifications,
            isLoadingUnreadNotifications,
            loadMoreUnreadNotifications,
            meta,
        }),
        [
            notifications,
            isLoadingNotifications,
            unreadNotifications,
            isLoadingUnreadNotifications,
            meta,
        ]
    )

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentUser?.id) {
                fetchNotifications()
                fetchUnreadNotifications()
            }
        }, REFETCH_TIME)

        return () => {
            clearInterval(interval)
        }
    }, [currentUser])

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationContextProvider
