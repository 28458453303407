import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import loadable from 'utils/loadable'

import ROUTES from 'constants/routes'

import { AdminLayout, FullScreenLayout } from 'components/layouts'

const SkillsManagement = loadable(() =>
    import('screens/admin/SkillsManagement')
)
const NotFoundPage = loadable(() => import('screens/common/NotFoundPage'))

const AdminRoutes = () => {
    return (
        <Routes>
            <Route element={<AdminLayout />}>
                <Route
                    path={ROUTES.SKILLS_MANAGEMENT}
                    element={<SkillsManagement />}
                />
            </Route>
            <Route element={<FullScreenLayout />}>
                <Route
                    path={ROUTES.NOT_FOUND_PAGE}
                    element={<NotFoundPage />}
                />
            </Route>
            <Route
                path="*"
                element={<Navigate to={ROUTES.SKILLS_MANAGEMENT} />}
            />
        </Routes>
    )
}

export default AdminRoutes
