import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, DIVE_MODES } from 'constants/enums'

import useQueryParams from 'hooks/useQueryParams'
import useSaveByStep from './hooks/useSaveByStep'

import {
    DIVE_DATA_TABS,
    getVisibleProfileTabs,
} from 'utils/diveRecordFunctions'

import {
    createEntityService,
    persistEntityService,
} from 'services/entity.service'

import StepButtons from 'components/StepButtons'
import TabNavigation from 'components/tabs/TabNavigation'
import DiveEventWrapper from './components/diveEvent/DiveEventWrapper'
import SurfaceDecoEventWrapper from './components/surfaceDecoEvent/SurfaceDecoEventWrapper'
import ChamberPressurizationEventWrapper from './components/chamberPressurizationEvent/ChamberPressurizationEventWrapper'
import ChamberDecompressionEventWrapper from './components/chamberDecompressionEvent/ChamberDecompressionEventWrapper'
import LockOutEventWrapper from './components/lockOutEvent/LockOutEventWrapper'
import BellRunEventWrapper from './components/bellRunEvent/BellRunEventWrapper'
import SaturationEventWrapper from './components/saturationEvent/SaturationEventWrapper'

const DiveProfileRecord = () => {
    const { search } = useLocation()

    const { setAlert } = useContext(AlertContext)

    const { diveRecord, handlePostSaveAction, goToStepRef } =
        useContext(DiveRecordContext)

    const { tab } = useQueryParams()

    const { data, fetchData } = diveRecord

    const formRef = useRef()

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const anyData = !!(
        data?.diveRecordDiveData?.diveEvents ||
        data?.diveRecordDiveData?.saturationDailyEvents ||
        data?.diveRecordDiveData?.surfaceDecompressionEvents ||
        data?.diveRecordDiveData?.chamberPressurizationEvents ||
        data?.diveRecordDiveData?.chamberDecompressionEvents ||
        data?.diveRecordDiveData?.bellRunEvents ||
        data?.diveRecordDiveData?.lockOutEvents
    )

    const anySaturationDailyEvent =
        !!data?.diveRecordDiveData?.saturationDailyEvents

    const closedBellSaturationType =
        data?.diveRecordGeneralInformation.divingMode.id ===
        DIVE_MODES.CLOSED_BELL_SATURATION.id

    const createDiveProfile = () => {
        try {
            createEntityService(ENTITIES.DIVE_RECORD_DIVE_DATA, {
                diveRecord: data,
                complete: false,
            }).then(() => {
                fetchData()
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useEffect(() => {
        if (data && !data.diveRecordDiveData) {
            createDiveProfile()
        }
        if (closedBellSaturationType && anySaturationDailyEvent) {
            setError(false)
        } else {
            setErrorMessage('form.error.addLeastOneSaturationDailyEvent')
        }
        if (anyData && !closedBellSaturationType) {
            setError(false)
        }
    }, [data])

    const handleSave = async (
        formData,
        formActions,
        complete = true,
        goBack = false
    ) => {
        if (complete && !anyData && !closedBellSaturationType) {
            setError(true)
            setErrorMessage('form.error.addLeastOneEvent')
            return
        }

        if (complete && !anySaturationDailyEvent && closedBellSaturationType) {
            setError(true)
            return
        }

        await persistEntityService(
            ENTITIES.DIVE_RECORD_DIVE_DATA,
            {
                id: data.diveRecordDiveData.id,
                complete: true, // TODO:: Change this when the validation for step 3 is done
            },
            data.diveRecordDiveData.id
        )

        handlePostSaveAction(complete, goBack, goToStepRef.current)
    }

    useSaveByStep(formRef, handleSave)

    if (!data || !data.diveRecordDiveData) {
        return null
    }

    const tabData = getVisibleProfileTabs(data, search)

    const renderTabContent = () => {
        let tabKey = tab
        if (typeof tab === 'undefined') {
            tabKey = tabData[0].key
        }

        switch (tabKey) {
            case DIVE_DATA_TABS.SURFACE_DECOMPRESSION_EVENT:
                return (
                    <SurfaceDecoEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        unitImperial={data.unitImperial}
                        divingMode={
                            diveRecord.data.diveRecordGeneralInformation
                                .divingMode
                        }
                        fetchDiveRecord={fetchData}
                    />
                )
            case DIVE_DATA_TABS.CHAMBER_PRESSURIZATION_EVENT:
                return (
                    <ChamberPressurizationEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        unitImperial={data.unitImperial}
                        fetchDiveRecord={fetchData}
                    />
                )
            case DIVE_DATA_TABS.CHAMBER_DECOMPRESSION_EVENT:
                return (
                    <ChamberDecompressionEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        fetchDiveRecord={fetchData}
                    />
                )
            case DIVE_DATA_TABS.BELL_RUN_EVENT:
                return (
                    <BellRunEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        unitImperial={data.unitImperial}
                        fetchDiveRecord={fetchData}
                    />
                )
            case DIVE_DATA_TABS.LOCK_OUT_EVENT:
                return (
                    <LockOutEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        unitImperial={data.unitImperial}
                        fetchDiveRecord={fetchData}
                    />
                )
            case DIVE_DATA_TABS.SATURATION_DAILY_EVENT:
                return (
                    <SaturationEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        fetchDiveRecord={fetchData}
                        unitImperial={data.unitImperial}
                    />
                )
            case DIVE_DATA_TABS.DIVE_EVENT:
                return (
                    <DiveEventWrapper
                        diveRecordDiveDataId={data.diveRecordDiveData.id}
                        unitImperial={data.unitImperial}
                        fetchDiveRecord={fetchData}
                    />
                )
            default:
                return <div>No data</div>
        }
    }

    return (
        <Fragment>
            <div className="-contentElements">
                <div className="_12">
                    <TabNavigation tabs={tabData} tabQueryParam="tab" />
                </div>

                {renderTabContent()}
            </div>
            <div className="_wr">
                <StepButtons
                    skipLabel="button.saveAndContinueLater"
                    handleNext={() => handleSave(null, null, true)}
                    handleSkip={() => handleSave(null, null, false)}
                    handleBack={() => handleSave(null, null, false, true)}
                    canSkip
                    error={error}
                    errorMessage={errorMessage}
                />
            </div>
        </Fragment>
    )
}

export default DiveProfileRecord
