import { useContext, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { DiveRecordContext } from '../contexts/DiveRecordContext'
import { filterDiveRecordSteps } from 'utils/diveRecordFunctions'

const GeneralInformationFormContext = ({ unitOfMeasurement }) => {
    const { isSupervisor, isSupervisorDiveRecord, setAllSteps } =
        useContext(DiveRecordContext)

    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const {
        lengthDistanceOfPenetration,
        divePhysicalCharacteristics,
        divingMode,
    } = values

    const firstRender = useRef(true)

    useEffect(() => {
        setFieldValue(
            'divePhysicalCharacteristics',
            divePhysicalCharacteristics.map((item) =>
                item.name.includes('Length/Distance of penetration')
                    ? {
                          ...item,
                          name:
                              lengthDistanceOfPenetration ||
                              lengthDistanceOfPenetration === 0
                                  ? `Length/Distance of penetration - ${lengthDistanceOfPenetration}${unitOfMeasurement}`
                                  : 'Length/Distance of penetration',
                      }
                    : item
            )
        )
    }, [lengthDistanceOfPenetration])

    useEffect(() => {
        if (!firstRender.current) {
            if (
                (lengthDistanceOfPenetration ||
                    lengthDistanceOfPenetration === 0) &&
                !divePhysicalCharacteristics.some((item) =>
                    item.name.includes('Length/Distance of penetration')
                )
            ) {
                setFieldValue('lengthDistanceOfPenetration', '')
                setFieldTouched('lengthDistanceOfPenetration', false)
            }
        } else {
            firstRender.current = false
        }
    }, [divePhysicalCharacteristics])

    useEffect(() => {
        if (!firstRender.current) {
            const steps = filterDiveRecordSteps(
                divingMode,
                isSupervisor,
                isSupervisorDiveRecord
            )
            setAllSteps(steps)
        }
    }, [divingMode])

    return null
}

export default GeneralInformationFormContext
