import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import {
    createEntityService,
    deleteEntityService,
    editEntityService,
} from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ROUTES from 'constants/routes'

import Loader from 'components/Loader'
import Button from 'components/Button'
import DiverPositionCard from './DiverPositionCard'

const DiverPositionList = ({ data, fetchDiveTeam, project }) => {
    const t = useTranslate()
    const { currentUser } = useContext(CurrentUserContext)
    const [unavailableProject, setUnavailableProject] = useState(false)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const navigate = useNavigate()
    const { setAlert } = useContext(AlertContext)

    useEffect(() => {
        if (unavailableProject) {
            setAlert(
                { message: t('general.successfullyWithdrawn') },
                ALERT_TYPES.SUCCESS
            )
            navigate(`${ROUTES.HOME}`)
        }
    }, [unavailableProject])

    const {
        data: diverProjectPositions,
        isLoading,
        loadMore,
        fetchData,
        meta,
    } = data

    const closeConfirmationModalAndRefreshPositions = () => {
        closeConfirmationModal()
        fetchData()
        fetchDiveTeam()
    }

    const handleConfirmationModal = (position, message) => {
        showConfirmationModal({
            customText: (
                <span className="-mt20 a-bodyTextRegular">
                    {`${t(message)} ${position.diverPositionName} ${t(
                        'general.onTheProject'
                    )}`}{' '}
                    <span className="a-bodyTextBold">
                        {position.projectName}
                    </span>
                </span>
            ),
            confirmLabel: 'button.ok',
            handleConfirm: () => closeConfirmationModalAndRefreshPositions(),
            handleCancel: () => closeConfirmationModalAndRefreshPositions(),
            translateMessage: false,
            hideCancel: true,
        })
    }

    const handleApply = async (position) => {
        try {
            await createEntityService(ENTITIES.INVITE, {
                diverEmail: currentUser.email,
                submittedByDiver: true,
                projectPosition: {
                    ...position,
                    entityType: ENTITIES.PROJECT_POSITIONS,
                },
            })
            handleConfirmationModal(
                position,
                'general.successfullyAppliedForThe'
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleReapply = async (position) => {
        try {
            await editEntityService(ENTITIES.INVITE, position.inviteId, {
                personReapplied: true
            })
            handleConfirmationModal(
                position,
                'general.successfullyReappliedForThe'
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleWithdraw = async (position) => {
        try {
            await deleteEntityService(ENTITIES.INVITE, position.inviteId)
            if (project.hidden) {
                setUnavailableProject(true)
                return
            }
            handleConfirmationModal(position, 'general.successfullyWithdrawn')
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleAccept = async (position) => {
        try {
            await editEntityService(ENTITIES.INVITE, position.inviteId, {
                inviteAccepted: true,
            })
            handleConfirmationModal(
                position,
                'general.successfullyAcceptedInvitation'
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleReject = async (position) => {
        try {
            await editEntityService(ENTITIES.INVITE, position.inviteId, {
                inviteRejected: true,
            })
            handleConfirmationModal(
                position,
                'general.successfullyRejectedInvitation'
            )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    if (isLoading) return <Loader />

    return (
        <div>
            {diverProjectPositions.map((position, index) => {
                return (
                    <div key={position.id + position.diverPositionName + index}>
                        <DiverPositionCard
                            data={position}
                            buttonActions={position.availableActions}
                            handleAccept={() => handleAccept(position)}
                            handleApply={() => handleApply(position)}
                            handleReapply={() => handleReapply(position)}
                            handleReject={() => handleReject(position)}
                            handleWithdraw={() => handleWithdraw(position)}
                        />
                    </div>
                )
            })}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
        </div>
    )
}

export default DiverPositionList
