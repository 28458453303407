import React, { useMemo, useState } from 'react'

export const ConfirmationModalContext = React.createContext()

const modalInitialState = {
    message: '',
    customText: null,
    title: '',
    confirmLabel: '',
    cancelLabel: '',
    handleConfirm: () => {},
    handleCancel: () => {},
    translateMessage: true,
    hideCancel: false,
}

const ConfirmationModalContextProvider = (props) => {
    const [confirmationModal, setConfirmationModal] =
        useState(modalInitialState)

    const showConfirmationModal = (data) => setConfirmationModal(data)

    const closeConfirmationModal = () => setConfirmationModal(modalInitialState)

    const contextValue = useMemo(
        () => ({
            confirmationModal,
            showConfirmationModal,
            closeConfirmationModal,
        }),
        [confirmationModal]
    )

    return (
        <ConfirmationModalContext.Provider value={contextValue}>
            {props.children}
        </ConfirmationModalContext.Provider>
    )
}

export default ConfirmationModalContextProvider
