import React from 'react'
import ReleasePage from '../ReleasePage'

const releaseObject = {
    title: 'Release 1.0.0',
    introText:
        "We're excited to share with you a range of new features, improvements, and bug fixes designed to enhance your user experience.",
    items: [
        {
            title: 'Easier Project Recruitment for Companies',
            description:
                'We’ve made it simpler for companies to recruit the best talent. Our revamped recruitment process now streamlines job postings, application reviews, and candidate selection, making everything faster and more efficient.',
        },
        {
            title: 'Expand User Profile',
            description:
                'We’ve enhanced the user profile to help divers showcase their information better.',
        },
        {
            title: 'Improved User Training Section',
            description:
                'We’ve reorganised the user training section into easy-to-navigate categories. This makes it easier for you to find and use educational resources effectively.',
        },
        {
            title: 'Show Off Your Verified Records',
            description:
                'You can now display the number of verified dive records right on your profile. This feature helps you highlight your achievements and boost your professional credibility.',
        },
        {
            title: 'Earn User Badges',
            description:
                'We’ve introduced user badges! These are awarded based on your profile data, showing off your accomplishments and milestones. Look out for more badges as we add new areas of recognition.',
        },
        {
            title: 'Export CV',
            description:
                'You and companies can now export profiles into a CV format PDF. This feature makes it easy to create a comprehensive CV from your profile data, perfect for presenting your professional information.',
        },
        {
            title: 'Better Date Picker',
            description:
                'Our new date picker is here! It’s more user-friendly, helping you select dates quickly and easily with an improved design.',
        },
        {
            title: 'Bug Fixes and Performance Improvements',
            description:
                'We’ve fixed various bugs and made numerous performance improvements across the platform. Enjoy a more stable, reliable, and smooth user experience.',
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingItems: [
        {
            title: 'User Account Improvements',
            description:
                'We’re making some enhancements to user accounts. These updates aim to provide a smoother and more user-friendly experience.',
        },
        {
            title: 'Gallery Improvements',
            description:
                'Our gallery is getting an upgrade! Expect better organization, faster load times, and a more visually appealing layout to showcase your photos and videos.',
        },
        {
            title: 'Improved and More Detailed Data Access Control',
            description:
                'We’re enhancing data access control with more detailed and customizable options. This will give you better control over who can see and interact with your information, ensuring greater privacy and security.',
        },
    ],
    outroText:
        'We trust that these updates will greatly improve your experience with Skill N Depth. We highly value your feedback and are dedicated to ongoing enhancement of our platform with your insights. Thank you for helping us refine and perfect Skill N Depth.',
}

const Release100 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release100
