import React from 'react'

const RenewableEnergyBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>Renewable Energy Diving</title>
            <circle cx="25" cy="25" r="23" fill="#00BA34" />
            <g filter="url(#filter0_i_21877_21074)">
                <circle cx="24.9995" cy="25" r="19.4615" fill="#F3F4F5" />
            </g>
            <path
                d="M25.1762 12.6154C24.9308 12.6154 24.724 12.8015 24.6942 13.0491L23.8377 20.1752C23.7692 20.745 24.6023 21.2463 25.1762 21.2463C25.7501 21.2463 26.5832 20.745 26.5147 20.1752L25.6581 13.0491C25.6283 12.8015 25.4215 12.6154 25.1762 12.6154Z"
                fill="#D1D2D8"
            />
            <path
                d="M35.0884 29.7781C35.2111 29.5656 35.1533 29.2935 34.9537 29.1439L29.2107 24.839C28.7515 24.4948 27.9008 24.9656 27.6138 25.4626C27.3269 25.9596 27.3445 26.9318 27.8722 27.1574L34.4718 29.9786C34.7011 30.0766 34.9657 29.9906 35.0884 29.7781Z"
                fill="#D1D2D8"
            />
            <path
                d="M15.1556 29.7781C15.0329 29.5656 15.0907 29.2935 15.2903 29.1439L21.0333 24.839C21.4925 24.4948 22.3432 24.9656 22.6302 25.4626C22.9171 25.9596 22.8995 26.9318 22.3718 27.1574L15.7722 29.9786C15.5429 30.0766 15.2783 29.9906 15.1556 29.7781Z"
                fill="#D1D2D8"
            />
            <ellipse
                cx="25.1767"
                cy="24.0236"
                rx="1.74721"
                ry="1.74721"
                fill="#00BA34"
            />
            <path
                d="M23.2745 37.3846L24.2509 27.1583H25.844L26.9745 37.3846H23.2745Z"
                fill="#D1D2D8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.0903 23.8749C33.0682 20.4077 30.8165 17.4695 27.6968 16.4222L28.2088 15.1848C31.8128 16.4348 34.4045 19.8507 34.4264 23.8749H33.0903ZM15.9272 23.8235C15.9701 19.8873 18.4716 16.541 21.9685 15.2478L22.4231 16.5046C19.4403 17.6117 17.3062 20.4656 17.2634 23.8235H15.9272ZM18.8488 30.6732L19.7757 29.7107C20.5554 30.439 21.4825 31.0115 22.5073 31.3786L22.0903 32.6488C20.8724 32.2179 19.7718 31.5391 18.8488 30.6732ZM28.0826 32.7105C29.2678 32.3186 30.3469 31.6936 31.2654 30.8897L30.4634 29.8153C29.6661 30.5315 28.7226 31.0881 27.6831 31.435L28.0826 32.7105Z"
                fill="#00BA34"
            />
            <defs>
                <filter
                    id="filter0_i_21877_21074"
                    x="5.53796"
                    y="5.53845"
                    width="39.2778"
                    height="39.2778"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.354733" dy="0.354733" />
                    <feGaussianBlur stdDeviation="0.177366" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21074"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default RenewableEnergyBadge
