import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'

import { persistEntityService } from 'services/entity.service'

import {
    positiveNumberAndLessThan100Validation,
    validate24Hours,
    validateMinutes,
} from 'utils/yupValidations'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { InputField } from 'components/formFields'
import Loader from 'components/Loader'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'

const LockOutEventFormModal = ({ initialData, fetchData, closeModal }) => {
    const t = useTranslate()

    const { diveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)

    const {
        data: { diveRecordDiveData, unitImperial },
    } = diveRecord

    const initialValues = {
        lockOutTimeHours: initialData?.lockOutTimeHours ?? '',
        lockOutTimeMinutes: initialData?.lockOutTimeMinutes ?? 0,
        breathingMixOxygen: initialData?.breathingMixOxygen ?? '',
        excursionDeepestDepth: initialData?.excursionDeepestDepth ?? '',
        excursionShallowestDepth: initialData?.excursionShallowestDepth ?? '',
        lockInTimeHours: initialData?.lockInTimeHours ?? '',
        lockInTimeMinutes: initialData?.lockInTimeMinutes ?? 0,
        comment: initialData?.comment ?? '',
        bottomTimeElapsedHours: initialData?.bottomTimeElapsedHours ?? '',
        bottomTimeElapsedMinutes: initialData?.bottomTimeElapsedMinutes ?? '',
    }

    const requiredMessage = t('form.error.required')

    const positiveNumberValidation = Yup.number()
        .required(requiredMessage)
        .positive(t('form.error.positiveNumber'))

    const validation = Yup.object({
        lockOutTimeHours: validate24Hours(t),
        lockOutTimeMinutes: validateMinutes(t),
        breathingMixOxygen: positiveNumberAndLessThan100Validation(
            t,
            requiredMessage
        ),
        excursionDeepestDepth: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .test('excursionDeepestDepth', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.excursionShallowestDepth)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanShallowestDepth'),
                        path: 'excursionDeepestDepth',
                    })
                }
                return true
            })
            .required(requiredMessage),
        excursionShallowestDepth: positiveNumberValidation,
        lockInTimeHours: validate24Hours(t),
        lockInTimeMinutes: validateMinutes(t),
        comment: Yup.string(),
        bottomTimeElapsedHours: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .max(12, t('form.error.maxHoursIs12'))
            .notRequired(),
        bottomTimeElapsedMinutes: Yup.number()
            .min(0, t('form.error.minutesError'))
            .max(59, t('form.error.minutesError'))
            .test(
                'bottomTimeElapsedMinutes',
                (value, { parent, createError }) => {
                    if (value > 0 && parent.bottomTimeElapsedHours === 12) {
                        return createError({
                            message: t('form.error.invalidBottomTimeMinutes'),
                            path: 'bottomTimeElapsedMinutes',
                        })
                    }
                    return true
                }
            )
            .notRequired(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                ENTITIES.LOCK_OUT_EVENT,
                {
                    ...formData,
                    diveRecordDiveData: diveRecordDiveData,
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    return (
        <Modal open={true} setOpen={closeModal} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting }) => {
                    return (
                        <Form>
                            <FocusError />
                            <div className="_wr">
                                <div className="_w">
                                    <h3 className="-mb20 _12">
                                        {t('lockOutEvent.title')}
                                    </h3>

                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t('form.label.lockOutTimeHours')}
                                            <span className="redStar"> *</span>
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="lockOutTimeHours"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                                required
                                            />
                                            <InputField
                                                name="lockOutTimeMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="_12 _l4">
                                        <InputField
                                            name="breathingMixOxygen"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.%"
                                            min={'0'}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _l4">
                                        <InputField
                                            name="excursionShallowestDepth"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            min={'0'}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="_w">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="excursionDeepestDepth"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            min={'0'}
                                            required
                                        />
                                    </div>

                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t('form.label.lockInTimeHours')}
                                            <span className="redStar"> *</span>
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="lockInTimeHours"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                                required
                                            />
                                            <InputField
                                                name="lockInTimeMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="_12 _l4 fakeLabel multipleInputs">
                                        <span className="a-mediumText a-lightText">
                                            {t('form.label.bottomTimeElapsed')}
                                        </span>
                                        <div className="space-between">
                                            <InputField
                                                name="bottomTimeElapsedHours"
                                                label="form.label.bottomTimeElapsed"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.hh"
                                                min={'0'}
                                            />
                                            <InputField
                                                name="bottomTimeElapsedMinutes"
                                                label="general.fakeLabel"
                                                placeholder="form.placeholder.0"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                units="form.label.min"
                                                min={'0'}
                                                max={'59'}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12">
                                        <TextAreaField name="comment" />
                                    </div>
                                </div>
                            </div>
                            <div className="_wr">
                                <div className="_w -buttons -pb20">
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.cancel"
                                            onClick={closeModal}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            label="button.save"
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    {isSubmitting && <Loader />}
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default LockOutEventFormModal
