import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'

import ExperienceForm from './ExperienceForm'
// import { ExperienceForm } from './..'

const EditExperience = ({
    data,
    open,
    setOpen,
    fetchData,
    divingModes,
    showActions,
    refetchData,
    divingMode,
}) => {
    const t = useTranslate()

    const handleSubmit = async (formData) => {
        await editEntityService(ENTITIES.EXPERIENCE, data.id, formData, true, [
            'experienceDivingModes',
        ])
        fetchData()
        showActions && refetchData && refetchData()
    }

    return (
        <ExperienceForm
            title={
                divingMode
                    ? t('general.editExperienceIn') +
                      ' ' +
                      divingMode.name +
                      ' ' +
                      t('general.divingMode')
                    : t('general.editExperience')
            }
            initialData={data}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            divingModes={divingModes}
            divingMode={divingMode}
        />
    )
}

EditExperience.propTypes = {
    showActions: PropTypes.bool,
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    divingModes: PropTypes.array,
    refetchData: PropTypes.func,
}

EditExperience.defaultProps = {
    showActions: false,
}

export default EditExperience
