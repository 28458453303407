import { useContext } from 'react'

import { ImagePreviewModalContext } from 'contexts/ImagePreviewModalContext'

import ICONS from 'constants/icons'
import { BUTTON_STATUS } from 'constants/enums'

import Button from './Button'

const ImagePreviewModal = () => {
    const {
        imagePreviewModal: {
            image,
            currentImageIndex,
            handleDownload,
            handleClose,
        },
    } = useContext(ImagePreviewModalContext)

    if (!image) return null

    return (
        <div className="m-modal -show -imagePreviewModal">
            <div className="wrapper">
                <div className="m-modal__content">
                    <div className="-insideContent">
                        <span
                            onClick={handleClose}
                            className="removeItemIcon"
                        ></span>
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <div className="_12 -headerTitle">
                                    <h3>{image?.alt}</h3>
                                </div>
                                <div className="_12 -mt20 -imagePreview">
                                    <img
                                        src={image}
                                        alt={`Image ${currentImageIndex}`}
                                    />
                                </div>
                                <div className="_12 -mt20">
                                    <Button
                                        onClick={handleDownload}
                                        btnClass={BUTTON_STATUS.ICON_BUTTON}
                                        icon={ICONS.DOWNLOAD_IMAGE}
                                        label="general.downloadImage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImagePreviewModal
