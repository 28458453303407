import React from 'react'
import PropTypes from 'prop-types'

const Dropdown = ({
    children,
    dropdownRef,
    handleReachedBottom,
    open,
    notificationDropdown,
    mainSearchDropdown,
    dropup,
}) => {
    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target
        const bottom = scrollTop + clientHeight >= scrollHeight - 5
        if (bottom) {
            handleReachedBottom()
        }
    }

    return (
        <div
            ref={dropdownRef}
            className={`m-dropdown ${open ? '-open' : ''} ${
                notificationDropdown ? '-notification' : ''
            } ${mainSearchDropdown ? '-mainSearch' : ''} ${
                dropup ? '-dropup' : ''
            }`}
        >
            <div
                className="-dropdownOptions"
                onScroll={handleReachedBottom ? handleScroll : undefined}
            >
                {children}
            </div>
        </div>
    )
}

Dropdown.propTypes = {
    notificationDropdown: PropTypes.bool,
    mainSearchDropdown: PropTypes.bool,
    dropup: PropTypes.bool,
}

Dropdown.defaultProps = {
    notificationDropdown: false,
    mainSearchDropdown: false,
    dropup: false,
}

export default Dropdown
