import React, { useEffect } from 'react'

import useToggleDropdown from 'hooks/useToggleDropdown'

import COLORS from 'constants/colors'
import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Icon from 'components/Icon'

const RejectionTemplateItem = ({
    template,
    selectedTemplate,
    chooseTemplate,
    openTemplate,
    setOpenTemplate,
    editTemplate,
}) => {
    const { contentRef, open, setOpen } = useToggleDropdown(false)

    useEffect(() => {
        setOpen(false)
    }, [template])

    return (
        <div
            key={template.id}
            className={`m-rejectionTemplateItem -mt10 ${
                selectedTemplate && template.id === selectedTemplate.id
                    ? '-selected'
                    : ''
            }`}
            onClick={(e) => chooseTemplate(template, e)}
        >
            <div
                className={`m-rejectionTemplateItem__header aligned-center ${
                    open ? 'open' : ''
                }`}
            >
                <span
                    className={'-iconWrapper aligned-center justify-center'}
                    onClick={() => {
                        openTemplate !== template.id
                            ? setOpenTemplate(template.id)
                            : setOpenTemplate(null)
                        setOpen(!open)
                    }}
                >
                    <Icon name={ICONS.CHEVRON_DOWN}></Icon>
                </span>
                <span className="a-bodyTextMedium">{template.title}</span>
            </div>
            <div
                className={`m-rejectionTemplateItem__body column ${
                    openTemplate === template.id ? 'open' : ''
                }`}
                ref={contentRef}
            >
                <p
                    className={
                        'a-bodyTextRegular a-lightText -opacity-60 -mt10'
                    }
                >
                    {template.body}
                </p>
                <div className="-mt20">
                    <Button
                        onClick={() => editTemplate(template)}
                        label="button.editTemplate"
                        icon={ICONS.ROUNDED_EDIT}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.LEFT}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                    />
                </div>
            </div>
        </div>
    )
}

export default RejectionTemplateItem
