import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { formatDate } from 'services/moment.service'

import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'

import { Stepper } from 'components/stepper'
import UnitOfMeasurementForm from './components/UnitOfMeasurementForm'
import Button from 'components/Button'

const DiveRecordSteps = () => {
    const t = useTranslate()
    const { currentUser } = useContext(CurrentUserContext)
    const {
        diveRecord,
        isSupervisor,
        activeStepIndex,
        activeStep,
        allSteps,
        formRef,
        goToStepRef,
        goBack,
        handleGoToSpecificStep,
        diveRecordId,
    } = useContext(DiveRecordContext)

    const currentStep = activeStep || 1
    const allowedStep = isSupervisor ? 6 : diveRecord.data?.currentStep

    const handleClick = (id) => {
        if (id === currentStep || id > allowedStep) return

        if (isSupervisor) {
            handleGoToSpecificStep(id)
        } else if (formRef.current) {
            goToStepRef.current = id
            formRef.current.handleSave(id)
        }
    }

    const diveRecordName = () => {
        if (
            diveRecord?.data?.diveRecordGeneralInformation?.nameOfProject !==
                undefined &&
            diveRecord?.data?.recordNumber !== undefined
        ) {
            return `${
                diveRecord?.data?.diveRecordGeneralInformation?.nameOfProject
            } ${formatDate(
                diveRecord?.data?.diveRecordGeneralInformation?.dateOfDive
            )} #${diveRecord?.data?.recordNumber}`
        } else {
            return t('general.newDiveRecord')
        }
    }

    if (!allSteps.length) return null

    return (
        <div className="m-step -pt15 -pb30">
            <div className="_wr">
                <div className="_w">
                    <div className="_12 m-step__backButton aligned-center space-between -gap20">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.SMALL}
                            icon={ICONS.ARROW_LEFT}
                            iconColor={COLORS.SECONDARY}
                            label="button.goBackFromDiveRecord"
                            onClick={goBack}
                        />
                        <h3>{diveRecordName()}</h3>
                        <div className="-fakeSpace"></div>
                    </div>
                    <div className="_12">
                        <Stepper
                            steps={allSteps}
                            activeStepIndex={activeStepIndex}
                            currentStep={currentStep}
                            allowedStep={allowedStep}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
            {currentUser?.userSetting.unitImperial === undefined &&
                !isSupervisor && <UnitOfMeasurementForm />}
            <div className="_wr m-step__content-wrapper">
                <div className="_w m-step__content">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default DiveRecordSteps
