import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import { useContext, useMemo } from 'react'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { formatDate } from 'services/moment.service'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import { getProjectStatusColor } from 'utils/dataFormatters'
import {
    formatDateRange,
    formatGteLteRange,
    formatPayRateRange,
} from 'utils/filterButtonFormatters'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    INFO_VALUE_TYPE,
} from 'constants/enums'
import {
    INDUSTRY_TYPE_KEY,
    PROJECT_TYPE_KEY,
} from 'constants/projectFilterKeys'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import SearchAndFilter from 'components/SearchAndFilter'
import ProjectFilters from './ProjectFilters'
import { PROJECT_SEARCH_FILED } from 'constants/constants'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'

const filters = {
    company: { entityType: ENTITIES.COMPANY, displayAttribute: 'name' },
    country: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    industryType: { entityType: ENTITIES.INDUSTRY, displayAttribute: 'name' },
    projectType: {
        entityType: ENTITIES.PROJECT_INDUSTRY_TYPES,
        displayAttribute: 'name',
    },
    divingMode: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    region: { entityType: ENTITIES.REGION, displayAttribute: 'name' },
    locationType: {
        entityType: ENTITIES.LOCATION_TYPE,
        displayAttribute: 'name',
    },
    locationDetail: { type: 'text' },
    keyword: { type: 'text' },
    fromDate: { type: 'dateRange', formatter: formatDateRange },
    toDurationDays: {
        type: 'range',
        unit: 'days',
        formatter: formatGteLteRange,
    },
    positionPayRate: {
        entityType: ENTITIES.DIVER_POSITION,
        sign: '$',
        formatter: formatPayRateRange,
        fetchAttribute: 'position',
    },
}

const ProjectList = ({
    userID,
    profilePage,
    projectStatusId,
    companyID,
    myProfile,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = useLocation()

    const { userId, isDiver } = useContext(CurrentUserContext)

    const { data, meta, isLoading, fetchData, loadMore } =
        useFetchDataByQueryParams(
            ENTITIES.PROJECT_ELASTIC,
            {
                'projectStatus.id': projectStatusId,
                'company.id': myProfile ? null : companyID,
                myDiveProjects: myProfile || null,
                include:
                    'company,divingMode,country,locationType,projectStatus,creator',
                acceptedForUser: !myProfile && profilePage ? userID : null,
            },
            true
        )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.DIVE_PROJECTS
    )

    const noProjectsText = useMemo(() => {
        if (profilePage) {
            if (myProfile) {
                return !isDiver
                    ? t('general.contractorNoProjects')
                    : t('general.profileNoProjects')
            }

            return t('general.profileNoProjects')
        }

        return t('general.noProjects')
    }, [profilePage, isDiver, myProfile])

    const condition =
        data.length === 0 &&
        Object.keys(activeFilters).length === 0 &&
        !isLoading

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    const handleEdit = ({ id }) => {
        navigate(`${ROUTES.PROJECT}${ROUTES.EDIT}/${id}`)
    }

    const handleGoToCreateProject = () => {
        if (location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`)
        } else {
            navigate(`${ROUTES.PROJECT}${ROUTES.CREATE}`)
        }
    }

    const handleFilterItemRemoved = (key, filters) => {
        if (
            key === INDUSTRY_TYPE_KEY &&
            filters.hasOwnProperty(PROJECT_TYPE_KEY)
        ) {
            delete filters[PROJECT_TYPE_KEY]
        }

        return filters
    }

    const handleOpenSingleProject = (item) => {
        if (location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}/${item.id}`)
        } else {
            navigate(`${ROUTES.PROJECT}/${item.id}`)
        }
    }

    return (
        <div>
            {profilePage ? (
                <div className="-mb20">
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.diveProjects')}
                        </span>
                    </div>
                    {myProfile && !isDiver && data.length > 0 && (
                        <AddNew
                            handleAction={handleGoToCreateProject}
                            label="button.addDiveProject"
                        />
                    )}
                </div>
            ) : (
                <SearchAndFilter
                    searchPlaceholder="general.searchProject"
                    activeFilters={activeFilters}
                    filters={filters}
                    modifyFiltersOnRemove={handleFilterItemRemoved}
                    searchKey={PROJECT_SEARCH_FILED}
                    showDefaultKey={false}
                >
                    <ProjectFilters
                        activeFilters={activeFilters}
                        myProfile={myProfile}
                    />
                </SearchAndFilter>
            )}
            {data.map((item, index) => (
                <div key={index} className="-mb10 -mt20">
                    <CardContainer
                        title={item?.name}
                        item={item}
                        statuses={[
                            {
                                type: 'status',
                                name: [item?.locationTypeName],
                                color: 'green',
                            },
                            {
                                type: 'status',
                                name: [item?.projectStatusName],
                                color: getProjectStatusColor(
                                    item?.projectStatusName
                                ),
                            },
                        ]}
                        actions={
                            item.creatorId !== userId
                                ? []
                                : [
                                      {
                                          handleAction: handleEdit,
                                          icon: ICONS.EDIT,
                                          iconColor: COLORS.PRIMARY,
                                          tooltip: 'button.editProject',
                                      },
                                  ]
                        }
                        cardContainerLink={`${
                            pathname.includes(ROUTES.MY_DIVE_PROJECTS)
                                ? ROUTES.MY_DIVE_PROJECTS
                                : ROUTES.PROJECT
                        }/${item.id}`}
                    >
                        <Info
                            label="form.label.contractor"
                            value={item?.companyName}
                            companyID={item?.companyProfileHash}
                            valueType={INFO_VALUE_TYPE.CONTRACTOR_LINK}
                        />
                        <Info
                            label="form.label.divingMode"
                            value={item?.divingModeName}
                            color="orange"
                        />
                        {(item?.fromDate || item?.durationTo) && (
                            <InfoRow>
                                {item?.fromDate && (
                                    <Info
                                        label="form.label.projectStart"
                                        value={formatDate(item?.fromDate)}
                                    />
                                )}
                                {item?.durationTo && (
                                    <Info
                                        label="form.label.expectedDuration"
                                        value={item?.durationTo}
                                    />
                                )}
                            </InfoRow>
                        )}
                        <InfoRow withButtons>
                            <Info
                                label="form.label.countryOfWork"
                                value={item?.countryName}
                            />
                            <div>
                                <Button
                                    label="button.viewProject"
                                    buttonSize={BUTTON_SIZE.SMALL}
                                    onClick={() =>
                                        handleOpenSingleProject(item)
                                    }
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.ARROW_RIGHT}
                                    iconColor={COLORS.SECONDARY}
                                    iconPosition={ICON_POSITION.RIGHT}
                                />
                            </div>
                        </InfoRow>
                    </CardContainer>
                </div>
            ))}
            {data.length === 0 && Object.keys(activeFilters).length !== 0 && (
                <span className="a-captionsTextRegular a-lightText justify-center">
                    {t('general.noFilteredProjects')}
                </span>
            )}
            {showEmptyValueMessage && (
                <>
                    <span className="a-captionsTextRegular a-lightText justify-center">
                        {noProjectsText}
                    </span>
                    <div className="justify-center-button">
                        {myProfile && !isDiver && (
                            <AddNew
                                handleAction={handleGoToCreateProject}
                                label="button.addDiveProject"
                            />
                        )}
                    </div>
                </>
            )}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {renderLoader(isLoading, profilePage, isLoadingFilters)}
        </div>
    )
}

ProjectList.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    companyID: PropTypes.number,
    projectStatus: PropTypes.array,
    myProfile: PropTypes.bool,
}

ProjectList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default ProjectList
