import { Fragment, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import useFetchData from 'hooks/useFetchData'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import { renderNoDataText } from '../utils/noDataHelper'

import ENTITIES from 'constants/entities'

import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'
import AddNew from '../AddNew'
import CreateTravelDocument from './components/CreateTravelDocument'
import TravelDocuments from './components/TravelDocuments'
import FieldError from '../../../../components/FieldError'

const TravelDocumentsOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    myProfile,
    profilePage,
    refetchData,
    hideFiles,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const [create, setCreate] = useState(
        location.state?.travelDocument || false
    )

    //NOTE: When navigated from profile page update button to the same tab you are already
    useEffect(() => {
        location.state?.travelDocument &&
            setCreate(location.state?.travelDocument)
    }, [location.state?.travelDocument])

    const [error, setError] = useState('')

    const showActions = myProfile || !profilePage

    const { data, isLoading, fetchData } = useFetchData(
        ENTITIES.TRAVEL_DOCUMENTATION,
        {
            include:
                'files,travelDocumentationType,countryIssue,visas,recordInformations,visas.country,visas.files,recordInformations.files',
            'user.id': userID,
        },
        true,
        false
    )

    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleOpenModal = () => setCreate(true)

    const hasRequiredDocuments = useMemo(() => {
        return data.some(
            (item) =>
                item.travelDocumentationType?.id === 1 ||
                item.travelDocumentationType?.id === 2
        )
    }, [data])

    useEffect(() => {
        hasRequiredDocuments && setError('')
    }, [hasRequiredDocuments])

    const handleGoToNext = () => {
        if (hasRequiredDocuments) {
            setError('')
            goToNextStep()
        } else {
            setError(t('message.noRequiredDocuments'))
        }
    }

    return (
        <Fragment>
            <div className={profilePage ? 'm-profileTabs' : '-contentElements'}>
                {profilePage ? (
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.travelDocumentation')}
                        </span>
                    </div>
                ) : (
                    <Fragment>
                        <h2 className="-mt35 _12 a-centeredText">
                            {t('general.travelDocumentation')}
                        </h2>
                        <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                            {t('general.provideTravelDocumentation')}
                        </p>
                    </Fragment>
                )}
                {hideFiles && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.noAccessToFiles')}
                    </p>
                )}
                {showActions && (
                    <div className={!profilePage ? '_12' : ''}>
                        <AddNew
                            handleAction={handleOpenModal}
                            label="general.addDocument"
                        />

                        {error && <FieldError error={error} />}
                    </div>
                )}

                {create && (
                    <CreateTravelDocument
                        open={create}
                        setOpen={setCreate}
                        fetchData={fetchData}
                        showActions={showActions}
                        refetchData={refetchData}
                    />
                )}

                <TravelDocuments
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    showActions={showActions}
                    profilePage={profilePage}
                    refetchData={refetchData}
                />
            </div>

            {!profilePage && (
                <div className="_wr">
                    <StepButtons
                        handleBack={goPreviousStep}
                        handleNext={handleGoToNext}
                    />
                </div>
            )}

            {showEmptyValueMessage &&
                renderNoDataText(
                    data,
                    isLoading,
                    t('general.noTravelDocumentation')
                )}

            {isLoading && data.length > 0 && <Loader />}
        </Fragment>
    )
}

TravelDocumentsOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
    hideFiles: PropTypes.bool,
}

TravelDocumentsOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
    hideFiles: false,
}

export default TravelDocumentsOnboarding
