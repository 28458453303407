import { PropTypes } from 'prop-types'

import BADGE from 'constants/badges'

import {
    AirDivingSupervisorBadge,
    BellSaturationSupervisorBadge,
    CertifiedNdtInspectionBadge,
    CivilEngineeringBadge,
    ConstructionDiverBadge,
    DiverMedicBadge,
    NumberOfDivesBadge,
    NumberOfDivesBronzeBadge,
    NumberOfDivesGoldBadge,
    NumberOfDivesPlatinumBadge,
    NumberOfDivesSilverBadge,
    OilGasIndustryBadge,
    RenewableEnergyBadge,
    SaturationBellDivingBadge,
    ScubaDivingBadge,
    SurfaceSuppliedBadge,
    VerifiedBadge,
} from './badges'

const BADGES = {
    [BADGE.VERIFIED]: VerifiedBadge,
    [BADGE.NUMBER_OF_DIVES]: NumberOfDivesBadge,
    [BADGE.NUMBER_OF_DIVES_BRONZE]: NumberOfDivesBronzeBadge,
    [BADGE.NUMBER_OF_DIVES_SILVER]: NumberOfDivesSilverBadge,
    [BADGE.NUMBER_OF_DIVES_GOLD]: NumberOfDivesGoldBadge,
    [BADGE.NUMBER_OF_DIVES_PLATINUM]: NumberOfDivesPlatinumBadge,
    [BADGE.SCUBA_DIVING]: ScubaDivingBadge,
    [BADGE.SURFACE_SUPPLIED]: SurfaceSuppliedBadge,
    [BADGE.SATURATION_BELL_DIVING]: SaturationBellDivingBadge,
    [BADGE.AIR_DIVING_SUPERVISOR]: AirDivingSupervisorBadge,
    [BADGE.BELL_SATURATION_SUPERVISOR]: BellSaturationSupervisorBadge,
    [BADGE.DIVER_MEDIC]: DiverMedicBadge,
    [BADGE.OIL_GAS_INDUSTRY]: OilGasIndustryBadge,
    [BADGE.RENEWABLE_ENERGY]: RenewableEnergyBadge,
    [BADGE.CIVIL_ENGINEERING]: CivilEngineeringBadge,
    [BADGE.CERTIFIED_NDT_INSPECTION]: CertifiedNdtInspectionBadge,
    [BADGE.CONSTRUCTION_DIVER]: ConstructionDiverBadge,
}

const Badge = ({ badgeKey, additionalBadgeKey, color }) => {
    const BadgeComponent = BADGES[additionalBadgeKey || badgeKey]

    if (!BadgeComponent) return null

    return <BadgeComponent color={color} />
}

Badge.propTypes = {
    badgeKey: PropTypes.string.isRequired,
    additionalBadgeKey: PropTypes.string,
}

export default Badge
