import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { AlertContext } from 'contexts/AlertContext'
import { NotificationContext } from 'contexts/NotificationContext'

import { editService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'

import Dropdown from 'components/Dropdown'
import IconButton from 'components/IconButton'
import NotificationItem from './NotificationItem'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'

const NotificationsDropdown = ({ open, setOpen }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const { setAlert } = useContext(AlertContext)
    const {
        notifications,
        fetchNotifications,
        meta,
        isLoadingNotifications,
        loadMoreNotifications,
        fetchUnreadNotifications,
    } = useContext(NotificationContext)

    const [displayNotifications, setDisplayNotifications] = useState([])

    const handleOpenNotifications = () => {
        setOpen(!open)
    }

    const handleReadAll = async () => {
        if (!!meta?.totalNumberOfUnread) {
            try {
                await editService(ENTITIES.MARK_READ, { seen: true })
                fetchNotifications()
                fetchUnreadNotifications()

                const notificationsCopy = [...displayNotifications]

                notificationsCopy.forEach(
                    (notification) => (notification.seen = true)
                )

                setDisplayNotifications(notificationsCopy)
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        }
    }

    // NOTE:: for later
    // const handleOpenSettings = () => {
    //     console.log('Handle open settings')
    // }

    const handleLoadMore = () => {
        if (!isLoadingNotifications && loadMoreNotifications) {
            fetchNotifications({
                page: meta?.currentPage + 1,
            })
        }
    }

    useEffect(() => {
        setDisplayNotifications([
            ...displayNotifications,
            ...notifications?.filter(
                (item) => !displayNotifications.some((el) => el.id === item.id)
            ),
        ])
    }, [notifications])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!open) {
                setDisplayNotifications([])
            }
        }, 3000)

        if (open) {
            setDisplayNotifications(notifications)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [open, notifications])

    useEffect(() => {
        if (open && isTablet) {
            document.body.style.overflow = 'hidden'
            return () => {
                document.body.style.overflow = 'unset'
            }
        }
    }, [open])

    return (
        <div className="-dropdownWrapper">
            <IconButton
                icon={
                    meta?.totalNumberOfUnread > 0
                        ? ICONS.NOTIFICATIONS
                        : ICONS.NOTIFICATIONS_NO_CIRCLE
                }
                size={ICON_SIZE.SIZE24}
                onClick={handleOpenNotifications}
            />

            <Dropdown
                open={open}
                notificationDropdown
                handleReachedBottom={handleLoadMore}
            >
                <div className="m-dropdown__heading">
                    <span className="a-mediumTextSemiBold">{`${t(
                        'general.notifications'
                    )} (${meta?.totalNumberOfUnread})`}</span>
                    <div>
                        <span
                            onClick={handleReadAll}
                            className={`a-captionsTextRegular -pointer ${
                                !!meta?.totalNumberOfUnread ? '' : '-disabled'
                            }`}
                        >
                            {t('general.markAllAsRead')}
                        </span>
                        {/* NOTE: hidden for now  */}
                        {/* <IconButton
                            icon={ICONS.SETTINGS}
                            size={ICON_SIZE.SIZE22}
                            onClick={handleOpenSettings}
                        /> */}
                    </div>
                </div>

                {displayNotifications.length > 0 ? (
                    <NotificationItem
                        displayNotifications={displayNotifications}
                        setDisplayNotifications={setDisplayNotifications}
                        handleOpenNotifications={handleOpenNotifications}
                    />
                ) : (
                    <span className="a-captionsTextRegular a-lightText justify-center -mb20">
                        {t('general.noNotifications')}
                    </span>
                )}
            </Dropdown>
        </div>
    )
}

NotificationsDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
}

export default clickOutsideHOC(NotificationsDropdown)
