import React from 'react'

const CivilEngineeringBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>Civil Engineering Diving</title>
            <circle cx="25" cy="25" r="23" fill="#171E3C" />
            <g filter="url(#filter0_i_21877_21035)">
                <circle cx="25" cy="24.9999" r="19.4615" fill="#F3F4F5" />
            </g>
            <path
                d="M21.6752 17.2555C21.6752 15.783 22.9106 15.4462 24.4014 15.4462H25.5984C27.0887 15.4462 28.3241 15.783 28.3241 17.2555L28.2973 21.7097C28.2973 21.7156 28.2972 21.7214 28.2972 21.7272V26.5639C28.2972 27.3404 28.9346 27.9701 29.7208 27.9701C30.5074 27.9701 31.1448 27.3404 31.1448 26.5639V18.6091C34.3227 20.7204 36.4314 24.4182 36.4314 28.6308C36.4314 28.9306 36.417 29.2271 36.3962 29.5217H13.6035C13.5822 29.2271 13.5683 28.9306 13.5683 28.6308C13.5683 24.4182 15.677 20.7204 18.8549 18.6091V26.5639C18.8549 27.3404 19.4923 27.9701 20.2784 27.9701C21.065 27.9701 21.7024 27.3404 21.7024 26.5639V21.7004L21.6752 17.2555Z"
                fill="#171E3C"
            />
            <path
                d="M37.6613 31.7328H12.3384C11.7283 31.7328 11.2335 31.2441 11.2335 30.6416V30.6126C11.2335 30.01 11.7283 29.5217 12.3384 29.5217H37.6613C38.2714 29.5217 38.7657 30.01 38.7657 30.6126V30.6416C38.7657 31.2441 38.2714 31.7328 37.6613 31.7328Z"
                fill="#171E3C"
            />
            <path
                d="M18.5819 18.7422L18.6061 18.7215C19.4746 17.9771 20.5472 17.5113 21.6841 17.385V26.6917C21.6841 27.5483 20.9897 28.2428 20.133 28.2428C19.2764 28.2428 18.5819 27.5483 18.5819 26.6916V18.7422Z"
                fill="#D1D2D8"
            />
            <path
                d="M31.4368 18.7422L31.4126 18.7215C30.5441 17.9771 29.4715 17.5113 28.3346 17.385V26.6917C28.3346 27.5483 29.029 28.2428 29.8857 28.2428C30.7423 28.2428 31.4368 27.5483 31.4368 26.6916V18.7422Z"
                fill="#D1D2D8"
            />
            <rect
                x="10.8459"
                y="29.4061"
                width="28.3077"
                height="2.32666"
                rx="1.16333"
                fill="#D1D2D8"
            />
            <defs>
                <filter
                    id="filter0_i_21877_21035"
                    x="5.53845"
                    y="5.53833"
                    width="39.2769"
                    height="39.2769"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.176923" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21035"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default CivilEngineeringBadge
