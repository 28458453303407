import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'

import renderLoader from '../../utils/loaderHelper'

import AddNew from '../../AddNew'
import SkillRecordManage from './SkillRecordManage'
import SkillRecordList from './SkillRecordList'

const SkillRecordWrapper = ({
    data,
    isLoading,
    fetchData,
    title,
    profilePage,
    showActions,
    noDataText,
    refetchData,
}) => {
    const t = useTranslate()

    const [createSkill, setCreateSkill] = useState(false)

    return (
        <>
            <h2 className="a-bodyTextMedium">{title && t(title)}</h2>

            {showActions && (
                <AddNew
                    handleAction={() => setCreateSkill(true)}
                    label="general.addSkills"
                    disabled={data && data.length > 0}
                />
            )}

            {createSkill && (
                <SkillRecordManage
                    data={data}
                    open={createSkill}
                    setOpen={setCreateSkill}
                    fetchData={fetchData}
                    showActions={showActions}
                    refetchData={refetchData}
                />
            )}

            <SkillRecordList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                profilePage={profilePage}
                showActions={showActions}
                noDataText={noDataText}
                refetchData={refetchData}
            />

            {renderLoader(isLoading, profilePage)}
        </>
    )
}

SkillRecordWrapper.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    title: PropTypes.string,
    noDataText: PropTypes.string,
    refetchData: PropTypes.func,
}

SkillRecordWrapper.defaultProps = {
    profilePage: false,
    showActions: false,
    title: '',
}

export default SkillRecordWrapper
