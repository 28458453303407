import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import useEmptyValueMessage from 'hooks/useEmptyValueMessage'
import useFetchData from 'hooks/useFetchData'

import { renderNoDataText } from '../utils/noDataHelper'

import ENTITIES from 'constants/entities'
import { MEDICAL_RECORD_TYPE } from '../constants'

import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'
import AddNew from '../AddNew'
import CreateMedicalRecord from './CreateMedicalRecord'
import MedicalRecords from './MedicalRecords'

const MedicalInfoOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    myProfile,
    profilePage,
    refetchData,
    hideFiles,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const [create, setCreate] = useState(location.state?.medicalInfo || false)

    //NOTE: When navigated from profile page update button to the same tab you are already
    useEffect(() => {
        location.state?.medicalInfo && setCreate(location.state?.medicalInfo)
    }, [location.state?.medicalInfo])

    const showActions = myProfile || !profilePage

    const { data, isLoading, fetchData } = useFetchData(
        ENTITIES.MEDICAL_RECORD,
        {
            include: 'medicalRecordType,files,medicalExam,medicalExamStandard',
            'user.id': userID,
        },
        true,
        false
    )

    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const dataLength = data.length

    const annuals = data.filter(
        (item) => item.medicalRecordType.id === MEDICAL_RECORD_TYPE.ANNUAL.id
    )

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleOpenModal = () => setCreate(true)

    return (
        <Fragment>
            <div className={profilePage ? 'm-profileTabs' : '-contentElements'}>
                {profilePage ? (
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.medicalInformation')}
                        </span>
                    </div>
                ) : (
                    <Fragment>
                        <h2 className="-mt35 _12 a-centeredText">
                            {t('general.medicalInfo')}
                        </h2>
                        <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                            {t('general.provideExistingMedicalRecords')}
                        </p>
                    </Fragment>
                )}
                {hideFiles && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.noAccessToFiles')}
                    </p>
                )}
                {showActions && (
                    <div className={!profilePage ? '_12' : ''}>
                        <AddNew
                            handleAction={handleOpenModal}
                            label="general.addNewMedicalRecord"
                        />
                    </div>
                )}

                {create && (
                    <CreateMedicalRecord
                        open={create}
                        setOpen={setCreate}
                        fetchMedicalRecords={fetchData}
                        filteredData={annuals}
                        refetchData={refetchData}
                    />
                )}

                <MedicalRecords
                    data={data}
                    isLoading={isLoading}
                    fetchMedicalRecords={fetchData}
                    showActions={showActions}
                    profilePage={profilePage}
                    refetchData={refetchData}
                    filteredData={annuals}
                />
            </div>

            {!profilePage && (
                <div className="_wr">
                    <StepButtons
                        handleBack={goPreviousStep}
                        handleNext={goToNextStep}
                        handleSkip={goToNextStep}
                        canSkip={!dataLength}
                    />
                </div>
            )}

            {showEmptyValueMessage &&
                renderNoDataText(data, isLoading, t('general.noMedicalInfo'))}

            {isLoading && dataLength > 0 && <Loader />}
        </Fragment>
    )
}

MedicalInfoOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
    hideFiles: PropTypes.bool,
}

MedicalInfoOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
    hideFiles: false,
}

export default MedicalInfoOnboarding
