import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { editEntityService } from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import { SelectField } from 'components/formFields'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { UNITS_OF_MEASUREMENT } from 'constants/constants'
import FocusError from '../../../../components/FocusError'

const UnitOfMeasurementForm = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { projectId } = useParams()

    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        unitImperial: null,
    }

    const validation = Yup.object({
        unitImperial: Yup.object().required(t('form.error.required')),
    })

    const handleSubmit = async ({ unitImperial }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await editEntityService(
                ENTITIES.USER_SETTING,
                currentUser.userSetting.id,
                { unitImperial: Boolean(unitImperial.id) }
            )
            fetchCurrentUser()
            window.location.reload()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    // NOTE:: issue with go back and cancel button navigation
    const handleCancel = () => {
        projectId
            ? navigate(`${ROUTES.PROJECT}/${projectId}`)
            : navigate(`/${ROUTES.DIVE_RECORDS}`)
    }

    return (
        <Modal closeOnClickOutside={false} type="-confirmModal -smallModal">
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className="-modalElements">
                        <FocusError />
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <h3 className="_12 a-centeredText -headerTitle">
                                    {t('general.selectUnitsOfMeasurement')}
                                </h3>
                                <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                                    {t('general.unitsOfMeasurementDescription')}
                                </p>
                                <div className="_12 -mt30">
                                    <SelectField
                                        name="unitImperial"
                                        label="form.label.unitImperial"
                                        placeholder="form.placeholder.pleaseSelect"
                                        defaultOptions={UNITS_OF_MEASUREMENT}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w -buttons -mt10">
                                <div className="_12 _xs6 -mt10">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.cancel"
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        onClick={handleCancel}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                <div className="_12 _xs6 -mt10">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        buttonSize={BUTTON_SIZE.LARGE}
                                        label="button.save"
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default UnitOfMeasurementForm
