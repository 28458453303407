import React from 'react'

const BellSaturationSupervisorBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>Bell/Saturation Supervisor</title>
            <path
                d="M5.41802 12.5045C5.41985 12.1735 5.59226 11.8663 5.87562 11.693L25 0V50C18.7063 46.4789 11.5735 40.5634 8.21684 33.8028C5.25128 27.8299 5.34241 19.585 5.4039 14.0216C5.40978 13.49 5.41538 12.9828 5.41802 12.5045Z"
                fill="#A031E4"
            />
            <path
                d="M44.582 12.5045C44.5802 12.1735 44.4078 11.8663 44.1244 11.693L25 0V50C31.2937 46.4789 38.4266 40.5634 41.7832 33.8028C44.7488 27.8299 44.6576 19.585 44.5962 14.0216C44.5903 13.49 44.5847 12.9828 44.582 12.5045Z"
                fill="#A031E4"
            />
            <g filter="url(#filter0_i_21877_21002)">
                <path
                    d="M8.48924 14.4269C8.49077 14.1468 8.63444 13.8869 8.87057 13.7403L24.8076 3.84619V46.1539C19.5628 43.1745 13.6188 38.1691 10.8216 32.4486C8.35029 27.3946 8.42623 20.4181 8.47747 15.7106C8.48237 15.2608 8.48704 14.8317 8.48924 14.4269Z"
                    fill="#F3F4F5"
                />
                <path
                    d="M41.1259 14.4269C41.1244 14.1468 40.9807 13.8869 40.7446 13.7403L24.8076 3.84619V46.1539C30.0523 43.1745 35.9964 38.1691 38.7936 32.4486C41.2649 27.3946 41.1889 20.4181 41.1377 15.7106C41.1328 15.2608 41.1281 14.8317 41.1259 14.4269Z"
                    fill="#F3F4F5"
                />
            </g>
            <path
                d="M19.9301 14.6154L19.056 17.2377H17.3077V27.7272H19.056L19.9301 31.2237L17.3077 33.8461H33.042L30.4196 31.2237L31.2938 27.7272H33.042V17.2377H31.2938L30.4196 14.6154H19.9301Z"
                fill="white"
            />
            <circle
                cx="25.1749"
                cy="22.4825"
                r="1.4967"
                fill="url(#paint0_linear_21877_21002)"
                stroke="#A031E4"
                strokeWidth="0.940171"
            />
            <circle
                cx="19.2746"
                cy="22.4825"
                r="1.4967"
                fill="url(#paint1_linear_21877_21002)"
                stroke="#A031E4"
                strokeWidth="0.940171"
            />
            <circle
                cx="31.0752"
                cy="22.4825"
                r="1.4967"
                fill="url(#paint2_linear_21877_21002)"
                stroke="#A031E4"
                strokeWidth="0.940171"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.056 14.6154V15.4895H19.5931L19.0017 17.2377H17.3077V27.7272H18.7565L19.7915 30.7867H19.056V31.6608H19.4891L18.2021 32.972H17.3077V33.8461H33.042V32.972H32.1819L30.8948 31.6608H31.2938V30.7867H30.5582L31.5932 27.7272H33.042V26.8531H22.5525V18.1119H27.7972V26.8531H28.6714V18.1119H32.1679V26.8531L33.042 26.8531V17.2377L31.3481 17.2377L30.7566 15.4895H31.2938V14.6154H19.056ZM29.8338 15.4895H28.0469L28.6383 17.2377H30.4253L29.8338 15.4895ZM30.6704 27.7272H28.657L27.8644 30.7867H29.6355L30.6704 27.7272ZM29.6699 31.6608H27.899L28.3426 32.972H30.957L29.6699 31.6608ZM27.7342 27.7272L26.9416 30.7867H23.5255L22.7329 27.7272H27.7342ZM21.8101 27.7272L22.6027 30.7867H20.7143L19.6793 27.7272H21.8101ZM19.4269 32.972L20.714 31.6608H22.5681L22.1246 32.972H19.4269ZM23.0473 32.972L23.4909 31.6608H26.9762L27.4198 32.972H23.0473ZM27.7155 17.2377L27.1241 15.4895H23.343L22.7516 17.2377H27.7155ZM20.5159 15.4895H22.4203L21.8288 17.2377H19.9245L20.5159 15.4895ZM18.1819 18.1119H21.6784V26.8531H18.1819V18.1119Z"
                fill="#A031E4"
            />
            <defs>
                <filter
                    id="filter0_i_21877_21002"
                    x="8.46143"
                    y="3.84619"
                    width="33.0769"
                    height="42.6924"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.384615" dy="0.384615" />
                    <feGaussianBlur stdDeviation="0.192308" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21002"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_21877_21002"
                    x1="25.1749"
                    y1="20.5157"
                    x2="25.1749"
                    y2="24.4493"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6EAFC" />
                    <stop offset="1" stopColor="#C684EF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_21877_21002"
                    x1="19.2746"
                    y1="20.5157"
                    x2="19.2746"
                    y2="24.4493"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6EAFC" />
                    <stop offset="1" stopColor="#C684EF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_21877_21002"
                    x1="31.0752"
                    y1="20.5157"
                    x2="31.0752"
                    y2="24.4493"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6EAFC" />
                    <stop offset="1" stopColor="#C684EF" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default BellSaturationSupervisorBadge
