import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'

import {
    NO_SKILLS_EXPERIENCE_TEXT,
    renderNoDataText,
} from '../../utils/noDataHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'
import EditExperience from './EditExperience'
import AddExperienceModal from './AddExperienceModal'
// import { EditExperience } from './..'

const ExperienceList = ({
    data,
    fetchData,
    isLoading,
    divingModes,
    profilePage,
    showActions,
    noDataText,
    refetchData,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [edit, setEdit] = useState(null)
    const [openItemForEdit, setOpenItemForEdit] = useState(null)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const showNoDataText =
        noDataText === NO_SKILLS_EXPERIENCE_TEXT.NO_EXPERIENCE

    const handleEdit = (item) => {
        if (item.totalYears || item.totalYears === 0) {
            setOpenItemForEdit(item)
        } else {
            setEdit(item)
        }
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.EXPERIENCE, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteExperience',
            title: 'general.deleteExperience',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    const formatExpItemValue = (expForDivingMode) => {
        const infoItems = {
            sum: {
                label: 'divesSum',
                color: 'purple',
                bottomTimeHoursKey: 'bottomTimeHoursSum',
                bottomTimeMinutesKey: 'bottomTimeMinutesSum',
            },
            air: {
                label: 'divesAir',
                color: 'orange',
                bottomTimeHoursKey: 'bottomTimeHoursAir',
                bottomTimeMinutesKey: 'bottomTimeMinutesAir',
            },
            nitrox: {
                label: 'divesNitrox',
                color: 'orange',
                bottomTimeHoursKey: 'bottomTimeHoursNitrox',
                bottomTimeMinutesKey: 'bottomTimeMinutesNitrox',
            },
            mixedGas: {
                label: 'divesMixedGas',
                color: 'orange',
                bottomTimeHoursKey: 'bottomTimeHoursMixedGas',
                bottomTimeMinutesKey: 'bottomTimeMinutesMixedGas',
            },
            closedBellSaturation: {
                label: 'daysBell',
                additionalLabel: 'bellRuns',
                color: 'purple',
                bottomTimeHoursKey: 'bottomTimeHoursBell',
                bottomTimeMinutesKey: 'bottomTimeMinutesBell',
            },
        }

        const infoKeys = Object.keys(infoItems)

        // Filter the keys with valid textItems
        const validKeys = infoKeys.filter((key) => {
            const { label, additionalLabel } = infoItems[key]
            return expForDivingMode[label] || expForDivingMode[additionalLabel]
        })

        return (
            <div>
                {validKeys.map((key, index) => {
                    const {
                        label,
                        additionalLabel,
                        color,
                        bottomTimeHoursKey,
                        bottomTimeMinutesKey,
                    } = infoItems[key]

                    const textItems = []

                    if (
                        expForDivingMode[label] ||
                        expForDivingMode[additionalLabel]
                    ) {
                        if (expForDivingMode[label]) {
                            textItems.push(
                                <span
                                    key={`${key}-label`}
                                    className="-darkerText"
                                >
                                    {expForDivingMode[label]}
                                </span>,
                                ` ${t(`form.label.${label}`)}`
                            )
                        }

                        if (expForDivingMode[additionalLabel]) {
                            if (textItems.length > 0) {
                                textItems.push(' | ')
                            }
                            textItems.push(
                                <span
                                    key={`${key}-additionalLabel`}
                                    className="-darkerText"
                                >
                                    {expForDivingMode[additionalLabel]}
                                </span>,
                                ` ${t(`form.label.${additionalLabel}`)}`
                            )
                        }

                        const hours =
                            expForDivingMode[bottomTimeHoursKey] || '0'
                        const minutes =
                            expForDivingMode[bottomTimeMinutesKey] || '0'

                        const h = (
                            <span key={`${key}-hours`} className="-darkerText">
                                {hours !== '0' ? `${hours}h ` : '0h '}
                            </span>
                        )
                        const m = (
                            <span
                                key={`${key}-minutes`}
                                className="-darkerText"
                            >
                                {minutes !== '0' ? `${minutes}m ` : '0min '}
                            </span>
                        )

                        if (textItems.length > 0) {
                            textItems.push(' | ')
                        }
                        textItems.push(
                            <span key={`${key}-bottomTime`}>
                                {h}
                                {m}
                                {t('general.bottomTime')}
                            </span>
                        )
                    }

                    return (
                        textItems.length > 0 && (
                            <Info
                                key={`${expForDivingMode.divingMode.id}-${key}`}
                                value={<>{textItems}</>}
                                translate={true}
                                color={color}
                                boxWithSeparators
                            />
                        )
                    )
                })}
            </div>
        )
    }

    return (
        <Fragment>
            {data &&
                !!data.length &&
                data.map((item) => (
                    <div key={item.id} className="12 -mt20">
                        <CardContainer
                            title={
                                item.divingMode
                                    ? item.divingMode.name
                                    : t('general.experience')
                            }
                            item={item}
                            actions={
                                showActions
                                    ? [
                                          {
                                              handleAction: handleEdit,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.PRIMARY,
                                              tooltip: 'button.editExperience',
                                          },
                                          {
                                              handleAction: handleDelete,
                                              icon: ICONS.DELETE,
                                              iconColor: COLORS.RED,
                                              tooltip:
                                                  'button.deleteExperience',
                                          },
                                      ]
                                    : []
                            }
                        >
                            {(item.totalYears || item.totalYears === 0) && (
                                <Info
                                    label="general.experienceTotalYears"
                                    value={item.totalYears}
                                />
                            )}
                            {!item.totalYears && formatExpItemValue(item)}
                        </CardContainer>
                    </div>
                ))}

            {!!edit && edit.divingMode ? (
                <EditExperience
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    divingModes={divingModes}
                    showActions={showActions}
                    refetchData={refetchData}
                    divingMode={edit.divingMode}
                />
            ) : (
                <AddExperienceModal
                    open={!!openItemForEdit}
                    setOpen={setOpenItemForEdit}
                    editMode={true}
                    itemForEdit={openItemForEdit}
                    fetchData={fetchData}
                />
            )}

            {!showActions &&
                showNoDataText &&
                renderNoDataText(data, isLoading, t('general.noExperience'))}
        </Fragment>
    )
}

ExperienceList.propTypes = {
    showActions: PropTypes.bool,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    divingModes: PropTypes.array,
    noDataText: PropTypes.string,
    refetchData: PropTypes.func,
}

ExperienceList.defaultProps = {
    showActions: false,
}

export default ExperienceList
