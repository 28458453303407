import AlertContextProvider from './AlertContext'
import ConfirmationModalContextProvider from './ConfirmationModalContext'
import CurrentUserContextProvider from './CurrentUserContext'
import ImagePreviewModalContextProvider from './ImagePreviewModalContext'
import NotificationContextProvider from './NotificationContext'
import TranslationContextProvider from './TranslationContext'

const compose = (contexts, children) =>
    contexts.reduceRight((acc, ContextProvider) => {
        return <ContextProvider>{acc}</ContextProvider>
    }, children)

const AppContext = ({ children }) => {
    return compose(
        [
            AlertContextProvider,
            ConfirmationModalContextProvider,
            ImagePreviewModalContextProvider,
            CurrentUserContextProvider,
            NotificationContextProvider,
            TranslationContextProvider,
        ],
        children
    )
}

export default AppContext
