import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import useWindowDimensions from 'hooks/useWindowDimension'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
    POSITIONS,
    PROJECT_POSITION_ACTIONS,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import Icon from 'components/Icon'

import diverImg from 'assets/images/Diver.png'
import divingSupervisorImg from 'assets/images/Dive medic.png'
import diveMedicImg from 'assets/images/Dive medic (1).png'
import equipmentTechnician from 'assets/images/Equipment techinician.png'
import lifeSupportImg from 'assets/images/Life support.png'

const DiverPositionCard = ({
    data,
    buttonActions,
    handleApply,
    handleReapply,
    handleAccept,
    handleReject,
    handleWithdraw,
}) => {
    const t = useTranslate()

    const { isSmallerThanXXlScreen } = useWindowDimensions()

    const { hash } = useLocation()

    const activePositionLink = hash.replace('#', '')

    useEffect(() => {
        const activePositionCard = document.querySelectorAll(
            '.a-positionCard.-active'
        )

        activePositionCard[0]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }, [])

    const {
        diverPositionName,
        numOfOpenPositions,
        positionFromPayRate,
        positionToPayRate,
        projectPositionDescription,
        requiredSkills,
        id,
        statusName
    } = data

    const getImage = (position) => {
        switch (position) {
            case POSITIONS.DIVER.name:
                return diverImg
            case POSITIONS.DIVING_SUPERVISOR.name:
                return divingSupervisorImg
            case POSITIONS.DIVING_MEDIC.name:
                return diveMedicImg
            case POSITIONS.EQUIPMENT_TECHNICIAN.name:
                return equipmentTechnician
            case POSITIONS.LIFE_SUPPORT.name:
                return lifeSupportImg
            default:
                break
        }
    }

    const formatLabel = (buttonAction) => {
        switch (buttonAction) {
            case PROJECT_POSITION_ACTIONS.APPLY:
                return 'button.applyForPosition'
            case PROJECT_POSITION_ACTIONS.ACCEPT:
                return 'button.accept'
            case PROJECT_POSITION_ACTIONS.REJECT:
                return 'button.reject'
            case PROJECT_POSITION_ACTIONS.WITHDRAW:
                return 'button.withdraw'
            default:
                break
        }
    }

    const getButtonActions = (buttonActions, numOfOpenPositions) => {

        if(!buttonActions.length && numOfOpenPositions === 0) {
            return (<span className="a-positionFilled">
                            {t('general.positionFilled')}
                        </span>);
        }

        return buttonActions.map((buttonAction, index) => {
            switch (buttonAction) {
                case PROJECT_POSITION_ACTIONS.ACCEPT:
                    return (
                        <div key={index} className="a-positionCard__button">
                            <Button
                                buttonSize={BUTTON_SIZE.MEDIUM}
                                label={formatLabel(buttonAction)}
                                onClick={handleAccept}
                            />
                        </div>
                    )
                case PROJECT_POSITION_ACTIONS.REJECT:
                    return (
                        <div
                            key={index}
                            className="a-positionCard__button -firstButton"
                        >
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                                label={formatLabel(buttonAction)}
                                onClick={handleReject}
                            />
                        </div>
                    )
                case PROJECT_POSITION_ACTIONS.APPLY:
                    return (
                        <div key={index} className="a-positionCard__button">
                            <Button
                                buttonSize={BUTTON_SIZE.MEDIUM}
                                label={formatLabel(buttonAction)}
                                onClick={handleApply}
                            />
                        </div>
                    )
                case PROJECT_POSITION_ACTIONS.REAPPLY:
                    return (
                        <div key={index} className="a-positionCard__button">
                            <Button
                                buttonSize={BUTTON_SIZE.SMALL}
                                label='button.reapply'
                                onClick={handleReapply}
                            />
                        </div>
                    )
                case PROJECT_POSITION_ACTIONS.WITHDRAW:
                    return (
                        <div key={index} className="a-positionCard__button">
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                                label={formatLabel(buttonAction)}
                                onClick={handleWithdraw}
                            />
                        </div>
                    )
                case PROJECT_POSITION_ACTIONS.APPROVED_INVITE:
                    return (
                        <span key={index} className="a-approved">
                            {statusName ? statusName : t('general.accepted')}
                        </span>
                    )
                case PROJECT_POSITION_ACTIONS.PERSON_EMPLOYED:
                    return (
                        <span key={index} className="a-approved">
                            {t('general.employed')}
                        </span>
                    )
                case PROJECT_POSITION_ACTIONS.DECLINED_INVITE:
                    return (
                        <span key={index} className="a-declined">
                            {t('general.rejected')}
                        </span>
                    )
                default:
                    break
            }
        })
    }

    return (
        <div
            className={`a-positionCard -mb10 ${
                activePositionLink == id ? '-active' : ''
            }`}
        >
            <div className="a-positionCard__image">
                <img
                    alt={diverPositionName}
                    src={getImage(diverPositionName)}
                />
            </div>
            <div className="a-positionCard__content">
                <div className="-titleAndImage">
                    <span className="a-bodyTextMedium -title">
                        {diverPositionName + ' (' + numOfOpenPositions + ')'}
                    </span>
                    {!isSmallerThanXXlScreen && buttonActions && (
                        <div className="-buttons">
                            {getButtonActions(buttonActions, numOfOpenPositions)}
                        </div>
                    )}
                </div>
                {(positionFromPayRate !== undefined ||
                    positionToPayRate !== undefined) && (
                    <div className="-mt10 -payRate aligned-center">
                        <Icon
                            name={ICONS.WALLET}
                            size={ICON_SIZE.SIZE20}
                            color={COLORS.DARK_BLUE_60}
                        />
                        <span className="a-mediumText -infoValue">
                            {(positionFromPayRate && !positionToPayRate
                                ? 'from '
                                : '') +
                                (positionFromPayRate || 0) +
                                (positionToPayRate ? '-' : '') +
                                (positionToPayRate || '') +
                                '$'}
                        </span>
                        <span className="a-mediumText -infoLabel">
                            {t('general.perDay')}
                        </span>
                    </div>
                )}
                {projectPositionDescription && (
                    <span className="a-mediumText -infoValue -mt10 a-lightText -opacity-60 -descriptionText">
                        {projectPositionDescription}
                    </span>
                )}
                <div className="-values -mt5">
                    {requiredSkills?.map((value, index) => {
                        return (
                            <div
                                key={index}
                                className="-mt5 a-status -orange a-captionsTextRegular"
                            >
                                {value}
                            </div>
                        )
                    })}
                </div>
            </div>
            {isSmallerThanXXlScreen && buttonActions && (
                <div className="-buttons">
                    {getButtonActions(buttonActions, numOfOpenPositions)}
                </div>
            )}
        </div>
    )
}

DiverPositionCard.propTypes = {
    data: PropTypes.object,
    handleApply: PropTypes.func,
    handleReapply: PropTypes.func,
    handleAccept: PropTypes.func,
    handleReject: PropTypes.func,
    handleWithdraw: PropTypes.func,
    buttonActions: PropTypes.array,
}

export default DiverPositionCard
