import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Banner from 'components/Banner'
import Breadcrumbs from 'components/Breadcrumbs'
import DiveRecordList from '../../common/diveRecordList/DiveRecordList'
import CreateDiveRecordModal from 'screens/common/CreateDiveRecordModal'
import { ProjectListSidebar } from 'components/projects'

const MyDiveRecords = () => {
    const t = useTranslate()

    const { currentUser } = useContext(CurrentUserContext)
    const { isSmallScreen } = useWindowDimensions()

    if (!currentUser) return null

    return (
        <div>
            <div className="m-boxes">
                <div className="m-boxes__breadcrumbs -sectionOrderSmallDevices">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.myDiveRecords',
                                to: `/${ROUTES.DIVE_RECORDS}`,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                            },
                        ]}
                    />
                </div>
                <div className="m-boxes__main">
                    <DiveRecordList />
                </div>
                <div
                    className={`m-boxes__side -button -sectionOrderSmallDevices ${
                        isSmallScreen ? '-mt0' : ''
                    }`}
                >
                    <div
                        className={`m-boxes__side ${
                            isSmallScreen ? '-mt0' : 0
                        }`}
                    >
                        <h3 className="-mb20">
                            {t('general.createDiveRecordForPastProject')}
                        </h3>

                        <CreateDiveRecordModal
                            route={`/${ROUTES.DIVE_RECORDS}`}
                        />
                    </div>
                    <div
                        className={`m-boxes__side -projectListSidebar ${
                            isSmallScreen ? '-mt0 -mb0' : ''
                        }`}
                    >
                        <h3 className="-mb10">
                            {t('general.yourActiveProjects')}
                        </h3>
                        <ProjectListSidebar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyDiveRecords
