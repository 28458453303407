import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import FieldError from './FieldError'

const StepButtons = ({
    backlabel,
    handleBack,
    nextlabel,
    handleNext,
    canSkip,
    skipLabel,
    handleSkip,
    children,
    disableNext,
    error,
    errorMessage,
}) => {
    const t = useTranslate()

    return (
        <div className="_w">
            <div
                className={`space-between -mt40 m-step__buttons _12 -pb20 ${
                    error ? '-withError' : ''
                }`}
            >
                {error && (
                    <div className="-mb20 -errorMesage">
                        <FieldError
                            error={t(errorMessage)}
                            className={'-boxError'}
                        />
                    </div>
                )}
                <div className="-backButton">
                    <Button
                        btnClass={BUTTON_STATUS.SECONDARY}
                        type={BUTTON_TYPE.BUTTON}
                        label={backlabel}
                        onClick={handleBack}
                        buttonSize={BUTTON_SIZE.LARGE}
                    />
                </div>
                {canSkip && (
                    <div className="-skipButton">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            type={BUTTON_TYPE.BUTTON}
                            label={skipLabel}
                            icon={ICONS.ARROW_RIGHT}
                            iconPosition={ICON_POSITION.RIGHT}
                            iconColor={COLORS.SECONDARY}
                            onClick={handleSkip}
                            buttonSize={BUTTON_SIZE.LARGE}
                        />
                    </div>
                )}
                <div className="-nextButton">
                    {children}
                    <Button
                        btnClass={BUTTON_STATUS.PRIMARY}
                        type={BUTTON_TYPE.BUTTON}
                        label={nextlabel}
                        onClick={handleNext}
                        buttonSize={BUTTON_SIZE.LARGE}
                        disabled={disableNext}
                    />
                </div>
            </div>
        </div>
    )
}

StepButtons.propTypes = {
    backlabel: PropTypes.string,
    handleBack: PropTypes.func,
    nextlabel: PropTypes.string,
    handleNext: PropTypes.func,
    canSkip: PropTypes.bool,
    skipLabel: PropTypes.string,
    handleSkip: PropTypes.func,
    disableNext: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
}

StepButtons.defaultProps = {
    backlabel: 'button.back',
    nextlabel: 'button.next',
    skipLabel: 'button.skipStep',
    canSkip: false,
    disableNext: false,
    error: false,
    errorMessage: 'form.error.addLeastOneEvent',
}

export default StepButtons
