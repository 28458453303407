import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchData from 'hooks/useFetchData'

import BADGE from 'constants/badges'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'

import About from './About'
import ContactInfo from './ContactInfo'
import Experience from './Experience'
import Badge from 'components/Badge'
import Icon from 'components/Icon'
import VerificationLevels from './VerificationLevels'

const PersonalInfoCard = ({ user, myProfile, setOpen }) => {
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    const {
        id,
        country,
        email,
        phoneNumber,
        dateOfBirth,
        nationality,
        totalExperienceYears,
        identityVerified,
    } = user

    const { data: userBadges, isReady } = useFetchData(
        ENTITIES.USER_BADGES,
        {
            'user.id': id,
        },
        true,
        false,
        id
    )

    const handleEdit = () => {
        setOpen(true)
    }

    if (!isReady) return null

    return (
        <div className="m-userMainInfo">
            {myProfile && (
                <div className="-editProfileButton" onClick={handleEdit}>
                    <Icon name={ICONS.EDIT} color={COLORS.DARK_BLUE_40} />
                </div>
            )}
            <div className="m-userMainInfo__mainInfo">
                <div className="m-userMainInfo__mainInfo--contactInfo">
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.personalInfo')}
                        </span>
                    </div>
                    <ContactInfo
                        phoneNumber={phoneNumber}
                        country={country}
                        email={email}
                        dateOfBirth={dateOfBirth}
                        nationality={nationality}
                        totalExperienceYears={totalExperienceYears}
                        user={user}
                        isSmallScreen={isSmallScreen}
                    />
                </div>
            </div>

            {user.about && (
                <div className="column">
                    <span className="a-mediumText -mt15 -mb10">
                        {t('form.label.aboutMe')}
                    </span>
                    <p className="a-mediumText a-lightText -opacity-60 -about">
                        {user.about}
                    </p>
                </div>
            )}

            {user.professionalSummary && (
                <div className="column">
                    <span className="a-mediumText -mt15 -mb10">
                        {t('general.professionalSummary')}
                    </span>
                    <p className="a-mediumText a-lightText -opacity-60 -about">
                        {user.professionalSummary}
                    </p>
                </div>
            )}
            {!!userBadges.length && (
                <div className="-badges -mt20 aligned-center">
                    {userBadges.map(({ id, badgeKey, additionalBadgeKey }) => (
                        <Badge
                            key={id}
                            badgeKey={badgeKey}
                            additionalBadgeKey={additionalBadgeKey}
                        />
                    ))}
                </div>
            )}
            <VerificationLevels user={user} />
            <About user={user} isSmallScreen={isSmallScreen} />
            <Experience user={user} isSmallScreen={isSmallScreen} />
        </div>
    )
}

PersonalInfoCard.propTypes = {
    user: PropTypes.object,
    myProfile: PropTypes.bool,
    setOpen: PropTypes.func,
}

PersonalInfoCard.defaultProps = {
    myProfile: false,
}

export default PersonalInfoCard
