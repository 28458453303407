import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BUTTON_STATUS, ICON_POSITION, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'

const TableFilters = ({ FilterComponent }) => {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <Button
                label="button.filters"
                onClick={() => setOpen(true)}
                icon={ICONS.FILTER}
                iconSize={ICON_SIZE.SIZE16}
                iconColor={COLORS.DARK_BLUE_60}
                btnClass={BUTTON_STATUS.QUATERNARY}
                iconPosition={ICON_POSITION.RIGHT}
            />
            {open && <FilterComponent open={open} setOpen={setOpen} />}
        </div>
    )
}

TableFilters.propTypes = {
    FilterComponent: PropTypes.elementType,
}

export default TableFilters
