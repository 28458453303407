import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslate } from 'react-polyglot'
import { useDropzone } from 'react-dropzone'
import { Gallery } from 'react-grid-gallery'
import heic2any from 'heic2any'

import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

import { deleteEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchData from 'hooks/useFetchData'
import useWindowDimensions from 'hooks/useWindowDimension'

import axios, { BASE_FILE_URL, POST } from 'utils/axiosClient'

import {
    MAX_NUMBER_OF_PICTURES_FOR_UPLOAD,
    UPLOAD_STATUS,
} from './constants/galleryConstants'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'

import Breadcrumbs from 'components/Breadcrumbs'
import Button from 'components/Button'
import Icon from 'components/Icon'
import ProgressBar from './components/ProgressBar'
import Loader from 'components/Loader'

const GalleryPage = () => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const { setAlert } = useContext(AlertContext)
    const { userId } = useContext(CurrentUserContext)

    const [index, setIndex] = useState(-1)
    const [galleryImages, setGalleryImages] = useState([])
    const [selectActive, setSelectActive] = useState(false)
    const [uploadActive, setUploadActive] = useState(false)
    const [progressObject, setProgressObject] = useState({
        numberOfFiles: 0,
        percentage: 0,
        status: UPLOAD_STATUS.UPLOADING,
    })

    const hasSelectedImages = useMemo(() => {
        return galleryImages.some((image) => image.isSelected === true)
    }, [galleryImages])

    const uploadedPhotos = galleryImages.length > 0
    const hidePercentageBar =
        progressObject.percentage === 100 || progressObject.percentage === 0
    const showSelectButtons =
        (selectActive || hasSelectedImages) && uploadedPhotos
    const finite = galleryImages.length > 0 && galleryImages.length < 5

    const {
        data: images,
        fetchData: getImages,
        isLoading: isImagesLoading,
    } = useFetchData(
        ENTITIES.GALLERY_IMAGES,
        { 'user.id': userId },
        true,
        false
    )

    useEffect(() => {
        setGalleryImages(
            images.map(({ id, path, width, height }) => ({
                id,
                src: BASE_FILE_URL + path,
                height,
                width,
            }))
        )
        setUploadActive(false)
    }, [images])

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > MAX_NUMBER_OF_PICTURES_FOR_UPLOAD) {
            setAlert(
                t('alerts.messages.maxNumberOfPhotosForUpload'),
                ALERT_TYPES.ERROR
            )
            return
        }

        setProgressObject({
            ...progressObject,
            numberOfFiles: acceptedFiles?.length,
            status: UPLOAD_STATUS.UPLOADING,
        })

        for (let i = 0; i < acceptedFiles.length; i++) {
            const formData = new FormData()
            let acceptedData = acceptedFiles[i]
            let fileName = acceptedData.name

            if (['image/heic', 'image/heif'].includes(acceptedData.type)) {
                // Convert .heic image to .jpg format
                const convertedImage = await heic2any({
                    blob: acceptedData,
                    toType: 'image/jpeg', // Convert to JPEG format
                })
                fileName = acceptedData.name.replace('.heic', '.jpeg')
                acceptedData = convertedImage
            }
            formData.append('file', acceptedData, fileName)

            try {
                await axios(POST, `/files/gallery-upload`, formData)

                setProgressObject({
                    ...progressObject,
                    numberOfFiles: acceptedFiles.length - i - 1,
                    percentage: ((i + 1) / acceptedFiles.length) * 100,
                    status: UPLOAD_STATUS.UPLOADING,
                })

                setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            } catch (error) {
                setProgressObject({
                    numberOfFiles: 0,
                    percentage: 0,
                    status: UPLOAD_STATUS.UPLOADING,
                })
                setAlert(error, ALERT_TYPES.ERROR, t)
            }

            await getImages()
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const handleDelete = async () => {
        const selectedImages = galleryImages.filter((image) => image.isSelected)

        setProgressObject({
            ...progressObject,
            numberOfFiles: selectedImages.length,
            status: UPLOAD_STATUS.DELETING,
        })

        for (let i = 0; i < selectedImages.length; i++) {
            if (selectedImages[i].isSelected) {
                try {
                    await deleteEntityService(
                        ENTITIES.GALLERY_IMAGES,
                        selectedImages[i].id,
                        {}
                    )

                    setProgressObject({
                        ...progressObject,
                        numberOfFiles: selectedImages.length - i - 1,
                        percentage: ((i + 1) / selectedImages.length) * 100,
                        status: UPLOAD_STATUS.DELETING,
                    })

                    setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
                } catch (error) {
                    setProgressObject({
                        numberOfFiles: 0,
                        percentage: 0,
                        status: UPLOAD_STATUS.DELETING,
                    })

                    setAlert(error, ALERT_TYPES.ERROR, t)
                }
            }
        }

        await getImages()
    }

    const handleClick = (index, item) => {
        setIndex(index)
    }

    const handleSelect = (index, item, event) => {
        const newImages = galleryImages.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        )

        setGalleryImages(newImages)
    }

    const changeSelectStatusForAll = (newValue) => {
        const newImages = galleryImages.map((image) => {
            return { ...image, isSelected: newValue }
        })

        setGalleryImages(newImages)
    }

    const selectAll = () => {
        changeSelectStatusForAll(true)
    }

    const deselectAll = () => {
        changeSelectStatusForAll(false)
    }

    if (isImagesLoading && hidePercentageBar) {
        return <Loader />
    }

    if (!images) {
        return null
    }

    return (
        <div className="_wr">
            <div className="_w">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.homepage',
                                to: ROUTES.HOME,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                            },
                            {
                                label: 'menuItems.gallery',
                                to: ROUTES.GALLERY,
                            },
                        ]}
                    />
                </div>

                <div className="m-boxes fullWidth">
                    <div className="m-boxes__main -fullWidth m-gallery">
                        <div className="space-between -mb20">
                            <h3>
                                {t('menuItems.gallery')}{' '}
                                {uploadedPhotos
                                    ? '(' + galleryImages.length + ')'
                                    : ''}
                            </h3>

                            {uploadedPhotos && (
                                <div className="space-between-buttons ">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        label={
                                            !showSelectButtons
                                                ? 'button.select'
                                                : 'button.cancel'
                                        }
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={() => {
                                            setSelectActive(!selectActive)
                                            changeSelectStatusForAll(false)
                                        }}
                                    />
                                    <Button
                                        icon={ICONS.UPLOAD}
                                        iconColor={COLORS.WHITE}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={() => {
                                            setUploadActive(!uploadActive)
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {showSelectButtons && (
                            <div className="space-between m-gallery__selectActive -mb20">
                                <div className="space-between-buttons">
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.CHECKMARK_ICON_ROUNDED}
                                        iconColor={COLORS.SECONDARY}
                                        label={'button.selectAll'}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={selectAll}
                                    />
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.CLOSE}
                                        iconColor={COLORS.SECONDARY}
                                        label={'button.deselectAll'}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={deselectAll}
                                    />
                                </div>
                                <Button
                                    disabled={!hasSelectedImages}
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.DELETE}
                                    iconColor={COLORS.RED}
                                    iconSize={ICON_SIZE.SIZE18}
                                    label={'button.deleteSelectedPhotos'}
                                    onClick={handleDelete}
                                />
                            </div>
                        )}

                        {!hidePercentageBar && (
                            <ProgressBar progressObject={progressObject} />
                        )}

                        {(uploadActive || !uploadedPhotos) && (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div
                                    className={`aligned-center justify-center column m-gallery__box ${
                                        uploadedPhotos ? '-photos' : '-noPhotos'
                                    }`}
                                >
                                    <div className="aligned-center column m-gallery__box--content">
                                        {!uploadedPhotos && (
                                            <Fragment>
                                                <Icon
                                                    name={ICONS.GALLERY_PHOTO}
                                                />
                                                <h3 className="-mt10">
                                                    {t('general.noPhotos')}
                                                </h3>
                                            </Fragment>
                                        )}

                                        <div className="aligned-center column">
                                            {isDragActive ? (
                                                <span className="-mt20 a-bodyTextRegular a-lightText -opacity-60">
                                                    {t('general.dropFile')}
                                                </span>
                                            ) : (
                                                <>
                                                    <span className="-mt20 a-bodyTextRegular a-lightText -opacity-60">
                                                        {t(
                                                            isTablet
                                                                ? 'gallery.addPhotosFromDevice'
                                                                : 'gallery.dragNDrop'
                                                        )}
                                                    </span>
                                                    {!isTablet && (
                                                        <span className="-mt10 a-bodyTextRegular a-lightText -opacity-60 a-allCaps">
                                                            {t('gallery.or')}
                                                        </span>
                                                    )}
                                                    <div className="justify-start -mt10">
                                                        <Button label="gallery.chooseFromDevice" />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div
                            className={`-mt20 ${
                                selectActive ? '-allowSelection' : ''
                            }`}
                        >
                            <Gallery
                                images={galleryImages}
                                onClick={handleClick}
                                onSelect={handleSelect}
                            />
                        </div>

                        <Lightbox
                            slides={galleryImages}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            controller={{
                                closeOnPullDown: true,
                                closeOnBackdropClick: true,
                            }}
                            carousel={{
                                finite: finite,
                            }}
                            plugins={[Captions, Thumbnails, Zoom]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryPage
