import React, { useEffect, useMemo, useState } from 'react'

import { getUserId } from 'services/localStorage.service'

import useFetchDataById from 'hooks/useFetchDataById'
import useFetchData from 'hooks/useFetchData'

import ENTITY from 'constants/entities'
import { ROLES } from 'constants/enums'

export const CurrentUserContext = React.createContext()

const CurrentUserContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [currentCompany, setCurrentCompany] = useState(null)
    const [chatClient, setChatClient] = useState(null)
    const [unreadMessages, setUnreadMessages] = useState(0)

    const userId = getUserId()

    const { data, isLoading, fetchData } = useFetchDataById(
        ENTITY.USER,
        userId,
        {
            include:
                'role,country,diverPositions,countryPhoneCode,userSetting,regions,userStatus,avatar,heroImage',
        }
    )

    const isDiver = currentUser?.role?.name === ROLES.ROLE_DIVER.name
    const isContractor =
        currentUser?.role?.name === ROLES.ROLE_DIVING_CONTRACTOR.name

    const {
        data: companyData,
        isLoading: isLoadingCurrentCompany,
        fetchData: fetchCompanyData,
    } = useFetchData(
        ENTITY.COMPANY,
        {
            'user.id': userId,
            include:
                'industries,services,user,vessels,clients,countryPhoneCode,hqCountry,size,regions,logo',
        },
        isContractor
    )

    useEffect(() => {
        isContractor && fetchCompanyData()
    }, [isContractor])

    useEffect(() => {
        setCurrentUser(data)
    }, [data])

    useEffect(() => {
        setCurrentCompany(companyData[0])
    }, [companyData])

    const contextValue = useMemo(
        () => ({
            currentUser,
            setCurrentUser,
            isLoadingCurrentUser: isLoading,
            fetchCurrentUser: fetchData,
            userId,
            isDiver,
            isContractor,
            currentCompany,
            setCurrentCompany,
            isLoadingCurrentCompany,
            fetchCompanyData,
            chatClient,
            setChatClient,
            unreadMessages,
            setUnreadMessages,
        }),
        [
            currentUser,
            isLoading,
            currentCompany,
            isLoadingCurrentCompany,
            chatClient,
            unreadMessages,
        ]
    )

    return (
        <CurrentUserContext.Provider value={contextValue}>
            {props.children}
        </CurrentUserContext.Provider>
    )
}

export default CurrentUserContextProvider
