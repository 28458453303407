import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'
import { removeSecondsFromTime } from 'utils/timeFormatter'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import ICONS from 'constants/icons'
import { GAS_CONTENTS } from '../../constants/gasContents'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'
import Button from 'components/Button'
import Separator from 'components/Separator'

const DiveEventList = ({
    data,
    fetchData,
    isLoading,
    setEditItem,
    hideActions,
    unitImperial,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.DIVE_EVENT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteDiveEvent',
            title: 'button.deleteDiveEvent',
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })

    const formatGasContents = (breathingMixture, oxygen, nitrogen, helium) => {
        switch (breathingMixture.name.toUpperCase()) {
            case GAS_CONTENTS.NITROX:
                return `${breathingMixture.name} (Oxygen ${oxygen}%, Nitrogen ${nitrogen}%)`
            case GAS_CONTENTS.HELIOX:
                return `${breathingMixture.name} (Oxygen ${oxygen}%, Helium ${helium}%)`
            case GAS_CONTENTS.TRIMIX:
                return `${breathingMixture.name} (Oxygen ${oxygen}%, Helium ${helium}%, Nitrogen ${nitrogen}%)`
            default:
                return breathingMixture.name
        }
    }

    if (isLoading && data.length === 0 && !hideActions) {
        return <CardPlaceholder />
    }

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    return (
        <div className="_wr">
            <div className="_w justify-center">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            !hideActions ? '_12 _l8 -mt20' : 'fullWidth'
                        }`}
                    >
                        <CardContainer
                            title={t('general.diveEvent')}
                            item={item}
                            actions={
                                hideActions
                                    ? []
                                    : [
                                          {
                                              handleAction: setEditItem,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.PRIMARY,
                                              tooltip: 'button.editDiveEvent',
                                          },
                                          {
                                              handleAction: handleDelete,
                                              icon: ICONS.DELETE,
                                              iconColor: COLORS.RED,
                                              tooltip: 'button.deleteDiveEvent',
                                          },
                                      ]
                            }
                            noBorderCard={!!hideActions}
                        >
                            <Info
                                label="general.repetitiveDive"
                                value={
                                    item.repetitiveDive
                                        ? t('general.yes')
                                        : t('general.no')
                                }
                                withBorder
                                withSpaceBetween
                            />
                            {item.repetitiveDive &&
                                (item.siSinceLastDiveHours !== undefined ||
                                    item.siSinceLastDiveMinutes !==
                                        undefined) && (
                                    <Info
                                        label="form.label.siSinceLastDiveHours"
                                        value={
                                            (item.siSinceLastDiveHours || 0) +
                                            'h ' +
                                            (item.siSinceLastDiveMinutes || 0) +
                                            'min'
                                        }
                                        withBorder
                                        withSpaceBetween
                                    />
                                )}
                            {item.beginningRGD && (
                                <Info
                                    label="form.label.beginningRGD"
                                    value={item.beginningRGD}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.repetitiveDive &&
                                (item.rntPenaltyHours !== undefined ||
                                    item.rntPenaltyMinutes !== undefined) && (
                                    <Info
                                        label="form.label.rntPenaltyHours"
                                        value={
                                            (item.rntPenaltyHours || 0) +
                                            'h ' +
                                            (item.rntPenaltyMinutes || 0) +
                                            'min'
                                        }
                                        withBorder
                                        withSpaceBetween
                                    />
                                )}
                            {item.breathingMixture && (
                                <Info
                                    label="form.label.breathingMixture"
                                    value={item.breathingMixture.displayName}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.breathingMixtureGasContents && (
                                <Info
                                    label="form.label.breathingMixtureGasContents"
                                    value={formatGasContents(
                                        item.breathingMixtureGasContents,
                                        item.breathingMixtureOxygen,
                                        item.breathingMixtureNitrogen,
                                        item.breathingMixtureHelium
                                    )}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.timeLeftSurfaceHours"
                                value={removeSecondsFromTime(
                                    item.timeLeftSurface
                                )}
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.maxDepthOfDive"
                                value={
                                    item.maxDepthOfDive + unitOfMeasurementLabel
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.timeLeftBottomHours"
                                value={removeSecondsFromTime(
                                    item.timeLeftBottom
                                )}
                                withBorder
                                withSpaceBetween
                            />
                            {item?.firstDecoStopDepth && (
                                <Info
                                    label="form.label.firstDecoStopDepth"
                                    value={
                                        item.firstDecoStopDepth +
                                        unitOfMeasurementLabel
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.decoBreathingMixture && (
                                <Info
                                    label="form.label.decoBreathingMixture"
                                    value={
                                        item.decoBreathingMixture.displayName
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.decoBreathingMixtureGasContents && (
                                <Info
                                    label="form.label.decoBreathingMixtureGasContents"
                                    value={
                                        item.decoBreathingMixtureGasContents
                                            .name
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.lastDecoStopDepth && (
                                <Info
                                    label="form.label.lastDecoStopDepth"
                                    value={
                                        item.lastDecoStopDepth +
                                        unitOfMeasurementLabel
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.totalDecoTimeHours"
                                value={
                                    item.totalDecoTimeHours +
                                    'h ' +
                                    item.totalDecoTimeMinutes +
                                    'min'
                                }
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.timeReachedSurfaceHours"
                                value={removeSecondsFromTime(
                                    item.timeReachedSurface
                                )}
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.bottomTimeHours"
                                value={
                                    item.bottomTimeHours +
                                    'h ' +
                                    item.bottomTimeMinutes +
                                    'min'
                                }
                                withBorder={
                                    item.totalBottomTimeHours ||
                                    item.decompressionType
                                        ? true
                                        : false
                                }
                                withSpaceBetween
                            />
                            {(item.totalBottomTimeHours !== undefined ||
                                item.totalBottomTimeMinutes !== undefined) && (
                                <Info
                                    label="form.label.totalBottomTimeHours"
                                    value={
                                        (item.totalBottomTimeHours || 0) +
                                        'h ' +
                                        (item.totalBottomTimeMinutes || 0) +
                                        'min'
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.endingRGD && (
                                <Info
                                    label="form.label.endingRGD"
                                    value={item.endingRGD}
                                    withBorder={item.decompressionType}
                                    withSpaceBetween
                                />
                            )}
                            {item.decompressionType && (
                                <Info
                                    label="form.label.decompressionType"
                                    value={item.decompressionType.name}
                                    withSpaceBetween
                                />
                            )}
                            <Separator fullWidth />
                            <span className="-mb10 a-bodyTextRegular">
                                {t('general.decompressionProcedure')}
                            </span>
                            <Info
                                label="form.label.decompressionTableType"
                                value={item.decompressionTableType.name}
                                withBorder
                                withSpaceBetween
                            />
                            <Info
                                label="form.label.decoScheduleUsedMeters"
                                value={`${item.decoScheduleUsedMeters}${unitOfMeasurementLabel} ${item.decoScheduleUsedMinutes}min`}
                                withBorder={item.comment ? true : false}
                                withSpaceBetween
                            />
                            {item.comment && (
                                <Info
                                    label="form.label.comment"
                                    value={item.comment}
                                    column
                                />
                            )}
                            {!hideActions && (
                                <Button
                                    label="button.viewFullDiveEvent"
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.ARROW_RIGHT}
                                    iconColor={COLORS.SECONDARY}
                                    iconPosition={ICON_POSITION.RIGHT}
                                    onClick={() => setEditItem(item)}
                                />
                            )}
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DiveEventList
