import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/DataAccessControl'

const MedicalInfoAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.MEDICAL_INFORMATION}
            accessTypeLabel="form.label.selectAccessForMedicalInfo"
            dataAccessAttribute="accessMedicalInformation"
            title="general.medicalInfo"
        />
    )
}

export default MedicalInfoAccessControl
