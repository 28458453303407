import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import useFetchData from 'hooks/useFetchData'

import { persistEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { InputField, MultiselectField } from 'components/formFields'
import Loader from 'components/Loader'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'

const SaturationEventFormModal = ({
    initialData,
    fetchData,
    closeModal,
    isSupervisor,
}) => {
    const t = useTranslate()
    const { projectId } = useParams()

    const { diveRecord, isSupervisorDiveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)
    const { data, isLoading } = useFetchData(ENTITIES.DIVERS)

    const {
        data: {
            diveRecordDiveData,
            unitImperial,
            diveRecordGeneralInformation: { divers },
        },
    } = diveRecord

    const initialValues = {
        dayInSaturation: initialData?.dayInSaturation ?? '',
        bellTeamOneMembers: initialData?.bellTeamOneMembers ?? [],
        bellTeamTwoMembers: initialData?.bellTeamTwoMembers ?? [],
        bellTeamThreeMembers: initialData?.bellTeamThreeMembers ?? [],
        bellTeamOneText: initialData?.bellTeamOneText ?? '',
        bellTeamTwoText: initialData?.bellTeamTwoText ?? '',
        bellTeamThreeText: initialData?.bellTeamThreeText ?? '',
        deepestChamberStorageDepth:
            initialData?.deepestChamberStorageDepth ?? '',
        chamberAverageTemperature: initialData?.chamberAverageTemperature ?? '',
        chamberAverageHumidity: initialData?.chamberAverageHumidity ?? '',
        comment: initialData?.comment ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validateChamberAverageTemperature = () =>
        Yup.string().when([], {
            is: () => unitImperial === true,
            then: () =>
                Yup.number()
                    .min(-20, t('form.error.minimumNumber'))
                    .max(100, t('form.error.maximumIs100'))
                    .required(requiredMessage),
            otherwise: () =>
                Yup.number()
                    .min(0, t('form.error.minimumNumberIs0'))
                    .max(37, t('form.error.maximumIs37'))
                    .required(requiredMessage),
        })

    const validation = Yup.object({
        dayInSaturation: Yup.number()
            .min(1, t('form.error.invalidNumber'))
            .max(60, t('form.error.maximumSixtyElements'))
            .required(requiredMessage),
        bellTeamOneMembers:
            projectId && isSupervisorDiveRecord
                ? Yup.array()
                      .min(1, t('form.error.minimumOneElement'))
                      .max(4, t('form.error.maximumFourElements'))
                      .required(requiredMessage)
                : null,
        bellTeamTwoMembers:
            projectId && isSupervisorDiveRecord
                ? Yup.array().max(4, t('form.error.maximumFourElements'))
                : null,
        bellTeamThreeMembers:
            projectId && isSupervisorDiveRecord
                ? Yup.array().max(4, t('form.error.maximumFourElements'))
                : null,
        deepestChamberStorageDepth: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .required(requiredMessage),
        chamberAverageTemperature: validateChamberAverageTemperature(),
        chamberAverageHumidity: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .max(100, t('form.error.maximumIs100'))
            .required(requiredMessage),
        comment: Yup.string(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                ENTITIES.SATURATION_EVENT,
                {
                    ...formData,
                    bellTeamOneMembers: formData.bellTeamOneMembers.map(
                        (team1Member) => ({
                            id: team1Member.id,
                            entityType: ENTITIES.USER,
                        })
                    ),
                    bellTeamTwoMembers: formData.bellTeamTwoMembers.map(
                        (team2Member) => ({
                            id: team2Member.id,
                            entityType: ENTITIES.USER,
                        })
                    ),
                    bellTeamThreeMembers: formData.bellTeamThreeMembers.map(
                        (team3Member) => ({
                            id: team3Member.id,
                            entityType: ENTITIES.USER,
                        })
                    ),
                    diveRecordDiveData: diveRecordDiveData,
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    const unitOfMeasurementTemperature = unitImperial
        ? 'form.label.fahrenheit'
        : 'form.label.celsius'

    if (!data) return null

    if (isLoading) return <Loader />

    return (
        <Modal open={true} setOpen={closeModal} closeOnClickOutside={false}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    values: {
                        bellTeamOneMembers,
                        bellTeamTwoMembers,
                        bellTeamThreeMembers,
                    },
                }) => (
                    <Form>
                        <FocusError />
                        <fieldset disabled={isSupervisor}>
                            <h3 className="-mb20 _12">
                                {t('general.saturationDailyEvent')}
                            </h3>
                            <div className="_wr">
                                <div className="_w">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="dayInSaturation"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        {projectId && isSupervisorDiveRecord ? (
                                            <MultiselectField
                                                name="bellTeamOneMembers"
                                                label="form.label.bellTeamOneMembers"
                                                placeholder="form.placeholder.bellTeamMembers"
                                                entityType={
                                                    ENTITIES.PROJECT_DIVERS
                                                }
                                                displayAttribute="fullName"
                                                params={{
                                                    project: projectId,
                                                    'divers.id': divers.map(
                                                        (diver) => diver.id
                                                    ),
                                                }}
                                                disabledItems={[
                                                    ...bellTeamTwoMembers,
                                                    ...bellTeamThreeMembers,
                                                ]}
                                                searchable
                                                required={
                                                    projectId ? true : false
                                                }
                                            />
                                        ) : (
                                            <InputField
                                                name="bellTeamOneText"
                                                label="form.label.bellTeamOneMembers"
                                                placeholder="form.placeholder.bellTeamText"
                                                type={INPUT_FILED_TYPE.TEXT}
                                            />
                                        )}
                                    </div>
                                    <div className="_12 _l4">
                                        {projectId && isSupervisorDiveRecord ? (
                                            <MultiselectField
                                                name="bellTeamTwoMembers"
                                                label="form.label.bellTeamTwoMembers"
                                                placeholder="form.placeholder.bellTeamMembers"
                                                entityType={
                                                    ENTITIES.PROJECT_DIVERS
                                                }
                                                displayAttribute="fullName"
                                                params={{
                                                    project: projectId,
                                                    'divers.id': divers?.map(
                                                        (diver) => diver.id
                                                    ),
                                                }}
                                                disabledItems={[
                                                    ...bellTeamOneMembers,
                                                    ...bellTeamThreeMembers,
                                                ]}
                                                searchable
                                            />
                                        ) : (
                                            <InputField
                                                name="bellTeamTwoText"
                                                label="form.label.bellTeamTwoMembers"
                                                placeholder="form.placeholder.bellTeamText"
                                                type={INPUT_FILED_TYPE.TEXT}
                                            />
                                        )}
                                    </div>
                                    <div className="_12 _l4">
                                        {projectId && isSupervisorDiveRecord ? (
                                            <MultiselectField
                                                name="bellTeamThreeMembers"
                                                label="form.label.bellTeamThreeMembers"
                                                placeholder="form.placeholder.bellTeamMembers"
                                                entityType={
                                                    ENTITIES.PROJECT_DIVERS
                                                }
                                                displayAttribute="fullName"
                                                params={{
                                                    project: projectId,
                                                    'divers.id': divers?.map(
                                                        (diver) => diver.id
                                                    ),
                                                }}
                                                disabledItems={[
                                                    ...bellTeamOneMembers,
                                                    ...bellTeamTwoMembers,
                                                ]}
                                                searchable
                                            />
                                        ) : (
                                            <InputField
                                                name="bellTeamThreeText"
                                                label="form.label.bellTeamThreeMembers"
                                                placeholder="form.placeholder.bellTeamText"
                                                type={INPUT_FILED_TYPE.TEXT}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="deepestChamberStorageDepth"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _l4">
                                        <InputField
                                            name="chamberAverageTemperature"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementTemperature}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _l4">
                                        <InputField
                                            name="chamberAverageHumidity"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.relativeHumidity"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="_w -mt5">
                                    <div className="_12">
                                        <TextAreaField
                                            name="comment"
                                            largeTextarea
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div className="_wr">
                            <div className="_w -buttons -pb20">
                                <div
                                    className={`${
                                        isSupervisor
                                            ? 'ofs_6 _6 ofs_m9 _m3'
                                            : '_12 _xs6 -mt40'
                                    }`}
                                >
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        type={BUTTON_TYPE.BUTTON}
                                        label={`${
                                            isSupervisor
                                                ? 'button.closeModal'
                                                : 'button.cancel'
                                        }`}
                                        onClick={closeModal}
                                        disabled={isSubmitting}
                                    />
                                </div>
                                {!isSupervisor && (
                                    <div className="_12 _xs6 -mt40">
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            type={BUTTON_TYPE.SUBMIT}
                                            label="button.save"
                                            onClick={
                                                isSupervisor
                                                    ? closeModal
                                                    : handleSubmit
                                            }
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                )}
                                {isSubmitting && <Loader />}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default SaturationEventFormModal
