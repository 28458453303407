import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'

import Button from './Button'

const ToggleButtons = ({
    isActiveButton,
    handleFirstButton,
    handleSecondButton,
}) => {
    return (
        <div className="m-toggleButtons aligned-center justify-center">
            <div className="m-toggleButtons__wrapper aligned-center justify-center">
                <Button
                    btnClass={
                        isActiveButton
                            ? BUTTON_STATUS.PRIMARY
                            : BUTTON_STATUS.TERTIARY
                    }
                    buttonSize={BUTTON_SIZE.SMALL}
                    label={'form.label.templates'}
                    onClick={handleFirstButton}
                ></Button>
                <Button
                    btnClass={
                        isActiveButton
                            ? BUTTON_STATUS.TERTIARY
                            : BUTTON_STATUS.PRIMARY
                    }
                    buttonSize={BUTTON_SIZE.SMALL}
                    label={'form.label.newMessage'}
                    onClick={handleSecondButton}
                ></Button>
            </div>
        </div>
    )
}

export default ToggleButtons
