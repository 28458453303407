import moment from 'moment'

export const MOMENT_FORMATS = {
    DATE: 'D/MMM/YYYY',
    DATETIME: 'D/MMM/YYYY HH:mm',
    DATE_API: 'yyyy-MM-DD',
    DATE_MONTHYEAR: 'MMMM YYYY',
    TIME: 'HH:mm',
}

export const EXPIRY_DATE = {
    EXPIRED: { status: 'expired', color: 'red' },
    WILL_EXPIRE: { status: 'will expire', color: 'orange' },
    NOT_EXPIRED: { status: 'not expired', color: 'green' },
}

const EXPIRING_DAYS = 60

export const formatDate = (date, format = MOMENT_FORMATS.DATE) => {
    return moment.utc(date).format(format)
}

export const formatMonthYearDate = (date) => {
    return moment.utc(date).format(MOMENT_FORMATS.DATE_MONTHYEAR)
}

export const formatDateTime = (dateTime) => {
    return moment.utc(dateTime).format(MOMENT_FORMATS.DATETIME)
}

export const getDateTimeAgo = (date, format) => {
    return moment(date, format).fromNow()
}

export const getIsDateSameOrBefore = (date, targetDate, format) => {
    return moment(date, format).isSameOrBefore(moment(targetDate, format))
}

export const getIsDateSameOrAfter = (date, targetDate, format) => {
    return moment(date, format).isSameOrAfter(moment(targetDate, format))
}

export const getIsDateSameOrBeforeCurrentDate = (
    value,
    format = MOMENT_FORMATS.DATE
) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    return getIsDateSameOrBefore(
        moment(value).format(format),
        moment(today).format(format),
        format
    )
}

export const formatDateApi = (date) => {
    return moment.utc(date, MOMENT_FORMATS.DATE_API).format(MOMENT_FORMATS.DATE)
}

export const checkExpiryDate = (value, numberOfDays = EXPIRING_DAYS) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const valueToDate = new Date(value)
    valueToDate.setHours(0, 0, 0, 0)

    const daysDifference = moment(valueToDate).diff(moment(today), 'days')

    if (daysDifference < 0) {
        return EXPIRY_DATE.EXPIRED.color
    } else if (daysDifference >= 0 && daysDifference < numberOfDays) {
        return EXPIRY_DATE.WILL_EXPIRE.color
    }

    return EXPIRY_DATE.NOT_EXPIRED.color
}

export const checkIfExpired = (value, numberOfDays = EXPIRING_DAYS) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const valueToDate = new Date(value)
    valueToDate.setHours(0, 0, 0, 0)

    const daysDifference = moment(valueToDate).diff(moment(today), 'days')

    return daysDifference < 0
}
