import PropTypes from 'prop-types'

const Separator = ({ fullWidth }) => {
    return (
        <div
            className={`a-separator -after -pt25 ${
                fullWidth ? '-fullWidth -mt25' : '-mt15'
            }`}
        ></div>
    )
}

Separator.propTypes = {
    fullWidth: PropTypes.bool,
}

Separator.defaultProps = {
    fullWidth: false,
}

export default Separator
