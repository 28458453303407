import React, { useState } from 'react'

import { BUTTON_STATUS, ICON_POSITION, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Modal from 'components/Modal'
import COLORS from 'constants/colors'

const DiveTeamFilters = () => {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <Button
                label="button.filters"
                onClick={() => setOpen(true)}
                icon={ICONS.FILTER}
                iconSize={ICON_SIZE.SIZE16}
                iconColor={COLORS.DARK_BLUE_60}
                btnClass={BUTTON_STATUS.QUATERNARY}
                iconPosition={ICON_POSITION.RIGHT}
            />
            {open && (
                <Modal
                    open={open}
                    setOpen={setOpen}
                    closeOnClickOutside={false}
                >
                    <div>
                        <h3 className="aligned-center justify-center">
                            Comming soon!
                        </h3>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default DiveTeamFilters
