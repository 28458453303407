import useMenuItems, { USER_MENU_ITEMS } from 'hooks/useMenuItems'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import useCanLeavePage from 'hooks/useCanLeavePage'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import AvatarDropdown from 'components/AvatarDropdown'
import Logo from 'components/Logo'
import MainSearch from 'components/MainSearch'
import IconButton from 'components/IconButton'
import { NotificationsDropdown } from 'components/notificationsDropdown'
import Icon from 'components/Icon'

const Header = ({
    openSidebar,
    setOpenSidebar,
    hamBtnRef,
    isSmallScreen,
    isSmallerThanXlScreen,
    isMobile,
    isTablet,
}) => {
    const t = useTranslate()

    const { canLeavePage, handleShowModal, pathname } = useCanLeavePage()

    const { unreadMessages } = useContext(CurrentUserContext)

    const handleOpenModal = (to) => {
        if (!canLeavePage) {
            handleShowModal(to)
        }
    }
    const [showSearchInput, setShowSearchInput] = useState(true)
    const { setAlert } = useContext(AlertContext)

    const toggleElements = () => {
        setShowSearchInput(!showSearchInput)
    }

    const menuItems = useMenuItems(USER_MENU_ITEMS)

    const getIsActive = (path) => {
        if (path !== ROUTES.HOME) return pathname.includes(path)
        return pathname === ROUTES.HOME
    }

    return (
        <header className="m-header">
            <div className="_wr">
                <div className="_w">
                    <div className="m-header__wrapper fullWidth">
                        <Logo
                            className="m-header__logo"
                            color1={COLORS.PRIMARY}
                            color2={COLORS.SECONDARY}
                            color3={COLORS.SECONDARY}
                            showSearchInput={showSearchInput}
                            isTablet={isTablet}
                        />
                        {!isSmallerThanXlScreen && (
                            <MainSearch showLabel={false} />
                        )}
                        {!isSmallScreen && (
                            <div
                                className={`-links ${
                                    !showSearchInput && isSmallerThanXlScreen
                                        ? '-movedLeft'
                                        : ''
                                } `}
                            >
                                {menuItems
                                    .filter((item) => item.showInHeader)
                                    .map(({ key, label, to }) => (
                                        <div key={key}>
                                            {key === 'calendar' ? (
                                                <p
                                                    className={`a-mediumText a-headerLink ${
                                                        getIsActive(to)
                                                            ? ' -active'
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        setAlert(
                                                            t(
                                                                'alerts.messages.notAvailable'
                                                            )
                                                        )
                                                    }
                                                >
                                                    {/* Remove for mvp */}
                                                    {/* {t(label)}  */}
                                                </p>
                                            ) : (
                                                <Link
                                                    className={`a-mediumText a-headerLink ${
                                                        getIsActive(to)
                                                            ? ' -active'
                                                            : ''
                                                    }`}
                                                    to={
                                                        canLeavePage
                                                            ? to
                                                            : undefined
                                                    }
                                                    onClick={() =>
                                                        canLeavePage
                                                            ? undefined
                                                            : handleOpenModal(
                                                                  to
                                                              )
                                                    }
                                                >
                                                    {t(label)}
                                                </Link>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}
                        <div className="m-header__main">
                            {isSmallerThanXlScreen &&
                                (showSearchInput ? (
                                    <IconButton
                                        onClick={toggleElements}
                                        icon={ICONS.SEARCH}
                                        size={ICON_SIZE.SIZE18}
                                    />
                                ) : (
                                    <div className="-searchAndExit">
                                        <MainSearch showLabel={false} />
                                        <div className="-exitSearch">
                                            <IconButton
                                                onClick={toggleElements}
                                                icon={ICONS.CLOSE}
                                                size={ICON_SIZE.SIZE18}
                                            />
                                        </div>
                                    </div>
                                ))}
                            <Link
                                to={canLeavePage ? ROUTES.CHAT : undefined}
                                onClick={() =>
                                    canLeavePage
                                        ? undefined
                                        : handleOpenModal(ROUTES.CHAT)
                                }
                                className={`-chatIcon ${
                                    getIsActive(ROUTES.CHAT) ? ' -active' : ''
                                } ${
                                    !!unreadMessages
                                        ? '-withIcon'
                                        : '-withoutIcon'
                                }`}
                            >
                                <Icon
                                    name={
                                        !!unreadMessages
                                            ? ICONS.CHAT_WITH_ICON
                                            : ICONS.CHAT
                                    }
                                    color={
                                        getIsActive(ROUTES.CHAT)
                                            ? COLORS.SECONDARY
                                            : COLORS.PRIMARY
                                    }
                                />
                            </Link>
                            <NotificationsDropdown />
                            {!isSmallScreen && <AvatarDropdown />}
                            {isSmallScreen && (
                                <div
                                    className={`m-toggler ${
                                        !showSearchInput && isMobile
                                            ? '-movedRight'
                                            : ''
                                    }`}
                                    ref={hamBtnRef}
                                    onClick={() => setOpenSidebar(!openSidebar)}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    openSidebar: PropTypes.bool.isRequired,
    setOpenSidebar: PropTypes.func.isRequired,
    hamBtnRef: PropTypes.object,
    isSmallScreen: PropTypes.bool,
    isSmallerThanXlScreen: PropTypes.bool,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
}

export default Header
