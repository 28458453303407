import React from 'react'

const NumberOfDivesBadge = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <title>100 completed dives</title>
            <circle cx="25" cy="25" r="23" fill="#D1D2D8" />
            <g filter="url(#filter0_i_21877_21103)">
                <circle cx="24.9996" cy="25.0001" r="19.0943" fill="#EBECF1" />
            </g>
            <mask
                id="mask0_21877_21103"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="46"
                height="46"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="22.8231"
                    fill="#F3F4F5"
                    stroke="#454B63"
                    strokeWidth="0.353846"
                />
            </mask>
            <g mask="url(#mask0_21877_21103)">
                <path
                    opacity="0.2"
                    d="M39.7544 2L49.267 11.5126L12.6828 48.0967L3.17026 38.5841L39.7544 2Z"
                    fill="white"
                />
                <path
                    opacity="0.2"
                    d="M25.1313 -4.5094L30.4312 0.790497L-6.15289 37.3746L-11.4528 32.0747L25.1313 -4.5094Z"
                    fill="white"
                />
                <path
                    opacity="0.2"
                    d="M56.3765 16.3208L61.6764 21.6207L25.0922 58.2048L19.7923 52.9049L56.3765 16.3208Z"
                    fill="white"
                />
            </g>
            <path
                d="M15.4982 29.0528V21.5843L16.7332 22.7445H14.0308V20.5934H18.384V29.0528H15.4982Z"
                fill="#74788A"
            />
            <path
                d="M23.3117 29.2462C22.5535 29.2462 21.8809 29.073 21.294 28.7265C20.7152 28.3801 20.2587 27.8766 19.9244 27.2159C19.5983 26.5553 19.4353 25.7577 19.4353 24.8231C19.4353 23.8885 19.5983 23.0909 19.9244 22.4303C20.2587 21.7696 20.7152 21.2661 21.294 20.9197C21.8809 20.5732 22.5535 20.4 23.3117 20.4C24.0698 20.4 24.7383 20.5732 25.3171 20.9197C25.9041 21.2661 26.3606 21.7696 26.6867 22.4303C27.0209 23.0909 27.188 23.8885 27.188 24.8231C27.188 25.7577 27.0209 26.5553 26.6867 27.2159C26.3606 27.8766 25.9041 28.3801 25.3171 28.7265C24.7383 29.073 24.0698 29.2462 23.3117 29.2462ZM23.3117 26.9621C23.4992 26.9621 23.6663 26.8977 23.813 26.7688C23.9598 26.6399 24.0739 26.4183 24.1554 26.1041C24.2451 25.7899 24.2899 25.3629 24.2899 24.8231C24.2899 24.2753 24.2451 23.8483 24.1554 23.5421C24.0739 23.2279 23.9598 23.0063 23.813 22.8774C23.6663 22.7485 23.4992 22.6841 23.3117 22.6841C23.1242 22.6841 22.957 22.7485 22.8103 22.8774C22.6636 23.0063 22.5454 23.2279 22.4557 23.5421C22.3742 23.8483 22.3334 24.2753 22.3334 24.8231C22.3334 25.3629 22.3742 25.7899 22.4557 26.1041C22.5454 26.4183 22.6636 26.6399 22.8103 26.7688C22.957 26.8977 23.1242 26.9621 23.3117 26.9621Z"
                fill="#74788A"
            />
            <path
                d="M31.7783 29.2462C31.0202 29.2462 30.3476 29.073 29.7607 28.7265C29.1818 28.3801 28.7253 27.8766 28.3911 27.2159C28.065 26.5553 27.902 25.7577 27.902 24.8231C27.902 23.8885 28.065 23.0909 28.3911 22.4303C28.7253 21.7696 29.1818 21.2661 29.7607 20.9197C30.3476 20.5732 31.0202 20.4 31.7783 20.4C32.5365 20.4 33.205 20.5732 33.7838 20.9197C34.3707 21.2661 34.8272 21.7696 35.1533 22.4303C35.4876 23.0909 35.6547 23.8885 35.6547 24.8231C35.6547 25.7577 35.4876 26.5553 35.1533 27.2159C34.8272 27.8766 34.3707 28.3801 33.7838 28.7265C33.205 29.073 32.5365 29.2462 31.7783 29.2462ZM31.7783 26.9621C31.9658 26.9621 32.1329 26.8977 32.2797 26.7688C32.4264 26.6399 32.5406 26.4183 32.6221 26.1041C32.7118 25.7899 32.7566 25.3629 32.7566 24.8231C32.7566 24.2753 32.7118 23.8483 32.6221 23.5421C32.5406 23.2279 32.4264 23.0063 32.2797 22.8774C32.1329 22.7485 31.9658 22.6841 31.7783 22.6841C31.5908 22.6841 31.4237 22.7485 31.277 22.8774C31.1302 23.0063 31.012 23.2279 30.9223 23.5421C30.8408 23.8483 30.8001 24.2753 30.8001 24.8231C30.8001 25.3629 30.8408 25.7899 30.9223 26.1041C31.012 26.4183 31.1302 26.6399 31.277 26.7688C31.4237 26.8977 31.5908 26.9621 31.7783 26.9621Z"
                fill="#74788A"
            />
            <defs>
                <filter
                    id="filter0_i_21877_21103"
                    x="5.90527"
                    y="5.90576"
                    width="38.5423"
                    height="38.5426"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.353846" dy="0.353846" />
                    <feGaussianBlur stdDeviation="0.353846" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_21877_21103"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default NumberOfDivesBadge
