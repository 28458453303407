import { useTranslate } from 'react-polyglot'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import { useRemoveFromList } from 'hooks/useRemoveUser'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import { getProfileUserStatusColor } from 'utils/profileUserStatusColors'
import { userFilters } from 'utils/userFiltersHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import SearchAndFilter from 'components/SearchAndFilter'
import { DiverFilters } from 'components/diverList'

const FavoriteUsers = () => {
    const t = useTranslate()
    const removeUser = useRemoveFromList()

    const [favoriteList, setFavorites] = useState([])

    const { search } = useLocation()

    const {
        data: favorites,
        meta,
        isLoading: isLoadingFavorites,
        fetchData: fetchFavorites,
        loadMore,
    } = useFetchDataByQueryParams(
        ENTITIES.DIVERS,
        {
            favoriteUser: true,
        },
        true
    )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        userFilters,
        FILTERS.DIVERS
    )

    useEffect(() => {
        setFavorites([
            ...favoriteList,
            ...favorites.filter(
                (item) => !favoriteList.some((el) => el.id === item.id)
            ),
        ])
    }, [favorites])

    useEffect(() => {
        setFavorites([])
    }, [search])

    const handleLoadMore = () => {
        if (!isLoadingFavorites && loadMore) {
            fetchFavorites({
                page: meta.currentPage + 1,
            })
        }
    }

    const formatWorkerLanguages = (workerLanguages) =>
        workerLanguages
            ?.map(
                ({ language, languageProficiency }) =>
                    `${
                        language.name
                    } (${languageProficiency.name.toLowerCase()})`
            )
            .join(', ')

    const formatExperiences = (experiences) => {
        if (!experiences || !experiences.length) {
            return 'N\\A'
        }

        return experiences.map(
            ({ dives, days, divingModeName }) =>
                `${divingModeName} ${dives || days} ${
                    dives !== null ? t('general.dives') : t('general.days')
                }`
        )
    }

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchDiver"
                activeFilters={activeFilters}
                filters={userFilters}
            >
                <DiverFilters activeFilters={activeFilters} />
            </SearchAndFilter>

            {favorites.map((item, index) => {
                return (
                    <div key={index} className="-mb10">
                        <CardContainer
                            withAvatar
                            avatar={item.avatarPath}
                            title={item.fullName}
                            item={item}
                            cardContainerLink={`${ROUTES.DIVER_PROFILE}/${item?.profileHash}`}
                            statuses={[
                                {
                                    type: 'status',
                                    name: item.status,
                                    color: getProfileUserStatusColor(
                                        item?.status
                                    ),
                                },
                            ]}
                            identityVerified={!!item.identityVerified}
                            // TO DO: Add role when BE is ready
                            // subtitle={item.roleName}
                        >
                            <Info
                                label="form.label.country"
                                value={item.countryName}
                            />
                            <Info
                                label="form.label.languages"
                                value={formatWorkerLanguages(
                                    item.diverLanguages
                                )}
                            />
                            <Info
                                label="form.label.experiencedInModes"
                                value={formatExperiences(item.experiences)}
                                color="orange"
                                spaceBetween={true}
                            />
                            <InfoRow withButtons>
                                <Info
                                    label="form.label.totalYearsOfExperience"
                                    value={
                                        item?.totalExperienceYears
                                            ? item?.totalExperienceYears?.toString()
                                            : '0'
                                    }
                                    color="gray"
                                />
                                <Button
                                    type={BUTTON_TYPE.BUTTON}
                                    label="general.removeFromFavorites"
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.FAVORITES}
                                    iconColor={
                                        item.favoriteUser
                                            ? COLORS.SECONDARY
                                            : COLORS.WHITE
                                    }
                                    onClick={() =>
                                        removeUser(
                                            item,
                                            favorites,
                                            setFavorites,
                                            fetchFavorites
                                        )
                                    }
                                    buttonSize={BUTTON_SIZE.SMALL}
                                />
                            </InfoRow>
                        </CardContainer>
                    </div>
                )
            })}

            {favorites.length === 0 && !isLoadingFavorites && (
                <span className="a-captionsTextRegular a-lightText justify-center">
                    {t(
                        `general.${
                            Object.keys(activeFilters).length !== 0
                                ? 'noFilteredUsers'
                                : 'noFavoriteUsers'
                        }`
                    )}
                </span>
            )}

            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.SECONDARY}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {renderLoader(isLoadingFavorites, null, isLoadingFilters)}
        </div>
    )
}

export default FavoriteUsers
