import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import useQueryParams from 'hooks/useQueryParams'

import { DEFAULT_SEARCH_FILED, SEARCH_DELAY } from 'constants/constants'

import FilterButton from './FilterButton'
import Search from './table/Search'

const SearchAndFilter = ({
    activeFilters,
    filters,
    searchPlaceholder,
    children,
    showFilterButton,
    modifyFiltersOnRemove = (key, filters) => filters,
    searchKey = DEFAULT_SEARCH_FILED,
    showDefaultKey = true,
    hiddenFilterKeys = []
}) => {
    const { filterParams, replaceFilterParams } =
        useQueryParams()

    const showFilterBtn = (key) => {
        return (hiddenFilterKeys.indexOf(key) === -1 && (key !== searchKey || showDefaultKey));
    }

    const handleRemove = (key, index = null) => {
        // If filters are used with tabs, those values should be saved
        const otherParams = Object.keys(filterParams)
            .filter((key) => !Object.keys(activeFilters).includes(key))
            .reduce((acc, key) => {
                acc[key] = filterParams[key]
                return acc
            }, {})

        let newFilters = { ...activeFilters }
        if (index !== null) {
            const values = [...newFilters[key]]
            values.splice(index, 1)
            newFilters[key] = values
        } else {
            delete newFilters[key]
        }
        newFilters = { ...modifyFiltersOnRemove(key, newFilters) }
        replaceFilterParams({ ...otherParams, ...newFilters })
    }

    const renderFilterButtons = (key) => {
        if (Array.isArray(activeFilters[key])) {
            return (
                <Fragment>
                    {activeFilters[key].map((item, index) => (
                        showFilterBtn(index) &&
                        <FilterButton
                            key={index}
                            value={item}
                            filter={filters[key]}
                            handleRemove={() => handleRemove(key, index)}
                        />
                    ))}
                </Fragment>
            )
        }
        return (
            showFilterBtn(key) &&
            <FilterButton
                value={activeFilters[key]}
                filter={filters[key]}
                handleRemove={() => handleRemove(key)}
            />
        )
    }

    return (
        <div className="a-searchAndFilter -mb20">
            <div className="space-between">
                <Search
                    searchKey={searchKey}
                    searchPlaceholder={searchPlaceholder}
                />
                {showFilterButton && children}
            </div>
            <div className="a-searchAndFilter__filters">
                {Object.keys(activeFilters).map((key) => (
                    <Fragment key={key}>{renderFilterButtons(key)}</Fragment>
                ))}
            </div>
        </div>
    )
}

SearchAndFilter.propTypes = {
    activeFilters: PropTypes.object,
    filters: PropTypes.object,
    searchPlaceholder: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    showFilterButton: PropTypes.bool,
}

SearchAndFilter.defaultProps = {
    showFilterButton: true,
}

export default SearchAndFilter
