import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'

import { ACCESS_CONTROL_STATUSES, ALERT_TYPES } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import Loader from 'components/Loader'

const RequestAccess = ({
    dataAccessItem,
    canRequestAccess,
    status,
    profile,
    checkAccessControl,
    requestInProgress
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [isLoading, setIsLoading] = useState(false)

    const { icon, title, module } = dataAccessItem

    const accessRequest = async () => {
        try {
            setIsLoading(true)
            await createEntityService(ENTITIES.DATA_ACCESS_REQUEST, {
                module,
                target: { id: profile.id, entityType: ENTITIES.USER },
            })
            checkAccessControl()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="m-profileTabs">
            <div className="aligned-center a-tabName">
                <span className="a-bodyTextMedium ">{t(title)}</span>
            </div>
            {canRequestAccess && (
                <Fragment>
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.sendRequestForDataAccess')}
                    </p>
                    <div className="-mt30">
                        <Button
                            disabled={isLoading || requestInProgress}
                            label="button.sendRequest"
                            onClick={accessRequest}
                        />
                    </div>
                </Fragment>
            )}
            {status === ACCESS_CONTROL_STATUSES.NEW.id && (
                <p className="a-mediumText a-lightText -mt20">
                    {t('general.accessRequestPending')}
                </p>
            )}
            {status === ACCESS_CONTROL_STATUSES.REJECTED.id && (
                <p className="a-mediumText a-lightText -mt20">
                    {t('general.accessRequestRejected')}
                </p>
            )}
            {isLoading && <Loader />}
        </div>
    )
}

RequestAccess.propTypes = {
    dataAccessItem: PropTypes.object,
    canRequestAccess: PropTypes.bool,
    status: PropTypes.number,
    profile: PropTypes.object,
    checkAccessControl: PropTypes.func,
}

export default RequestAccess
