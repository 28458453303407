import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ROUTES from 'constants/routes'

import getCommonUserRoutes from './getCommonUserRoutes'

import { AuthLayout } from 'components/layouts'
import {
    Homepage,
    CreateProject,
    EditProject,
    ProjectDiveTeam,
} from 'screens/divingContractor'
import { MyDiveProjects } from '../../screens/diver/MyDiveProjects'
import { GalleryPage } from '../../screens/common/gallery'
import DiveRecordSummary from 'screens/diver/DiveRecordSummary'
import { ChatPage } from 'screens/common/chat/ChatPage'

const DivingContractorRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path={ROUTES.HOME} element={<Homepage />} />
                <Route
                    path={`${ROUTES.PROJECT}/:id${ROUTES.DIVE_TEAM}`}
                    element={<ProjectDiveTeam />}
                />
                <Route
                    path={ROUTES.MY_DIVE_PROJECTS}
                    element={<MyDiveProjects />}
                />
                <Route path={ROUTES.GALLERY} element={<GalleryPage />} />
                <Route path={ROUTES.CHAT} element={<ChatPage />} />
                <Route
                    path={`${ROUTES.PROJECT}${ROUTES.CREATE}`}
                    element={<CreateProject />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`}
                    element={<CreateProject />}
                />
                <Route
                    path={`${ROUTES.PROJECT}${ROUTES.EDIT}/:id`}
                    element={<EditProject />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}${ROUTES.EDIT}/:id`}
                    element={<EditProject />}
                />
                <Route
                    path={`${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORDS}/:diveRecordId`}
                    element={<DiveRecordSummary />}
                />
                <Route
                    path={`${ROUTES.DIVE_RECORDS}/:diveRecordId?`}
                    element={<DiveRecordSummary />}
                />
            </Route>
            {getCommonUserRoutes()}
        </Routes>
    )
}

export default DivingContractorRoutes
