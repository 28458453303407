import { Formik, Form } from 'formik'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import useFetchData from 'hooks/useFetchData'

import { BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import { CheckboxGroupField } from 'components/formFields'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const DiverPositions = ({ userData, setUserData, setStep }) => {
    const t = useTranslate()

    const { data: positions } = useFetchData(ENTITIES.DIVER_POSITION, {
        pagination: false,
    })

    const initialValues = {
        diverPositions: userData.diverPositions ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        diverPositions: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
    })

    const handleSubmit = (formData) => {
        setUserData((data) => ({ ...data, ...formData }))
        setStep((step) => step + 1)
    }

    if (!positions.length) return null

    return (
        <div className="m-form -register -mb20">
            <h1 className="-title -mb50">{t('general.selectRoles')}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <CheckboxGroupField
                            name="diverPositions"
                            showLabel={false}
                            defaultOptions={positions}
                            required
                            column={true}
                        />
                        <div className="-mt20">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.next"
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

DiverPositions.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
}

export default DiverPositions
