import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { editEntityService } from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import { UNITS_OF_MEASUREMENT } from 'constants/constants'

import Loader from 'components/Loader'
import Button from 'components/Button'
import { RadioGroupField } from 'components/formFields'

import useFetchDataById from 'hooks/useFetchDataById'
import FocusError from '../../../components/FocusError'

const UnitsOfMeasurement = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const { data: initialData } = useFetchDataById(
        ENTITIES.USER_SETTING,
        currentUser.userSetting.id
    )

    if (!initialData) return null

    const initialValues = {
        unitImperial: initialData ? Number(initialData.unitImperial) : null,
    }

    const validation = Yup.object({
        unitImperial: Yup.number()
            .typeError(t('form.error.required'))
            .required(),
    })

    const handleSubmit = async ({ unitImperial }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await editEntityService(
                ENTITIES.USER_SETTING,
                currentUser.userSetting.id,
                { unitImperial: Boolean(unitImperial) }
            )
            fetchCurrentUser()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
        showConfirmationModal({
            title: 'general.changesSaved',
            message: 'general.unitsOfMeasurementSavedDescription',
            handleConfirm: () => closeConfirmationModal(),
            handleCancel: () => closeConfirmationModal(),
            hideCancel: true,
        })
    }

    const handleCancel = () => {
        navigate(`${ROUTES.HOME}`)
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
        >
            {({ isSubmitting }) => (
                <Form className="_wr">
                    <FocusError />
                    <div className="-unitsOfMeasurement">
                        <div className="_w">
                            <span className="_12 a-bodyTextMedium">
                                {t('general.unitsOfMeasurement')}
                            </span>
                            <div className="_12 -mt20 radioButtonLeftAligned">
                                <RadioGroupField
                                    name="unitImperial"
                                    label="general.selectUnitsOfMeasurement"
                                    defaultOptions={UNITS_OF_MEASUREMENT}
                                    valueType={SELECT_VALUE_TYPE.STRING}
                                    required
                                />
                            </div>
                        </div>
                        <div className="_w space-between -buttons -pb20">
                            <div className="-mt40 -prevButton _12 _m6 _l4">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label="button.discard"
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    onClick={handleCancel}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="-mt40 -nextButton _12 _m6 ofs_l4 _l4">
                                <Button
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    label="button.saveChanges"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default UnitsOfMeasurement
