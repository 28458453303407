import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import { refetchProfile } from '../../utils/fetchHelper'

import ENTITIES from 'constants/entities'
import { MEDICAL_RECORD_TYPE } from '../../constants'

import MedicalRecordForm from '../MedicalRecordForm'

const EditMedicalRecord = ({
    data,
    open,
    setOpen,
    fetchMedicalRecords,
    refetchData,
    filteredData,
}) => {
    const handleSubmit = async (newData) => {
        try {
            await editEntityService(
                ENTITIES.MEDICAL_RECORD,
                data.id,
                newData,
                true
            )
            fetchMedicalRecords()

            const condition = [
                MEDICAL_RECORD_TYPE.ANNUAL.id,
                MEDICAL_RECORD_TYPE.BIENNIAL.id,
            ].includes(newData?.medicalRecordType.id)

            refetchData &&
                refetchProfile(
                    condition,
                    filteredData,
                    refetchData,
                    true,
                    false
                )
        } catch (error) {
            throw error
        }
    }

    return (
        <MedicalRecordForm
            title="general.editMedicalRecord"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
        />
    )
}

EditMedicalRecord.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
    fetchMedicalRecords: PropTypes.func,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
}

export default EditMedicalRecord
